import React from 'react';
import { PromiseState, ReduxFetch } from 'react-redux-fetch';
import { ApiConfig } from '../api';
import { DistributorDto } from '../../../../config/api/types';
import { useTranslation } from 'react-i18next';
import { createStyles, InputAdornment, Theme, WithStyles, withStyles } from '@material-ui/core';
import Field from '../../../../components/Form';
import { formatDistributorCode, validateDistributorCode } from '../../../organisation/domain';
import WhenFieldChanges from '../../../../components/Form/WhenFieldChanges';

type Props = WithStyles & {
  distributorUrl: string;
  isUpdate: boolean;
};
type ReduxFetchRenderProps = {
  distributorFetch: PromiseState<DistributorDto>;
};
const styles = createStyles((theme: Theme) => ({
  inputAdornment: {
    '& > *': { color: theme.palette.text.disabled },
  },
}));

const FundCodeField = ({ classes, distributorUrl, isUpdate }: Props) => {
  const { t } = useTranslation();

  const disabledField = (
    <Field label={t('form_fund_code')} type="text" name="code" disabled readOnly />
  );

  if (!distributorUrl || isUpdate) {
    return disabledField;
  }

  return (
    <ReduxFetch
      key={distributorUrl}
      config={[ApiConfig.getDistributor(distributorUrl)]}
      fetchOnMount
    >
      {({ distributorFetch }: ReduxFetchRenderProps) => {
        if (!distributorFetch || !distributorFetch.value) {
          return disabledField;
        }

        return (
          <>
            <Field
              label={t('form_fund_code')}
              type="text"
              name="code"
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" classes={{ root: classes.inputAdornment }}>
                    {distributorFetch.value.code}
                  </InputAdornment>
                ),
              }}
              helperText={t('form_fund_code_help')}
              format={formatDistributorCode}
              validate={(value: string) =>
                validateDistributorCode(formatDistributorCode(value))
                  ? t('form_fund_code_help')
                  : ''
              }
            />
            <WhenFieldChanges
              field="code"
              set="fullCode"
              to={(value) => {
                const code = distributorFetch.value && distributorFetch.value.code;
                return `${code}${value}`.toUpperCase();
              }}
            />
          </>
        );
      }}
    </ReduxFetch>
  );
};

export default withStyles(styles)(FundCodeField);
