import React, { FC, useEffect } from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import EditIcon from '@material-ui/icons/Edit';
import Button, { ButtonProps } from '../../../../components/Button';
import { useTranslation } from 'react-i18next';
import usePath from '../../../../config/routes/usePath';
import ROUTE_KEY from '../../../../config/routes/routeKeys';
import { Title } from '../../../../config/api/types';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import { getLink } from '../../../../helpers/hateoas';
import useAccessControlAllowMethods from '../../../../helpers/useAccessControlAllowMethods';

const useStyles = makeStyles((theme: Theme) => ({
  editButtonRoot: {
    // marginTop: theme.spacing(2),
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
}));

type Props = Partial<Omit<ButtonProps, 'title'>> & {
  title: Title;
  deniedComponent?: React.ReactElement<any>;
  pendingComponent?: React.ReactElement<any>;
  redirectPath?: string;
};

const EditButton: FC<Props> = ({
  title,
  deniedComponent,
  pendingComponent,
  redirectPath,
  ...btnProps
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [canUpdate, fetchCanUpdate, isPending] = useAccessControlAllowMethods(
    title._links.self.href,
    'PUT'
  );
  const path = usePath(ROUTE_KEY.MANAGE_TITLE, { id: getIdFromUrl(getLink(title, 'self') || '') });

  useEffect(() => {
    fetchCanUpdate();
  }, [fetchCanUpdate]);

  if (isPending) {
    return pendingComponent || null;
  }

  if (!path || !canUpdate) {
    return deniedComponent || null;
  }

  return (
    <Button
      className={classes.editButtonRoot}
      link={{ pathname: path, state: { path: redirectPath } }}
      size="small"
      {...btnProps}
    >
      {t('title_edit_btn')} <EditIcon className={classes.icon} fontSize="small" />
    </Button>
  );
};

export default EditButton;
