import React, { ComponentPropsWithoutRef, forwardRef } from 'react';
import { Page } from 'react-pdf';
import useFlipbookStyles from './useFlipbookStyles';

interface Props extends ComponentPropsWithoutRef<'div'> {
  pageNumber: number;
  width: number;
}

export const FlipbookPage = forwardRef<HTMLDivElement, Props>(({ pageNumber, width }, ref) => {
  const classes = useFlipbookStyles();

  return (
    <div ref={ref} className={classes.page}>
      <Page pageNumber={pageNumber} width={width} className={classes.page} />
      <div className={classes.pageShadow}></div>
    </div>
  );
});
