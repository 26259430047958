import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { TitleFull } from '../../../../config/api/types';
import { useTranslation } from 'react-i18next';
import { apiDate, isValidDate } from '../../../../helpers/date';
import { TypographyProps } from '@material-ui/core/Typography';

// const useStyles = makeStyles((theme: Theme) => ({
//   root: {},
// }));

type Props = {
  title: TitleFull;
};

const T = (props: TypographyProps) => <Typography variant="caption" component="span" {...props} />;

const TitleMetaData = ({ title }: Props) => {
  const { t } = useTranslation();
  // const classes = useStyles();

  return (
    <Grid container justify="space-between">
      <Grid item>
        <T>{t('title_created_at', { date: apiDate(title.createdAt).toDay() })}</T>
        {title.createdBy && <T gutterBottom>{t('title_created_by', { by: title.createdBy })}</T>}
        {title.isBibliographicalVerified && (
          <>
            <br />
            <T gutterBottom>{t('title_isBibliographicalVerified_long')}</T>
          </>
        )}
      </Grid>
      <Grid item>
        {title.changedAt && isValidDate(title.changedAt) && (
          <>
            <T>{t('title_changed_at', { date: apiDate(title.changedAt).toDay() })}</T>
            {title.changedBy && (
              <T gutterBottom>{t('title_changed_by', { by: title.changedBy })}</T>
            )}
          </>
        )}
      </Grid>
      <Grid item>
        {title.updatedAt && isValidDate(title.updatedAt) && (
          <T>{t('title_updated_at', { date: apiDate(title.updatedAt).toDay() })}</T>
        )}
      </Grid>
    </Grid>
  );
};

export default TitleMetaData;
