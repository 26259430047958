import React from 'react';
import { ShoppingCartLine } from '../../../../config/api/models/shop';
import { makeStyles, Theme, Grid, Typography, Tooltip } from '@material-ui/core';
import { getProductForm } from '../../../title/domain';
import formatEuro from '../../../../helpers/formatEuro';
import publisher from '../../../organisation/publisher';
import { IsbnLink } from '../../../title';
import { useTranslation } from 'react-i18next';
import CopyToClipboardButton from '../../../app/components/CopyToClipboardButton';

type Props = {
  orderLine: ShoppingCartLine;
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(1),
    },
    copyToClipboardButton: {
      marginLeft: theme.spacing(0.25),
    },
  }),
  { name: 'OrderLine' }
);

const OrderLine = ({ orderLine }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justify="space-between"
      className={classes.root}
    >
      <Grid item xs={2} sm={4}>
        <Typography noWrap>{orderLine._embedded.title.title}</Typography>
      </Grid>

      <Grid item style={{ flexGrow: 0, maxWidth: '19%', flexBasis: '19%' }}>
        <Typography>
          <IsbnLink orderLine={orderLine} />
          <CopyToClipboardButton
            textToCopy={orderLine._embedded.title.gtin13}
            tooltipText={t('copy_isbn')}
            className={classes.copyToClipboardButton}
          />
          {' | '}
          {getProductForm(orderLine._embedded.title)}
        </Typography>
      </Grid>

      <Grid item xs={2}>
        <publisher.components.Publisher
          publisher={orderLine._embedded.title.publisher}
          imprint={orderLine._embedded.title.imprint}
          showBothIfPossible={false}
        />
      </Grid>

      <Grid item xs={1}>
        <Tooltip placement="top-start" title={orderLine.reference || ''} enterDelay={500}>
          <Typography noWrap>{orderLine.reference}</Typography>
        </Tooltip>
      </Grid>

      <Grid
        item
        style={{ width: 168 }}
        container
        spacing={1}
        alignItems="center"
        justify="flex-end"
      >
        <Grid item style={{ textAlign: 'right' }}>
          {t('order_qty', { count: orderLine.quantity })}
        </Grid>
        <Grid item style={{ width: 80, textAlign: 'right' }}>
          {formatEuro(orderLine.unitPrice)}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderLine;
