import React, { FC } from 'react';
import useApiRoute from '../../../../config/api/useApiRoute';
import { useFetch } from '@react-redux-fetch/hooks';
import { getRetoursRequest } from '../api';
import { useSelector } from 'react-redux';
import { getRetours } from '../selectors';
import DelayedLoader from '../../../../components/DelayedLoader';
import { RetoursTable } from './RetoursTable';

export const RetourHistory: FC = () => {
  const route = useApiRoute('retours') || '';
  const pagedRetours = useSelector(getRetours);
  const defaultUrl = pagedRetours?._links.self.href || route;
  const [retourFetch, fetchRetours] = useFetch((url = defaultUrl) => getRetoursRequest(url), {
    eager: true,
  });

  return (
    <DelayedLoader data={pagedRetours}>
      {pagedRetours && (
        <RetoursTable
          pagedRetours={pagedRetours}
          isLoading={retourFetch?.pending}
          fetchRetours={fetchRetours}
        />
      )}
    </DelayedLoader>
  );
};
