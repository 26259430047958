import React, { FC, HTMLProps, MouseEvent } from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles, Theme } from '@material-ui/core';
import { IndicatorProps } from 'react-select';

type Props = IndicatorProps<any, any> & {
  clearValue: () => void;
  selectProps: { onClearValue?: (event: MouseEvent<HTMLDivElement>) => void };
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.grey['700'],
    display: 'flex',
    boxSizing: 'border-box',
    padding: 8,
    transition: 'color 150ms ease 0s',
    '&:hover': {
      color: theme.palette.common.black,
      cursor: 'pointer',
    },
  },
  icon: {
    fontSize: 18,
  },
}));

const ClearIndicator: FC<Props> = ({ clearValue, selectProps }) => {
  const classes = useStyles();

  const handleClick: HTMLProps<HTMLDivElement>['onMouseDown'] = (e) => {
    clearValue();
    if (selectProps.onClearValue) {
      selectProps.onClearValue(e);
    }
  };

  return (
    <div onMouseDown={handleClick} className={classes.button}>
      <ClearIcon className={classes.icon} />
    </div>
  );
};

export default ClearIndicator;
