import React, { useEffect, useState } from 'react';
import { Avatar, Card, CardContent, CardHeader, Theme, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import useDebounce from '../../../../helpers/useDebounce';
import { useSelector } from 'react-redux';
import { getShoppingCartAlert } from '../selectors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    right: theme.spacing(1),
    top: theme.toolbar.height + theme.spacing(1),
    zIndex: 9999,
  },
  card: {
    maxWidth: 340,
  },
  check: {
    backgroundColor: theme.palette.messages.success,
  },
  title: {
    fontWeight: 500,
  },
}));

const HIDE_NOTIFICATION_AFTER = 3000;

const ItemAddedNotification = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const titleInfo = useSelector(getShoppingCartAlert);
  const [debouncedTitleInfo, setDebouncedTitleInfo] = useDebounce(
    titleInfo,
    HIDE_NOTIFICATION_AFTER
  );
  const titleIsbn = titleInfo && titleInfo.isbn;

  useEffect(() => {
    if (titleIsbn) {
      setShow(true);
    }
  }, [titleIsbn, titleInfo?.timestamp]);

  const debouncedTitleIsbn = debouncedTitleInfo?.isbn;

  useEffect(() => {
    if (titleIsbn && titleIsbn === debouncedTitleIsbn) {
      setShow(false);
      setDebouncedTitleInfo(null);
    }
  }, [titleIsbn, debouncedTitleIsbn, setDebouncedTitleInfo]);

  if (!titleInfo || !show) {
    return null;
  }

  const message = titleInfo?.itemExistsInCart ? 'cart_update_qty_success' : 'cart_add_success';

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardHeader
          title={t(message, { count: titleInfo.qty })}
          avatar={
            <Avatar className={classes.check}>
              <Check />
            </Avatar>
          }
        />
        <CardContent>
          <Typography className={classes.title}>{titleInfo.title}</Typography>
          <Typography>{[titleInfo.isbn, titleInfo.productForm].join(' - ')}</Typography>
        </CardContent>
        {/*<CardActions>*/}
        {/*  <Button size="small" color="secondary" onClick={() => setShow(false)}>*/}
        {/*    {t('close')}*/}
        {/*  </Button>*/}
        {/*  <Button link={clientUrlToShoppingCart} size="small" color="primary">*/}
        {/*    {t('cart_order')}*/}
        {/*  </Button>*/}
        {/*</CardActions>*/}
      </Card>
    </div>
  );
};

export default ItemAddedNotification;
