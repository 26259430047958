import createTheme from './createTheme';
import './types';
import { Theme } from '@material-ui/core';
import palette from './palette';
import overrides from './overrides';

const theme: Theme = createTheme({
  palette,
  typography: {
    h1: {
      fontSize: '2rem',
    },
    h6: {
      fontSize: '1.2rem',
    },
    subtitle2: {
      fontSize: '1rem',
    },
  },
  overrides,
  props: {
    MuiButton: {
      variant: 'contained',
      color: 'primary',
    },
  },
  shape: {
    borderRadius: 0,
  },
});

export default theme;
