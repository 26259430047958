import React from 'react';
import { TableCell } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { TableCellProps } from '@material-ui/core/TableCell';

const styles = createStyles((theme: Theme) => ({
  true: {
    color: theme.palette.messages.success,
  },
  false: {
    color: theme.palette.messages.error,
  },
  cell: {
    paddingLeft: 0,
    paddingRight: theme.spacing(2),
  },
}));

type Props = TableCellProps &
  WithStyles & {
    data: boolean;
    className?: string;
    trueOnly?: boolean;
  };

const BooleanCell = ({ classes, data, trueOnly, className, ...other }: Props) => (
  <TableCell {...other} className={classNames(classes.cell, className)}>
    {data ? (
      <CheckIcon className={classes.true} />
    ) : trueOnly ? null : (
      <CloseIcon className={classes.false} />
    )}
  </TableCell>
);

export default withStyles(styles)(BooleanCell);
