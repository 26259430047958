import * as React from 'react';
import MUICheckBox, { CheckboxProps } from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FieldRenderProps } from 'react-final-form';
import { FormHelperText, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { toErrorString } from './toErrorString';

type Props = FieldRenderProps<any, any> & { label?: string };

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    // color: theme.palette.text.primary,
    // fontFamily: 'Roboto, Helvetica, Arial, sans-serif', // put in theme
    width: '100%',
    alignItems: 'flex-start',
  },
  root: {
    padding: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}));

export const BaseCheckBox = (props: CheckboxProps) => {
  const classes = useStyles();

  return (
    <MUICheckBox
      {...props}
      color="primary"
      classes={{
        root: classes.root,
        checked: classes.checked,
      }}
    />
  );
};

// todo split Props between Formcontrollabel & CheckBox by type
const CheckBox = ({
  input: { name, onChange, value, checked, ...restInput },
  meta,
  ...rest
}: Props) => {
  const classes = useStyles();
  const submitError = !meta.dirtySinceLastSubmit && meta.submitError;
  const err = toErrorString(meta.error || submitError);

  return (
    <>
      <FormControlLabel
        control={
          <MUICheckBox
            {...rest}
            checked={checked}
            onChange={onChange}
            value={value}
            color="primary"
            classes={{
              root: classes.root,
              checked: classes.checked,
            }}
          />
        }
        label=""
        name={name}
        className={classes.label}
        {...rest}
      />
      {err && <FormHelperText error>{err}</FormHelperText>}
    </>
  );
};

export default CheckBox;
