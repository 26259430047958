import React from 'react';
import ImageIcon from '@material-ui/icons/Image';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import isImage from '../helpers/isImage';

type Props = {
  fileName: string;
  fontSize?: SvgIconProps['fontSize'];
};

const FileIcon = ({ fileName, fontSize = 'small' }: Props) =>
  isImage(fileName) ? (
    <ImageIcon fontSize={fontSize} />
  ) : (
    <InsertDriveFileIcon fontSize={fontSize} />
  );

export default FileIcon;
