import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Typography } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '../../../../../components/Button';
import { useState } from 'react';
import { Organisation } from '../../../../../config/api/types';

type Props = {
  onSubmit: () => void;
  disabled: boolean;
  organisation: Organisation;
};

const SubmitConfirmationButton = ({ onSubmit, disabled, organisation }: Props) => {
  const { t } = useTranslation();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const toggleModal = () => {
    setOpenConfirmationModal(!openConfirmationModal);
  };

  return (
    <>
      <Button disabled={disabled} onClick={toggleModal}>
        {t('form_action_save')}
      </Button>

      <Dialog open={openConfirmationModal} onClose={toggleModal}>
        <DialogTitle id="alert-dialog-title">{organisation.name}</DialogTitle>
        <DialogContent>
          <Typography>
            {t('organisation_edit_membership_confirm_title', {
              organisation: organisation.name,
            })}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleModal} variant="outlined" color="default">
            {t('form_confirm_disagree')}
          </Button>
          <Button
            onClick={() => {
              onSubmit();
              toggleModal();
            }}
            color="primary"
            autoFocus
            type="submit"
          >
            {t('form_confirm_agree')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SubmitConfirmationButton;
