import { Theme, Typography } from '@material-ui/core';
import React from 'react';
import { TypographyProps } from '@material-ui/core/Typography';
import { styled } from '@material-ui/styles';

const FieldsetTitle = ({ children, ...other }: TypographyProps) => (
  <Typography variant="h3" {...other}>
    {children}
  </Typography>
);

type Props = {
  theme: Theme;
};

export default styled(FieldsetTitle)(({ theme }: Props) => ({
  padding: `${theme.spacing(1)}px 0px`,
  margin: `0px ${theme.spacing(1)}px`,
  borderBottom: `3px solid ${theme.palette.common.white}`,
  textTransform: 'uppercase',
}));
