import { Box, Theme, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TextLink from '../../../../../components/TextLink';
import { Title as TitleType } from '../../../../../config/api/types';
import usePrizeIconMap from '../../../../../helpers/usePrizeIconMap';
import CopyToClipboardButton from '../../../../app/components/CopyToClipboardButton';
import publisher from '../../../../organisation/publisher';
import Collection from '../../../detail/components/Collection';
import { getDate, getProductForm } from '../../../domain';
import { TitleContributors } from './TitleContributors';

const useStyles = makeStyles((theme: Theme) => ({
  infoTitle: {
    lineHeight: 1.2,
  },
  infoSubTitle: {
    marginTop: '-0.3rem',
    minHeight: '0.5rem',
  },
  collection: {
    lineHeight: 2,
    // marginTop: '-0.2rem',
  },
  fixMargin: {
    lineHeight: 1.2,
    margin: '4px 0',
  },
  increasedLineHeight: {
    lineHeight: 2,
  },
  copyToClipboardButton: {
    marginLeft: theme.spacing(0.25),
  },
}));

type Props = {
  title: TitleType;
  detailLink: string;
  changeParams: (values: Record<string, string | number>) => void;
};

const TitleInfo = ({ title, detailLink, changeParams }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const iconMap = usePrizeIconMap();

  const literaturePrizeCode =
    title.titleLiteraturePrizes?.find(({ code }) => code === '01')?.code ||
    title.titleLiteraturePrizes?.find(({ code }) => code === '02')?.code ||
    title.titleLiteraturePrizes?.find(({ code }) => code === '06')?.code ||
    title.titleLiteraturePrizes?.[0].code;

  const showSupplyDateInsteadOfFirstPublished =
    (title.availability.code === '10' || title.availability.code === '12') &&
    getDate(title, 'availability.supplyDate').isInFuture();

  return (
    <>
      <div>
        <Box display="flex" alignItems="flex-end">
          {literaturePrizeCode && (
            <Tooltip title={t('title_prize_tooltip')} arrow placement="top">
              <div>{iconMap[literaturePrizeCode]}</div>
            </Tooltip>
          )}
          <Typography variant="h6" component="h2" className={classes.infoTitle}>
            <TextLink to={detailLink}>{title.title}</TextLink>
          </Typography>
          <CopyToClipboardButton
            textToCopy={title.title}
            tooltipText={t('copy_title')}
            className={classes.copyToClipboardButton}
          />
        </Box>
        {title.subtitle && (
          <Typography
            variant="subtitle2"
            component="h3"
            className={clx(classes.fixMargin, classes.infoSubTitle)}
          >
            {title.subtitle}
          </Typography>
        )}
        <Collection
          collection={title.collection}
          className={classes.collection}
          variant="body2"
          changeParams={changeParams}
        />
        {title.contributors.length > 0 && (
          <TitleContributors
            title={title}
            className={classes.increasedLineHeight}
            changeParams={changeParams}
          />
        )}
      </div>

      <div>
        <publisher.components.Publisher
          publisher={title.publisher}
          imprint={title.imprint}
          typographyProps={{
            variant: 'body2',
            className: classes.fixMargin,
          }}
          withInfoModal
        />
        <Typography variant="body2" className={classes.fixMargin}>
          {`${getProductForm(title)} ${
            !!title.editionTypes?.length
              ? `(${title.editionTypes
                  .slice(0, 3) //Show max 2 productforms. Replace the forth with '...'
                  .reduce(
                    (prev, next, index) =>
                      prev === '' ? next.name : `${prev}, ${index > 1 ? '...' : next.name}`,
                    ''
                  )})`
              : ''
          }`}
          {!!title?.languages?.length &&
            (title.languages.length > 1
              ? ` - ${t('title_more_languages')}`
              : ` - ${title.languages![0].label}`)}
          {showSupplyDateInsteadOfFirstPublished ? (
            <>
              {` - `}
              <span style={{ whiteSpace: 'nowrap' }}>
                {getDate(title, 'availability.supplyDate').format()}
              </span>
            </>
          ) : (
            title.isbnFirstPublished && (
              <>
                {` - `}
                <span style={{ whiteSpace: 'nowrap' }}>{getDate(title).format()}</span>
              </>
            )
          )}
          {title.gtin13 && (
            <>
              {` - ${title.gtin13}`}
              <CopyToClipboardButton
                textToCopy={title.gtin13}
                tooltipText={t('copy_isbn')}
                className={classes.copyToClipboardButton}
              />
            </>
          )}
        </Typography>
      </div>
    </>
  );
};

export default TitleInfo;
