import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShoppingCartLine } from '../../../../config/api/models/shop';
import { makeStyles, Theme, Box, Grid, Tooltip, Typography } from '@material-ui/core';
import { getProductForm, getTitleStatus } from '../../../title/domain';
import formatEuro from '../../../../helpers/formatEuro';
import { IsbnLink } from '../../../title';
import { TitleFull } from '../../../../config/api/types';
import AddToCart from '../../../title/cart/components/AddToCart';
import { Form } from 'react-final-form';
import AutoSave from '../../../../components/Form/AutoSave';
import OrderStatusSelect from '../OrderStatusSelect';
import distributor from '../../../organisation/distributor';
import { useFetch } from '@react-redux-fetch/hooks';
import { patchOrderLineRequest } from '../../api';
import { getLink } from '../../../../helpers/hateoas';
import StatusLabel from '../../../../components/StatusLabel';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import CopyToClipboardButton from '../../../app/components/CopyToClipboardButton';

type Props = {
  orderLine: ShoppingCartLine;
  orderRef: string;
  onAddToCart?: () => void;
  isCbMember: boolean;
};

type FormValues = {
  state: string;
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: `0 ${theme.spacing(1)}px`,
    },
    copyToClipboardButton: {
      marginLeft: theme.spacing(0.25),
    },
  }),
  { name: 'LibraryOrderLine' }
);

const LibraryOrderLine = ({ orderLine, orderRef, onAddToCart, isCbMember }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [, patchOrderLine] = useFetch(patchOrderLineRequest);

  const orderLineUrl = getLink(orderLine, 'changeOrderLineStatus');
  const orderLineId = orderLineUrl ? getIdFromUrl(orderLineUrl) : '';

  const onSubmit = (values: FormValues) => {
    if (orderLineUrl && values.state && values.state !== orderLine.stateBookstore) {
      patchOrderLine(orderLineUrl, values, orderRef, 'stateBookstore');
    }
  };

  const title = ({
    ...orderLine._embedded.title,
    fund: orderLine._embedded.title._embedded.fund,
  } as any) as TitleFull;

  return (
    <Grid
      container
      spacing={1}
      alignItems="center"
      justify="space-between"
      className={classes.root}
    >
      <Grid item xs={2} wrap="nowrap" container>
        <Box mr={1}>
          <StatusLabel status={getTitleStatus(orderLine._embedded.title)} />
        </Box>
        <Tooltip placement="top-start" title={orderLine._embedded.title.title} enterDelay={500}>
          <Typography noWrap>{orderLine._embedded.title.title}</Typography>
        </Tooltip>
      </Grid>

      <Grid item style={{ flexGrow: 0, maxWidth: '19%', flexBasis: '19%' }}>
        <Typography>
          <IsbnLink orderLine={orderLine} />
          <CopyToClipboardButton
            textToCopy={orderLine._embedded.title.gtin13}
            tooltipText={t('copy_isbn')}
            className={classes.copyToClipboardButton}
          />
          {' | '}
          {getProductForm(title)}
        </Typography>
      </Grid>

      <Grid item xs={2}>
        <distributor.components.Distributor
          fund={orderLine._embedded.title._embedded.fund}
          isCbMember={isCbMember}
        />
      </Grid>

      <Grid item xs={1}>
        <Tooltip placement="top-start" title={orderLine.reference} enterDelay={500}>
          <Typography noWrap>{orderLine.reference}</Typography>
        </Tooltip>
      </Grid>

      <Grid
        item
        style={{ width: 160 }}
        container
        spacing={1}
        alignItems="center"
        justify="flex-end"
      >
        <Grid item style={{ textAlign: 'right' }}>
          {t('order_qty', { count: orderLine.quantity })}
        </Grid>
        <Grid item style={{ width: 110, textAlign: 'right' }}>
          {formatEuro(orderLine.unitPrice)}
          {title.discountCode && (
            <Tooltip title={title.discountCode.label} arrow>
              <Typography display="inline" style={{ marginLeft: 4, position: 'relative', top: -1 }}>
                ({title.discountCode.code})
              </Typography>
            </Tooltip>
          )}
        </Grid>
      </Grid>

      <Grid item style={{ width: 210 }}>
        {title.isOrderableByBookstore && (
          <AddToCart
            title={title}
            initialValue={orderLine.quantity}
            orderLineRef={orderLineId}
            onAddToCart={onAddToCart}
          />
        )}
      </Grid>

      <Grid item style={{ width: 150 }}>
        <Form initialValues={{ state: orderLine.stateBookstore } as FormValues} onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <>
              <OrderStatusSelect />
              <AutoSave save={handleSubmit} />
            </>
          )}
        </Form>
      </Grid>
    </Grid>
  );
};

export default LibraryOrderLine;
