import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { Actions } from '../actions';
import Login from '../components/Login';
import { loginRequest } from '../api';
import { LoginDTO } from '../../../config/api/types';
import { useFetch } from '@react-redux-fetch/hooks';
import { getAuthResponseToken } from '../selectors';

const defaultFormValues: LoginDTO =
  process.env.NODE_ENV === 'development'
    ? {
        username: process.env.REACT_APP_USER || '',
        password: process.env.REACT_APP_PW || '',
      }
    : {
        username: '',
        password: '',
      };

const LoginForm: FC = () => {
  const [authFetch, authenticate] = useFetch(loginRequest);
  const authResponseToken = useSelector(getAuthResponseToken);
  const dispatch = useDispatch();

  useEffect(() => {
    if (authResponseToken) {
      dispatch(Actions.login(authResponseToken));
    }
  }, [authResponseToken, dispatch]);

  return (
    <Form
      onSubmit={(values) => {
        authenticate(values);
      }}
      initialValues={defaultFormValues}
    >
      {({ handleSubmit, values }) => {
        return (
          <Login
            onSubmit={handleSubmit}
            disabled={authFetch?.pending}
            error={authFetch?.rejected && authFetch.reason.message}
            errorCode={authFetch?.rejected && authFetch.reason.cause.code}
            values={values}
          />
        );
      }}
    </Form>
  );
};

export default LoginForm;
