import * as React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles((theme: Theme) => ({
  iconWrapper: {
    display: 'flex',
    margin: '6px 0',
    '& + span': {
      display: 'none',
    },
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

export function DropdownIndicator(props: any) {
  const classes = useStyles();
  return (
    <div className={classes.iconWrapper}>
      <ArrowDropDown className={classes.icon} />
    </div>
  );
}
