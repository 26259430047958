import { Paper, makeStyles } from '@material-ui/core';
import React from 'react';
import { SelectOption } from './types';
import clsx from 'clsx';
import { MenuProps } from 'react-select';

type Props = MenuProps<SelectOption, any> & {
  isLoading?: boolean;
};

const useStyles = makeStyles(() => ({
  menuRoot: {
    minWidth: '100%',
    width: 'fit-content',
  },
}));

export function Menu({ isLoading, hasValue, options, selectProps, innerProps, children }: Props) {
  const classes = useStyles();

  return (
    <Paper square className={clsx(selectProps.classes.paper, classes.menuRoot)} {...innerProps}>
      {children}
    </Paper>
  );
}
