import React, { FC } from 'react';
import SectionWrapper from '../SectionWrapper';
import FieldsetTitle from '../FieldsetTitle';
import Fieldset from '../Fieldset';
import { useTranslation } from 'react-i18next';
import AgeRangeField from '../../containers/fields/AgeRangeField';
import { Grid } from '@material-ui/core';
import AviLevel from '../../containers/fields/AviLevel';
import useApiRoute from '../../../../../../config/api/useApiRoute';
import InfoTooltip from '../../../../../../components/InfoTooltip';

type Props = {
  isBibliographicalVerified?: boolean;
};

const TargetAudience: FC<Props> = ({ isBibliographicalVerified = false }) => {
  const { t } = useTranslation();
  const agesApiLink = useApiRoute('titleAges') || '';
  const aviLevelsNewApiLink = useApiRoute('titleAviLevelsNew') || '';
  const aviLevelsOldApiLink = useApiRoute('titleAviLevelsOld') || '';

  return (
    <SectionWrapper id="targetAudience">
      <FieldsetTitle>{t('title_section_targetAudience')}</FieldsetTitle>
      <Fieldset>
        <AgeRangeField url={agesApiLink} />
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <AviLevel
              url={aviLevelsNewApiLink}
              fieldName="aviLevelsNew"
              label={
                <>
                  {t('title_aviLevelsNew')}
                  {isBibliographicalVerified && (
                    <InfoTooltip
                      title={t('title_isBibliographicalVerified_disabled_field')}
                      inline
                    />
                  )}
                </>
              }
              disabled={isBibliographicalVerified}
            />
          </Grid>
          <Grid item xs={6}>
            <AviLevel
              url={aviLevelsOldApiLink}
              fieldName="aviLevelsOld"
              label={
                <>
                  {t('title_aviLevelsOld')}
                  {isBibliographicalVerified && (
                    <InfoTooltip
                      title={t('title_isBibliographicalVerified_disabled_field')}
                      inline
                    />
                  )}
                </>
              }
              disabled={isBibliographicalVerified}
            />
          </Grid>
        </Grid>
      </Fieldset>
    </SectionWrapper>
  );
};

export default React.memo(TargetAudience);
