import React from 'react';
import { ListItemIcon, ListItemText, MenuItem, LinkProps } from '@material-ui/core';
import { useLocation } from 'react-router';
import { HashLink as Link } from 'react-router-hash-link';
import Score from './Score';
import { SectionTitle } from '../domain';

type Props = {
  score: number;
  title: string;
  hasErrors?: boolean;
  section: SectionTitle;
  activeSection: SectionTitle;
  onClick: (section: SectionTitle) => void;
};

const SectionNavItem = ({ title, score, hasErrors, section, activeSection, onClick }: Props) => {
  const { state } = useLocation();
  const active = section === activeSection;

  return (
    <MenuItem
      selected={active}
      component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>((props, ref) => (
        <Link
          smooth
          to={{ hash: section, state }}
          {...props}
          onClick={() => {
            onClick(section);
          }}
          ref={undefined}
        />
      ))}
    >
      <ListItemIcon style={{ minWidth: 40 }}>
        <Score score={score} hasErrors={hasErrors} />
      </ListItemIcon>
      <ListItemText>{title}</ListItemText>
    </MenuItem>
  );
};

export default React.memo(SectionNavItem);
