import React, { FC, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Multiline from '../../../../components/Multiline';
import { styled } from '@material-ui/styles';
import Editor from 'react-simple-code-editor';
import Prism, { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import Button from '../../../../components/Button';
import { useFetch } from '@react-redux-fetch/hooks';
import { postCheckOrderabilityRequest } from '../../api';
import _ from 'lodash';
import { Prompt } from 'react-router-dom';
import usePath from '../../../../config/routes/usePath';
import ROUTE_KEY from '../../../../config/routes/routeKeys';

type Props = {
  isValidationPageRendered: boolean;
  onNext: () => void;
};

type Error = {
  line: number;
  message: string;
};

type Duplicate = Error;

const Example = styled('p')({
  marginTop: '1rem',
});

const MaxError = styled('span')({
  color: 'red',
  fontWeight: 600,
});

const QuickOrdersEditor: FC<Props> = ({ isValidationPageRendered, onNext }) => {
  const { t } = useTranslation();
  const [codeValue, setCodeValue] = useState('');
  const [lineCount, setLineCount] = useState<number>(1);
  const [errorLines, setErrorLines] = useState<number[]>([]);
  const [errorIsbn, setErrorIsbn] = useState<number[]>([]);
  const [errorAmount, setErrorAmount] = useState<Error[]>([]);
  const [errorReference, setErrorReference] = useState<Error[]>([]);
  const [duplicates, setDuplicates] = useState<Duplicate[]>([]);
  const [split, setSplit] = useState<string[]>([]);
  const [subSplit, setSubSplit] = useState<string[][]>([]);
  const [, postCheckOrderAbility] = useFetch(postCheckOrderabilityRequest);
  const shoppingCartPath = usePath(ROUTE_KEY.SHOPPING_CART);

  useEffect(() => {
    // Reset errors after codeValue change
    setErrorLines([]);
    setErrorIsbn([]);
    setErrorAmount([]);
    setErrorReference([]);
    setDuplicates([]);

    const split = codeValue.split(/\r\n|\r|\n/);
    setSplit(split);

    const subSplit = split
      .filter((value) => value !== '')
      .map((value) => {
        const firstPart = value.match(/(.[^,;\s]*[,;\s]){1,2}/gm)?.[0] ?? '';
        const secondPart = value.replace(firstPart, '');
        return [...firstPart.split(/[,;\s]/), secondPart].filter(Boolean);
      });

    setSubSplit(subSplit);

    subSplit.forEach((item, index) => {
      // Check if ISBN is valid
      if (item[0].length !== 13 || !/^\d+$/.test(item[0])) {
        setErrorIsbn((errorIsbn: any) => [...errorIsbn, index + 1]);
      }

      // Check if amount is a decimal
      if (!/^\d+$/.test(item[1])) {
        setErrorAmount((errorAmount) => [
          ...errorAmount,
          {
            line: index + 1,
            message: 'quick_order_error_amount',
          },
        ]);
      } else {
        // Check if amount is a greater than 0
        if (!/^[1-9][0-9]*$/.test(item[1])) {
          setErrorAmount((errorAmount) => [
            ...errorAmount,
            { line: index + 1, message: 'quick_order_error_low' },
          ]);
        } else if (!/^\d{0,4}$/.test(item[1])) {
          // Check if amount is a smaller than 9999
          setErrorAmount((errorAmount) => [
            ...errorAmount,
            {
              line: index + 1,
              message: 'quick_order_error_high',
            },
          ]);
        }
      }

      // Check if Line reference is valid
      if (item[2]?.length > 255) {
        setErrorReference((errorReference) => [
          ...errorReference,
          {
            line: index + 1,
            message: 'quick_order_error_reference_max',
          },
        ]);
        // eslint-disable-next-line
      } else if (!/^[a-zA-Z0-9_\-\+\* \/\\\:#€$âéèüëö¨?&;.,\?!\'}{)(\]\[]*$/.test(item[2])) {
        setErrorReference((errorReference) => [
          ...errorReference,
          { line: index + 1, message: 'quick_order_error_reference_special_characters' },
        ]);
      }

      //Check for duplicate isbn
      if (
        subSplit.filter(
          (subSplitItem) => subSplitItem[0] === item[0] && item[2] === subSplitItem[2]
        ).length > 1
      ) {
        setDuplicates((duplicate) => [
          ...duplicate,
          { line: index + 1, message: 'quick_order_table_duplicate_isbn' },
        ]);
      }
    });
  }, [codeValue]);

  useEffect(() => {
    setErrorLines(
      _.union(
        errorIsbn,
        errorAmount.map(({ line }) => line),
        errorReference.map(({ line }) => line)
      )
    );
    setLineCount(split.length);
  }, [errorIsbn, errorAmount, errorReference, split]);

  const checkOrderabilityUrl = '/shopping_carts/bulk-validate';

  const hightlightWithLineNumbers = (input: any, language: any) =>
    highlight(input, Prism.languages.plain, language)
      .split('\n')
      .map((line, i) => {
        return `${
          codeValue.length !== 0 && errorIsbn.includes(i + 1)
            ? `<span class="editor-error-tooltip">${t('quick_order_error_isbn')}</span>`
            : codeValue.length !== 0 && errorAmount.filter(({ line }) => line === i + 1).length > 0
            ? `<span class="editor-error-tooltip">${t(
                errorAmount.find(({ line }) => line === i + 1)?.message
              )}</span>`
            : codeValue.length !== 0 &&
              errorReference.filter(({ line }) => line === i + 1).length > 0
            ? `<span class="editor-error-tooltip">${t(
                errorReference.find(({ line }) => line === i + 1)?.message
              )}</span>`
            : codeValue.length !== 0 && duplicates.filter(({ line }) => line === i + 1).length > 0
            ? `<span class="editor-error-tooltip editor-duplicate-tooltip">${t(
                duplicates.find(({ line }) => line === i + 1)?.message
              )}</span>`
            : ''
        }<span id='editorLineNumber-${i + 1}' class='editorLineNumber ${
          codeValue.length !== 0 && errorLines.includes(i + 1) ? 'editorLineNumberError' : ''
        }'>${i + 1}</span><span class='editorLineValue ${
          codeValue.length !== 0 && errorLines.includes(i + 1) && 'editorLineValueError'
        }'>${line}</span>`;
      })
      .join('\n');

  const checkOrderAbility = () => {
    if (errorLines.length > 0 || subSplit.length <= 0) return;
    postCheckOrderAbility(checkOrderabilityUrl, {
      titles: subSplit
        .filter((line) => line.length >= 2)
        .map((line) => {
          return {
            gtin13: line[0],
            quantity: parseInt(line[1]),
            reference: line[2],
          };
        }),
    });
    onNext();
  };

  return (
    <>
      <Multiline>{t('quick_order_descritopn')}</Multiline>
      <Example>{t('quick_order_example')}</Example>

      <Editor
        value={codeValue}
        onValueChange={(code) => setCodeValue(code)}
        highlight={(code) => hightlightWithLineNumbers(code, languages.js)}
        padding={10}
        textareaId="codeArea"
        className="editor"
        style={{
          fontFamily: '"Roboto", sans-serif',
          fontSize: 16,
          outline: 0,
          minHeight: '400px',
          counterReset: 'line',
          border: lineCount > 100 ? '1px solid red' : '1px solid #ededed',
          overflow: 'visible',
        }}
      />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '15px',
          alignItems: 'flex-start',
        }}
      >
        {errorLines && errorLines.length !== 0 ? (
          <MaxError>{errorLines.length + ' lijnen met fouten'}</MaxError>
        ) : lineCount > 100 ? (
          <MaxError>{t('quick_order_max_error')}</MaxError>
        ) : (
          <span>{t('quick_order_max')}</span>
        )}
        <Button disabled={errorLines.length > 0 || lineCount > 100} onClick={checkOrderAbility}>
          {t('quick_order_check_order')}
        </Button>
      </Box>
      <Prompt
        when={codeValue.length > 0}
        message={(location) => {
          if (location.pathname === shoppingCartPath && isValidationPageRendered) return true;
          return t('quick_order_prompt');
        }}
      />
    </>
  );
};

export default QuickOrdersEditor;
