import React, { useEffect, useState } from 'react';
import { FormApi } from 'final-form';
import { useTranslation } from 'react-i18next';
import Table from '../../../../components/Table';
import { CellType, DataType } from '../../../../components/Table/types';
import { Box, Tooltip, Typography } from '@material-ui/core';
import moment from 'moment';
import { IsbnLink } from '../../../title';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import formatEuro from '../../../../helpers/formatEuro';
import OrdersHistoryForm from '../ordersHistory/OrdersHistoryForm';
import organisation from '../../../organisation';
import TextLink from '../../../../components/TextLink';
import { getLink } from '../../../../helpers/hateoas';
import { useOrderHistoryLinesFetch } from './useOrderHistoryLinesFetch';
import OrganisationDialog from '../../../organisation/containers/OrganisationDialog';
import DelayedLoader from '../../../../components/DelayedLoader';

const OrdersHistoryLines = () => {
  const { t } = useTranslation();
  const {
    orderPath,
    pagedOrderLines,
    orderLinesFetch,
    fetchOrderLines,
    cancelFetch,
  } = useOrderHistoryLinesFetch({ processed: '' }, 'ordersHistoryLines');

  const [isRefetching, setIsRefetching] = useState(false);

  const headers = [
    { id: 'ordered_at', label: t('table_headers_ordered_at'), width: '100px' },
    { id: 'order_ref', label: t('table_headers_order_reference') },
    { id: 'line_ref', label: t('table_headers_line_reference') },
    { id: 'isbn', label: t('table_headers_isbn'), width: '120px' },
    { id: 'title', label: t('table_headers_title'), width: '240px' },
    { id: 'distributors', label: t('table_headers_distributor'), width: '180px' },
    { id: 'num_pieces', label: t('table_headers_num_pieces'), width: '80px', align: 'center' },
    { id: 'price', label: t('table_headers_price'), width: '80px', align: 'right' },
    { id: 'price_total', label: t('table_headers_price_total'), width: '80px', align: 'right' },
  ];

  const data: DataType | null = pagedOrderLines
    ? {
        resource: 'orderlines',
        rows: pagedOrderLines._embedded.items.map((orderLine) => {
          return {
            id: orderLine.id,
            cells: [
              {
                type: CellType.Text,
                data: moment(orderLine.orderedAt).format(`DD/MM/YYYY`),
              },
              {
                type: CellType.Text,
                data: (
                  <TextLink
                    underline
                    to={{
                      pathname: orderPath.replace(':id', getIdFromUrl(getLink(orderLine, 'order'))),
                      state: { from: window.location.pathname },
                    }}
                  >
                    {orderLine.orderReference.length > 20 ? (
                      <Tooltip title={orderLine.orderReference}>
                        <Typography noWrap>{orderLine.orderReference}</Typography>
                      </Tooltip>
                    ) : (
                      orderLine.orderReference
                    )}
                  </TextLink>
                ),
              },
              {
                type: CellType.Text,
                data:
                  orderLine.reference?.length > 20 ? (
                    <Tooltip title={orderLine.orderReference}>
                      <Typography noWrap>{orderLine.orderReference}</Typography>
                    </Tooltip>
                  ) : (
                    orderLine.reference
                  ),
              },
              {
                type: CellType.Text,
                data: (
                  <IsbnLink
                    orderLine={{
                      ...orderLine,
                      titleId: getIdFromUrl(orderLine._embedded.title._links.self.href),
                    }}
                  />
                ),
              },
              {
                type: CellType.Text,
                data: (
                  <Box width={240}>
                    <Tooltip
                      placement="top-start"
                      title={orderLine._embedded.title.title}
                      enterDelay={500}
                    >
                      <Typography noWrap>{orderLine._embedded.title.title}</Typography>
                    </Tooltip>
                  </Box>
                ),
              },
              {
                type: CellType.Text,
                data: (
                  <Box width={180}>
                    <OrganisationDialog
                      organisationUrl={orderLine._embedded.distributor._links.organisation.href}
                    >
                      {orderLine._embedded.distributor._embedded.organisation.name}{' '}
                      {orderLine._embedded.distributor.isCbMember ? t('cb') : ''}
                    </OrganisationDialog>
                  </Box>
                ),
              },
              {
                type: CellType.Text,
                data: (
                  <Box textAlign="center">
                    <Typography>{orderLine.quantity}</Typography>
                  </Box>
                ),
              },
              {
                type: CellType.Text,
                data: (
                  <Box textAlign="right">
                    <Typography>{formatEuro(orderLine.unitPrice)}</Typography>
                  </Box>
                ),
              },
              {
                type: CellType.Text,
                data: (
                  <Box textAlign="right">
                    <Typography>{formatEuro(orderLine.total)}</Typography>
                  </Box>
                ),
              },
            ],
          };
        }),
      }
    : null;

  const refetch = (url: string) => {
    setIsRefetching(true);
    cancelFetch();
    fetchOrderLines(url);
  };
  useEffect(() => {
    if (orderLinesFetch?.fulfilled && !orderLinesFetch?.pending) {
      setIsRefetching(false);
    }
  }, [orderLinesFetch]);

  return (
    <DelayedLoader data={data}>
      {data != null && (
        <>
          <OrdersHistoryForm
            fetchOrders={refetch}
            url={pagedOrderLines?._links.self.href ?? ''}
            numResults={pagedOrderLines?.total}
            exportButtonLabel={t('orderlines_multiple_export')}
          >
            {({ form, values }: { form: FormApi; values: Record<string, string> }) => (
              <organisation.components.DistributorsSelectField
                label={t('table_headers_distributor')}
                url={'/order/distributors'}
                change={form.change}
                distributorId={values.distributor_id}
                onClearValue={form.submit}
              />
            )}
          </OrdersHistoryForm>
          <Table
            data={data}
            headers={headers}
            pagedResource={pagedOrderLines}
            loading={isRefetching}
            dispatchFn={refetch}
          />
        </>
      )}
    </DelayedLoader>
  );
};

export default OrdersHistoryLines;
