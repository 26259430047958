import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withRouter, RouteComponentProps, RouteChildrenProps } from 'react-router';
import Divider from '@material-ui/core/Divider/Divider';
import GlobalHeader from '../../../../components/Header';
import replace from 'ramda/es/replace';
import { ClientRouteContext } from '../../../../config/routes/ClientRouteContext';
import ROUTE_KEY from '../../../../config/routes/routeKeys';
import RoleSwitch from './RoleSwitch';
import { Organisation } from '../../../../config/api/types';
import { tabHeaderStyles } from '../../../../components/tabHeaderStyles';

type MatchType = RouteChildrenProps['match'] & { params: { id: string } };

type Props = RouteComponentProps &
  WithStyles<typeof tabHeaderStyles> & {
    match: MatchType;
    title: string;
    tabsToShow: string[];
    subHeader?: React.ReactChild | React.ReactChildren;
    organisation?: Organisation;
  };

const getResourceId = (match: MatchType) => match && match.params && match.params.id;

const Header = ({ classes, title, history, match, tabsToShow, subHeader, organisation }: Props) => {
  const { flatRoutes: clientRoutes } = useContext(ClientRouteContext);

  const organisationRoute = clientRoutes.find((r) => r.key === ROUTE_KEY.ADMIN_ORG_EDIT);

  const handleTabClick = (event: React.ChangeEvent<{}>, value: string) => {
    if (match.path !== value) {
      history.push(replace(':id', getResourceId(match))(value));
    }
  };

  const subRoutes =
    organisationRoute?.subRoutes?.filter((route) => tabsToShow.includes(route.key)) || [];

  return (
    <div>
      <GlobalHeader title={title} goBack={organisationRoute && organisationRoute.path} />
      <Tabs
        value={
          subRoutes.find((route) => route.path === match.path)
            ? match.path
            : organisationRoute?.path
        }
        onChange={handleTabClick}
        classes={{ flexContainer: classes.tabsContainer, indicator: classes.indicator }}
      >
        {organisationRoute && (
          <Tab
            value={organisationRoute.path}
            label={organisationRoute.name}
            classes={{
              root: classes.tab,
              selected: classes.selectedTab,
            }}
          />
        )}
        {subRoutes.map((route) => (
          <Tab
            key={route.path}
            value={route.path}
            label={
              <>
                <span>{route.name}</span>
                {organisation && <RoleSwitch routeKey={route.key} organisation={organisation} />}
              </>
            }
            classes={{
              root: classes.tab,
              selected: classes.selectedTab,
            }}
          />
        ))}
      </Tabs>
      <Typography style={{ lineHeight: '30px', float: 'right' }} align="right" variant="caption">
        {subHeader}
      </Typography>
      <Divider className={classes.divider} />
    </div>
  );
};

export default withRouter(withStyles(tabHeaderStyles)(Header));
