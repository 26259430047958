import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import TitleForm from '../containers/TitleForm';
import ConstrainedPageContent from '../../../../../components/ConstrainedPageContent';

type Props = RouteComponentProps<{}, {}, { path: string; gtin13: string } | undefined>;

const NewTitlePage: FC<Props> = ({ match, history, location }) => {
  return (
    <ConstrainedPageContent flex>
      <TitleForm
        navigate={history.push}
        previousPath={location.state?.path}
        gtin13={location.state?.gtin13}
      />
    </ConstrainedPageContent>
  );
};
export default NewTitlePage;
