import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Box, Typography } from '@material-ui/core';
import { Contributor } from '../../../../../../config/api/types';
import ContributorsField from '../../containers/fields/contributors/ContributorsField';
import SectionWrapper from '../SectionWrapper';
import FieldsetTitle from '../FieldsetTitle';
import Fieldset from '../Fieldset';
import AddContributorsField from '../../containers/fields/contributors/AddContributorsField';
import { ContributorsError } from './ContributorsError';

type Props = {
  formContributors: Contributor[];
  onChangeFormValue: (name: string, value?: any) => void;
  validationErrors?: Record<number, string>;
  submitError?: string;
  isBibliographicalVerified?: boolean;
};
const Contributors: FC<Props> = ({
  formContributors,
  onChangeFormValue,
  isBibliographicalVerified = false,
}) => {
  const { t } = useTranslation();

  return (
    <SectionWrapper id="contributors">
      <FieldsetTitle>{t('title_section_contributors')}</FieldsetTitle>
      <Fieldset>
        {!isBibliographicalVerified && formContributors.length > 0 && (
          <Typography gutterBottom style={{ marginBottom: 8 }}>
            {t('form_drag_tooltip')}
          </Typography>
        )}
        <DndProvider backend={HTML5Backend}>
          <ContributorsField
            formContributors={formContributors}
            onChange={onChangeFormValue}
            isBibliographicalVerified={isBibliographicalVerified}
          />
        </DndProvider>

        <ContributorsError />

        {!isBibliographicalVerified && (
          <Box mt={1}>
            <AddContributorsField
              onPush={() => onChangeFormValue('contributors', [...formContributors, undefined])}
              onPop={() => {
                const c = [...formContributors];
                c.pop();
                onChangeFormValue('contributors', c);
              }}
              name={`contributors[${formContributors.length - 1}]`}
              onChange={onChangeFormValue}
              formNewContributors={formContributors}
            />
          </Box>
        )}
      </Fieldset>
    </SectionWrapper>
  );
};

export default Contributors;
