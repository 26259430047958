import React, { FC, useEffect } from 'react';
import { Form } from 'react-final-form';
import { useTranslation, Trans } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import Field from '../../../components/Form';
import Button from '../../../components/Button';
import { useFetch } from '@react-redux-fetch/hooks';
import { setPasswordRequest } from '../api';
import { SetPasswordDTO } from '../../../config/api/models/security';
import { useAsyncValidation } from '../../../helpers/finalFormAsyncValidation';
import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import usePath from '../../../config/routes/usePath';
import ROUTE_KEY from '../../../config/routes/routeKeys';

type Props = {
  onSuccess: () => void;
  token: string;
};

const SetPasswordForm: FC<Props> = ({ onSuccess, token }) => {
  const { t } = useTranslation();
  const [setPasswordFetch, setPassword] = useFetch(setPasswordRequest);
  const { createSubmissionPromise } = useAsyncValidation(setPasswordFetch);
  const forgotPWLink = usePath(ROUTE_KEY.FORGOT_PW);

  const setPasswordSuccessful = setPasswordFetch?.fulfilled;

  useEffect(() => {
    if (setPasswordSuccessful) {
      onSuccess();
    }
  }, [setPasswordSuccessful, onSuccess]);

  const errorMessage = () => {
    if (setPasswordFetch?.rejected) {
      if (setPasswordFetch?.reason.cause?.message === 'The token has expired') {
        return (
          <span>
            <Trans
              i18nKey="token_invalid"
              components={[<Link to={{ pathname: forgotPWLink }} />]}
            />
          </span>
        );
      }
      return setPasswordFetch.reason.cause?.[0]?.message || setPasswordFetch.reason.cause?.message;
    }
  };

  return (
    <Form
      onSubmit={(values: SetPasswordDTO) => {
        setPassword(values, token);
        return createSubmissionPromise();
      }}
      validate={(values) => {
        if (
          values.password &&
          values.passwordConfirmation &&
          values.password !== values.passwordConfirmation
        ) {
          return {
            passwordConfirmation: t('security_pw_dont_match'),
          };
        }
        return;
      }}
    >
      {({ handleSubmit, hasValidationErrors }) => {
        return (
          <form onSubmit={handleSubmit}>
            {errorMessage() && (
              <Box mb={2}>
                <Alert severity="error">{errorMessage()}</Alert>
              </Box>
            )}
            <Field
              name="password"
              type="password"
              label={t('security_pw')}
              autoComplete="off"
              margin="normal"
            />
            <Field
              name="passwordConfirmation"
              type="password"
              label={t('security_pw_repeat')}
              autoComplete="off"
              margin="normal"
            />
            <Button type="submit" disabled={hasValidationErrors || setPasswordFetch?.pending}>
              {t('security_set_password')}
            </Button>
          </form>
        );
      }}
    </Form>
  );
};

export default SetPasswordForm;
