import React from 'react';
import { TextField, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import cxs from 'classnames';
import { ControlProps } from 'react-select';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
    background: theme.palette.background.default,
  },
}));

function inputComponent({ inputRef, ...props }: any) {
  return <div ref={inputRef} {...props} />;
}

export function Control(props: ControlProps<any, any>) {
  const classes = useStyles();

  return (
    <TextField
      fullWidth
      variant="outlined"
      error={props.selectProps.meta && props.selectProps.meta.submitError}
      disabled={props.isDisabled}
      InputProps={{
        inputComponent,
        inputProps: {
          className: cxs(
            classes.input
            // classes.inputField,
            // props.selectProps.margin === 'normal' && classes.inputMarginNormal
          ),
          inputRef: props.innerRef,
          children: props.children,
          ...(props.innerProps as any),
        },
      }}
    />
  );
}
