import { ApiConfig } from '../api';
import { PromiseState, ReduxFetch } from 'react-redux-fetch';
import React from 'react';
import createFinalFormAsyncValidation from '../../../../helpers/finalFormAsyncValidation';
import { RouteComponentProps } from 'react-router';
import { Fund, FundDto, StatisticsCode, User, FundStatus } from '../../../../config/api/types';
import useApiRoute from '../../../../config/api/useApiRoute';
import { connect } from 'react-redux';
import { RootState } from '../../../../config/store/types';
import security from '../../../security';
import { STATISTICS } from '../../domain';

export type FormValues = {
  code?: string;
  fullCode: string;
  description?: string;
  distributorUrl?: string;
  distributor?: string;
  fundUrl: string;
  statisticsCode: StatisticsCode | typeof STATISTICS.EMPTY;
  status: FundStatus;
  isDefault: boolean;
  isAvailable: boolean;
};

type FetchRenderProps = {
  fetch: PromiseState;
  dispatch: (url: string, body: FundDto) => void;
  createSubmissionPromise: () => Promise<any>;
  initialValues: FormValues;
};

type MappedState = {
  user?: User;
};

type Props = {
  prevLocation: string;
  history: RouteComponentProps['history'];
  children: ({
    fetch,
    dispatch,
    createSubmissionPromise,
    initialValues,
  }: FetchRenderProps) => React.ReactNode;
  id?: string;
};

const mapState = (state: RootState): MappedState => ({
  user: security.selectors.getUser(state),
});

export const CreateNewFundFetch = connect(mapState)(
  ({ prevLocation, history, children, user }: MappedState & Props) => {
    const userOrg = user && user._embedded && user._embedded.organisation;
    const { createSubmissionPromise, handleFulfil, handleReject } = createFinalFormAsyncValidation(
      'newFundFetch'
    );

    return (
      <ReduxFetch
        config={[ApiConfig.createFund()]}
        onFulfil={(...args) => {
          history.push(prevLocation);
          return handleFulfil(args[0]);
        }}
        onReject={handleReject}
      >
        {({ newFundFetch, dispatchNewFundPost }) => {
          const distributor = userOrg && userOrg._links && userOrg._links.distributor;

          return children({
            fetch: newFundFetch,
            dispatch: dispatchNewFundPost,
            createSubmissionPromise,
            initialValues: {
              status: 'status_active',
              statisticsCode: 'Vlaams',
              distributor: distributor ? userOrg!.name : '',
              distributorUrl: distributor ? userOrg!._links!.distributor.href : '',
              fullCode: '',
              fundUrl: distributor ? userOrg!._links!.funds.href : '',
              isDefault: false,
              isAvailable: false,
            },
          });
        }}
      </ReduxFetch>
    );
  }
);

type UpdateFundFetchRenderProps = {
  fundFetch: PromiseState<Fund>;
  dispatchUpdateFundPut: (body: FundDto) => void;
};

export const UpdateFundFetch = ({ prevLocation, history, children, id }: Props) => {
  const { createSubmissionPromise, handleFulfil, handleReject } = createFinalFormAsyncValidation(
    'updateFundFetch'
  );
  const apiPath = `${useApiRoute(['fundsDistributor', 'fundsAdmin', 'fundsBookstore'])}/${id}`;

  return (
    <ReduxFetch
      key={id}
      config={[ApiConfig.getFund(apiPath), ApiConfig.updateFund(apiPath)]}
      onFulfil={(resourceName) => {
        if (resourceName === 'updateFundFetch') {
          history.push(prevLocation, { keepFilters: true });
        }
        return handleFulfil(resourceName);
      }}
      onReject={handleReject}
      fetchOnMount={['fundGet']}
    >
      {({ fundFetch, dispatchUpdateFundPut }: UpdateFundFetchRenderProps) => {
        if (!fundFetch || !fundFetch.value) {
          return null;
        }
        return children({
          fetch: fundFetch,
          dispatch: (newFundUrl, body) => {
            dispatchUpdateFundPut(body);
          },
          createSubmissionPromise,
          initialValues: {
            status: fundFetch.value.status,
            statisticsCode: fundFetch.value.statisticsCode,
            fullCode: fundFetch.value.code,
            code: fundFetch.value.code,
            description: fundFetch.value.description,
            distributorUrl:
              fundFetch.value.organisation &&
              fundFetch.value.organisation._links &&
              fundFetch.value.organisation._links.distributor.href,
            distributor: fundFetch.value.organisation && fundFetch.value.organisation.name,
            fundUrl: '',
            isDefault: fundFetch.value.isDefault,
            isAvailable: fundFetch.value.isAvailable,
          },
        });
      }}
    </ReduxFetch>
  );
};
