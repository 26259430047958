import React from 'react';
import {
  Stepper as MuiStepper,
  Step as MuiStep,
  StepButton,
  StepperProps,
  makeStyles,
  Theme,
  StepLabel,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    color: theme.palette.error.main,
  },
}));

type Step = {
  label: string;
  error?: string;
};

type Props = Omit<StepperProps, 'children'> & {
  steps: Step[];
  children?: React.ReactNode;
  onSelectStep: (id: number) => void;
};

const Stepper = ({ children, steps, onSelectStep, ...props }: Props) => {
  const classes = useStyles();

  return (
    <MuiStepper {...props} style={{ padding: 0 }}>
      {children
        ? children
        : steps.map((step, index) => (
            <MuiStep key={index}>
              <StepButton
                onClick={() => onSelectStep(index)}
                optional={step.error}
                className={step.error ? classes.error : ''}
              >
                <StepLabel error={Boolean(step.error)}>{step.label}</StepLabel>
              </StepButton>
            </MuiStep>
          ))}
    </MuiStepper>
  );
};

export default Stepper;
