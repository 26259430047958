import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../../../components/PageTitle';
import MasterprefixTableWrapper from '../containers/MasterPrefixTableWrapper';
import { makeStyles, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import Button from '../../../../components/Button';
import AddMasterPrefixWrapper from '../containers/AddMasterPrefixWrapper';

const useStyles = makeStyles(() => ({
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogBody: {
    minWidth: 400,
    marginBottom: 16,
  },
}));

const MasterPrefixOverview = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <div className={classes.pageHeader}>
        <PageTitle>{t('master_prefix_title')}</PageTitle>
        <Button onClick={() => setDialogOpen(true)}>{t('master_prefix_add')}</Button>
      </div>
      <MasterprefixTableWrapper />

      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>{t('master_prefix_add')}</DialogTitle>
        <DialogContent className={classes.dialogBody}>
          <AddMasterPrefixWrapper onClose={handleClose} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MasterPrefixOverview;
