import React from 'react';
import { Link } from '@material-ui/core';
import { LinkProps } from '@material-ui/core';

// @DESCRIPTION:
// Component to use for linking to an external page (outside of the app)
// Always opens a new tab
// Uses "noopener" & "noreferrer" to prevent security and performance vulnerabilities (see https://web.dev/external-anchors-use-rel-noopener/ for more info)

const ExternalLink = ({ ...props }: LinkProps) => {
  return <Link target="_blank" rel="noopener noreferrer" {...props} />;
};

export default ExternalLink;
