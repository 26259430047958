import React, { FC, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { Box, Grid } from '@material-ui/core';
import { TextField } from '../../../../../../components/Form';
import Button from '../../../../../../components/Button';
import { useFetch } from '@react-redux-fetch/hooks';
import { getTitleRequest } from '../../../../detail/api';
import { updateQueryParameters } from '../../../../../../helpers/hateoas';
import useApiRoute from '../../../../../../config/api/useApiRoute';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectTitle } from '../../selectors';

type Props = {
  onNext: () => void;
  onSubmit: (gtin13: string) => void;
};

const SearchTitle: FC<Props> = ({ onNext, onSubmit }) => {
  const { t } = useTranslation();
  const url = useApiRoute('titles');
  const [gtin13, setGtin13] = useState('');
  const [titleFetch, getTitle] = useFetch(getTitleRequest);
  const foundTitle = useSelector(selectTitle);
  const isFulfilled = titleFetch?.fulfilled;

  useEffect(() => {
    if (isFulfilled && foundTitle && gtin13 === foundTitle.gtin13) {
      onNext();
    }
    if (isFulfilled && !foundTitle && gtin13) {
      onSubmit(gtin13);
    }
  }, [onNext, isFulfilled, foundTitle, onSubmit, gtin13]);

  return (
    <Grid container>
      <Form
        onSubmit={(values) => {
          if (!values.gtin13) {
            return;
          }

          const updatedUrl = updateQueryParameters(url || '', {
            q: values.gtin13,
          });
          setGtin13(values.gtin13);
          getTitle(updatedUrl, values.gtin13);
        }}
        validate={(values) => {
          const gtin13 = values.gtin13;

          if (gtin13 && gtin13.length !== 13) {
            return { gtin13: t('form_validation_invalid_gtin13') };
          }

          return {};
        }}
        initialValues={{ gtin13: '' }}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit }) => {
          return (
            <Grid
              container
              component="form"
              onSubmit={handleSubmit}
              spacing={2}
              alignItems="flex-end"
            >
              <Grid item style={{ width: 184 }}>
                <TextField
                  name="gtin13"
                  label={t('title_gtin13')}
                  fullWidth
                  type="number"
                  isClearable
                  autoFocus
                />
              </Grid>
              <Grid item>
                <Box mb={1}>
                  <Button type="submit" disabled={titleFetch?.pending}>
                    {t('form_next')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          );
        }}
      </Form>
    </Grid>
  );
};

export default SearchTitle;
