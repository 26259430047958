import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Add } from '@material-ui/icons';
import Button from '../../../../../../../components/Button';
import { Contributor } from '../../../../../../../config/api/types';

const newContributor: Contributor = {
  firstName: '',
  lastName: '',
  name: '',
  role: { code: 'A01', label: 'Auteur' },
  roleCode: 'A01',
  sequenceNumber: 0,
  titleContributorId: '',
  titleId: '',
};

type Props = {
  onPush: () => void;
  onPop: () => void;
  onChange: (name: string, value?: any) => void;
  name: string;
  formNewContributors: Partial<Contributor>[];
};
const AddContributorsField: FC<Props> = ({ onChange, formNewContributors }) => {
  const { t } = useTranslation();

  return (
    <Button
      size="small"
      variant="text"
      onClick={() => {
        onChange('contributors', [
          ...formNewContributors,
          {
            ...newContributor,
            sequenceNumber: formNewContributors.length + 1, // @NOTE: sequenceNumber must start at 1 apparently for ONIX exports to work which is kinda BS IMO - See https://documents.calibrate.be/issues/155928
            titleContributorId: `${formNewContributors.length}`,
          },
        ]);
      }}
      style={{ marginTop: 8 }}
    >
      <Add />{' '}
      {t(
        formNewContributors.length
          ? 'title_add_another_contributor_btn'
          : 'title_add_contributor_btn'
      )}
    </Button>
  );
};

export default AddContributorsField;
