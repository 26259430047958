import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import security from '../../security/index';
import SettingsButton from '../components/AppBar/SettingsButton';
import { FC } from 'react';
import { Actions } from '../../security/actions';
import { User } from '../../../config/api/types';

const Settings: FC = () => {
  const user = useSelector(security.selectors.getUser);
  const impersonation = useSelector(security.selectors.getImpersonate);
  const dispatch = useDispatch();
  if (!user) {
    return null;
  }

  const handleLogout = () => {
    if (impersonation) {
      dispatch(Actions.impersonate());
    } else {
      dispatch(Actions.logout());
    }
  };

  return <SettingsButton user={(impersonation as User) || user} onLogout={handleLogout} />;
};

export default Settings;
