import React, { ChangeEvent } from 'react';
import MUITable, { TableProps } from '@material-ui/core/Table';
import { TablePaginationProps } from '@material-ui/core/TablePagination';
import TableHead from './TableHead';
import TableRow from './TableRow';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from './TablePagination';
import { DataType } from './types';
import { Paged } from '../../config/api/types';
import { getLink } from '../../helpers/hateoas';
import { CircularProgress, Grid, TableFooter, TableRow as MuiTableRow } from '@material-ui/core';

type Props = {
  data: DataType;
  headers: Array<{ label: string }>;
  limit?: number;
  page?: number;
  pages?: number;
  total?: number;
  orderBy?: string;
  orderDir?: 'asc' | 'desc';
  onSortChange?: (orderBy?: string, orderDir?: 'asc' | 'desc') => void;
  onNextPage?: () => void;
  onPrevPage?: () => void;
  onChangeLimit?: (limit: string) => void;
  tableProps?: TableProps;
  dispatchFn?: (url: string) => void;
  pagedResource?: Paged<any>;
  hidePagination?: boolean;
  dense?: boolean;
  noBorder?: boolean;
  loading?: boolean;
  paginationSettings?: Partial<TablePaginationProps>;
};

// type SortState = {
//   orderBy?: string;
//   orderDir?: 'asc' | 'desc';
// };

const Table = ({
  data,
  headers,
  limit,
  page,
  pages,
  total,
  orderBy,
  orderDir,
  onSortChange,
  onNextPage,
  onPrevPage,
  onChangeLimit,
  tableProps,
  dispatchFn,
  pagedResource,
  hidePagination,
  dense,
  noBorder,
  loading,
  paginationSettings,
}: Props) => {
  // const initialSortState = { orderBy, orderDir };
  // const [sortState, updateSortState] = useState<SortState>(initialSortState);

  const finalLimit = typeof limit !== 'undefined' ? limit : pagedResource && pagedResource.limit;
  const finalPage = typeof page !== 'undefined' ? page : pagedResource && pagedResource.page;
  const finalPages = typeof pages !== 'undefined' ? pages : pagedResource && pagedResource.pages;
  const finalTotal = typeof total !== 'undefined' ? total : pagedResource && pagedResource.total;

  if (
    !loading &&
    (typeof finalLimit === 'undefined' ||
      typeof finalPage === 'undefined' ||
      typeof finalPages === 'undefined' ||
      typeof finalTotal === 'undefined') &&
    !hidePagination
  ) {
    throw new Error(
      'Failed prop type: Either provide limit, page, pages and total prop or a pagedResource object'
    );
  }

  const currentPage = finalPage ? finalPage - 1 : 0;

  const handleChangePage = (evt: any, newPage: number) => {
    if (newPage > currentPage) {
      if (dispatchFn && pagedResource) {
        dispatchFn(getLink(pagedResource, 'next') || '');
      } else if (onNextPage) {
        onNextPage();
      }
    } else if (newPage < currentPage) {
      if (dispatchFn && pagedResource) {
        dispatchFn(getLink(pagedResource, 'previous') || '');
      } else if (onPrevPage) {
        onPrevPage();
      }
    }
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (dispatchFn && pagedResource) {
      const firstPageLink = getLink(pagedResource, 'first') || '';
      if (!firstPageLink) {
        return;
      }
      dispatchFn(firstPageLink.replace(/limit=[0-9]*/, 'limit=' + event.target.value));
    } else if (onChangeLimit) {
      onChangeLimit(event.target.value);
    }
  };

  const handleColumnSortChange = (event: any, order?: string) => {
    if (onSortChange) {
      onSortChange(order, orderDir === 'desc' ? 'asc' : 'desc');
    }
  };

  return (
    <>
      {loading ? (
        <Grid container justify="center" alignItems="center" style={{ marginTop: 20 }}>
          <CircularProgress />
        </Grid>
      ) : (
        <MUITable {...tableProps}>
          <TableHead
            headers={headers}
            orderBy={orderBy}
            orderDir={orderDir}
            onSortChange={handleColumnSortChange}
            dense={dense}
            noBorder={noBorder}
          />
          <TableBody>
            {data.rows.map((row: any, index: number) => (
              <TableRow
                key={`row${row.id + index}`}
                row={row.cells}
                link={row.link}
                linkState={row.linkState}
                dense={dense}
                noBorder={noBorder}
                variant={row.variant}
              />
            ))}
          </TableBody>
          {!hidePagination && (
            <TableFooter>
              <MuiTableRow>
                <td>
                  {loading && (
                    <CircularProgress color="secondary" style={{ width: 20, height: 20 }} />
                  )}
                </td>
                <TablePagination
                  page={currentPage}
                  rowsPerPage={finalLimit || 50}
                  count={finalTotal || 0}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  {...paginationSettings}
                />
              </MuiTableRow>
            </TableFooter>
          )}
        </MUITable>
      )}
    </>
  );
};

export default Table;
