import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFetch } from '@react-redux-fetch/hooks';
import { Redirect } from 'react-router';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import useApiRoute from '../../../../../config/api/useApiRoute';
import { DistributorLine as DistributorLineModel } from '../../../../../config/api/models/shop';
import formatEuro from '../../../../../helpers/formatEuro';
import Button from '../../../../../components/Button';
import { getShoppingCartRequest } from '../../api';
import { getShoppingCart } from '../../selectors';
import DistributorLine from './DistributorLine';
import OrderByUserLine from './OrderByUserLine';
import Confirmation from './Confirmation';
import usePath from '../../../../../config/routes/usePath';
import ROUTE_KEY from '../../../../../config/routes/routeKeys';

const ShoppingCart: FC = () => {
  const { t } = useTranslation();
  const url = useApiRoute('shoppingCart');
  const quickOrderPath = usePath(ROUTE_KEY.QUICK_ORDERS);
  const [invalidLineFlags, setInvalidLineFlags] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [dialogInfo, setDialogInfo] = useState<
    | {}
    | {
        distributorLines: DistributorLineModel[] | undefined;
        userLines: Record<string, DistributorLineModel> | undefined;
        confirmationUrl: string;
      }
  >({});

  const [shoppingCartFetch, fetchShoppingCart] = useFetch(getShoppingCartRequest);
  const shoppingCart = useSelector(getShoppingCart);

  useEffect(() => {
    if (url) {
      fetchShoppingCart(url);
    }
  }, [fetchShoppingCart, url]);

  const handleHasInvalidLine = useCallback((titleId: string, isInvalid: boolean) => {
    setInvalidLineFlags((prevState) => ({
      ...prevState,
      [titleId]: isInvalid,
    }));
  }, []);

  if (!url) {
    return <Redirect to="/" />;
  }

  const openDialogInfo = (
    confirmationUrl: string,
    distributorLines?: DistributorLineModel[],
    userLines?: Record<string, DistributorLineModel>
  ) => {
    setShowDialog(true);
    setDialogInfo({
      confirmationUrl,
      distributorLines,
      userLines,
    });
  };

  const distributorLines = shoppingCart?.linesByDistributor;
  const userLines = shoppingCart?.linesByUser;
  const orderLines = distributorLines || (userLines ? Object.values(userLines) : undefined);
  const hasInvalidLines = Object.values(invalidLineFlags).some(Boolean);
  const disableButtons = hasInvalidLines;

  const callToAction = shoppingCart && (
    <Grid container justify="flex-end" alignItems="center" spacing={2}>
      <Grid item>
        <Typography variant="subtitle1">
          {t(`cart${userLines ? '_library' : ''}_total`)}{' '}
          <strong>{formatEuro(shoppingCart.resaleValue)}</strong>
        </Typography>
      </Grid>
      <Grid item>
        <Button
          disabled={disableButtons}
          onClick={() => {
            if (orderLines) {
              openDialogInfo(
                shoppingCart._links.shoppingCartConfirmation.href || '',
                distributorLines,
                userLines
              );
            }
          }}
        >
          {t(`cart_place_full${userLines ? '_library' : ''}_order`)}
        </Button>
      </Grid>
    </Grid>
  );

  const cartEmpty = <Typography style={{ marginBottom: '1rem' }}>{t('cart_empty')}</Typography>;

  return (
    <>
      {shoppingCart && orderLines ? (
        <>
          {orderLines.length > 0 ? (
            <Grid container>
              <Grid item>
                <Button link={quickOrderPath} variant={'outlined'}>
                  {t('nav_quickorder')}
                </Button>
              </Grid>
              <Grid item style={{ flexGrow: 1 }}>
                {callToAction}
              </Grid>
            </Grid>
          ) : (
            <>
              <div>{cartEmpty}</div>{' '}
              <Button link={quickOrderPath} variant="contained">
                {t('nav_quickorder')}
              </Button>
            </>
          )}

          {distributorLines?.map((lineByDistributor) => (
            <DistributorLine
              distributorLine={lineByDistributor}
              key={lineByDistributor._embedded.organisation.name}
              onOpenDialogInfo={openDialogInfo}
              disabled={disableButtons}
              onInvalidLine={handleHasInvalidLine}
            />
          ))}

          {userLines &&
            Object.keys(userLines).map((key) => (
              <OrderByUserLine
                userLine={userLines[key]}
                userId={key}
                key={key}
                onOpenDialogInfo={openDialogInfo}
                disabled={disableButtons}
                onInvalidLine={handleHasInvalidLine}
              />
            ))}

          {orderLines.length > 0 && callToAction}
        </>
      ) : !shoppingCartFetch?.pending ? (
        cartEmpty
      ) : (
        <Grid container justify="center" alignItems="center" style={{ marginTop: 20 }}>
          <CircularProgress />
        </Grid>
      )}

      {showDialog && (
        <Confirmation
          onClose={() => setShowDialog(false)}
          {...dialogInfo}
          redirectToTitles={!shoppingCart || orderLines?.length === 0}
        />
      )}
    </>
  );
};

export default ShoppingCart;
