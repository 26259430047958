import React from 'react';
import { Typography } from '@material-ui/core';
// import { makeStyles } from '@material-ui/styles';
//
// const useStyles = makeStyles((theme: Theme) => ({
//   root: {},
// }));

type Props = { children: string };

const ListHeading = ({ children }: Props) => {
  // const classes = useStyles();

  return (
    <Typography variant="subtitle2" gutterBottom>
      {children}
    </Typography>
  );
};

export default ListHeading;
