import {
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  Popover,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React, { useRef, useState } from 'react';
import { ClosePopoverButton } from '../../../../../../components/ClosePopoverButton';
import TreeSelect, {
  MultiValueProps,
} from '../../../../../../components/Form/TreeSelect/TreeSelect';
import {
  FilterTreeNode,
  TreeData,
  Value,
} from '../../../../../../components/Form/TreeSelect/types';
import { defaultTreeNodeFilter } from '../../../../../../components/Form/TreeSelect/util';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiInputBase-adornedEnd': {
      backgroundColor: 'white',
    },
  },
  error: {
    fontSize: '0.75rem',
    color: theme.palette.error.main,
  },
}));

const defaultGetLabel = <TValue extends Value>(item: TValue) => (
  <ListItemText primary={`${item.code} - ${item.label}`} style={{ flexGrow: 0 }} />
);

type SearchableTreeSelectProps<TData extends TreeData, TValue extends Value> = Omit<
  MultiValueProps<TData, TValue>,
  'searchValue' | 'onSearch' | 'filterTreeNode' | 'treeData'
> & {
  treeData?: TData[];
  filterTreeNode?: FilterTreeNode;
  value: TValue | TValue[];
  label: React.ReactNode;
  error?: string;
  closeOnSelect?: boolean;
  renderSelectedValue?: (item: TValue) => React.ReactNode;
};
const SearchableTreeSelect = <TData extends TreeData, TValue extends Value>({
  getLabel = defaultGetLabel,
  filterTreeNode = defaultTreeNodeFilter,
  label,
  value,
  onSelect,
  treeData,
  closeOnSelect,
  error,
  renderSelectedValue,
  ...other
}: SearchableTreeSelectProps<TData, TValue>) => {
  const classes = useStyles();
  const titleRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchValue, handleSearch] = useState('');

  const handleClick = () => {
    setAnchorEl(anchorEl ? null : titleRef.current);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = (value: TValue | TData | (TValue | TData)[]) => {
    if (closeOnSelect) {
      handleClose();
    }
    // @ts-ignore
    onSelect(value);
  };

  const valueArray = value ? (Array.isArray(value) ? value : [value]) : undefined;

  return (
    <div>
      <Grid container alignItems="center" ref={titleRef} spacing={2}>
        <Grid item>
          <InputLabel>{label}</InputLabel>
        </Grid>
        <Grid item>
          <IconButton size="small" onClick={handleClick}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      {error && <Typography className={classes.error}>{error}</Typography>}
      <Typography style={{ marginTop: 4, marginBottom: 8, paddingLeft: 8 }}>
        {valueArray?.map((v: TValue) => {
          return renderSelectedValue ? (
            renderSelectedValue(v)
          ) : (
            <Typography key={`${v.code} - ${v.label}`} component="span">
              {v.code} - {v.label} <br />
            </Typography>
          );
        })}
      </Typography>

      <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
        <ClosePopoverButton onClick={handleClose} />
        {treeData && (
          <TreeSelect
            treeData={treeData}
            searchValue={searchValue}
            onSearch={handleSearch}
            treeDefaultExpandAll={Boolean(searchValue)}
            onSelect={handleSelect}
            getLabel={getLabel}
            filterTreeNode={filterTreeNode}
            width={anchorEl?.clientWidth}
            value={value}
            {...other}
          />
        )}
      </Popover>
    </div>
  );
};

export default SearchableTreeSelect;
