import React from 'react';
import { Form } from 'react-final-form';
import Field, { CheckBox } from '../../../../../components/Form/index';
import { Grid } from '@material-ui/core';
import Button from '../../../../../components/Button';
import { useTranslation } from 'react-i18next';

export type FormValues = {
  cbRelationId: string;
  isPrimary: boolean;
};

type Props = {
  onClose: () => void;
  onSubmit: (formValues: FormValues) => void;
};

const CbRelationForm = ({ onClose, onSubmit }: Props) => {
  const { t } = useTranslation();
  return (
    <Form
      onSubmit={(values) => onSubmit(values as FormValues)}
      initialValues={{ isPrimary: false }}
    >
      {({ handleSubmit, submitting, form: { reset } }) => (
        <form
          onSubmit={(event) => {
            if (!handleSubmit) {
              return;
            }
            const promise = handleSubmit(event);
            if (promise) {
              promise.then((err) => {
                if (!err) {
                  reset();
                }
              });
            }
          }}
        >
          <Grid container direction="column">
            <Grid item xs={4} sm={3}>
              <Field type="text" name="cbRelationId" label={t('form_cb_relation_id')} />
              <CheckBox name="isPrimary" label={t('form_is_primary')} />
            </Grid>
            <Grid item container xs={12} spacing={8}>
              <Grid item>
                <Button type="submit" disabled={submitting}>
                  {t('form_action_save')}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" color="default" disabled={submitting} onClick={onClose}>
                  {t('form_action_cancel')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Form>
  );
};

export default CbRelationForm;
