import { Grid, InputAdornment } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Field from '../../../../../../components/Form';
import FormFieldWrapper from '../../../../../../components/Form/FormFieldWrapper';
import { EBookType, ProductForm, TitleFull } from '../../../../../../config/api/types';
import useApiRoute from '../../../../../../config/api/useApiRoute';
import EBookVersionField from '../../containers/fields/EBookVersionField';
import ProductFormDetailsField from '../../containers/fields/ProductFormDetailsField';
import ProductFormField from '../../containers/fields/ProductFormField';
import {
  showDimensionFields,
  showDurationField,
  showEPubField,
  showKindleField,
  showPagesField,
  showProductFormDetailsField,
} from '../../domain';
import Fieldset from '../Fieldset';
import FieldsetTitle from '../FieldsetTitle';
import SectionWrapper from '../SectionWrapper';
import TitleFormatField from './TitleFormatField';

type Props = {
  productFormFromServer?: ProductForm;
  selectedProductForm?: ProductForm;
  selectedEBookType?: EBookType;
  onChangeFormValue: (name: string, value?: any) => void;
  newTitleForm?: boolean;
  isBibliographicalVerified?: boolean;
  title?: TitleFull;
};

const TitleFormat = ({
  productFormFromServer,
  onChangeFormValue,
  selectedProductForm,
  isBibliographicalVerified,
  selectedEBookType,
}: Props) => {
  const { t } = useTranslation();

  const uomMetricAdornment = {
    endAdornment: <InputAdornment position="end">{t('uom_metric')}</InputAdornment>,
  };
  const uomWeightAdornment = {
    endAdornment: <InputAdornment position="end">{t('uom_weight')}</InputAdornment>,
  };
  const uomTimeAdornment = {
    endAdornment: <InputAdornment position="end">{t('uom_time')}</InputAdornment>,
  };
  const eBookTypeApiLink = useApiRoute('titleEbookTypes') || '';
  const productFormApiLink = useApiRoute('titleProductForms') || '';
  const productFormDetailsApiLink = useApiRoute('titleProductFormDetails') || '';

  const renderEBookField = () => {
    if (showEPubField(selectedProductForm)) {
      if (selectedEBookType?.code.includes('116')) {
        onChangeFormValue('selectedEBookType', null);
      }
      return (
        <Grid xs={6} item>
          <EBookVersionField url={eBookTypeApiLink} version="epub" />
        </Grid>
      );
    } else if (showKindleField(selectedProductForm)) {
      if (selectedEBookType?.code.includes('101')) {
        onChangeFormValue('selectedEBookType', null);
      }
      return (
        <Grid xs={6} item>
          <EBookVersionField url={eBookTypeApiLink} version="kindle" />
        </Grid>
      );
    } else {
      onChangeFormValue('selectedEBookType', null);
    }
  };

  const renderProductFormDetailsField = () => {
    if (selectedProductForm != null && showProductFormDetailsField(selectedProductForm)) {
      return (
        <Grid xs={6} item>
          <ProductFormDetailsField
            selectedProductForm={selectedProductForm}
            url={productFormDetailsApiLink}
          />
        </Grid>
      );
    } else {
      onChangeFormValue('selectedProductFormDetails', null);
    }
  };

  const renderPagesField = () => {
    if (showPagesField(selectedProductForm)) {
      return (
        <Grid container spacing={1}>
          <Grid xs={3} item>
            <TitleFormatField
              component={Field}
              label={t('title_num_pages')}
              type="text"
              name="numPages"
            />
          </Grid>
        </Grid>
      );
    } else {
      onChangeFormValue('numPages', '');
      return null;
    }
  };

  const renderDimensionsField = () => {
    if (showDimensionFields(selectedProductForm)) {
      return (
        <>
          <Grid container spacing={1}>
            <Grid xs={3} item>
              <TitleFormatField
                component={Field}
                label={t('title_height')}
                type="text"
                name="height"
                InputProps={uomMetricAdornment}
              />
            </Grid>
            <Grid xs={3} item>
              <TitleFormatField
                component={Field}
                label={t('title_width')}
                type="text"
                name="width"
                InputProps={uomMetricAdornment}
              />
            </Grid>
            <Grid xs={3} item>
              <TitleFormatField
                component={Field}
                label={t('title_thickness')}
                type="text"
                name="thickness"
                InputProps={uomMetricAdornment}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid xs={3} item>
              <TitleFormatField
                component={Field}
                label={t('title_weight_short')}
                type="text"
                name="weight"
                InputProps={uomWeightAdornment}
              />
            </Grid>
          </Grid>
        </>
      );
    } else {
      onChangeFormValue('height', '');
      onChangeFormValue('width', '');
      onChangeFormValue('thickness', '');
      onChangeFormValue('weight', '');
      return null;
    }
  };

  const renderDurationField = () => {
    if (showDurationField(selectedProductForm)) {
      return (
        <Grid container spacing={1}>
          <Grid xs={3} item>
            <TitleFormatField
              component={Field}
              label={t('title_duration_short')}
              type="text"
              name="duration"
              InputProps={uomTimeAdornment}
            />
          </Grid>
        </Grid>
      );
    } else {
      onChangeFormValue('duration', '');
    }
  };

  return (
    <SectionWrapper id="format">
      <FieldsetTitle>{t('title_section_format')}</FieldsetTitle>
      <Fieldset>
        <Grid container spacing={1}>
          <Grid xs={6} item>
            <ProductFormField
              onSelect={(format) => {
                onChangeFormValue('selectedProductForm', format);
                onChangeFormValue('selectedProductFormDetails', null);
              }}
              productFormFromServer={productFormFromServer}
              url={productFormApiLink}
              required
              isBibliographicalVerified={isBibliographicalVerified}
            />
          </Grid>
          {renderEBookField()}
          {renderProductFormDetailsField()}
        </Grid>
      </Fieldset>
      {selectedProductForm?.code && (
        <Fieldset>
          {renderPagesField()}
          {renderDimensionsField()}
          {renderDurationField()}
        </Fieldset>
      )}
      <Fieldset>
        <FormFieldWrapper>
          <TitleFormatField
            component={Field}
            label={t('title_product_form_additional_description')}
            type="text"
            name="productForm.additionalDescription"
            helperText={t('title_productFormAdditionalDescription_helperText')}
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <TitleFormatField
            component={Field}
            label={t('title_illustrations_note')}
            type="text"
            name="illustrationsNote"
            helperText={t('title_illustrations_note_helperText')}
          />
        </FormFieldWrapper>
      </Fieldset>
    </SectionWrapper>
  );
};

export default TitleFormat;
