import React from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  STATUS_FAILED_CLIENT,
  STATUS_FAILED_SERVER,
  STATUS_PROCESSED,
  STATUS_PROCESSING,
  STATUS_UPLOADED,
  STATUS_PROCESSED_WARNING,
  StatusType,
  STATUS_EMPTY,
} from '../../../../../config/api/types';
import StatusLabel, { StatusLabelStatus } from '../../../../../components/StatusLabel';

const styles = (theme: Theme) =>
  createStyles({
    cell: {
      display: 'flex',
    },
    icon: {
      marginRight: 5,
      width: 20,
      height: 20,
    },
    red: { color: theme.palette.messages.error },
    orange: { color: theme.palette.messages.warning },
    green: { color: theme.palette.buttons.highlight },
    grey: { color: theme.palette.buttons.lightDisabled },
  });

type Props = WithStyles & {
  success?: number;
  total?: number;
  status: StatusType;
  pending?: boolean;
};

const StatusCell = ({ classes, success = 0, total = 0, status, pending }: Props) => {
  const { t } = useTranslation();
  const isStatus = (checkStatus: string) => status === checkStatus;

  const failed = isStatus(STATUS_FAILED_CLIENT) || isStatus(STATUS_FAILED_SERVER);
  const nothingProcessed = success === 0 && total === 0 && failed;

  const getCategoryAndText = (): { category: StatusLabelStatus; text: string } => {
    if (nothingProcessed) {
      return {
        category: 'fail',
        text: t('processing_error'),
      };
    }

    if ((isStatus(STATUS_PROCESSED_WARNING) || isStatus(STATUS_PROCESSED)) && success === total) {
      return {
        category: 'success',
        text: `${success} / ${total} ${t('processed')}`,
      };
    }

    if (isStatus(STATUS_EMPTY)) {
      return {
        category: 'empty',
        text: t('empty'),
      };
    }

    if (
      isStatus(STATUS_PROCESSED_WARNING) ||
      (failed && success > 0) ||
      isStatus(STATUS_PROCESSED)
    ) {
      return {
        category: 'warning',
        text: `${success} / ${total} ${t('processed')}`,
      };
    }

    if (failed && success === 0 && total > 0) {
      return {
        category: 'error',
        text: `${success} / ${total} ${t('processed')}`,
      };
    }

    if (isStatus(STATUS_UPLOADED)) {
      return {
        category: 'default',
        text: t('pending'),
      };
    }

    if (isStatus(STATUS_PROCESSING)) {
      return {
        category: 'default',
        text: `${success} / ${total} ${t('processing')}`,
      };
    }

    return {
      category: 'default',
      text: t('pending'),
    };
  };

  const { category, text } = getCategoryAndText();

  return <StatusLabel status={category}>{text}</StatusLabel>;
};

export default withStyles(styles)(StatusCell);
