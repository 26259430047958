import React, { FC, useEffect, useState } from 'react';
import { OrgLogin, OrgLogins } from '../../../../../config/api/models/organisation';
import Table from '../../../../../components/Table';
import { Maybe, Organisation } from '../../../../../config/api/types';
import { Form } from 'react-final-form';
import { useFetch } from '@react-redux-fetch/hooks';
import { updateLoginRequest } from '../../../api';
import { useAsyncValidation } from '../../../../../helpers/finalFormAsyncValidation';
import useUserTableData from '../../hooks/useUserTableData';

type Props = {
  orgLogins: Maybe<OrgLogins>;
  loading: boolean;
  organisation: Organisation;
  setSwitchingUser: (user: OrgLogin | null) => void;
};

const OrgLoginsTable: FC<Props> = ({
  orgLogins,
  loading,
  organisation,
  setSwitchingUser,
}: Props) => {
  const [editingRow, setEditingRow] = useState<OrgLogin | null>(null);
  const [updateLoginFetch, updateLogin] = useFetch(updateLoginRequest);
  const { createSubmissionPromise } = useAsyncValidation(updateLoginFetch);

  const updateLoginFetchSuccess = updateLoginFetch?.fulfilled;

  const { headers, body } = useUserTableData(
    setSwitchingUser,
    false,
    organisation,
    orgLogins?._embedded.items,
    editingRow,
    setEditingRow,
    updateLoginFetch
  );

  useEffect(() => {
    if (updateLoginFetchSuccess) {
      setEditingRow(null);
    }
  }, [updateLoginFetchSuccess]);

  return (
    <Form
      onSubmit={(values) => {
        if (!values || !editingRow) {
          return;
        }

        updateLogin(editingRow._links.self.href, values);

        return createSubmissionPromise();
      }}
      initialValues={editingRow}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Table
            loading={loading}
            data={body}
            headers={headers}
            pagedResource={orgLogins || undefined}
          />
        </form>
      )}
    </Form>
  );
};

export default OrgLoginsTable;
