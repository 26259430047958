import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { TitleFull } from '../../../../../config/api/types';
import ROUTE_KEY from '../../../../../config/routes/routeKeys';
import usePath from '../../../../../config/routes/usePath';
import titleDetail from '../../../detail';
import { getDetailLink, getFrontCover } from '../../../domain';
import TitleCta from './TitleCta';
import TitleInfo from './TitleInfo';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    display: 'flex',
    borderTop: `1px solid ${theme.palette.divider}`,
    '& > *': {
      padding: theme.spacing(3, 1),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '&:first-child': { paddingLeft: 0 },
      '&:last-child': { paddingRight: 0 },
    },
  },
  link: {
    width: 120,
    height: '100%',
    textAlign: 'center',
  },
  image: {
    '& img': { maxWidth: 120, maxHeight: 180 },
  },
  placeholder: {
    height: '100%',
    background: theme.palette.background.appbar,
    display: 'flex',
    alignItems: 'center',
    maxHeight: 176,
  },
  info: {
    flexGrow: 1,
  },
  cta: {
    '& > div': { width: theme.spacing(43) },
  },
}));

type Props = {
  title: TitleFull;
  changeParams: (values: Record<string, string | number>) => void;
};

const Title = ({ title, changeParams }: Props) => {
  const classes = useStyles();
  const detailPath = usePath(ROUTE_KEY.TITLE);
  const detailLink = getDetailLink(detailPath, title);
  const frontCover = getFrontCover(title);

  return (
    <div className={classes.title}>
      <div className={classes.image}>
        <Link to={detailLink} className={classes.link}>
          <titleDetail.SmallFrontCover title={title.title} frontCover={frontCover} />
        </Link>
      </div>
      <div className={classes.info}>
        <TitleInfo title={title} detailLink={detailLink} changeParams={changeParams} />
      </div>
      <div className={classes.cta}>
        <TitleCta title={title} />
      </div>
    </div>
  );
};

export default Title;
