import { useTranslation } from 'react-i18next';
import { CellType, DataType } from '../../../../components/Table/types';
import { OrgLogin } from '../../../../config/api/models/organisation';
import { Organisation } from '../../../../config/api/types';
import { useLibraryDepartmentsAndBookstores } from '../../library/hooks/useLibraryDepartmentsAndBookstores';
import { Grid, Tooltip } from '@material-ui/core';
import React from 'react';
import { CheckBox, TextField } from '../../../../components/Form';
import DepartmentField from '../../../title/cart/components/shoppingCart/DepartmentField';
import Button from '../../../../components/Button';
import CloseIcon from '@material-ui/icons/Close';
import TransferIcon from '@material-ui/icons/TransferWithinAStation';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import UserIcon from '@material-ui/icons/Person';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Actions } from '../../../security/actions';
import usePath from '../../../../config/routes/usePath';
import ROUTE_KEY from '../../../../config/routes/routeKeys';
import { PromiseState } from 'react-redux-fetch';

type Header =
  | {
      id: string;
      label: any;
      minWidth: number | undefined;
      width?: undefined;
    }
  | {
      id: string;
      label: any;
      width: number;
      minWidth?: undefined;
    }
  | {
      id: string;
      label: any;
      minWidth?: undefined;
      width?: undefined;
    };

type UserTableData = {
  headers: Header[];
  body: DataType;
};

const useUserTableData = (
  setSwitchingUser: (user: OrgLogin | null) => void,
  hideActions: boolean = false,
  organisation?: Organisation,
  orgLogins?: OrgLogin[],
  editingRow?: OrgLogin | null,
  setEditingRow?: React.Dispatch<React.SetStateAction<OrgLogin | null>>,
  updateLoginFetch?: PromiseState | undefined
): UserTableData => {
  const { t } = useTranslation();

  const { departments } = useLibraryDepartmentsAndBookstores(organisation);

  const dispatch = useDispatch();

  const titleRoute = usePath(ROUTE_KEY.TITLES);

  const history = useHistory();

  const handleImpersonate = (user: OrgLogin) => {
    dispatch(Actions.impersonate(user, organisation));
    history.push(titleRoute);
  };

  const isLibrary = organisation?.roles?.['ROLE_LIBRARY'];

  const headers = [
    { id: 'name', label: t('table_headers_name'), minWidth: editingRow ? 248 : undefined },
    {
      id: 'department',
      label: isLibrary ? t('table_headers_department') : '',
      width: isLibrary ? 200 : 0,
    },
    { id: 'username', label: t('table_headers_username') },
    { id: 'createdAt', label: t('table_headers_createdAt') },
    { id: 'lastLoginAt', label: t('table_headers_lastLoginAt') },
    { id: 'status', label: t('table_headers_status'), width: 120 },
    { id: 'actions', label: '', width: 140 },
  ];

  const body: DataType = {
    resource: 'orgLogins',
    rows: orgLogins
      ? orgLogins.map((login) => {
          const isEditing = login === editingRow;

          return {
            id: login.email,
            cells: [
              {
                type: CellType.Text,
                data: isEditing ? (
                  <Grid container spacing={1}>
                    <Grid item xs={departments ? 4 : 6}>
                      <TextField name="firstName" label={t('form_firstName')} />
                    </Grid>
                    <Grid item xs={departments ? 4 : 6}>
                      <TextField name="lastName" label={t('form_lastName')} />
                    </Grid>
                  </Grid>
                ) : (
                  [login.firstName, login.lastName].join(' ')
                ),
              },
              {
                type: CellType.Text,
                data: isLibrary
                  ? isEditing
                    ? Boolean(isLibrary && departments) && (
                        <DepartmentField departments={departments} name="departmentId" />
                      )
                    : departments?.find((d) => d.departmentId === login.departmentId)?.title ?? ''
                  : null,
              },
              {
                type: CellType.Text,
                data: login.email,
              },
              {
                type: CellType.Text,
                data: login.createdAt,
              },
              {
                type: CellType.Text,
                data: login.lastLoginAt,
              },
              {
                type: CellType.Text,
                data: isEditing ? (
                  <>
                    <CheckBox name="isActive" label={t('status_active')} />
                  </>
                ) : (
                  t(login.isActive ? 'status_active' : 'status_not_active')
                ),
              },
              {
                type: CellType.Action,
                data:
                  !hideActions && setEditingRow != null ? (
                    isEditing ? (
                      <>
                        <Button
                          variant="text"
                          round
                          aria-label="Done"
                          type="submit"
                          disabled={updateLoginFetch?.pending}
                        >
                          <DoneIcon>done_icon</DoneIcon>
                        </Button>
                        <Button
                          variant="text"
                          round
                          secondary
                          aria-label="Cancel"
                          onClick={() => setEditingRow(null)}
                          disabled={updateLoginFetch?.pending}
                        >
                          <CloseIcon>close_icon</CloseIcon>
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="text"
                          round
                          secondary
                          aria-label="Edit"
                          onClick={(e) => {
                            e.preventDefault();
                            return setEditingRow(login);
                          }}
                        >
                          <EditIcon>edit_icon</EditIcon>
                        </Button>
                        <Button
                          variant="text"
                          round
                          secondary
                          aria-label="impersonate"
                          onClick={() => handleImpersonate(login)}
                        >
                          <UserIcon />
                        </Button>
                        <Tooltip title={t('organisation_switch_button_tooltip')} arrow>
                          <Button
                            variant="text"
                            round
                            secondary
                            aria-label="Transfer"
                            onClick={() => setSwitchingUser(login)}
                          >
                            <TransferIcon>transfer_icon</TransferIcon>
                          </Button>
                        </Tooltip>
                      </>
                    )
                  ) : (
                    <Button onClick={() => setSwitchingUser(null)} variant="text">
                      {t('organisation_switch_cancel_cta')}
                    </Button>
                  ),
              },
            ],
          };
        })
      : [],
  };

  return { headers, body };
};

export default useUserTableData;
