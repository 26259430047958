import React from 'react';
import SearchIcon from '@material-ui/icons/Search';

export function DropdownIndicator(props: any) {
  return (
    <div className={props.selectProps.classes.searchIcon}>
      <SearchIcon className={props.selectProps.classes.icon} />
    </div>
  );
}
