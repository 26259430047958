import groupBy from 'lodash/fp/groupBy';
import sortBy from 'lodash/fp/sortBy';
import get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import moment from 'moment';
import { UseTranslationResponse } from 'react-i18next';
import { StatusLabelStatus } from '../../components/StatusLabel';
import { Thema, ThemaQualifiers, Themas } from '../../config/api/models/dataSets';
import { contributorIsPerson } from '../../config/api/typeGuards';
import {
  Availability,
  Contributor,
  ContributorRole,
  Maybe,
  Media,
  MediaCover,
  MediaSample,
  ProductForm,
  Resource,
  Title,
  TitleFull,
  UserRole,
} from '../../config/api/types';
import { UI_DATE_FORMAT, onixToMoment } from '../../helpers/date';
import getIdFromUrl from '../../helpers/getIdFromUrl';
import { getLink } from '../../helpers/hateoas';
import removeProtocol from '../../helpers/removeProtocol';
import slugify from '../../helpers/slugify';

// TODO: Instead of hardcoding the weight, we can also sort the roles based on code.
const contributorRoleWeight: ContributorRoleWeight = {
  A01: 1,
  A12: 2,
  A13: 3,
  B01: 4,
  B06: 5,
  E07: 6,
};

export const MAX_CONTRIBUTORS = 3;

export const TITLES_PER_PAGE = 15;

// --------------
// General
// --------------

export const getDetailLink = (detailPath: string, title: Resource<{ title?: string }>) => {
  const slug = slugify(title?.title ?? '');
  return detailPath
    .replace(':slug', slug ? slug : 'detail')
    .replace(':id', getIdFromUrl(getLink(title, 'self') || ''));
};

// const wordBlacklist = ['the', 'het', 'de', 'een'];

export const getSearchQuery = (value?: string) => {
  if (!value) {
    return '';
  }

  const trimmedValue = value.trim();

  return trimmedValue;
  /*
  const words = trimmedValue.split(' ');

  if (words.length === 1) {
    return trimmedValue;
  }

  const whiteListedWords = words.filter(
    (word) => !wordBlacklist.includes(word) && isNaN(Number(word))
  );

  return `"${trimmedValue}" | ${whiteListedWords.join(' ')}`;

   */
};

// --------------
// Dates
// --------------

export const getDate = (title: Title, dateProp = 'isbnFirstPublished') => {
  const date = get(title, dateProp, '');

  const dateLength = date.length;
  const momentDate = moment(
    date,
    dateLength === 8 ? 'YYYYMMDD' : dateLength === 6 ? 'YYYYMM' : 'YYYY'
  );

  return {
    momentDate,
    format: () => {
      if (!momentDate.isValid()) {
        return null;
      }

      const format =
        momentDate.isBefore(moment().subtract(10, 'years')) || dateLength === 4
          ? 'YYYY'
          : dateLength === 6
          ? 'MM-YYYY'
          : UI_DATE_FORMAT;
      return momentDate.format(format);
    },
    isInFuture: () => {
      if (!momentDate.isValid()) {
        return false;
      }
      return momentDate.isAfter();
    },
  };
};

export const getSupplyDate = (title: Title) => {
  return title.availability.supplyDate
    ? onixToMoment(title.availability.supplyDate).format('DD-MM-YYYY')
    : '';
};

export const showOrderTime = (title: Title, userRoles: UserRole[]) => {
  if (title.orderTime == null) return false;
  if (['10', '12', '31', '32'].includes(title.availability.code)) return false;
  if (!title.isOrderableByBookstore) return false;
  if (
    !userRoles.some((role) =>
      [
        'ROLE_ADMIN',
        'ROLE_DISTRIBUTOR',
        'ROLE_DATA_CONSUMER',
        'ROLE_DATA_PRODUCER',
        'ROLE_BOOKSTORE',
      ].includes(role)
    )
  )
    return false;
  return true;
};

export const getOrderTime = (title: Title) => {
  let translationKey = '';
  if (title.orderTime === 0) translationKey = 'zero';
  if (title.orderTime === 1) translationKey = 'one';
  if (title.orderTime != null && title.orderTime !== '' && title.orderTime > 1)
    translationKey = 'greaterThanOne';
  const days = title?.orderTime?.toString();
  return {
    translationKey,
    days,
  };
};

// --------------
// Product form
// --------------

export const isPhysicalBook = (productForm: Maybe<ProductForm>) => {
  return Boolean(productForm?.book && !productForm?.digital);
};

export const isEBook = (productForm: Maybe<ProductForm>) => {
  return Boolean(productForm?.book && productForm?.digital);
};

export const isNonBook = (productForm: Maybe<ProductForm>) => {
  return !productForm?.book;
};

export const getProductForm = <T extends { productForm: ProductForm }>(
  title: T,
  full = false
): string => {
  return title.productForm
    ? full
      ? title.productForm.label || title.productForm.shortLabel
      : title.productForm.shortLabel || title.productForm.label
    : '';
};

export const getFullProductForm = (title: TitleFull): string => {
  return `${isEBook(title.productForm) ? title.productForm.label : getProductForm(title, true)}`;
};

export const getProductFormDetails = (title: TitleFull): string => {
  // Show eBookType when eBook, otherwise show details. Exception for code EA when not an eBook
  if (
    title.productForm.code === 'EA' &&
    title.productForm.details?.every(({ eBookType }) => eBookType == null)
  )
    return '';
  return (
    title.productForm?.details?.reduce((prev, { label, eBookType }) => {
      return prev === ''
        ? ` - ${eBookType != null ? eBookType.label : label}`
        : `${prev}, ${eBookType != null ? eBookType.label : label}`;
    }, '') ?? ''
  );
};

export const getAdditionalProductDescription = (title: TitleFull): Maybe<string> => {
  return title.productForm.additionalDescription;
};

// --------------
// FUND
// --------------

export const getFundCode = (title: TitleFull) => {
  return (title.fund && title.fund.code) || null;
};

// --------------
// NUR
// --------------

export const getNur = (title: TitleFull) => {
  if (!title.nur) {
    return null;
  }
  return `${title.nur.code} - ${title.nur.label}`;
};

// --------------
// Thema
// --------------

export const getThema = (title: TitleFull) => {
  if (!title.themas) {
    return null;
  }
  return title.themas.map((thema) => `${thema.code} - ${thema.label}`).join(', ');
};

const sortByCode = sortBy('code');
const sortByMainSubject = sortBy((thema: Thema) => !thema.isMainSubject);

export const getThemas = <T extends { themas?: Themas }>({ themas }: T): Themas => {
  if (!themas) return [];

  return sortByMainSubject(sortByCode(themas));
};

export const getThemaQualifiers = <T extends { themaQualifiers?: ThemaQualifiers }>({
  themaQualifiers,
}: T): ThemaQualifiers => {
  if (!themaQualifiers) return [];

  return _sortBy(themaQualifiers, 'code');
};

export const isNotSelectableThemaQualifier = (themaCode: string) => {
  return [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '3A',
    '4T',
    '4Z',
    '5A',
    '5L',
    '5P',
    '5Y',
    '6A',
    '6B',
    '6C',
    '6D',
    '6E',
    '6F',
    '6G',
    '6H',
    '6J',
    '6K',
    '6L',
    '6M',
    '6N',
    '6P',
    '6Q',
    '6R',
    '6S',
    '6T',
    '6U',
    '6V',
    '6W',
    '6X',
  ].includes(themaCode);
};

// --------------
// Keywords
// --------------

export const getKeywords = (title: TitleFull): string => {
  return title.keywords ? title.keywords.map((keyword) => keyword.keyword).join(', ') : '';
};

// --------------
// Edition
// --------------

export const getEditionNumber = (title: TitleFull): string => {
  const editionNumber = title.editionNumber ? title.editionNumber.toString() : '';
  if (title.editionDescription && editionNumber) {
    return `${editionNumber} (${title.editionDescription})`;
  }
  return editionNumber;
};

// --------------
// Languages
// --------------

export const getLanguages = (title: TitleFull): string => {
  return title.languages ? title.languages.map((language) => language.label).join(', ') : '';
};

export const getOriginalLanguage = (title: TitleFull): string => {
  return title.originalLanguage ? title.originalLanguage.label : '';
};

// --------------
// Dimensions
// --------------

export function roundedMMToCM(valueInMM: number) {
  const valueInCM = valueInMM / 10;
  const rounded = Math.round(valueInCM);
  return valueInCM === rounded ? rounded : valueInCM;
}

export const getDimensions = (title: TitleFull, t: UseTranslationResponse['t']) => {
  if (!title.height && !title.width && !title.thickness) {
    return null;
  }

  return [
    title.height
      ? t('title_dimensions_value_height', { height: roundedMMToCM(title.height) })
      : null,
    title.width ? t('title_dimensions_value_width', { width: roundedMMToCM(title.width) }) : null,
    title.thickness
      ? t('title_dimensions_value_thickness', { thickness: roundedMMToCM(title.thickness) })
      : null,
  ]
    .filter(Boolean)
    .join(' x ');
};

export const getDuration = (title: TitleFull) => {
  return title.duration ? Math.round(moment.duration(title.duration).asMinutes()).toString() : null;
};

// --------------
// AVI-Levels
// --------------

export const getAviLevelsNew = (title: TitleFull) => {
  const aviLevelsNew = title.aviLevelsNew || [];
  return aviLevelsNew.map((level) => level.code).join(', ');
};

export const getAviLevelsOld = (title: TitleFull) => {
  const aviLevelsOld = title.aviLevelsOld || [];
  return aviLevelsOld.map((level) => level.code).join(', ');
};

// --------------
// Dimensions
// --------------

export function durationToMinutes(title: TitleFull) {
  return title.duration && Math.round(moment.duration(title.duration).asMinutes()).toString();
}

// --------------
// Age Range
// --------------

export function getAgeRange(title: TitleFull, t: UseTranslationResponse['t']) {
  const from = t(title.ageRange?.from?.qualifier, { count: title.ageRange?.from?.value });
  const to = t(title.ageRange?.to?.qualifier, { count: title.ageRange?.to?.value });

  const label =
    title.ageRange?.from?.qualifier && title.ageRange?.to?.qualifier
      ? from === to
        ? 'title_age_range_from_to_equal'
        : 'title_age_range_from_to'
      : title.ageRange?.from?.qualifier
      ? 'title_age_range_from'
      : null;

  return t(label, { from, to });
}

// --------------
// Contributors
// --------------

type ContributorRoleWeight = { [role in ContributorRole['code']]: number };

const sortContributors = sortBy([
  (c: Contributor) => contributorRoleWeight[c.role.code],
  (c: Contributor) => c.sequenceNumber,
  (c: Contributor) => (contributorIsPerson(c) ? c.lastName : c.corporateName),
]);

const groupContributors = groupBy((contributor: Contributor) => {
  return contributor.role.label;
});

export const getFullName = (contributor: Contributor) => {
  return contributorIsPerson(contributor)
    ? `${contributor.firstName || ''} ${contributor.lastName}`
    : contributor.corporateName;
};

export const getGroupedContributors = (title: Title) => {
  return groupContributors(sortContributors(title.contributors));
};

export const getContributorsList = (
  title: Title,
  maxContributors = MAX_CONTRIBUTORS
): { name: string; label: string; role: string }[] => {
  const sortedContributors = sortContributors(title.contributors);

  const contributors =
    sortedContributors.length > maxContributors
      ? sortedContributors.slice(0, maxContributors)
      : sortedContributors;

  return contributors.map((contributor) => {
    const name = getFullName(contributor);
    return {
      name,
      role: contributor.role.code === 'A01' ? '' : contributor.role.label,
      label: contributor.role.code === 'A01' ? name : `${name} (${contributor.role.label})`,
    };
  });
};

export const getContributors = (title: Title, maxContributors = MAX_CONTRIBUTORS): string => {
  return getContributorsList(title, maxContributors)
    .map(({ label }) => label)
    .join(', ');
};

// --------------
// Media
// --------------

export const isFrontCover = (media: Media): media is MediaCover =>
  media.type === 'MEDIA_TYPE_FRONT';
export const isBackCover = (media: Media): media is MediaCover => media.type === 'MEDIA_TYPE_BACK';
export const isMediaSample = (media: Media): media is MediaSample =>
  media.type === 'MEDIA_TYPE_SAMPLE_CONTENT';

const removeSchemaFromMediaCoverUrls = (media?: MediaCover): MediaCover | undefined =>
  media
    ? {
        ...media,
        cover: removeProtocol(media.cover),
        coverBig: removeProtocol(media.coverBig),
        original: removeProtocol(media.original),
        thumbnail: removeProtocol(media.thumbnail) as string,
      }
    : undefined;

const removeSchemaFromMediaSampleUrls = (media?: MediaSample): MediaSample | undefined =>
  media
    ? {
        ...media,
        original: removeProtocol(media.original),
      }
    : undefined;

export const getFrontCover = (
  title: Resource<any, any, { media?: Media[] }>
): MediaCover | undefined => {
  return (
    title._embedded.media &&
    removeSchemaFromMediaCoverUrls(title._embedded.media.find(isFrontCover))
  );
};

export const getBackCover = (title: Title): MediaCover | undefined => {
  return (
    title._embedded.media && removeSchemaFromMediaCoverUrls(title._embedded.media.find(isBackCover))
  );
};

export const getSampleContent = (title: Title): MediaSample | undefined => {
  return (
    title._embedded.media &&
    removeSchemaFromMediaSampleUrls(title._embedded.media.find(isMediaSample))
  );
};

// --------------
// Availability
// --------------
type AvailabilityItem = {
  availability: Availability;
};
export const getTitleStatus = <T extends AvailabilityItem>(title: T): StatusLabelStatus => {
  switch (title.availability.level) {
    case 100:
      return 'success';
    case 200:
      return 'warning';
    case 300:
      return 'error';
    default:
      return 'default';
  }
};

// --------------
// Price
// --------------

type PriceInfo = {
  price: number | undefined;
  label?: 'price_action' | 'price_gbp_action' | 'price_recommended' | 'price_gbp';
  icon?: 'lock' | 'unlock';
  labelOptions?: {
    start: string;
    end: string;
  };
  disabled?: boolean;
  disabledIcon?: boolean;
  tooltipLabel?: string;
};
export type PriceInfos = PriceInfo[];

// https://documents.calibrate.be/issues/69521
export const getPriceData = (title: Title): PriceInfos => {
  const now = moment();
  const priceActionStart = moment(title.priceAction && title.priceAction.startDate);
  const priceActionEnd = moment(title.priceAction && title.priceAction.endDate);
  const regulatedPriceActionStart = moment(
    title.priceRegulatedAction && title.priceRegulatedAction.startDate
  );
  const regulatedPriceActionEnd = moment(
    title.priceRegulatedAction && title.priceRegulatedAction.endDate
  );
  const priceRegulatedStart = moment(title.priceRegulated && title.priceRegulated.startDate);
  const priceRegulatedEnd = moment(title.priceRegulated && title.priceRegulated.endDate);
  const hasRegulatedPrice = title.priceRegulated && title.priceRegulated.price;
  const hasRegulatedActionPrice = title.priceRegulatedAction && title.priceRegulatedAction.price;
  const hasActionPrice = title.priceAction && title.priceAction.price;

  const futureActionPrice = hasActionPrice && now.isBefore(priceActionStart);
  const activeActionPrice = hasActionPrice && now.isBetween(priceActionStart, priceActionEnd);
  const noActiveActionPrice = !hasActionPrice || now.isAfter(priceActionEnd);
  const futureRegulatedPrice = hasRegulatedPrice && now.isBefore(priceRegulatedStart);
  const activeRegulatedPrice =
    hasRegulatedPrice && now.isBetween(priceRegulatedStart, priceRegulatedEnd);
  const pastRegulatedPrice = hasRegulatedPrice && now.isAfter(priceRegulatedEnd);
  const futureRegulatedActionPrice =
    hasRegulatedActionPrice && now.isBefore(regulatedPriceActionStart);
  const activeRegulatedActionPrice =
    hasRegulatedActionPrice && now.isBetween(regulatedPriceActionStart, regulatedPriceActionEnd);
  const pastRegulatedActionPrice = hasRegulatedActionPrice && now.isAfter(regulatedPriceActionEnd);

  // Scenario 1 ✔
  if (!hasRegulatedPrice && noActiveActionPrice && !hasRegulatedActionPrice) {
    return [
      {
        price: title.price,
        label: 'price_recommended',
      },
    ];
  }

  // Scenario 2 ✔
  if (!hasRegulatedPrice && futureActionPrice && !hasRegulatedActionPrice) {
    return [
      {
        price: title.price,
        label: 'price_recommended',
      },
    ];
  }

  // Scenario 3 ✔
  // "|| futureRegulatedPrice" was added as of ISS-04863 ✔
  if (
    (!hasRegulatedPrice || futureRegulatedPrice) &&
    activeActionPrice &&
    !hasRegulatedActionPrice
  ) {
    return [
      {
        price: title.priceAction!.price,
        label: 'price_action',
        labelOptions: {
          start: priceActionStart.format('DD/MM/YYYY'),
          end: priceActionEnd.format('DD/MM/YYYY'),
        },
      },
      {
        price: title.price,
        label: 'price_recommended',
        disabled: true,
      },
    ];
  }

  // Scenario 4 ✔
  if (futureRegulatedPrice && !hasRegulatedActionPrice) {
    return [
      {
        price: title.priceRegulated!.price,
        label: 'price_gbp',
        labelOptions: {
          start: priceRegulatedStart.format('DD/MM/YYYY'),
          end: priceRegulatedEnd.format('DD/MM/YYYY'),
        },
        icon: 'unlock',
      },
    ];
  }

  // Scenario 5 ✔
  // Scenario 8 ✔
  // Scenario 10 ✔
  if (
    activeRegulatedPrice &&
    // Scenario 5
    (!hasRegulatedActionPrice ||
      // Scenario 8
      pastRegulatedActionPrice)
  ) {
    return [
      {
        price: title.priceRegulated!.price,
        label: 'price_gbp',
        labelOptions: {
          start: priceRegulatedStart.format('DD/MM/YYYY'),
          end: priceRegulatedEnd.format('DD/MM/YYYY'),
        },
        icon: 'lock',
        tooltipLabel: 'price_gbp_current',
      },
    ];
  }

  // Scenario 6 ✔
  if (activeRegulatedPrice && futureRegulatedActionPrice) {
    return [
      {
        price: title.priceRegulated!.price,
        label: 'price_gbp',
        labelOptions: {
          start: priceRegulatedStart.format('DD/MM/YYYY'),
          end: priceRegulatedEnd.format('DD/MM/YYYY'),
        },
        icon: 'lock',
        tooltipLabel: 'price_gbp_current',
      },
      {
        price: title.priceRegulatedAction!.price,
        label: 'price_gbp_action',
        labelOptions: {
          start: regulatedPriceActionStart.format('DD/MM/YYYY'),
          end: regulatedPriceActionEnd.format('DD/MM/YYYY'),
        },
        icon: 'unlock',
      },
    ];
  }

  // Scenario 7 ✔
  if (activeRegulatedPrice && activeRegulatedActionPrice) {
    return [
      {
        price: title.priceRegulatedAction!.price,
        label: 'price_gbp_action',
        labelOptions: {
          start: regulatedPriceActionStart.format('DD/MM/YYYY'),
          end: regulatedPriceActionEnd.format('DD/MM/YYYY'),
        },
        icon: 'lock',
        tooltipLabel: 'price_gbp_current',
      },
      {
        price: title.priceRegulated!.price,
        label: 'price_gbp',
        disabled: true,
        icon: 'lock',
        labelOptions: {
          start: priceRegulatedStart.format('DD/MM/YYYY'),
          end: priceRegulatedEnd.format('DD/MM/YYYY'),
        },
      },
    ];
  }

  // Scenario 9 ✔
  if (pastRegulatedPrice && noActiveActionPrice) {
    return [
      {
        price: title.price,
        label: 'price_recommended',
      },
      {
        price: title.priceRegulated!.price,
        label: 'price_gbp',
        disabled: true,
        labelOptions: {
          start: priceRegulatedStart.format('DD/MM/YYYY'),
          end: priceRegulatedEnd.format('DD/MM/YYYY'),
        },
        icon: 'lock',
        disabledIcon: true,
        tooltipLabel: 'price_gbp_past',
      },
    ];
  }

  // Scenario 11 ✔
  if (pastRegulatedPrice && activeActionPrice) {
    return [
      {
        price: title.priceAction!.price,
        label: 'price_action',
        labelOptions: {
          start: priceActionStart.format('DD/MM/YYYY'),
          end: priceActionEnd.format('DD/MM/YYYY'),
        },
      },
      {
        price: title.price,
        label: 'price_recommended',
        disabled: true,
      },
      {
        price: title.priceRegulated!.price,
        label: 'price_gbp',
        labelOptions: {
          start: priceRegulatedStart.format('DD/MM/YYYY'),
          end: priceRegulatedEnd.format('DD/MM/YYYY'),
        },
        disabled: true,
        disabledIcon: true,
        icon: 'lock',
        tooltipLabel: 'price_gbp_past',
      },
    ];
  }

  // Scenario 12 ✔
  if (futureRegulatedPrice && futureRegulatedActionPrice && activeActionPrice) {
    return [
      {
        price: title.priceAction!.price,
        label: 'price_action',
        labelOptions: {
          start: priceActionStart.format('DD/MM/YYYY'),
          end: priceActionEnd.format('DD/MM/YYYY'),
        },
      },
      {
        price: title.price,
        label: 'price_recommended',
        disabled: true,
      },
    ];
  }

  console.warn('No scenario found for title', title);

  return [
    {
      price: title.price,
      label: 'price_recommended',
    },
  ];
};
