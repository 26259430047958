import React, { useState, useEffect } from 'react';
import { Prefix, Prefixes } from '../../../../../config/api/types';
import Table from '../../../../../components/Table/Table';
import { CellType, DataType } from '../../../../../components/Table/types';
import { getLink } from '../../../../../helpers/hateoas';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import Button from '../../../../../components/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmationDialog from '../../../../../components/ConfirmationDialog';
import { PromiseState } from 'react-redux-fetch';

type Props = {
  dispatchPrefixesGet: () => void;
  prefixes: Prefixes;
  removePrefix: (url: string) => void;
  removePrefixFetch: PromiseState;
  requestPending: boolean;
};

const PrefixList = ({
  prefixes,
  dispatchPrefixesGet,
  removePrefix,
  requestPending,
  removePrefixFetch,
}: Props) => {
  const { t } = useTranslation();
  const [prefixToDelete, setPrefixToDelete] = useState<Prefix | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (removePrefixFetch?.fulfilled) {
      setPrefixToDelete(null);
    }
  }, [removePrefixFetch]);

  const getPrefixError = (prefix: Prefix) => {
    if (removePrefixFetch?.rejected && !dialogOpen && prefixToDelete?.prefix === prefix.prefix) {
      const err = Array.isArray(removePrefixFetch.reason?.cause)
        ? removePrefixFetch.reason?.cause.find((c) => c.property_path === 'prefixId')
        : null;

      return <Typography color="error">{t(err?.message || 'prefix_used_error')}</Typography>;
    }
  };

  const headers = [
    { id: 'prefix', label: t('table_headers_prefix') },
    { id: 'delete', label: '' },
    { id: 'error', label: '' },
  ];
  const data: DataType = {
    resource: 'prefixes',
    rows: prefixes._embedded.items.map((prefix) => ({
      id: getLink(prefix, 'self') || '',
      cells: [
        {
          type: CellType.Text,
          data: prefix.prefix,
        },
        {
          type: CellType.Action,
          data: (
            <Button
              variant="text"
              round
              secondary
              aria-label="Primary"
              onClick={() => {
                setPrefixToDelete(prefix);
                setDialogOpen(true);
                // removePrefix(prefix._links.self.href);
              }}
              size="small"
              disabled={requestPending}
            >
              <DeleteIcon color="action" />
            </Button>
          ),
        },
        {
          type: CellType.Text,
          data: getPrefixError(prefix),
        },
      ],
    })),
  };

  return (
    <Grid item xs={6} sm={4} style={{ marginBottom: 16 }}>
      <Table
        data={data}
        headers={headers}
        dispatchFn={dispatchPrefixesGet}
        pagedResource={prefixes}
        hidePagination={prefixes.total < prefixes.limit}
        dense
        noBorder
      />
      {prefixToDelete && dialogOpen && (
        <ConfirmationDialog
          onCancel={() => setPrefixToDelete(null)}
          onConfirm={() => {
            if (prefixToDelete._links) {
              removePrefix(prefixToDelete._links.self.href);
            }
            setDialogOpen(false);
          }}
          title={t('confirm_remove_prefix_title', { prefix: prefixToDelete.prefix })}
          content={t('confirm_remove_prefix_content')}
        />
      )}
    </Grid>
  );
};

export default PrefixList;
