import merge from 'ramda/src/merge';
import { Actions } from './actions';
import { LOGGED_IN, LOGGED_OUT, IMPERSONATE } from './actionTypes';
import { AuthState, State } from './model';
import { User, UserRole } from '../../config/api/types';

type SecurityReducer = (state: State | undefined, action: Actions) => State;

const initialState: State = {
  token: '',
  authState: AuthState.NOT_AUTHENTICATED,
  impersonate: undefined,
};

const transitionFromNotAuthenticated = (state: State, action: Actions): State => {
  const mergeWithCurrentState = merge(state);
  switch (action.type) {
    case LOGGED_IN:
      return mergeWithCurrentState({
        token: action.payload.token,
        authState: AuthState.AUTHENTICATED,
      });
  }
  return state;
};

const transitionFromAuthenticated = (state: State, action: Actions): State => {
  switch (action.type) {
    case IMPERSONATE: {
      const { user, organisation } = action.payload;
      const impersonate: User | undefined = Boolean(user && organisation)
        ? {
            ...user,
            roles: Object.keys(organisation?.roles || {}).filter(
              (role) => organisation?.roles?.[role as UserRole] === true
            ) as UserRole[],
            _embedded: {
              ...user?._embedded,
              organisation,
            },
            _links: {
              ...user!._links,
              organisation: organisation!._links.self,
            },
          }
        : undefined;
      return { ...state, impersonate };
    }
    case LOGGED_OUT:
      return state.impersonate ? { ...state, impersonate: undefined } : initialState;
  }
  return state;
};

const securityReducer: SecurityReducer = (state = initialState, action) => {
  switch (state.authState) {
    case AuthState.NOT_AUTHENTICATED:
      return transitionFromNotAuthenticated(state, action);
    case AuthState.AUTHENTICATED:
      return transitionFromAuthenticated(state, action);
    default:
      return state;
  }
};

export default securityReducer;
