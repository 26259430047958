import React, { FC } from 'react';
import { Field as FinalFormField, FieldRenderProps } from 'react-final-form';
import NumberStepField from './NumberStepField';
import InputLabel from '@material-ui/core/InputLabel';
import { useStyles } from './TextField';

type NumberStepFieldWrapperProps = FieldRenderProps<any, any>;

const NumberStepFieldWrapper: FC<NumberStepFieldWrapperProps> = ({
  input: { name, onChange, value },
}) => {
  return <NumberStepField onChange={onChange} value={value || ''} name={name} />;
};

type Props = {
  name: string;
  label: string;
};

export const NumberStepFormField: FC<Props> = ({ name, label }) => {
  const classes = useStyles();
  return (
    <>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <div className={classes.root}>
        <FinalFormField component={NumberStepFieldWrapper} name={name} />
      </div>
    </>
  );
};
