import React, { useState } from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { MediaCover } from '../../../../../config/api/types';
import Placeholder from '../../../../../components/Placeholder';

const useStyles = makeStyles((theme: Theme) => ({
  coverRoot: {
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
  },
  cover: {
    cursor: 'pointer',
    position: 'relative',
  },
  img: {
    maxWidth: '100%',
  },
  searchIcon: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    background: 'rgba(255,255,255,0.6)',
    padding: '0.2rem',
    opacity: 0.8,
  },
  thumbnails: { '& > *': { marginRight: 4, marginTop: theme.spacing(1), cursor: 'pointer' } },
  activeThumbnail: { border: `1px solid ${theme.palette.primary.main}` },
}));

type Props = {
  frontCover?: MediaCover;
  backCover?: MediaCover;
  caption: string;
};

type View = {
  source: string;
  thumbnail?: string;
  caption?: string;
};

const Covers = ({ frontCover, backCover, caption }: Props) => {
  const classes = useStyles();
  const [lightBoxIsOpen, setLightBoxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const images: View[] = [frontCover, backCover]
    .filter((cover) => cover && (cover.thumbnail || cover.thumbnailSmall))
    .map((m) => ({
      source: m!.coverBig || m!.cover || '',
      thumbnail: m!.thumbnail || m!.thumbnailSmall,
      caption,
    }));

  function openLightBox() {
    setLightBoxOpen(true);
  }

  function closeLightBox() {
    setLightBoxOpen(false);
  }

  function setImageIndex(newIndex: number) {
    setCurrentImage(Math.abs(newIndex % images.length));
  }

  const imagesWithCover = images.filter((image) => Boolean(image.source));
  const currentImageIndex = currentImage < imagesWithCover.length ? currentImage : 0;

  return (
    <div className={classes.coverRoot}>
      {images.length > 0 ? (
        <div
          className={images[currentImage].source ? classes.cover : ''}
          onClick={images[currentImage].source ? openLightBox : () => {}}
        >
          <img
            src={images[currentImage].thumbnail}
            alt={`Cover ${images[currentImage].caption}`}
            className={classes.img}
          />
          {images[currentImage].source && (
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
          )}
        </div>
      ) : (
        <Placeholder width="100%" />
      )}

      {images.length > 1 && (
        <div className={classes.thumbnails}>
          {images.map((image, index) => (
            <img
              key={image.thumbnail}
              src={image.thumbnail}
              width="25%"
              onClick={() => setImageIndex(index)}
              className={index === currentImage ? classes.activeThumbnail : ''}
              alt={`thumbnail ${image.caption}`}
            />
          ))}
        </div>
      )}

      {lightBoxIsOpen && (
        <Lightbox
          mainSrc={imagesWithCover[currentImageIndex].source}
          nextSrc={imagesWithCover[(currentImageIndex + 1) % imagesWithCover.length].source}
          prevSrc={
            imagesWithCover[
              (currentImageIndex + imagesWithCover.length - 1) % imagesWithCover.length
            ].source
          }
          onCloseRequest={closeLightBox}
          onMovePrevRequest={() => setImageIndex(currentImageIndex - 1)}
          onMoveNextRequest={() => setImageIndex(currentImageIndex + 1)}
          reactModalStyle={{ overlay: { zIndex: 9999 } }}
        />
      )}
    </div>
  );
};

export default Covers;
