import { useFetch } from '@react-redux-fetch/hooks';
import React from 'react';
import { connect, DispatchProp, useSelector } from 'react-redux';
import { actions, PromiseState, ReduxFetch, ReduxFetchProps } from 'react-redux-fetch';
import { from, Immutable } from 'seamless-immutable';
import { Titles } from '../../../../config/api/types';
import { getLink, retrieveQueryParameter } from '../../../../helpers/hateoas';
import { ApiConfig, getSearchRequest, KEY_DROPDOWN_SEARCH } from '../api';
import { getTitleSearch } from '../selectors';

export type SearchFetch = PromiseState<Immutable<Titles>>;

export type SearchRenderProps = {
  dispatchSearchGet: (path: string) => void;
  searchFetch: SearchFetch;
  currentPath: string;
  initialInputValue?: string;
  onEmptyFocus?: () => void;
  shouldMakeCall: (value: string) => boolean;
  cancelRequest?: () => void;
};

type Props = {
  apiTitleSearchPath: string;
  onFulfil: ReduxFetchProps['onFulfil'];
  children: (args: SearchRenderProps) => React.ReactNode;
};

type DropdownSearchProps = Props & DispatchProp;

export const CatalogSearch = ({ apiTitleSearchPath, onFulfil, children }: Props) => {
  const [searchFetch, dispatchSearchGet, cancel] = useFetch(getSearchRequest);
  const titles = useSelector(getTitleSearch);

  const currentPath = getLink(titles, 'self') || apiTitleSearchPath;

  return (
    <>
      {children({
        searchFetch: from({
          ...searchFetch,
          value: titles || undefined,
        }) as PromiseState<Immutable<Titles>>,
        dispatchSearchGet,
        currentPath,
        shouldMakeCall: (value) => value.length === 0 || value.length > 3,
        initialInputValue: retrieveQueryParameter(currentPath, 'userInput') || '',
        cancelRequest: cancel,
      })}
    </>
  );

  // return (
  //   <ReduxFetch config={[ApiConfig.search(apiTitleSearchPath)]} onFulfil={onFulfil}>
  //
  //   </ReduxFetch>
  // );
};

export const DropdownSearch = connect()(
  ({ apiTitleSearchPath, onFulfil, children, dispatch }: DropdownSearchProps) => {
    return (
      <ReduxFetch config={[ApiConfig.dropdownSearch(apiTitleSearchPath)]} onFulfil={onFulfil}>
        {({ dropdownSearchFetch, dispatchDropdownSearchGet }) => {
          const currentPath = getLink(dropdownSearchFetch.value, 'self') || apiTitleSearchPath;

          const handleEmptyFocus = () => {
            dispatch(actions.clear(KEY_DROPDOWN_SEARCH));
          };

          return children({
            searchFetch: dropdownSearchFetch,
            dispatchSearchGet: dispatchDropdownSearchGet,
            currentPath,
            shouldMakeCall: (value) => value.length > 3,
            onEmptyFocus: handleEmptyFocus,
          });
        }}
      </ReduxFetch>
    );
  }
);
