import * as React from 'react';
import { useState } from 'react';
import { Dialog, Typography, IconButton, Theme } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import { TypographyProps } from '@material-ui/core/Typography';
import OrganisationDialogContent from './OrganisationDialogContent';

type Props = {
  children: TypographyProps['children'];
  organisationUrl: string;
  toggleButtonProps?: TypographyProps;
};

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(1, 2),
    minWidth: 400,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    padding: theme.spacing(0.5),
  },
}));

const OrganisationDialog = ({ organisationUrl, children, toggleButtonProps }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const classes = useStyles();

  const onClose = () => setDialogOpen(false);
  const onOpen = () => setDialogOpen(true);

  return (
    <>
      <Typography
        component="a"
        style={{ textDecoration: 'underline', cursor: 'pointer' }}
        display="inline"
        onClick={onOpen}
        {...(toggleButtonProps as any)}
      >
        {children}
      </Typography>
      {dialogOpen && (
        <Dialog open={dialogOpen} onClose={onClose}>
          <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
            {children}
            <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <OrganisationDialogContent organisationUrl={organisationUrl} />
          <DialogContent />
        </Dialog>
      )}
    </>
  );
};

export default OrganisationDialog;
