import React from 'react';
import { OrderFileResult, OrderFileResultItem } from '../../../../config/api/models/shop';
import { useTranslation } from 'react-i18next';
import { DataType, CellType } from '../../../../components/Table/types';
import Table from '../../../../components/Table';
import { IsbnLink } from '../../../title';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import { Tooltip, Typography } from '@material-ui/core';

type Props = {
  orderFileResult: OrderFileResult;
  fetchOrderFileResult: (url: string) => void;
};

const OrderFileResultTable = ({ orderFileResult, fetchOrderFileResult }: Props) => {
  const { t } = useTranslation();
  const pagedOrderFileResult = orderFileResult.pager;

  const headers = [
    { label: t('table_headers_lineNr') },
    { label: t('table_headers_order_reference') },
    { label: t('table_headers_isbn_or_ean') },
    { label: t('table_headers_title') },
    { label: t('table_headers_notification') },
  ];

  const data: DataType = {
    resource: 'orderFileResult',
    rows: pagedOrderFileResult._embedded.items.map((item: OrderFileResultItem) => ({
      id: item.lineNumber,
      cells: [
        {
          type: CellType.Text,
          data: item.lineNumber,
        },
        {
          type: CellType.Text,
          data: item.orderRef,
        },
        {
          type: CellType.Text,
          data: item._embedded ? (
            <IsbnLink
              orderLine={{
                titleId: getIdFromUrl(item._embedded.title._links.self.href),
                _embedded: { title: item._embedded.title },
              }}
            />
          ) : (
            item.gtin13
          ),
        },
        {
          type: CellType.Text,
          data: item._embedded ? (
            <Tooltip placement="top-start" title={item._embedded.title.title} enterDelay={500}>
              <Typography noWrap>{item._embedded.title.title}</Typography>
            </Tooltip>
          ) : (
            ''
          ),
          style: { maxWidth: '400px' },
        },

        {
          type: CellType.Text,
          data: item.message,
        },
      ],
    })),
  };

  return (
    <Table
      data={data}
      headers={headers}
      page={pagedOrderFileResult.page}
      pages={pagedOrderFileResult.pages}
      limit={pagedOrderFileResult.limit}
      total={pagedOrderFileResult.total}
      pagedResource={pagedOrderFileResult}
      dispatchFn={fetchOrderFileResult}
    />
  );
};

export default OrderFileResultTable;
