import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button';
import ROUTE_KEY from '../../../../../config/routes/routeKeys';
import usePath from '../../../../../config/routes/usePath';

const OrganisationsHeader = () => {
  const { t } = useTranslation();
  const path = usePath(ROUTE_KEY.ADMIN_ORG_CREATE);

  return (
    <Grid container justify="flex-end">
      <Button link={path}>{t('form_organisation_create')}</Button>
    </Grid>
  );
};

export default OrganisationsHeader;
