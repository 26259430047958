import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from '@react-redux-fetch/hooks';
import { useSelector } from 'react-redux';
import useApiRoute from '../../../../config/api/useApiRoute';
import BaseReactSelectField from '../../../../components/Form/BaseReactSelectField';
import { getRetourReasonsRequest } from './api';
import { getRetourReasons } from './selectors';

export const RetourReasonField: FC = () => {
  const url = useApiRoute('retourReasons') || '';
  const { t } = useTranslation();
  const [, fetchRetourReasons] = useFetch(getRetourReasonsRequest);
  const retourReasons = useSelector(getRetourReasons);

  useEffect(() => {
    fetchRetourReasons(url);
  }, [fetchRetourReasons, url]);

  return (
    <BaseReactSelectField
      label={t('form_retourReason') + ' *'}
      name="reason"
      options={retourReasons}
      // getOptionLabel={(option: string) => option}
      // getOptionValue={(option: string) => option}
      // disabled={!languages || !languages.value}
    />
  );
};
