import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Add } from '@material-ui/icons';
import { Organisation, PrefixDto } from '../../../../../config/api/types';
import Button from '../../../../../components/Button';
import FormTitle from '../../components/FormTitle';
import PrefixForm from '../../components/Publisher/PrefixForm';
import { ReduxFetch } from 'react-redux-fetch';
import { ApiConfig } from '../../../api';
import createFinalFormAsyncValidation from '../../../../../helpers/finalFormAsyncValidation';
import PrefixList from '../../components/Publisher/PrefixList';

type Props = {
  organisation: Organisation;
};

const Prefixes = ({ organisation }: Props) => {
  const { t } = useTranslation();
  const [showAddPrefixForm, setShowAddPrefixForm] = useState(false);

  const prefixApiPath = organisation._links ? organisation._links.prefixes.href : '';
  const { createSubmissionPromise, handleFulfil, handleReject } = createFinalFormAsyncValidation(
    'prefixFetch'
  );

  return (
    <>
      <FormTitle title={t('organisation_edit_publisher_prefix_title')} />
      <ReduxFetch
        config={[ApiConfig.getPrefixes(prefixApiPath), ApiConfig.removePrefix()]}
        fetchOnMount={['prefixes']}
        onFulfil={(key, state, dispatchFunctions) => {
          if (key === 'prefixFetch') {
            dispatchFunctions.dispatchPrefixesGet();
          }
        }}
      >
        {({ dispatchPrefixesGet, prefixesFetch, dispatchPrefixDelete, prefixFetch }) =>
          prefixesFetch.value ? (
            <>
              <PrefixList
                prefixes={prefixesFetch.value}
                dispatchPrefixesGet={dispatchPrefixesGet}
                removePrefix={dispatchPrefixDelete}
                removePrefixFetch={prefixFetch}
                requestPending={
                  (prefixFetch && prefixFetch.pending) || (prefixesFetch && prefixesFetch.pending)
                }
              />

              {showAddPrefixForm ? (
                <ReduxFetch
                  config={[ApiConfig.createPrefix(prefixApiPath)]}
                  onFulfil={handleFulfil}
                  onReject={handleReject}
                >
                  {({ dispatchPrefixPost }) => (
                    <PrefixForm
                      onSubmit={(formValues) => {
                        const prefixDto: PrefixDto = {
                          prefix: formValues.prefix,
                        };

                        dispatchPrefixPost(prefixDto);
                        return createSubmissionPromise();
                      }}
                      onClose={() => setShowAddPrefixForm(false)}
                    />
                  )}
                </ReduxFetch>
              ) : (
                <Button variant="text" onClick={() => setShowAddPrefixForm(true)}>
                  <Add /> {t('form_prefix_create')}
                </Button>
              )}
            </>
          ) : null
        }
      </ReduxFetch>
    </>
  );
};

export default Prefixes;
