import React from 'react';
import { TypographyProps } from '@material-ui/core/Typography';
import { Typography } from '@material-ui/core';
import { Fund } from '../../../../config/api/types';
import OrganisationDialog from '../../containers/OrganisationDialog';
import { getLink } from '../../../../helpers/hateoas';
import { useTranslation } from 'react-i18next';

type Props = {
  fund: Fund;
  withInfoModal?: boolean;
  typographyProps?: TypographyProps;
  isCbMember?: boolean;
};

const Distributor = ({ fund, withInfoModal, typographyProps, isCbMember }: Props) => {
  const { t } = useTranslation();
  const enableModal = withInfoModal && fund._links && fund._links.organisation;
  const distributorName = fund.organisation ? fund.organisation.name : null;

  if (!distributorName) {
    return null;
  }

  return enableModal ? (
    <OrganisationDialog
      organisationUrl={getLink(fund, 'organisation') || ''}
      toggleButtonProps={typographyProps}
    >
      {distributorName}
    </OrganisationDialog>
  ) : (
    <Typography display="inline" {...typographyProps}>
      {distributorName} {isCbMember ? t('cb') : ''}
    </Typography>
  );
};

export default Distributor;
