import { makeStyles, MenuItem, Theme } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { OptionProps } from 'react-select';
import Placeholder from '../../../../../components/Placeholder';
import StatusLabel from '../../../../../components/StatusLabel';
import { Title } from '../../../../../config/api/types';
import publisher from '../../../../organisation/publisher';
import { getDate, getFrontCover, getProductForm, getTitleStatus } from '../../../domain';
import { SelectOption } from './types';

interface Props extends OptionProps<SelectOption, any> {
  data: SelectOption & { showLanguageInTitle?: boolean };
}

const useStyles = makeStyles((theme: Theme) => ({
  cover: {
    maxHeight: 60,
    maxWidth: '100%',
  },
  menuItemRoot: {
    height: 80,
  },
  itemImgContainer: {
    width: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginRight: theme.spacing(1),
  },
  itemContentContainer: { width: '85%' },
  itemTitle: {
    fontSize: 15,
    lineHeight: 1.4,
    fontWeight: 500,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  itemInfo: { fontSize: 15, lineHeight: 1.4 },
}));

type OptionContentProps = {
  title: Title;
  showLanguageInTitle?: boolean;
};

export const OptionContent: FC<OptionContentProps> = ({ title, showLanguageInTitle }) => {
  const { t } = useTranslation();
  const metaData = [getProductForm(title), title.gtin13, getDate(title).format()].filter((i) =>
    Boolean(i)
  );
  const frontCover = getFrontCover(title);

  const classes = useStyles();

  return (
    <>
      <div className={classes.itemImgContainer}>
        {frontCover ? (
          <img
            src={frontCover.thumbnail || frontCover.thumbnailSmall}
            alt={`Cover ${title.title}`}
            className={classes?.cover}
          />
        ) : (
          <Placeholder alt={`Cover ${title.title}`} className={classes?.cover} />
        )}
      </div>
      <div className={classes.itemContentContainer}>
        <div className={classes.itemTitle}>
          <StatusLabel status={getTitleStatus(title)}>{`${title.title}${
            showLanguageInTitle && title.languages?.length
              ? title.languages.length === 1
                ? ` - ${title.languages[0].label}`
                : ` - ${t('title_more_languages')}`
              : ''
          }`}</StatusLabel>
        </div>
        <div className={classes.itemInfo}>{metaData.join(' - ')}</div>
        <div className={classes.itemInfo}>
          <publisher.components.Publisher publisher={title.publisher} imprint={title.imprint} />
        </div>
      </div>
    </>
  );
};

export function Option({
  innerRef,
  isFocused,
  innerProps,
  selectProps,
  data: { title, showLanguageInTitle, ...other },
}: Props) {
  const classes = useStyles();

  return (
    <MenuItem
      buttonRef={innerRef}
      selected={isFocused}
      component="div"
      className={classes.menuItemRoot}
      {...innerProps}
    >
      <OptionContent title={title} showLanguageInTitle={showLanguageInTitle} />
    </MenuItem>
  );
}
