import React, { FC } from 'react';
import {
  Typography,
  Box,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ConstrainedPageContent from '../../../components/ConstrainedPageContent';
import PageTitle from '../../../components/PageTitle';
import security from '../../security';
import { useSelector } from 'react-redux';
import { useFaq } from '../hooks/useFaq';
import ReactHtmlParser from 'react-html-parser';
import { Category } from '../types';

export const FAQPage: FC = () => {
  const { t } = useTranslation();
  const user = useSelector(security.selectors.getUser);
  const userRoles = useSelector(security.selectors.getUserRole) || [];

  const isMember = user?._embedded?.organisation?.hasMembership ?? false;

  const faq = useFaq(userRoles, isMember);
  const faqByCategory = Object.keys(Category)
    .map((key) => ({
      [key]: faq.filter((item) => item.category === key),
    }))
    .filter((cat) => Object.values(cat)[0].length > 0);

  return (
    <ConstrainedPageContent center={false}>
      <PageTitle>{t('nav_help_faq')}</PageTitle>
      <Box mb={4} mt={4}>
        {faqByCategory.map((cat) => {
          return (
            <Box key={`faq-${Object.keys(cat)[0]}`} mb={3}>
              <Box mb={1}>
                <Typography variant="h3">
                  <strong>{t(`faq_cat_${Object.keys(cat)[0].toLowerCase()}`)}</strong>
                </Typography>
              </Box>
              {Object.values(cat)[0].map(({ q, a }, index) => (
                <ExpansionPanel key={`faq-${Object.keys(cat)[0]}-${index}`}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <b>{q}</b>
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>{ReactHtmlParser(a)}</Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
            </Box>
          );
        })}
      </Box>
    </ConstrainedPageContent>
  );
};
