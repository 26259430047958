import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import React, { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AppNotification } from '../../../config/api/models/notifications';
import OrderConfirmedForBookstoreNotification from './OrderConfirmedForBookstoreNotification';
import OrderConfirmedNotification from './OrderConfirmedNotification';
import OrderWithErrorNotification from './OrderWithErrorNotification';

type Props = {
  onClose: () => void;
  anchorEl: HTMLElement;
  notifications: AppNotification[];
  onClearNotification: (notification: AppNotification) => void;
  onReadNotification: (notification: AppNotification, redirectPath?: string) => void;
  onLoadMore: () => void;
  hasMore: boolean;
};

const NotificationsMenu: FC<Props> = ({
  onClose,
  anchorEl,
  notifications,
  onClearNotification,
  onReadNotification,
  onLoadMore,
  hasMore,
}) => {
  const renderMenuItem = (notification: AppNotification, index: number) => {
    switch (notification.type) {
      case 'OrderConfirmedNotification':
        return (
          <OrderConfirmedNotification
            notification={notification}
            key={index + notification.message}
            onClick={(notification, redirectPath) => {
              onReadNotification(notification, redirectPath);
            }}
            onDelete={() => onClearNotification(notification)}
          />
        );
      case 'OrderConfirmedForBookstoreNotification':
        return (
          <OrderConfirmedForBookstoreNotification
            notification={notification}
            key={index + notification.message}
            onClick={(notification, redirectPath) => {
              onReadNotification(notification, redirectPath);
            }}
            onDelete={() => onClearNotification(notification)}
          />
        );
      case 'OrderWithErrorNotification':
        return (
          <OrderWithErrorNotification
            notification={notification}
            key={index + notification.message}
            onClick={(notification, redirectPath) => {
              onReadNotification(notification, redirectPath);
            }}
            onDelete={() => onClearNotification(notification)}
          />
        );
      default:
        return (
          <MenuItem
            onClick={() => {
              onReadNotification(notification);
              onClose();
            }}
            key={index + (notification as any).message}
            style={{ fontWeight: (notification as any).readAt == null ? 500 : 400 }}
          >
            {(notification as any).message}
          </MenuItem>
        );
    }
  };

  return (
    <Popper
      open={true}
      anchorEl={anchorEl}
      role={undefined}
      transition
      disablePortal
      placement="bottom-end"
    >
      {({ TransitionProps, placement }) => (
        <Grow {...TransitionProps}>
          <Paper
            style={{ maxHeight: '500px', minWidth: '400px', overflow: 'auto' }}
            id="scrollableTarget"
          >
            <InfiniteScroll
              dataLength={notifications.length}
              next={onLoadMore}
              hasMore={hasMore}
              scrollableTarget="scrollableTarget"
              loader={
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  mt={1}
                  mb={2}
                  key={0}
                >
                  <CircularProgress size={20} />
                </Box>
              }
            >
              <ClickAwayListener onClickAway={onClose}>
                <MenuList autoFocusItem={true} id="menu-list-grow">
                  {notifications.map((notification, index) => renderMenuItem(notification, index))}
                </MenuList>
              </ClickAwayListener>
            </InfiniteScroll>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

/*
<Menu
    classes={{
      paper: classes.root,
    }}
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={handleClose}
  >
    <MenuItem className={classes.markRead}>
      <p className={classes.markReadInnertext}>Markeer als gelezen</p>
    </MenuItem>
    {map(notification => (
      <MenuItem className={classes.menuItem} key={get('date')(notification)}>
        <p>{get('subject')(notification)}</p>
        <p>{get('description')(notification)}</p>
        <Divider />
      </MenuItem>
    ))(notifications)}
  </Menu>
 */
export default NotificationsMenu;
