import React, { FC } from 'react';
import { ThemeProvider } from '@material-ui/core';
import cloneDeep from 'lodash/cloneDeep';
import Button, { ButtonProps } from './Button';
import theme from '../config/theme';

const warningTheme = cloneDeep(theme);
warningTheme.palette.primary = cloneDeep(theme.palette.warning);

export const WarningButton: FC<ButtonProps> = (props) => {
  return (
    <ThemeProvider theme={warningTheme}>
      <Button {...props} />
    </ThemeProvider>
  );
};
