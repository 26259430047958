import React from 'react';
import { ApiConfig } from '../api';
import { PromiseState, ReduxFetch } from 'react-redux-fetch';
import { TitleFiles as TitleFilesType } from '../../../../../config/api/types';
import TitleTable from '../components/TitleTable';
import DelayedLoaderForFetch from '../../../../../components/DelayedLoaderForFetch';
import useApiRoute from '../../../../../config/api/useApiRoute';
import { Redirect } from 'react-router';
import DropZone from '../../../../core/dropZone';
import { useTranslation } from 'react-i18next';
import apiRoutes from '../../../../../config/api/routes';
import { useStartStop } from '../../../../../helpers/useStartStop';
import { PollForChanges } from '../../../../../components/PollForChanges/PollForChanges';

type ReduxFetchProps = {
  titleFilesFetch: PromiseState<TitleFilesType>;
  dispatchTitleFilesGet: (url?: string) => void;
};

const TitleFiles = () => {
  const { t } = useTranslation();
  const { start, stop, started } = useStartStop();
  const titleFilesApiPath = useApiRoute('titleFiles');

  if (!titleFilesApiPath) {
    return <Redirect to="/" />;
  }

  const apiPath = `${titleFilesApiPath}?limit=100`;

  return (
    <ReduxFetch config={[ApiConfig.files(apiPath)]} fetchOnMount>
      {({ titleFilesFetch, dispatchTitleFilesGet }: ReduxFetchProps) => {
        return (
          <>
            <DropZone
              message={t('title_files_upload')}
              activeMessage=""
              apiRoute={apiRoutes.create(apiPath)}
              accept=".onx,.txt,.csv,.xml,.xlsx"
              onUploadDone={(...args) => {
                dispatchTitleFilesGet(...args);
                start();
              }}
            />
            <DelayedLoaderForFetch fetchObj={titleFilesFetch}>
              <PollForChanges
                query={dispatchTitleFilesGet}
                onStopPolling={stop}
                pollForChanges={started}
                pagedResponse={titleFilesFetch?.value}
                pollingInterval={3000}
                withoutLoader
              >
                <TitleTable
                  titleFilesFetch={titleFilesFetch}
                  dispatchTitleFilesGet={dispatchTitleFilesGet}
                  loading={false}
                />
              </PollForChanges>
            </DelayedLoaderForFetch>
          </>
        );
      }}
    </ReduxFetch>
  );
};

export default TitleFiles;
