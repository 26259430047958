import React, { FC, useEffect } from 'react';
import ExportButton from '../../../../core/exports';
import { ButtonProps, Dialog, DialogActions, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { useDisclosure } from '../../../../../helpers/useDisclosure';

type Props = {
  url: string;
  total?: number;
  disabled?: boolean;
  btnLabel?: string;
};

const MAX_ROWS = 500;

const TitleExportButton: FC<Props> = ({ url, total = MAX_ROWS, disabled, btnLabel }) => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  useEffect(() => {
    if (!disabled) {
      onClose();
    }
  }, [disabled, onClose]);

  const btnProps: ButtonProps = {
    size: 'small',
    disabled,
  };
  const exportButton = (
    <ExportButton
      url={url}
      fetchConfig={{
        method: 'GET',
        fetchOptions: { headers: { accept: 'application/vnd.ms-excel' } },
      }}
      onClick={onClose}
      {...btnProps}
    >
      {btnLabel || t('catalog_export')}
    </ExportButton>
  );

  if (total <= MAX_ROWS) {
    return exportButton;
  }
  return (
    <>
      <Button {...btnProps} onClick={onOpen}>
        {t('catalog_export')}
      </Button>
      <Dialog open={isOpen} onClose={onClose} maxWidth="xs">
        <DialogTitle id="alert-dialog-title">{t('catalog_export')}</DialogTitle>
        <DialogContent>
          <Typography>
            {t('catalog_export_exceeded', {
              max: MAX_ROWS,
            })}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="text">
            {t('form_confirm_disagree')}
          </Button>
          {exportButton}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TitleExportButton;
