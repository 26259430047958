import React, { FC } from 'react';
import { IconButton, IconButtonProps, styled } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const StyledIconButton = styled(IconButton)({
  position: 'absolute',
  right: 8,
  top: 8,
});

export const ClosePopoverButton: FC<IconButtonProps> = (props) => {
  return (
    <StyledIconButton size="small" {...props}>
      <CloseIcon style={{ fontSize: '1.75rem' }} />
    </StyledIconButton>
  );
};
