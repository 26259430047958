import React from 'react';
import { RouteComponentProps } from 'react-router';
import ConstrainedPageContent from '../../../components/ConstrainedPageContent';
import OrdersTabHeader from '../components/OrdersTabHeader';
import OrdersHistoryConfirmed from '../components/ordersHistoryConfirmed/OrdersHistoryConfirmed';
import { useTranslation } from 'react-i18next';

type Props = RouteComponentProps;

export const OrdersHistoryHeader = (props: Props) => {
  const { t } = useTranslation();

  return (
    <OrdersTabHeader
      {...props}
      tabs={[
        { route: '/orders', label: t('page_tab_orderlines') },
        { route: '/orders/orders', label: t('page_tab_orders') },
      ]}
      title={t('page_title_order_overview')}
    />
  );
};

const OrdersConfirmedPage = (props: Props) => {
  return (
    <ConstrainedPageContent center={false}>
      <OrdersHistoryHeader {...props} />
      <OrdersHistoryConfirmed />
    </ConstrainedPageContent>
  );
};

export default OrdersConfirmedPage;
