import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  styled,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { BulkOrderTitle } from '../../../../config/api/models/shop';
import { TypographyProps } from '@material-ui/core/Typography';

type Props = {
  orders: BulkOrderTitle[];
  type: 'invalid' | 'valid';
};

const TableLinesHeader = styled(TableCell)({
  color: '#333333',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  paddingLeft: 0,
  paddingRight: '16px',
  borderBottom: '2px solid #646464',
  '&:first-child': {
    width: '40px',
  },
  '&:nth-child(2)': {
    width: '121px',
  },
  '&:nth-child(3)': {
    width: '64px',
  },
});

const TableLinesCell = styled(TableCell)({
  position: 'relative',
  padding: '0 16px 0 0',
  fontSize: '0.875rem',
  textAlign: 'left',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 400,
  lineHeight: 1.43,
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
  letterSpacing: '0.01071em',
  verticalAlign: 'inherit',
  '&:first-child': {
    width: '40px',
  },
  '&:nth-child(2)': {
    width: '121px',
  },
  '&:nth-child(3)': {
    width: '64px',
  },
});

const BodyTableRow = styled(TableRow)({
  height: '1.5rem',
});

const Title = styled((props: TypographyProps & { type?: Props['type'] }) => (
  <Typography {...props} />
))(
  // @ts-ignore
  ({ theme, type }) => ({
    marginBottom: '1rem',
    ...(type === 'valid' && { color: theme.palette.status.availableText }),
    fontWeight: theme.typography.fontWeightBold,
  })
);

const QuickOrdersToProcess = ({ orders, type }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Title variant="body1" color={type === 'invalid' ? 'error' : 'inherit'} type={type}>
        {type === 'invalid'
          ? t('quick_order_table_title_invalid')
          : t('quick_order_table_title_valid')}
      </Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableLinesHeader>#</TableLinesHeader>
            <TableLinesHeader>{t('quick_order_table_header_isbn')}</TableLinesHeader>
            <TableLinesHeader>{t('quick_order_table_header_amount')}</TableLinesHeader>
            <TableLinesHeader>
              {type === 'valid'
                ? t('quick_order_table_header_reference')
                : t('quick_order_table_header_title')}
            </TableLinesHeader>
            {type === 'invalid' && (
              <TableLinesHeader>{t('quick_order_table_header_error')}</TableLinesHeader>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((row, index) => (
            <BodyTableRow key={index}>
              <TableLinesCell>
                {row.combined && (
                  <span className="editor-error-tooltip editor-duplicate-tooltip editor-duplicate-tooltip-step2">
                    {t('quick_order_table_duplicate_isbn_step2')}
                  </span>
                )}
                {index + 1}
              </TableLinesCell>
              <TableLinesCell>{row.gtin13}</TableLinesCell>
              <TableLinesCell>{row.quantity}</TableLinesCell>
              <TableLinesCell>{type === 'valid' ? row.reference : row.title}</TableLinesCell>
              {type === 'invalid' && (
                <TableLinesCell style={{ color: '#d32f2f' }}>{row.error}</TableLinesCell>
              )}
            </BodyTableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default QuickOrdersToProcess;
