import { Box, FormControlLabel, Grid, Tooltip, Typography } from '@material-ui/core';
import { useFetch } from '@react-redux-fetch/hooks';
import { FormApi } from 'final-form';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DelayedLoader from '../../../../components/DelayedLoader';
import { BaseCheckBox } from '../../../../components/Form/CheckBox';
import Table from '../../../../components/Table';
import { CellType, DataType } from '../../../../components/Table/types';
import TextLink from '../../../../components/TextLink';
import useApiRoute from '../../../../config/api/useApiRoute';
import formatEuro from '../../../../helpers/formatEuro';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import { getLink } from '../../../../helpers/hateoas';
import organisation from '../../../organisation';
import { DeliveryNoteIcon } from '../../../retour/retourDetail/DeliveryNoteIcon';
import { IsbnLink } from '../../../title';
import DepartmentField from '../../../title/cart/components/shoppingCart/DepartmentField';
import { getOrderLineStatusesRequest } from '../../api';
import { EditableOrderLibraryState } from '../EditableOrderLibraryState';
import OrdersHistoryForm from '../ordersHistory/OrdersHistoryForm';
import { useOrderHistoryLinesFetch } from './useOrderHistoryLinesFetch';

const OrdersHistoryLinesForLibrary = () => {
  const { t } = useTranslation();
  const statusesApiUrl = useApiRoute('orderLineStatus');
  const {
    orderPath,
    pagedOrderLines,
    orderLinesFetch,
    fetchOrderLines,
    cancelFetch,
  } = useOrderHistoryLinesFetch({ processed: '' }, 'ordersHistoryLines');
  const [, fetchOrderLineStatuses] = useFetch(getOrderLineStatusesRequest);

  const [isRefetching, setIsRefetching] = useState(false);

  useEffect(() => {
    if (statusesApiUrl) fetchOrderLineStatuses(statusesApiUrl);
  }, [fetchOrderLineStatuses, statusesApiUrl]);

  const refetch = (url: string) => {
    setIsRefetching(true);
    cancelFetch();
    fetchOrderLines(url);
  };
  useEffect(() => {
    if (orderLinesFetch?.fulfilled && !orderLinesFetch?.pending) {
      setIsRefetching(false);
    }
  }, [orderLinesFetch]);

  const { departments } = organisation.hooks.useLibraryDepartmentsAndBookstores();

  const headers = [
    { id: 'ordered_at', label: t('table_headers_date'), width: '100px' },
    { id: 'order_ref', label: t('table_headers_library_order_reference'), width: '120px' },
    { id: 'line_ref', label: t('table_headers_line_reference_short'), align: 'center' },
    { id: 'department', label: t('table_headers_department'), width: '10rem' },
    { id: 'bookstore', label: t('table_headers_bookstore') },
    { id: 'isbn', label: t('table_headers_isbn'), width: '120px' },
    { id: 'title', label: t('table_headers_title'), width: '200px' },
    { id: 'num_pieces', label: t('table_headers_num_pieces'), width: '80px', align: 'center' },
    { id: 'gross_price', label: t('table_headers_gross_price'), width: '80px', align: 'right' },
    { id: 'price_total', label: t('table_headers_price_total'), width: '80px', align: 'right' },
    { id: 'state_bookstore', label: t('table_headers_status'), width: '120px' },
  ];

  const data: DataType | null = pagedOrderLines
    ? {
        resource: 'orderlines',
        rows: pagedOrderLines._embedded.items.map((orderLine) => {
          return {
            id: orderLine.id,
            cells: [
              {
                type: CellType.Text,
                data: moment(orderLine.orderedAt).format(`DD/MM/YYYY`),
              },
              {
                type: CellType.Text,
                data: (
                  <TextLink
                    underline
                    to={{
                      pathname: orderPath.replace(':id', getIdFromUrl(getLink(orderLine, 'order'))),
                      state: { from: window.location.pathname },
                    }}
                  >
                    {orderLine.orderReference.length > 20 ? (
                      <Tooltip title={orderLine.orderReference}>
                        <Typography noWrap style={{ maxWidth: 120 }}>
                          {orderLine.orderReference}
                        </Typography>
                      </Tooltip>
                    ) : (
                      orderLine.orderReference
                    )}
                  </TextLink>
                ),
              },
              {
                type: CellType.Text,
                data: (
                  <Box textAlign="center">
                    {orderLine.reference && <DeliveryNoteIcon note={orderLine.reference} />}
                  </Box>
                ),
              },
              {
                type: CellType.Text,
                data: orderLine._embedded.department?.title,
              },
              {
                type: CellType.Text,
                data: orderLine._embedded.bookstore?._embedded?.organisation.name,
              },
              {
                type: CellType.Text,
                data: (
                  <IsbnLink
                    orderLine={{
                      ...orderLine,
                      titleId: getIdFromUrl(orderLine._embedded.title._links.self.href),
                    }}
                  />
                ),
              },
              {
                type: CellType.Text,
                data: (
                  <Box width={200}>
                    <Tooltip
                      placement="top-start"
                      title={orderLine._embedded.title.title}
                      enterDelay={500}
                    >
                      <Typography noWrap>{orderLine._embedded.title.title}</Typography>
                    </Tooltip>
                  </Box>
                ),
              },
              {
                type: CellType.Text,
                data: (
                  <Box textAlign="center">
                    <Typography>{orderLine.quantity}</Typography>
                  </Box>
                ),
              },
              {
                type: CellType.Text,
                data: (
                  <Box textAlign="right">
                    <Typography>{formatEuro(orderLine.unitPrice)}</Typography>
                  </Box>
                ),
              },
              {
                type: CellType.Text,
                data: (
                  <Box textAlign="right">
                    <Typography>{formatEuro(orderLine.total)}</Typography>
                  </Box>
                ),
              },
              {
                type: CellType.Text,
                style: { paddingRight: 0 },
                data: (
                  <Box width={120}>
                    <EditableOrderLibraryState
                      orderLine={orderLine}
                      onRefetch={() => fetchOrderLines(pagedOrderLines._links.self.href)}
                    />
                  </Box>
                ),
              },
            ],
          };
        }),
      }
    : null;

  return (
    <DelayedLoader data={data}>
      {data != null && (
        <>
          <OrdersHistoryForm
            fetchOrders={refetch}
            url={pagedOrderLines?._links.self.href ?? ''}
            numResults={pagedOrderLines?.total}
            exportButtonLabel={t('orderlines_library_multiple_export')}
            role="library"
            extraFormFields={({
              form,
              values,
            }: {
              form: FormApi<Record<string, string>>;
              values: Record<string, string>;
            }) => (
              <Grid xs={12} item>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <BaseCheckBox
                        onChange={(e, checked) => {
                          form.change('to_process', checked || undefined);
                          form.submit();
                        }}
                      />
                    }
                    name="to_process"
                    label={t('orderlines_library_to_process')}
                    style={{ alignItems: 'center' }}
                    value={values.to_process}
                  />
                </Grid>
              </Grid>
            )}
          >
            {({ form, values }: { form: FormApi; values: Record<string, string> }) => (
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <organisation.components.BookstoresSelectField
                    label={t('table_headers_bookstore')}
                    placeholder={t('table_headers_bookstore')}
                    change={form.change}
                    bookstoreId={values.bookstore_id}
                    onClearValue={form.submit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DepartmentField
                    departments={departments}
                    isClearable
                    onClearValue={form.submit}
                  />
                </Grid>
              </Grid>
            )}
          </OrdersHistoryForm>
          <Table
            data={data}
            headers={headers}
            pagedResource={pagedOrderLines}
            loading={isRefetching}
            dispatchFn={refetch}
          />
        </>
      )}
    </DelayedLoader>
  );
};

export default OrdersHistoryLinesForLibrary;
