import React, { FC } from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

type Props = WithStyles & {
  gutterBottom?: boolean;
};

const styles = createStyles((theme: Theme) => ({
  title: {
    color: theme.palette.text.title,
    fontSize: 30,
    // marginBottom: theme.spacing.unit * 1.5,
  },
}));

const PageTitle: FC<Props> = ({ children, classes, gutterBottom = true }) => (
  <Typography variant="h1" gutterBottom={gutterBottom} className={classes.title}>
    {children}
  </Typography>
);

export default withStyles(styles)(PageTitle);
