import { Grid } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Field, { CheckBox } from '../../../../../../components/Form';
import InfoTooltip from '../../../../../../components/InfoTooltip';
import useApiRoute from '../../../../../../config/api/useApiRoute';
import security from '../../../../../security';
import EditionType from '../../containers/fields/EditionType';
import GprcCheckbox from '../../containers/fields/GprcCheckbox';
import LanguageField from '../../containers/fields/LanguageField';
import PublisherField from '../../containers/fields/PublisherField';
import Fieldset from '../Fieldset';
import FieldsetTitle from '../FieldsetTitle';
import SectionWrapper from '../SectionWrapper';
import LegalDepotNumberMask from '../textMask/LegalDepotNumberMask';
import BibliographicField from './BibliographicField';

type Props = {
  publisherName?: string;
  newTitleForm?: boolean;
  showIsBibliographicalVerified?: boolean;
  hideGprcCheckbox?: boolean;
  isBibliographicalVerified?: boolean;
};

const Bibliographic = ({
  publisherName,
  newTitleForm,
  showIsBibliographicalVerified,
  hideGprcCheckbox,
  isBibliographicalVerified,
}: Props) => {
  const { t } = useTranslation();
  const languagesApiLink = useApiRoute('titleLanguages') || '';
  const editionTypesLink = useApiRoute('titleEditionTypes') || '';
  const publishersApiLink = useApiRoute('publishers') || '';
  const userRole = useSelector(security.selectors.getUserRole);
  const isAdmin = userRole.includes('ROLE_ADMIN');

  return (
    <SectionWrapper id="bibliographic">
      <FieldsetTitle>{t('title_section_bib')}</FieldsetTitle>
      <Fieldset>
        <Grid container spacing={1}>
          {!newTitleForm && (
            <Grid xs={6} item>
              <PublisherField
                publisherName={publisherName}
                url={publishersApiLink}
                isBibliographicalVerified={isBibliographicalVerified}
                required
              />
            </Grid>
          )}
          <Grid xs={6} item>
            <BibliographicField
              component={Field}
              label={
                <>
                  {t('title_imprint')} <InfoTooltip title={t('title_imprint_tooltip')} inline />
                </>
              }
              type="text"
              name="imprint"
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid xs={3} item>
            <BibliographicField
              component={Field}
              label={
                <>
                  {t('title_firstPublished')}{' '}
                  {isBibliographicalVerified ? (
                    <InfoTooltip
                      title={t('title_isBibliographicalVerified_disabled_field')}
                      inline
                    />
                  ) : (
                    <InfoTooltip title={t('title_firstPublished_tooltip')} inline />
                  )}
                </>
              }
              type="date"
              name="isbnFirstPublished"
              disabled={isBibliographicalVerified}
            />
          </Grid>
          <Grid xs={3} item>
            <BibliographicField
              component={Field}
              label={
                <>
                  {`${t('title_edition_number')} (*)`}
                  <InfoTooltip title={t('title_edition_number_tooltip')} inline />
                </>
              }
              type="number"
              name="editionNumber"
            />
          </Grid>
          <Grid xs={6} item>
            <BibliographicField
              component={Field}
              label={t('title_edition_description')}
              type="text"
              name="editionDescription"
              helperText={t('title_edition_description_helperText')}
            />
          </Grid>
        </Grid>
      </Fieldset>
      <Fieldset>
        <LanguageField
          url={languagesApiLink}
          required
          isBibliographicalVerified={isBibliographicalVerified}
        />
      </Fieldset>
      <Fieldset>
        <Grid container spacing={1}>
          <Grid xs={6} item>
            <EditionType url={editionTypesLink} isMulti />
          </Grid>
          <Grid xs={6} item>
            <BibliographicField
              component={Field}
              label={
                <>
                  {t('title_legal_depot_number')}{' '}
                  <InfoTooltip title={t('title_legal_depot_number_tooltip')} inline />
                </>
              }
              type="text"
              name="legalDepotNumber"
              helperText={t('title_legal_depot_number_helperText')}
              inputComponent={LegalDepotNumberMask}
            />
          </Grid>
        </Grid>
      </Fieldset>

      {isAdmin && showIsBibliographicalVerified && (
        <Fieldset>
          <BibliographicField
            component={CheckBox}
            label={t('title_isBibliographicalVerified')}
            name="isBibliographicalVerified"
          />
        </Fieldset>
      )}

      {!hideGprcCheckbox && (
        <Fieldset>
          <GprcCheckbox />
        </Fieldset>
      )}
    </SectionWrapper>
  );
};

export default Bibliographic;
