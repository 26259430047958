import React from 'react';
import { PrefixApplication } from '../../../../config/api/types';
import { useTranslation } from 'react-i18next';
import { Grid, Divider, makeStyles } from '@material-ui/core';
import SubTitle from '../../../../components/SubTitle';
import Button from '../../../../components/Button';
import { calculatePrefixSizeBasedOnPrefix } from '../../domain';

type Props = {
  prefixApplication: PrefixApplication;
  generatePrefix: () => void;
};

const useStyles = makeStyles(() => ({
  verticalPadding: {
    padding: '8px 0px',
  },
}));

const PrefixApplicationPrefixReview = ({ prefixApplication, generatePrefix }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Grid item xs className={classes.verticalPadding}>
        <SubTitle>{t('prefix_application_requested_length')}</SubTitle>
        <Divider />
      </Grid>

      <Grid
        item
        container
        className={classes.verticalPadding}
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs className={classes.verticalPadding}>
          {t('isbn_requested_numbers', { count: +prefixApplication.prefixSize })}
        </Grid>

        <Grid item>
          <Button disabled={Boolean(prefixApplication.prefix)} onClick={generatePrefix}>
            {t('isbn_generate_prefix')}
          </Button>
        </Grid>
      </Grid>

      {prefixApplication.prefix && (
        <>
          <Grid item xs className={classes.verticalPadding}>
            <SubTitle>{t('prefix_application_generated_prefix')}</SubTitle>
            <Divider />
          </Grid>
          <Grid item xs className={classes.verticalPadding}>
            {prefixApplication.prefix} (
            {t('isbn_requested_numbers', {
              count: calculatePrefixSizeBasedOnPrefix(prefixApplication.prefix),
            })}
            )
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default PrefixApplicationPrefixReview;
