import React, { ReactNode, memo } from 'react';
import MUIAvatar from '@material-ui/core/Avatar/Avatar';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    avatar: {
      marginRight: '16px',
      backgroundColor: theme.palette.primary.main,
    },
  });

type Props = WithStyles & { children: ReactNode };

const Avatar = ({ classes, children }: Props) => (
  <MUIAvatar className={classes.avatar}>
    {typeof children === 'string'
      ? children
          .split(' ')
          .map((part) => part[0])
          .join('')
          .substring(0, 3)
          .toUpperCase()
      : ''}
  </MUIAvatar>
);

export default memo(withStyles(styles)(Avatar));
