import React, { FC, useState } from 'react';
import pick from 'lodash/pick';
import { Grid } from '@material-ui/core';
import Stepper from '../../../components/Stepper';
import PageTitle from '../../../components/PageTitle';
import { useTranslation } from 'react-i18next';
import OrganisationStep from '../containers/PrefixOrganisationStep';
import PrefixStep from '../containers/PrefixApplicationPrefixStep';
import OverviewStep from '../containers/PrefixOverviewStep';
import { Form } from 'react-final-form';
import { PrefixFormStep, PrefixApplicationFormValues } from '../types';
import { useFetch } from '@react-redux-fetch/hooks';
import { createPrefixApplicationRequest } from '../api';
import { PrefixApplicationDto } from '../../../config/api/types';
import { useSelector } from 'react-redux';
import { getUser } from '../../security/selectors';
import useApiRoute from '../../../config/api/useApiRoute';
import { useAsyncValidation } from '../../../helpers/finalFormAsyncValidation';
import { defaultPrefixApplicationFormValues } from '../domain';
import ConstrainedPageContent from '../../../components/ConstrainedPageContent';

const propertyMapper = {
  prefixSize: 'requestedIsbnNumbers',
};

const PrefixApplicationPage: FC = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [submitPrefixApplicationRequest, submitPrefixApplication] = useFetch(
    createPrefixApplicationRequest
  );
  const { createSubmissionPromise } = useAsyncValidation(
    submitPrefixApplicationRequest,
    propertyMapper
  );
  const apiRoute = useApiRoute('prefixApplicationCreate') || '';
  const user = useSelector(getUser);
  const [formValues, setFormValues] = useState<PrefixApplicationFormValues | null>(null);

  const steps: PrefixFormStep[] = [
    {
      name: t('form_isbn_step_publisher'),
      component: OrganisationStep,
      fields: ['organisation'],
    },
    {
      name: t('form_isbn_step_prefix'),
      component: PrefixStep,
      fields: ['prefix', 'prefixSize', 'masterPrefixToUse'],
    },
    {
      name: t('form_isbn_step_overview'),
      component: OverviewStep,
      fields: ['organisation', 'prefix', 'prefixSize', 'masterPrefixToUse'],
    },
  ];

  const currentStep = steps[activeStep];
  const Component = currentStep.component;

  const getDataToSubmit = (dto: PrefixApplicationFormValues) => {
    dto.prefixSize = dto.requestIsbnNumbers;
    if (currentStep.fields?.length) {
      const partialDto = {} as PrefixApplicationDto;
      currentStep.fields.forEach((field) => {
        partialDto[field] = dto[field] || (null as any);
      });
      return partialDto;
    }
    return dto;
  };

  const handleIsbnSubmit = (values: PrefixApplicationFormValues) => {
    setFormValues(values);
    const dataToSubmit = getDataToSubmit(values);
    submitPrefixApplication(apiRoute, dataToSubmit);

    return createSubmissionPromise().then((result) => {
      if (result) {
        const resultDto = result as Partial<PrefixApplicationDto>;
        const stepErrors = pick(resultDto, currentStep.fields || []);

        if (Object.keys(stepErrors).length) {
          return stepErrors;
        }

        if (activeStep < steps.length - 1) {
          setActiveStep(activeStep + 1);
        }
      }
    });
  };

  if (!user) {
    return null;
  }

  return (
    <ConstrainedPageContent center={false}>
      <Grid container direction="column">
        <Grid item md={7}>
          <PageTitle>{t('page_title_request_prefix')}</PageTitle>
        </Grid>
        <Grid item md={7} style={{ marginTop: 12, marginBottom: 20 }}>
          <Stepper
            steps={steps.map((step) => ({ label: step.name }))}
            alternativeLabel
            activeStep={activeStep}
            onSelectStep={setActiveStep}
          />
        </Grid>
      </Grid>
      <Form
        initialValues={formValues || defaultPrefixApplicationFormValues(user)}
        onSubmit={handleIsbnSubmit}
        keepDirtyOnReinitialize={true}
        render={({ handleSubmit, submitSucceeded, ...props }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Component
                user={user}
                changeStep={(value) => setActiveStep(activeStep + value)}
                submitSucceeded={Boolean(
                  submitSucceeded && submitPrefixApplicationRequest?.fulfilled
                )}
                {...props}
                submitting={Boolean(submitPrefixApplicationRequest?.pending)}
              />
            </form>
          );
        }}
      />
    </ConstrainedPageContent>
  );
};

export default PrefixApplicationPage;
