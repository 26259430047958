import React, { useEffect } from 'react';
import { Organisation } from '../../../../../config/api/types';
import { CircularProgress } from '@material-ui/core';
import Fieldset from '../../components/Organisation/Fieldset';
import { useFetch } from '@react-redux-fetch/hooks';
import { getLibraryRequest } from '../../../api';
import Departments from './Departments';
import { useSelector } from 'react-redux';
import { getLibrary } from '../../selectors';
import { getLink } from '../../../../../helpers/hateoas';
import FavoriteBookstores from './FavoriteBookstores';

type Props = {
  organisation?: Organisation;
};

const Library = ({ organisation }: Props) => {
  const [, fetchLibrary] = useFetch(getLibraryRequest);
  const library = useSelector(getLibrary);

  const libraryLink = getLink(organisation, 'library');

  useEffect(() => {
    if (libraryLink) {
      fetchLibrary(libraryLink);
    }
  }, [fetchLibrary, libraryLink]);

  if (!organisation) {
    return <CircularProgress />;
  }

  return (
    <>
      <Fieldset>
        <Departments library={library} />
      </Fieldset>

      <Fieldset>
        <FavoriteBookstores library={library} />
      </Fieldset>
    </>
  );
};

export default Library;
