import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  Typography,
  makeStyles,
  Theme,
  DialogProps,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import * as React from 'react';
import { useState } from 'react';
import Button from '../../../../../../components/Button';
import { useTranslation } from 'react-i18next';
import { FormApi } from 'final-form';
import { Wysiwyg } from '../../../../../../components/Form';

type Props = {
  label: string;
  name: string;
  text?: string;
  onChangeFormValue: (name: string, value?: any) => void;
  onSubmit: FormApi['submit'];
  error?: string;
  maxWidth?: DialogProps['maxWidth'];
  minHeight?: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    fontSize: '0.75rem',
    color: theme.palette.error.main,
  },
}));

const LongTextField = ({
  label,
  name,
  text,
  onChangeFormValue,
  onSubmit,
  error,
  maxWidth,
  minHeight,
}: Props) => {
  const { t } = useTranslation();
  const [showDialog, setShowDialog] = useState(false);
  const [originalText, setOriginalText] = useState('');
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <InputLabel>{label}</InputLabel>
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              onClick={() => {
                setOriginalText(text || '');
                setShowDialog(true);
              }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
        {error && <Typography className={classes.error}>{error}</Typography>}
        <Typography
          variant={'inherit'}
          dangerouslySetInnerHTML={{ __html: text || '' }}
          style={{ marginTop: 4 }}
        />
      </Grid>
      {showDialog && (
        <Dialog open fullWidth maxWidth={maxWidth} onClose={() => setShowDialog(false)}>
          <DialogTitle>{label}</DialogTitle>
          <DialogContent>
            <Wysiwyg name={name} autoFocus minHeight={minHeight} />
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              onClick={() => {
                setShowDialog(false);
                onChangeFormValue(name, originalText);
              }}
            >
              {t('form_action_cancel')}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                onSubmit();
                setShowDialog(false);
              }}
            >
              {t('form_action_save')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default LongTextField;
