import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import StarBorder from '@material-ui/icons/StarBorder';
import Star from '@material-ui/icons/Star';
import DeleteIcon from '@material-ui/icons/Delete';
import { CbRelation, CbRelationDto, CbRelations } from '../../../../../config/api/types';
import Table from '../../../../../components/Table/Table';
import { CellType, DataType } from '../../../../../components/Table/types';
import { getLink } from '../../../../../helpers/hateoas';
import Button from '../../../../../components/Button';
import ConfirmationDialog from '../../../../../components/ConfirmationDialog';

type Props = {
  dispatchCbRelationsGet: () => void;
  updateCbRelation: (url: string, body: CbRelationDto) => void;
  removeCbRelation: (url: string) => void;
  cbRelations: CbRelations;
  requestPending: boolean;
};

const CbRelationList = ({
  cbRelations,
  dispatchCbRelationsGet,
  updateCbRelation,
  requestPending,
  removeCbRelation,
}: Props) => {
  const { t } = useTranslation();
  const [cbRelationToDelete, setCbRelationToDelete] = useState<CbRelation | null>(null);

  const headers = [
    { id: 'id', label: t('table_headers_cb_relation') },
    { id: 'isPrimary', label: t('table_headers_primary') },
    { id: 'delete', label: '' },
  ];
  const data: DataType = {
    resource: 'cbRelations',
    rows: cbRelations._embedded.items.map((cbRelation) => ({
      id: getLink(cbRelation, 'self') || '',
      cells: [
        {
          type: CellType.Text,
          data: cbRelation.cbRelationId,
        },
        {
          type: CellType.Action,
          data: (
            <Button
              variant="text"
              round
              secondary
              aria-label="Primary"
              onClick={() => {
                if (cbRelation._links) {
                  updateCbRelation(cbRelation._links.self.href, {
                    cbRelationId: cbRelation.cbRelationId,
                    isPrimary: true,
                  });
                }
              }}
              size="small"
              disabled={requestPending}
            >
              {cbRelation.isPrimary ? <Star color="primary" /> : <StarBorder color="action" />}
            </Button>
          ),
        },
        {
          type: CellType.Action,
          data: (
            <Button
              variant="text"
              round
              secondary
              aria-label="Primary"
              onClick={() => {
                setCbRelationToDelete(cbRelation);
              }}
              size="small"
              disabled={requestPending}
            >
              <DeleteIcon color="action" />
            </Button>
          ),
        },
      ],
    })),
  };

  return (
    <Grid item xs={6} sm={4} style={{ marginBottom: 16 }}>
      <Table
        data={data}
        headers={headers}
        dispatchFn={dispatchCbRelationsGet}
        pagedResource={cbRelations}
        hidePagination={cbRelations.total < cbRelations.limit}
        dense
        noBorder
      />
      {cbRelationToDelete !== null && (
        <ConfirmationDialog
          onCancel={() => setCbRelationToDelete(null)}
          onConfirm={() => {
            if (cbRelationToDelete._links) {
              removeCbRelation(cbRelationToDelete._links.self.href);
            }
            setCbRelationToDelete(null);
          }}
          title={t('confirm_remove_cb_relation_title', { id: cbRelationToDelete.cbRelationId })}
          content={t('confirm_remove_cb_relation_content')}
        />
      )}
    </Grid>
  );
};

export default CbRelationList;
