import LaunchIcon from '@material-ui/icons/Launch';
import { useFetch } from '@react-redux-fetch/hooks';
import { debounce } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../../../../components/Button';
import RemoteAutocomplete, {
  RemoteAutocompleteProps,
} from '../../../../../../components/Form/RemoteAutocomplete';
import SetInitialValueWhenLoaded from '../../../../../../components/Form/SetInitialValueWhenLoaded';
import { Title } from '../../../../../../config/api/types';
import useApiRoute from '../../../../../../config/api/useApiRoute';
import ROUTE_KEY from '../../../../../../config/routes/routeKeys';
import usePath from '../../../../../../config/routes/usePath';
import { updateQueryParameters } from '../../../../../../helpers/hateoas';
import { Option } from '../../../../catalog/components/Search/Option';
import { getDate, getDetailLink } from '../../../../domain';
import { getTitleReplacementSearchRequest } from '../../api';
import { SelectedTitle } from '../../domain';
import { getTitleReplacementSearchTitles } from '../../selectors';

type Props = Partial<RemoteAutocompleteProps> & {
  label: string;
  name: string;
  selectedValue?: SelectedTitle;
};

const transformTitleToOption = (title: Title): SelectedTitle => {
  return {
    value: title.gtin13,
    label: `${title.gtin13} - ${title.title}${
      title.isbnFirstPublished ? ` - ${getDate(title).format()}` : ''
    }`,
    title,
  };
};

const SearchTitleField = ({ initialValue, selectedValue, name, ...other }: Props) => {
  const { t } = useTranslation();
  const url = useApiRoute('titles');
  const [titlesFetch, fetchTitles] = useFetch(getTitleReplacementSearchRequest);
  const titles = useSelector(getTitleReplacementSearchTitles);
  const detailPath = usePath(ROUTE_KEY.TITLE);
  const titleLink = selectedValue?.title ? getDetailLink(detailPath, selectedValue.title) : '';

  const createUrl = useCallback(
    (term: string) => {
      return url ? updateQueryParameters(url, { q: term }) : '';
    },
    [url]
  );

  const findInitialTitleFromIsbn = useCallback((title: Title) => initialValue === title.gtin13, [
    initialValue,
  ]);

  useEffect(() => {
    if (initialValue) {
      fetchTitles(createUrl(initialValue));
    }
  }, [fetchTitles, initialValue, createUrl]);

  return (
    <>
      <RemoteAutocomplete
        {...other}
        name={name}
        placeholder={t('search_placeholder')}
        createUrlFromInput={createUrl}
        promiseState={titlesFetch}
        makeRequest={debounce(fetchTitles, 200)}
        options={titles.map(transformTitleToOption)}
        filterOption={() => true}
        components={{ Option: Option }}
        isClearable
      />
      {initialValue && (
        <SetInitialValueWhenLoaded
          name={name}
          dataSelector={getTitleReplacementSearchTitles}
          findValue={findInitialTitleFromIsbn}
          getValue={transformTitleToOption}
        />
      )}
      {titleLink && (
        <Button variant="text" link={titleLink} openInNewWindow>
          {t('title_open_detail', { title: selectedValue?.title?.title })}{' '}
          <LaunchIcon fontSize="small" />
        </Button>
      )}
    </>
  );
};

export default SearchTitleField;
