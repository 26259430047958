import React from 'react';
import { Typography } from '@material-ui/core';

type Props = {
  title: string;
};

const FormSubTitle = ({ title }: Props) => {
  return (
    <Typography variant="subtitle2" style={{ textTransform: 'uppercase' }} gutterBottom>
      {title}
    </Typography>
  );
};

export default FormSubTitle;
