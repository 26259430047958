import React, { useState, useEffect } from 'react';
import { Prefix, Maybe, ReserveIsbnDto } from '../../../../config/api/types';
import Button from '../../../../components/Button';
import { Form } from 'react-final-form';
import { TextField } from '../../../../components/Form';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFetch } from '@react-redux-fetch/hooks';
import useApiRoute from '../../../../config/api/useApiRoute';
import { reserveIsbnNumbersRequest } from '../../api';
import { useAsyncValidation } from '../../../../helpers/finalFormAsyncValidation';
import { useSelector } from 'react-redux';
import { getReservedIsbnNumbers } from '../../selectors';
import useFileExport from '../../../../helpers/useFileExport';
import { updateQueryParameters } from '../../../../helpers/hateoas';

type Props = {
  prefix: Maybe<Prefix>;
  onSuccess: () => void;
};

type FormValues = {
  amount: number;
};

const useStyles = makeStyles(() => ({
  dialog: {
    width: 400,
  },
  text: {
    paddingBottom: 10,
  },
}));

const ReservePrefixNumbersButton = ({ prefix, onSuccess }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const reservedNumbers = useSelector(getReservedIsbnNumbers);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [reservedLength, setReservedLength] = useState<number | undefined>(undefined);

  const isbnReserveUrl = useApiRoute('reservedIsbnRequest') || '';
  const isbnBaseUrl = useApiRoute('isbnExport') || '';
  const firstReservedNumber = reservedNumbers?.length ? reservedNumbers[0].gtin13 : '';
  const isbnExportUrl = updateQueryParameters(isbnBaseUrl, {
    type: 'reserved',
    offset: firstReservedNumber,
    limit: reservedLength,
  });

  const [reserveNumbersResponse, reserveNumbers] = useFetch(reserveIsbnNumbersRequest);
  const { createSubmissionPromise } = useAsyncValidation(reserveNumbersResponse, {
    prefix: 'amount',
  });
  const [exportInProgress, exportReservedNumbers] = useFileExport(
    isbnExportUrl,
    undefined,
    undefined,
    onSuccess
  );

  const handleReserveNumbers = (values: FormValues) => {
    if (prefix) {
      setReservedLength(values.amount);
      const body: ReserveIsbnDto = { amount: values.amount, prefix: prefix.prefix };
      reserveNumbers(isbnReserveUrl, body);
      return createSubmissionPromise().then((result) => {
        if (!result) {
          setDialogOpen(false);
        } else {
          let typedResult = result as { amount: string };
          if (typedResult.amount === 'prefix_full') {
            typedResult.amount = t('prefix_reserve_numbers_full_error');
          }
          return typedResult;
        }
      });
    }
  };

  useEffect(() => {
    if (reserveNumbersResponse?.fulfilled) {
      exportReservedNumbers();
    }
  }, [reserveNumbersResponse, exportReservedNumbers]);

  return (
    <>
      <Button onClick={() => setDialogOpen(true)} disabled={exportInProgress} size="small">
        {t('prefix_reserve_numbers')}
      </Button>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Form
          onSubmit={handleReserveNumbers}
          keepDirtyOnReinitialize
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle>{t('prefix_reserve_numbers')}</DialogTitle>
              <DialogContent className={classes.dialog}>
                <Typography className={classes.text}>
                  {t('prefix_available_numbers', { count: prefix?.freeIsbnCount })}
                </Typography>
                <TextField
                  name="amount"
                  label={t('isbn_prefix_amount_label')}
                  type="number"
                  autoFocus
                />
              </DialogContent>
              <DialogActions>
                <Button type="submit" disabled={reserveNumbersResponse?.pending}>
                  {t('form_confirm_agree')}
                </Button>
                <Button onClick={() => setDialogOpen(false)}>{t('form_cancel')}</Button>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

export default ReservePrefixNumbersButton;
