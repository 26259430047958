import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { SvgIcon as MuiSvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

export enum Icons {
  LOCK = 'M12.64 7.712H3.36a.53.53 0 0 0-.54.54v7.207c0 .307.234.541.54.541h9.28a.53.53 0 0 0 .54-.54V8.251a.542.542 0 0 0-.54-.54zm-.54 7.207H3.9V8.793h8.2v6.126zM4.981 4.109A3.034 3.034 0 0 1 8.009 1.08a3.022 3.022 0 0 1 3.027 3.027v2.937h1.081V4.108C12.1 1.838 10.243 0 7.991 0c-2.252 0-4.09 1.838-4.09 4.108v3.027h1.081V4.108z',
  UNLOCK = 'M9.635 7.567H.53c-.3 0-.53.23-.53.53v7.072c0 .3.23.53.53.53h9.105c.3 0 .53-.23.53-.53V8.097c0-.3-.247-.53-.53-.53zm-.53 7.072H1.061V8.628h8.044v6.01zM11.969 0a4.04 4.04 0 0 0-4.03 4.031v2.97h1.06v-2.97a2.977 2.977 0 0 1 2.97-2.97 2.965 2.965 0 0 1 2.97 2.97v2.882H16V4.03C15.982 1.803 14.179 0 11.97 0z',
  CUP = 'M19 5h-2V3H7v2H5c-1.1 0-2 .9-2 2v1c0 2.55 1.92 4.63 4.39 4.94.63 1.5 1.98 2.63 3.61 2.96V19H7v2h10v-2h-4v-3.1c1.63-.33 2.98-1.46 3.61-2.96C19.08 12.63 21 10.55 21 8V7c0-1.1-.9-2-2-2zM5 8V7h2v3.82C5.84 10.4 5 9.3 5 8zm14 0c0 1.3-.84 2.4-2 2.82V7h2v1z',
  RIBBON = 'M9.68 13.69 12 11.93l2.31 1.76-.88-2.85L15.75 9h-2.84L12 6.19 11.09 9H8.25l2.31 1.84-.88 2.85zM20 10c0-4.42-3.58-8-8-8s-8 3.58-8 8c0 2.03.76 3.87 2 5.28V23l6-2 6 2v-7.72c1.24-1.41 2-3.25 2-5.28zm-8-6c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6 2.69-6 6-6z',
}

const useStyles = makeStyles((theme: Theme) => ({
  svgIconRoot: {
    // width: '1.6rem',
    // height: '1.6rem',
  },
}));

type Props = SvgIconProps & {
  icon: Icons;
};

const SvgIcon = (props: Props) => {
  const { icon, ...other } = props;
  const classes = useStyles();

  return (
    <MuiSvgIcon className={classes.svgIconRoot} {...other}>
      <path d={icon} />
    </MuiSvgIcon>
  );
};

export default SvgIcon;
