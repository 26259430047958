import React from 'react';
import { Maybe, TitleFull } from '../../../../config/api/types';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import ListHeading from './ListHeading';
import List from './List';
import ListItem from './ListItem';
import { snakeCase } from 'lodash';

export type formatFn = (
  title: TitleFull,
  t: UseTranslationResponse['t']
) => Maybe<string | React.ReactNode>;

type ValueItem = {
  label?: string;
  value: keyof TitleFull;
};

type FormatItem = {
  label: string;
  format: formatFn;
};

export type Item = ValueItem | FormatItem;

type Props = {
  title: TitleFull;
  items: Item[];
  heading: string;
};

const SimpleList = ({ title, heading, items }: Props) => {
  const { t } = useTranslation();

  const getLabelValueForFormatItem = (item: FormatItem) => {
    const label = item.label;
    const value = item.format(title, t);
    return { label, value };
  };

  const getLabelValueForValueItem = (item: ValueItem) => {
    const label = item.label || item.value;
    const titleValue = title[item.value];

    const value = item.value
      ? Array.isArray(titleValue)
        ? titleValue.join(', ')
        : titleValue
      : null;

    return { label, value };
  };

  const prepareItem = (item: Item) =>
    item.hasOwnProperty('format')
      ? getLabelValueForFormatItem(item as FormatItem)
      : getLabelValueForValueItem(item as ValueItem);

  const preparedItems = items.map(prepareItem).filter(({ value }) => Boolean(value));

  if (preparedItems.length === 0) {
    return null;
  }

  return (
    <>
      <ListHeading>{t(`title_heading_${heading}`)}</ListHeading>
      <List>
        {preparedItems.map(({ label, value }) => (
          <ListItem label={t(`title_${snakeCase(label)}`)} value={value} key={label} />
        ))}
      </List>
    </>
  );
};

export default SimpleList;
