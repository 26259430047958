import React, { FC } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import Multiline from '../../../../components/Multiline';

type Props = {
  onClose: () => void;
};
export const ConfirmSuccessDialog: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle style={{ textAlign: 'center' }}>{t('retour_confirmed_title')}</DialogTitle>
      <DialogContent>
        <Typography align="center">
          <Multiline>{t('retour_confirmed_body')}</Multiline>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box textAlign="center" width="100%">
          {/*<Button link={redirectToTitles ? titlesPath : undefined} onClick={onClose}>*/}
          <Button onClick={onClose}>{t('cart_order_confirmed_return')}</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
