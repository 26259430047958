import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import BaseReactSelectField from '../../../../components/Form/BaseReactSelectField';
import { useFetch } from '@react-redux-fetch/hooks';
import { getLibrariesWithOrdersRequest } from '../../api';
import { useSelector } from 'react-redux';
import { getLibraries } from '../../selectors';
import { Library } from '../../../../config/api/types';

type Props = {
  onClearValue?: () => void;
};

const LibrarySelectField: FC<Props> = ({ onClearValue }) => {
  const { t } = useTranslation();
  const [, fetchLibraries] = useFetch(getLibrariesWithOrdersRequest);
  const libraries = useSelector(getLibraries) || [];
  const getValue = (value: string | Library) => {
    if (!libraries || typeof value !== 'string') {
      return value;
    }
    return libraries.find((library) => library.libraryId === value);
  };

  useEffect(() => {
    // TODO get url from /routes
    fetchLibraries('/orders/libraries');
  }, [fetchLibraries]);

  return (
    <BaseReactSelectField
      label={t('form_library')}
      placeholder={t('form_library')}
      name="libraryId"
      options={libraries}
      getOptionValue={(option: Library) => option.libraryId}
      getOptionLabel={(option: Library) => option.organisation.name}
      disabled={!libraries.length}
      parse={(value: Library | null) => value?.libraryId}
      format={getValue}
      blurInputOnSelect
      isClearable
      onClearValue={onClearValue}
    />
  );
};

export default LibrarySelectField;
