import 'react-app-polyfill/ie11';
import './config/sentry/initSentry';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { StylesProvider, ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import configureStore from './config/store/storeConfig';
import './config/i18n';
import theme from './config/theme';
import app from './packages/app';
import initReduxFetch from './config/store/initReduxFetch';
import ErrorBoundary from './components/ErrorBoundary';

const [store, persistor] = configureStore(app.rootReducer, [...app.middleware]);

initReduxFetch();

ReactDOM.render(
  // Material-ui and react-final-form are not ready for strict mode
  // <React.StrictMode>
  <Sentry.ErrorBoundary
    fallback={'Oeps, er is iets fout gelopen. Probeer deze pagina opnieuw te laden.'}
  >
    <ThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <CssBaseline />
          <ErrorBoundary>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <app.containers.App />
              </PersistGate>
            </Provider>
          </ErrorBoundary>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  </Sentry.ErrorBoundary>,
  // </React.StrictMode>,
  document.getElementById('root')
);
