import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import apiRoutes from '../../../../../config/api/routes';
import useApiRoute from '../../../../../config/api/useApiRoute';
import createXmlHttpRequest from '../../../../../helpers/createXmlHttpRequest';
import { updateQueryParameter } from '../../../../../helpers/hateoas';
import security from '../../../../security';

type Props = {
  accept?: string;
  beforeUpload: (file: File) => { file: File; fileName: string };
};

const useMediaUpload = ({ beforeUpload, accept }: Props) => {
  const jwtToken = useSelector(security.selectors.getJwtToken);
  const mediaFilesApiPath = useApiRoute('titleMediaFiles');
  const impersonate = useSelector(security.selectors.getImpersonate);
  const handleDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length === 0) {
        return;
      }

      const { file, fileName } = beforeUpload(acceptedFiles[0]);

      const formData = new FormData();
      let url = apiRoutes.create(mediaFilesApiPath || '');

      if (impersonate) {
        url = updateQueryParameter(url, '_switch_user', impersonate.username);
      }

      formData.append('file', file, fileName);
      createXmlHttpRequest(
        'POST',
        url,
        formData,
        jwtToken
        // progress => calculateProgress(encodeURI(file.name), progress),
        // () => finishItemLoad(file.name)
        // (statusCode, response) => errorOnItem(encodeURI(file.name), statusCode, response)
      );
    },
    [jwtToken, mediaFilesApiPath, beforeUpload, impersonate]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept,
    multiple: false,
    maxSize: 50000000,
  });

  return { getRootProps, getInputProps, isDragActive };
};

export default useMediaUpload;
