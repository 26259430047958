import React from 'react';
import { IsbnSteppedFormProps } from '../types';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  makeStyles,
} from '@material-ui/core';
import IsbnRequestOverview from '../components/IsbnRequestOverview';
import { useHistory } from 'react-router-dom';
import usePath from '../../../config/routes/usePath';
import ROUTE_KEY from '../../../config/routes/routeKeys';
import { useSelector } from 'react-redux';
import { getPrefixByValue } from '../selectors';
import { getPublisher } from '../../organisation/admin/selectors';

const useStyles = makeStyles(() => ({
  dialog: {
    textAlign: 'center',
  },
  dialogButtons: {
    justifyContent: 'center',
  },
}));

const OverviewStep = ({
  changeStep,
  submitting,
  values,
  submitSucceeded,
  user,
  ...props
}: IsbnSteppedFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const route = usePath(user ? ROUTE_KEY.ISBN : ROUTE_KEY.LOGIN);
  const prefix = useSelector(getPrefixByValue(values.prefix || ''));
  const publisher = useSelector(getPublisher);
  const showBilling = values.prefix
    ? Boolean(prefix && prefix.billingType.code === 'FLEMISH_OLD' && !prefix.willNotBeBilled)
    : Boolean(values.requestIsbnNumbers && values.requestIsbnNumbers !== 'use_existing_prefix');
  const purchasOrderRequired =
    publisher?.isPurchaseOrderRequired && prefix?.billingType.code === 'FLEMISH_OLD';
  const disableSubmit =
    (showBilling && !values.billingConsent) ||
    submitting ||
    (purchasOrderRequired && !values.purchaseOrderConsent);

  const handleClose = () => {
    history.push(route);
  };

  const getDialogContent = () => {
    let result: string = t('isbn_application_dialog_content');
    if (prefix) {
      if (showBilling) {
        result += ` ${t('isbn_application_dialog_flemish_old')}`;
      } else if (prefix.billingType.code !== 'FLEMISH_OLD') {
        result += ` ${t('isbn_application_dialog_flemish_new')}`;
      }
    }
    return result;
  };

  return (
    <Grid container spacing={3} direction="column">
      <Grid item xs={12} md={7}>
        <IsbnRequestOverview
          values={values}
          showBilling={showBilling}
          purchaseOrderRequired={purchasOrderRequired}
        />
      </Grid>

      <Grid container md={7} xs={12} item justify="space-between">
        <Grid item>
          <Button onClick={() => changeStep(-1)} variant="outlined" disabled={submitting}>
            {t('form_previous')}
          </Button>
        </Grid>
        <Grid item>
          <Button type="submit" disabled={disableSubmit}>
            {t('isbn_submit_application')}
          </Button>
        </Grid>
      </Grid>

      <Dialog open={submitSucceeded} className={classes.dialog}>
        <DialogTitle>{t('isbn_application_dialog_title')}</DialogTitle>
        <DialogContent>{getDialogContent()}</DialogContent>
        <DialogActions className={classes.dialogButtons}>
          <Button onClick={handleClose}>
            {t(
              user ? 'isbn_application_dialog_button' : 'isbn_application_dialog_anonymous_button'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default OverviewStep;
