import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    listStyleType: 'none',
    margin: 0,
    marginBottom: theme.spacing(2),
    padding: 0,
    '& > *:nth-child(2n)': {
      backgroundColor: theme.palette.background.listEven,
    },
    '& > *:nth-child(2n+1)': {
      backgroundColor: theme.palette.background.listOdd,
    },
  },
}));

type Props = React.HTMLAttributes<HTMLElement>;

const List = ({ children }: Props) => {
  const classes = useStyles();

  return <ul className={classes.list}>{children}</ul>;
};

export default List;
