import React, { ChangeEvent, useEffect } from 'react';
import { useFetch } from '@react-redux-fetch/hooks';
import useApiRoute from '../../../../config/api/useApiRoute';
import { getPrefixesRequest } from '../../api';
import PrefixTable from '../components/PrefixTable';
import { useSelector } from 'react-redux';
import { getPrefixes } from '../../selectors';
import TableFilterWrapper from '../../../../components/Table/TableFilterWrapper';
import { SearchField } from '../../../../components/Form';
import BillingTypeFilter from '../components/filters/BillingTypeFilter';
import { useTranslation } from 'react-i18next';
import { updateQueryParameter, getLink } from '../../../../helpers/hateoas';
import StatusFilter from '../components/filters/StatusFilter';
import BilledFilter from '../components/filters/BilledFilter';
import WillNoteBeBilledFilter from '../components/filters/WillNotBeBilledFilter';
import useQueryParams from '../../../../helpers/useQueryParams';
import { BillingType } from '../../../../config/api/types';
import { useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';

type PrefixQueryParams = {
  q: string;
  billed: 'true' | 'false' | '';
  will_not_be_billed: 'true' | 'false' | '';
  active: 'true' | 'false' | '';
  billing_type: BillingType['code'];
};

const PrefixTableWrapper = () => {
  const { t } = useTranslation();
  const prefixApiRoute = useApiRoute('prefixes') || '';
  const [prefixesFetch, fetchPrefixes] = useFetch(getPrefixesRequest);
  const prefixes = useSelector(getPrefixes);
  const url = getLink(prefixes, 'self') || prefixApiRoute;
  const { queryObj } = useQueryParams<PrefixQueryParams>(getLink(prefixes, 'self') || '');
  const locationParams = useLocation();
  const keepFilters = locationParams.state?.keepFilters;

  const handleBillingTypeChange = (event: ChangeEvent<{ name?: string; value: string }>) => {
    fetchPrefixes(updateQueryParameter(url, 'billing_type', event.target.value));
  };

  const handleSearchTermChange = (term: string) => {
    fetchPrefixes(updateQueryParameter(url, 'q', term.trim()));
  };

  const handleStatusChange = (event: ChangeEvent<{ name?: string; value: string | undefined }>) => {
    fetchPrefixes(updateQueryParameter(url, 'active', event.target.value));
  };

  const handleBilledChange = (event: ChangeEvent<{ name?: string; value: string }>) => {
    fetchPrefixes(updateQueryParameter(url, 'billed', event.target.value));
  };

  const handleWillNotBeBilledChange = (event: ChangeEvent<{ name?: string; value: string }>) => {
    fetchPrefixes(updateQueryParameter(url, 'will_not_be_billed', event.target.value));
  };

  useEffect(() => {
    if (!prefixesFetch?.fulfilled && !prefixesFetch?.pending) {
      fetchPrefixes(url);
    }
  }, [prefixesFetch, fetchPrefixes, url]);

  return (
    <>
      <TableFilterWrapper>
        <Box flex={1}>
          <SearchField
            placeholder={t('form_placeholder_prefix_search')}
            searchCallback={handleSearchTermChange}
            initialValue={keepFilters ? queryObj.q : ''}
            isClearable
          />
        </Box>
        <BillingTypeFilter
          onChange={handleBillingTypeChange}
          initialValue={keepFilters ? queryObj.billing_type : ''}
        />
        <StatusFilter
          onChange={handleStatusChange}
          initialValue={keepFilters ? queryObj.active : ''}
        />
        <BilledFilter
          onChange={handleBilledChange}
          initialValue={keepFilters ? queryObj.billed : ''}
        />
        <WillNoteBeBilledFilter
          onChange={handleWillNotBeBilledChange}
          initialValue={keepFilters ? queryObj.will_not_be_billed : ''}
        />
      </TableFilterWrapper>

      <PrefixTable
        prefixes={prefixes}
        dispatchFn={fetchPrefixes}
        loading={prefixesFetch?.pending || false}
      />
    </>
  );
};

export default PrefixTableWrapper;
