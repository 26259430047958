import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { RouteChildrenProps } from 'react-router';
import equals from 'ramda/src/equals';
import split from 'ramda/src/split';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { NestedClientRoute } from '../../../../config/routes/types';
import MyNavLink from './NavLink';
import useNavPermission from './useNavPermission';

type Match = RouteChildrenProps['match'];

type Props = { match: Match; route: NestedClientRoute };

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    marginLeft: 32,
    marginRight: 32,
    backgroundColor: '#4A5860!important',
  },
}));

const isParentRoute = (parentRoute: string, match: Match) =>
  match && match.path ? equals(parentRoute, `/${split('/', match.path)[1]}`) : false;

const NavItem = ({ route, match }: Props) => {
  const classes = useStyles();
  const isRoute = isParentRoute(route.path, match);

  const visibleSubRoutes = route.subRoutes?.filter((r) => !r.hideInNav) || [];
  const allowedSubRoutes = useNavPermission(visibleSubRoutes);

  const subRoutes = allowedSubRoutes.map((subRoute) => {
    return <MyNavLink key={subRoute.key} match={match} route={subRoute} isChild />;
  });

  if (visibleSubRoutes?.length && !subRoutes?.length) {
    return null;
  }
  return (
    <div key={route.path}>
      <MyNavLink route={route} match={match} exact />

      {subRoutes && (
        <Collapse in={isRoute} unmountOnExit>
          <List disablePadding>{subRoutes}</List>
        </Collapse>
      )}
      <Divider classes={{ root: classes.divider }} />
    </div>
  );
};

export default NavItem;
