import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core';
import Content from './Content';

type Props = WithStyles & {
  open: boolean;
  onClose: () => void;
  variant: 'success' | 'warning' | 'error' | 'info';
  message: string;
  anchorOrigin?: {
    vertical: 'bottom' | 'top';
    horizontal: 'right' | 'left' | 'center';
  };
};

const styles = createStyles((theme: Theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

const AlertMessage = ({
  open,
  onClose,
  variant,
  message,
  classes,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
}: Props) => (
  <Snackbar
    anchorOrigin={anchorOrigin}
    open={open}
    autoHideDuration={10000}
    onClose={onClose}
    style={{ zIndex: 9999 }}
  >
    <Content variant={variant} className={classes.margin} message={message} onClose={onClose} />
  </Snackbar>
);

export default withStyles(styles)(AlertMessage);
