import React from 'react';
import ReactDropZone, { DropzoneProps } from 'react-dropzone';
import { Theme, createStyles, WithStyles, withStyles, Box } from '@material-ui/core';
import classNames from 'classnames';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';

type Props = WithStyles & {
  onDropAccepted: DropzoneProps['onDrop'];
  onDropRejected: DropzoneProps['onDropRejected'];
  accept?: DropzoneProps['accept'];
  activeMessage: string;
  message: string;
  validationErrors?: { count: number; message: string };
};

const styles = createStyles((theme: Theme) => ({
  dropZoneWrapper: {
    border: '2px #C8C8C8 dashed',
    padding: theme.spacing(1),
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
  dropZoneContent: {
    position: 'relative',
    width: '100%',
    minHeight: 80,
    backgroundColor: '#f2fbee',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.buttons.highlight,
  },
  cloudIcon: {
    height: 50,
    width: 50,
    marginRight: theme.spacing(1),
  },
  greenIcon: {},
  drop: {},
  active: {},
  reject: {
    background: theme.palette.error.light,
    color: theme.palette.error.main,
  },
  inputField: {
    display: 'block',
  },
  text: {},
  tooltip: {
    backgroundColor: theme.palette.messages.error,
    fontSize: 16,
  },
}));

const StyledDropZone = ({
  onDropAccepted,
  onDropRejected,
  classes,
  activeMessage,
  message,
  validationErrors,
  accept,
}: Props) => {
  return (
    <>
      <ReactDropZone onDrop={onDropAccepted} onDropRejected={onDropRejected} accept={accept}>
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
          return (
            <div className={classes.dropZoneWrapper} {...getRootProps()}>
              <div
                className={classNames(classes.dropZoneContent, {
                  [classes.active]: isDragActive,
                  [classes.reject]: isDragReject,
                })}
              >
                <input {...getInputProps()} />
                {isDragActive && !isDragReject ? (
                  <AddIcon className={classNames([classes.cloudIcon, classes.greenIcon])} />
                ) : (
                  <AttachFileIcon className={classes.cloudIcon} />
                )}
                {isDragActive && !isDragReject ? (
                  <Typography className={classes.text}>{activeMessage}</Typography>
                ) : (
                  <Typography className={classes.text}>{message}</Typography>
                )}
              </div>
            </div>
          );
        }}
      </ReactDropZone>
      {validationErrors?.message && (
        <Box mt={2}>
          <Alert severity="error">{validationErrors.message}</Alert>
        </Box>
      )}
    </>
  );
};

export default withStyles(styles)(StyledDropZone);
