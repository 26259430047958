import React, { FC, useEffect } from 'react';
import PageTitle from '../../../components/PageTitle';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import usePath from '../../../config/routes/usePath';
import ROUTE_KEY from '../../../config/routes/routeKeys';
import { useFetch } from '@react-redux-fetch/hooks';
import { getPrefixesRequest } from '../api';
import { getPrefixes } from '../selectors';
import { useSelector } from 'react-redux';
import { getUser } from '../../security/selectors';
import PrefixListItem from '../components/PrefixListItem';
import { Box, List } from '@material-ui/core';
import DelayedLoader from '../../../components/DelayedLoader';
import { Redirect } from 'react-router-dom';
import ConstrainedPageContent from '../../../components/ConstrainedPageContent';

const ISBNPrefixesPage: FC = () => {
  const { t } = useTranslation();
  const prefixApplicationPath = usePath(ROUTE_KEY.PREFIX_APPLICATION);
  const loginRoute = usePath(ROUTE_KEY.LOGIN);

  const [, fetchPrefixes] = useFetch(getPrefixesRequest);
  const user = useSelector(getUser);
  const prefixes = useSelector(getPrefixes);
  const prefixApiRoute =
    user?._embedded?.organisation?._links.prefixesWithRemainingIsbns?.href.replace(
      /(1|true)$/,
      'false'
    ) || '';

  useEffect(() => {
    if (prefixApiRoute) {
      fetchPrefixes(prefixApiRoute);
    }
  }, [prefixApiRoute, fetchPrefixes]);

  if (loginRoute && !user) {
    return <Redirect to={loginRoute} />;
  }

  return (
    <ConstrainedPageContent center={false}>
      <Box pb={2}>
        <PageTitle>{t('page_title_isbn_prefixes')}</PageTitle>
        <Button link={prefixApplicationPath}>{t('isbn_prefix_application')}</Button>
      </Box>

      <DelayedLoader data={prefixes}>
        <List>
          {prefixes?._embedded.items.map((prefix) => (
            <PrefixListItem prefix={prefix} key={prefix.prefix} />
          ))}
        </List>
      </DelayedLoader>
    </ConstrainedPageContent>
  );
};

export default ISBNPrefixesPage;
