import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { Form } from 'react-final-form';
import { Moment } from 'moment';
import FacetWrapper from '../../components/Facet/FacetWrapper';
import AutoSave from '../../../../../components/Form/AutoSave';
import Field from '../../../../../components/Form';

type Props = {
  onFilter: (from?: Moment, to?: Moment) => void;
  filters: { from?: Moment; to?: Moment };
  active?: boolean;
};

type FormValues = {
  from?: Moment;
  to?: Moment;
};

const blurOnEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
  if (e.key === 'Enter') {
    e.currentTarget.querySelector('input')?.blur();
  }
};

const FirstPublishedDateRangeFacet: FC<Props> = ({ onFilter, filters, active }: Props) => {
  const { t } = useTranslation();
  const { from, to } = filters;

  return (
    <FacetWrapper title={t('facet_firstPublishedDateRange')} collapsible={false} active={active}>
      <Form
        onSubmit={(values: FormValues) => {
          if ((!values.from || values.from.isValid()) && (!values.to || values.to.isValid())) {
            onFilter(values.from, values.to);
          }
        }}
        render={({ form: { submit } }) => (
          <>
            <AutoSave save={submit} />
            <Box mb={-1.5}>
              <Field
                type="date"
                name="from"
                placeholder={t('from')}
                defaultValue={from}
                onAccept={submit}
                isClearable
                onClear={submit}
                onKeyUp={blurOnEnter}
              />
            </Box>

            <Box mb={-1}>
              <Field
                type="date"
                name="to"
                placeholder={t('to')}
                defaultValue={to}
                onAccept={submit}
                isClearable
                onClear={submit}
                onKeyUp={blurOnEnter}
              />
            </Box>
          </>
        )}
      />
    </FacetWrapper>
  );
};

export default FirstPublishedDateRangeFacet;
