import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ConstrainedPageContent from '../../../components/ConstrainedPageContent';
import OrderBookstoreDetail from '../components/orderDetail/OrderBookstoreDetail';

type Props = RouteComponentProps<{ id: string }>;

const OrderByLibraryDetailPage = ({ match, history }: Props) => {
  return (
    <ConstrainedPageContent center={false}>
      <OrderBookstoreDetail
        orderId={match.params.id}
        goBackPath={history.location.state && history.location.state.from}
      />
    </ConstrainedPageContent>
  );
};

export default OrderByLibraryDetailPage;
