import { useTheme } from '@material-ui/core';
import React from 'react';
import SvgIcon, { Icons } from '../components/SvgIcon';

const usePrizeIconMap = () => {
  const theme = useTheme();

  return {
    '01': (
      <SvgIcon
        icon={Icons.CUP}
        style={{ color: '#FFD700', margin: '0 0.2rem 0 -0.2rem', display: 'block' }}
      />
    ),
    '02': (
      <SvgIcon
        icon={Icons.CUP}
        style={{ color: '#C0C0C0', margin: '0 0.2rem 0 -0.2rem', display: 'block' }}
      />
    ),
    '03': (
      <SvgIcon
        icon={Icons.RIBBON}
        style={{
          color: theme.palette.primary.highlight,
          margin: '0 0.2rem 0 -0.2rem',
          display: 'block',
        }}
      />
    ),
    '04': (
      <SvgIcon
        icon={Icons.RIBBON}
        style={{
          color: theme.palette.primary.highlight,
          margin: '0 0.2rem 0 -0.2rem',
          display: 'block',
        }}
      />
    ),
    '05': (
      <SvgIcon
        icon={Icons.RIBBON}
        style={{
          color: theme.palette.primary.highlight,
          margin: '0 0.2rem 0 -0.2rem',
          display: 'block',
        }}
      />
    ),
    '06': (
      <SvgIcon
        icon={Icons.CUP}
        style={{ color: '#cc6633', margin: '0 0.2rem 0 -0.2rem', display: 'block' }}
      />
    ),
    '07': (
      <SvgIcon
        icon={Icons.RIBBON}
        style={{
          color: theme.palette.primary.highlight,
          margin: '0 0.2rem 0 -0.2rem',
          display: 'block',
        }}
      />
    ),
  };
};

export default usePrizeIconMap;
