import React, { useState } from 'react';
import { PrefixApplication } from '../../../../config/api/types';
import { useTranslation } from 'react-i18next';
import { BaseTextField } from '../../../../components/Form/TextField';
import { Grid } from '@material-ui/core';
import Button from '../../../../components/Button';

type Props = {
  prefixApplication: PrefixApplication;
  onUpdate: (prefixApplication: PrefixApplication) => void;
};

const EditPrefixPurchaseOrderWrapper = ({ prefixApplication, onUpdate }: Props) => {
  const { t } = useTranslation();
  const [purchaseOrder, setPurchaseOrder] = useState<string>(
    prefixApplication.purchaseOrderNumber || ''
  );

  return (
    <Grid container direction="column">
      <Grid item xs={4}>
        <BaseTextField
          label={t('form_isbn_purchase_order_number')}
          value={purchaseOrder}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setPurchaseOrder(event.target.value)
          }
        />
      </Grid>
      <Grid item>
        <Button
          onClick={() => onUpdate({ ...prefixApplication, purchaseOrderNumber: purchaseOrder })}
        >
          {t('form_action_save')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default EditPrefixPurchaseOrderWrapper;
