import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { selectTitle } from '../../selectors';
import { OptionContent } from '../../../../catalog/components/Search/Option';
import EditButton from '../../../../detail/containers/EditButton';
import Button from '../../../../../../components/Button';

type Props = {
  onPrev: () => void;
};

const EditTitle: FC<Props> = ({ onPrev }) => {
  const foundTitle = useSelector(selectTitle);
  const { t } = useTranslation();
  const { pathname } = useLocation();

  if (!foundTitle) {
    return null;
  }

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Typography variant="h6">{t('form_validation_title_exists')}</Typography>
      </Grid>
      <Grid item>
        <Box display="flex" alignItems="center">
          <OptionContent title={foundTitle} />
        </Box>
      </Grid>
      <Grid item>
        <Button onClick={onPrev} variant="outlined" style={{ marginRight: 16 }}>
          {t('form_back')}
        </Button>
        <EditButton
          title={foundTitle}
          size="medium"
          pendingComponent={<CircularProgress size={20} />}
          deniedComponent={
            <Box width={400} mt={2}>
              <Alert severity="warning">{t('form_validation_cannot_edit')}</Alert>
            </Box>
          }
          redirectPath={pathname}
        />
      </Grid>
    </Grid>
  );
};
export default EditTitle;
