import React, { ComponentType } from 'react';
import { styled } from '@material-ui/styles';
import { Link, LinkProps } from 'react-router-dom';
import { Theme } from '@material-ui/core';

type OwnProps = {
  theme?: Theme;
  underline?: boolean;
};
type Props = LinkProps & OwnProps;

const TextLink = styled<ComponentType<Props>>(({ underline, ...other }) => <Link {...other} />)(
  ({ theme, underline }: OwnProps) => ({
    color: 'inherit',
    textDecoration: underline ? 'underline' : 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  })
);

export default TextLink;
