import { ListItemText, Popover, Typography } from '@material-ui/core';
import memoize from 'lodash/memoize';
import memoizeOne from 'memoize-one';
import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClosePopoverButton } from '../../../../../components/ClosePopoverButton';
import TreeSelect from '../../../../../components/Form/TreeSelect/TreeSelect';
import { TreeData, Value } from '../../../../../components/Form/TreeSelect/types';
import { QueryObjectValue } from '../../../../../helpers/elasticQueryDSL';
import { useDisclosure } from '../../../../../helpers/useDisclosure';
import FacetWrapper from '../../components/Facet/FacetWrapper';

type Props = {
  value?: QueryObjectValue;
  onFilter: (themas: string[]) => void;
  treeData: TreeData[];
  labelKey?: string;
  titleKey?: string;
};

const toArray = <T extends string | number | boolean>(value?: T | T[]): T[] =>
  !value ? [] : Array.isArray(value) ? value : [value];

const getLabel = <TValue extends Value>(item: TValue) => (
  <ListItemText primary={`${item.code} - ${item.label}`} style={{ flexGrow: 0 }} />
);

const removeWildCard = (item: string | number | boolean): typeof item =>
  typeof item === 'string' ? item.replace('*', '') : item;

const findInTree = memoizeOne((treeData: TreeData[]) => {
  return memoize(function findByCode(code: string) {
    const codeParts = code.split('');

    let subCode = '';
    let subTree = treeData;
    let subTreeRoot: TreeData | null = null;

    codeParts.forEach((letter) => {
      subCode += letter;
      subTreeRoot = subTree.find((item) => item.code === subCode) || null;

      if (subTreeRoot && subTreeRoot.children) {
        subTree = subTreeRoot.children;
      }
    });

    return subTreeRoot;
  });
});

export const ThemaBaseFacet: FC<Props> = ({
  value,
  onFilter,
  treeData,
  labelKey = 'facet_thema_select',
  titleKey = 'facet_thema',
}) => {
  const { t } = useTranslation();
  const [searchValue, handleSearch] = useState('');
  const popoverRef = useRef<HTMLDivElement>(null);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const findInTreeData = findInTree(treeData);

  const valueList = toArray(value).map(removeWildCard);

  const valueCollection = (valueList
    .map((code) => typeof code === 'string' && findInTreeData(code))
    .filter(Boolean) as unknown) as TreeData[];

  const selectableTreeData = treeData.map((data) => ({ ...data, notSelectable: false }));

  return (
    <FacetWrapper title={t(titleKey)} collapsible={false} active={valueCollection.length > 0}>
      <div
        ref={popoverRef}
        onClick={onOpen}
        style={{ minHeight: 24, marginTop: 4, cursor: 'pointer' }}
      >
        {valueCollection.map((thema) => (
          <Typography noWrap key={thema.code}>
            {thema.code} - {thema.label}
          </Typography>
        ))}

        {valueCollection.length === 0 && (
          <Typography variant="body2" style={{ textDecoration: 'underline' }}>
            {t(labelKey)}
          </Typography>
        )}
      </div>
      {popoverRef?.current && (
        <Popover open={isOpen} anchorEl={popoverRef.current} onClose={onClose}>
          <ClosePopoverButton onClick={onClose} />
          {selectableTreeData && (
            <TreeSelect
              treeData={selectableTreeData}
              searchValue={searchValue}
              onSearch={handleSearch}
              treeDefaultExpandAll={Boolean(searchValue)}
              onSelect={(values: TreeData[]) => {
                onFilter(values.map((t) => `${t.code}*`));
              }}
              getLabel={getLabel}
              value={valueCollection}
              width={800}
            />
          )}
        </Popover>
      )}
    </FacetWrapper>
  );
};
