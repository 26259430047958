import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import MUIPagination, { PaginationProps } from 'material-ui-flat-pagination';

const buttonStyles = (theme: Theme) => ({
  '&&': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  rootStandard: buttonStyles(theme),
  rootEnd: buttonStyles(theme),
  rootCurrent: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    boxShadow: 'none',
  },
  rootEllipsis: {
    backgroundColor: 'transparent!important',
  },
}));

type Props = PaginationProps & {};

const Pagination = (props: Props) => {
  const classes = useStyles();

  return <MUIPagination {...props} classes={classes} />;
};

export default Pagination;
