import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  flex?: boolean;
  center?: Breakpoint | false;
  lg?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: Props) => ({
    maxWidth: theme.spacing(props.lg ? 196 : 160),
    display: props.flex ? 'flex' : 'block',
    ...((props.center || typeof props.center === 'undefined') && {
      [theme.breakpoints.up(props.center || 'xl')]: {
        margin: '0 auto',
      },
    }),
  }),
}));

const ConstrainedPageContent = (props: Props) => {
  const { flex, center, lg, ...divProps } = props;
  const classes = useStyles(props);

  return <div {...divProps} className={classes.root} />;
};

export default ConstrainedPageContent;
