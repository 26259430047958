import { Box, Grid, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import _ from 'lodash';
import React, { ComponentType, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import ShowMoreButton from '../../../../components/ShowMoreButton';
import { EmbeddedTitle, Language } from '../../../../config/api/types';
import ROUTE_KEY from '../../../../config/routes/routeKeys';
import usePath from '../../../../config/routes/usePath';
import { onixToMoment } from '../../../../helpers/date';
import formatEuro from '../../../../helpers/formatEuro';
import { getDetailLink, getFrontCover } from '../../domain';
import SmallFrontCover from './SmallFrontCover';
import TitleStatus from './TitleStatus';

type MatchingProps = {
  level?: number;
};
type SC = {
  theme: Theme;
};
const MatchingTitle = styled<ComponentType<MatchingProps & LinkProps>>(({ level, ...other }) => (
  <Link {...other} />
))(({ theme, level }: MatchingProps & SC) => ({
  // marginTop: theme.spacing(2),
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  cursor: 'pointer',
  textDecoration: 'none',
  color: theme.palette.text.primary,
  position: 'relative',

  display: 'flex',
  border: `1px solid ${
    level === 100
      ? theme.palette.status.available
      : level === 200
      ? theme.palette.status.expected
      : theme.palette.status.notAvailable
  }`,
  transition: 'all 0.2s linear',
  padding: theme.spacing(0.5),
  '&:hover': {
    border: `1px solid ${
      level === 100
        ? theme.palette.status.availableText
        : level === 200
        ? theme.palette.status.expectedText
        : theme.palette.status.notAvailableText
    }`,
  },
  '& > *:first-child': {
    '& img': {
      maxWidth: 50,
      maxHeight: 75,
    },
    maxWidth: 50,
    height: 75,
    marginRight: theme.spacing(1),
  },
}));

const StatusIndicator = styled(TitleStatus)(({ theme }: SC) => ({
  marginRight: 0,
  top: 0,
  padding: `0 ${theme.spacing(0.5)}px`,
  '& > svg': {
    margin: 0,
  },
}));

const MoreButton = styled(ShowMoreButton)({
  margin: '0 auto',
});

type Props = {
  titles?: EmbeddedTitle[];
  type: 'nstc' | 'language';
};
const TitleMatches: FC<Props> = ({ titles, type }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const languages = _.uniqBy(
    titles?.reduce(
      (prev, next) => [...prev, ...(next?.languages?.length ? next.languages : [])],
      [] as Language[]
    ),
    'code'
  );

  const theme = useTheme();
  const xl = useMediaQuery(theme.breakpoints.up('xl'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const md = useMediaQuery(theme.breakpoints.up('md'));

  const maxPerLine = xl ? 5 : lg ? 3 : md ? 2 : 1;

  const numMatching = titles?.length ?? 0;
  const [showAll, setShowAll] = useState(numMatching <= maxPerLine + 1);

  const detailPath = usePath(ROUTE_KEY.TITLE);

  useEffect(() => {
    setShowAll(numMatching <= maxPerLine + 1);
  }, [maxPerLine, numMatching]);

  if (numMatching <= 0) {
    return null;
  }

  const matches = (showAll ? titles : titles?.slice(0, maxPerLine)) ?? [];

  const applyMinWidth = numMatching <= maxPerLine;

  return (
    <>
      <Typography style={{ fontWeight: 500, marginBottom: '0.25rem' }} noWrap>
        {languages.length === 1
          ? t(`title_heading_matches_one_language`, {
              count: numMatching,
              language: languages[0]?.label ?? '',
            })
          : t(`title_heading_matches_other_languages`, {
              count: numMatching,
            })}
      </Typography>
      <Box mb={2}>
        <Grid container spacing={2}>
          {matches.map((match) => {
            const detailLink = getDetailLink(detailPath, {
              ...match,
              title: match.title,
            });
            const frontCover = getFrontCover(match);

            return (
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={3}
                xl={2}
                key={detailLink}
                style={applyMinWidth ? { minWidth: 200, maxWidth: 200 } : {}}
              >
                <MatchingTitle
                  to={{ pathname: detailLink, state: { from: pathname } }}
                  level={match.availability.level}
                >
                  <div>
                    <SmallFrontCover title={match.title} frontCover={frontCover} />
                  </div>
                  <div>
                    <Typography style={{ fontWeight: 500 }} noWrap>
                      {type === 'nstc' || languages?.length <= 1
                        ? match.productForm.shortLabel
                        : (match.languages?.length ?? 0) > 1
                        ? t('title_more_languages')
                        : match.languages?.[0]?.label ?? ''}
                    </Typography>
                    <Typography>
                      {match.isbnFirstPublished &&
                        onixToMoment(match.isbnFirstPublished).format('YYYY')}{' '}
                      &nbsp;
                    </Typography>
                    <Typography>{match.price ? formatEuro(match.price) : ' '}</Typography>
                  </div>
                  <StatusIndicator availability={match.availability} showLabel={false} />
                </MatchingTitle>
              </Grid>
            );
          })}

          {numMatching > maxPerLine + 1 && (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={2}
              container
              justify="center"
              alignItems="center"
            >
              <MoreButton invert={showAll} onClick={() => setShowAll((prevState) => !prevState)} />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default TitleMatches;
