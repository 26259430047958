import React, { ReactElement } from 'react';
import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Price from '../../../../components/Price';
import TaxRate from '../../../../components/TaxRate';
import { Title } from '../../../../config/api/types';
import { useTranslation } from 'react-i18next';
import PriceSecondary from '../../../../components/PriceSecondary';
import SvgIcon, { Icons } from '../../../../components/SvgIcon';
import { PriceInfos } from '../../domain';

const useStyles = makeStyles((theme: Theme) => ({
  price: {
    ...theme.typography.h5,
    verticalAlign: 'middle',
  },
  taxRate: {
    marginTop: 4,
    // ...theme.typography.title,
  },
}));

type Props = {
  title: Title;
  compact?: boolean;
  classes?: {
    price?: string;
    tax?: string;
  };
  suffix?: ReactElement<any>;
  priceInfos: PriceInfos;
};

const Prices = ({ title, classes, suffix, compact = false, priceInfos }: Props) => {
  const { t } = useTranslation();
  const ownClasses = useStyles();

  const iconMap = {
    lock: (
      <SvgIcon
        icon={Icons.LOCK}
        viewBox="0 0 16 16"
        fontSize="inherit"
        style={{ verticalAlign: 'middle' }}
      />
    ),
    unlock: (
      <SvgIcon
        icon={Icons.UNLOCK}
        viewBox="0 0 16 16"
        fontSize="inherit"
        style={{ verticalAlign: 'middle' }}
      />
    ),
    default: undefined,
  };
  const secondaryIconMap = {
    lock: (
      <SvgIcon
        icon={Icons.LOCK}
        viewBox="0 0 16 16"
        fontSize="inherit"
        style={{ verticalAlign: 'middle' }}
      />
    ),
    unlock: (
      <SvgIcon
        icon={Icons.UNLOCK}
        viewBox="0 0 16 16"
        fontSize="inherit"
        style={{ verticalAlign: 'middle' }}
      />
    ),
    default: undefined,
  };

  const primaryPrice = priceInfos[0];
  const secondaryPrices =
    compact && priceInfos.length > 1
      ? [priceInfos[priceInfos.length - 1]]
      : priceInfos.slice(1, priceInfos.length);

  return (
    <>
      <Price
        price={primaryPrice.price}
        icon={iconMap[primaryPrice.icon || 'default']}
        iconPlacement={compact ? 'after' : 'before'}
        freeOfCharge={title.priceIsFreeOfCharge}
        toBeAnnounced={title.priceIsToBeAnnounced}
        label={!compact && primaryPrice.label && t(primaryPrice.label, primaryPrice.labelOptions)}
        classes={{
          price: ownClasses.price,
          ...classes,
        }}
        suffix={suffix}
        tooltip={primaryPrice.tooltipLabel && t(primaryPrice.tooltipLabel)}
      />
      {secondaryPrices.map(
        (priceInfo, index) =>
          priceInfo.price && (
            <PriceSecondary
              price={priceInfo.price}
              icon={secondaryIconMap[priceInfo.icon || 'default']}
              iconPlacement={compact ? 'after' : 'before'}
              label={!compact && priceInfo.label && t(priceInfo.label, priceInfo.labelOptions)}
              strike={priceInfo.disabled}
              strikeIcon={priceInfo.disabledIcon}
              key={index}
              tooltip={priceInfo.tooltipLabel && t(priceInfo.tooltipLabel)}
            />
          )
      )}
      {(primaryPrice.price || secondaryPrices.some((priceInfo) => Boolean(priceInfo.price))) &&
        title.productForm.taxRate && (
          <TaxRate
            tax={title.productForm.taxRate}
            classes={{ tax: ownClasses.taxRate, ...classes }}
          />
        )}
      {!compact && title.discountCode && (
        <Typography variant="body1">
          {t('title_discount_code', {
            code: title.discountCode.label,
          })}
          {title.inBookshelf && ` (${t('title_in_bookshelf')})`}
        </Typography>
      )}
    </>
  );
};

export default Prices;
