import { Box, Grid, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import React, { FC } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import WhenFieldChanges from '../../../../components/Form/WhenFieldChanges';
import { Availability } from '../../../../config/api/types';
import useApiRoute from '../../../../config/api/useApiRoute';
import elasticQueryDSL from '../../../../helpers/elasticQueryDSL';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import { updateQueryParameters } from '../../../../helpers/hateoas';
import urlencode from '../../../../helpers/urlencode';
import security from '../../../security';
import TitleExportButton from '../../catalog/components/Export/TitleExportButton';
import AvailabilityFilter from './filters/AvailabilityFilter';
import DistributorFilter from './filters/DistributorFilter';
import FirstPublishedDateRangeFilter from './filters/FirstPublishedDateRangeFilter';
import FundsFilter from './filters/FundsFilter';
import PersonalFilter from './filters/PersonalFilter';
import PublisherFilter from './filters/PublisherFilter';
import UpdatedDateRangeFilter from './filters/UpdatedDateRangeFilter';

const ExportTitles: FC = () => {
  const { t } = useTranslation();
  const apiTitleSearchPath = useApiRoute('titles') || '';

  const userRoles = useSelector(security.selectors.getUserRole) || [];
  const isPublisher = userRoles.includes('ROLE_PUBLISHER');
  const isDistributor = userRoles.includes('ROLE_DISTRIBUTOR');
  const isAdmin = userRoles.includes('ROLE_ADMIN');
  const isDataConsumer = userRoles.includes('ROLE_DATA_CONSUMER');
  const currentUser = useSelector(security.selectors.getUser);
  const impersonate = useSelector(security.selectors.getImpersonate);
  const user = impersonate || currentUser;
  const isMember = user?._embedded?.organisation?.hasMembership ?? false;

  return (
    <Form onSubmit={console.log}>
      {({ form: { change }, values }) => {
        const filter = {
          ...values.personal?.value,
          'fund.organisation.organisationId':
            getIdFromUrl(values.distributor?._links.self.href) ||
            values.personal?.value['fund.organisation.organisationId'],
          'fund.code': values.fund?.code,
          'publisher.organisation.organisationId':
            values.personal?.value['publisher.organisation.organisationId'] ||
            values.publisher?.organisation.organisationId,
          isbnFirstPublishedDate:
            values.from || values.to
              ? `[${values.from?.format('YYYY-MM-DD') || '*'} TO ${
                  values.to?.format('YYYY-MM-DD') || '*'
                }]`
              : '',
          changedAt:
            values.changedFrom || values.changedTo
              ? `[${values.changedFrom?.format('YYYY-MM-DD') || '*'} TO ${
                  values.changedTo?.format('YYYY-MM-DD') || '*'
                }]`
              : '',
          'availability.code': values.availability?.length
            ? `(${values.availability.map((a: Availability) => a.code).join(' OR ')})`
            : '',
        };

        const f = urlencode(elasticQueryDSL.stringify(filter));

        const url = updateQueryParameters(apiTitleSearchPath, {
          full: '1',
          f,
        });

        const fieldsRow1 = [
          (isPublisher || isDistributor) && (
            <PersonalFilter
              isPublisher={isPublisher}
              isDistributor={isDistributor}
              value={values.personal}
              onChange={change}
            />
          ),
        ].filter(Boolean);

        const fieldsRow2 = [
          (isAdmin || (isDataConsumer && isMember)) && (
            <DistributorFilter isDataConsumer={isDataConsumer} />
          ),
          (isAdmin || isDistributor || (isDataConsumer && isMember)) && <FundsFilter />,
          (isAdmin || isDistributor || (isDataConsumer && isMember)) &&
            !values.personal?.value['publisher.organisation.organisationId'] && <PublisherFilter />,
        ].filter(Boolean);

        const fieldsRow3 = [<FirstPublishedDateRangeFilter />, <AvailabilityFilter />].filter(
          Boolean
        );

        const fieldsRow4 = [<UpdatedDateRangeFilter />];

        const fields = [fieldsRow1, fieldsRow2, fieldsRow3, fieldsRow4];

        return (
          <>
            {fields.map((fieldsRow, fieldsIndex) => (
              <Grid container spacing={1} key={fieldsIndex}>
                {fieldsRow.map((field, index) => (
                  <Grid item xs={4} key={index}>
                    {field}
                  </Grid>
                ))}
              </Grid>
            ))}

            <WhenFieldChanges
              field="personal"
              set="publisher"
              to={(value, fieldValue) => {
                if (value.value['publisher.organisation.organisationId']) {
                  return '';
                }
                return fieldValue;
              }}
            />

            <Box mt={1} mb={2}>
              <TitleExportButton url={url} btnLabel={t('page_titles_export_btn') as string} />
            </Box>
            <Typography variant="body2">
              <WarningIcon
                fontSize="small"
                style={{ verticalAlign: 'top', marginRight: '0.5rem' }}
              />
              {t('page_titles_export_note')}
            </Typography>
          </>
        );
      }}
    </Form>
  );
};

export default ExportTitles;
