import React from 'react';
import cxs from 'classnames';
import { makeStyles, Theme, ListItem, ListItemIcon, ListItemProps } from '@material-ui/core';
import CircleIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    //
  },
  legendCircle: {
    minWidth: 24,
  },
  legendCircleIcon: {
    width: 16,
    height: 16,
  },
}));

type Props = ListItemProps & {
  color?: string;
  children?: React.ReactChild;
};

const LegendListItem = ({ color, children, className, ...props }: Props) => {
  const classes = useStyles();

  return (
    <ListItem disableGutters className={cxs(classes.root, className)}>
      <ListItemIcon className={classes.legendCircle}>
        <CircleIcon className={classes.legendCircleIcon} style={{ color: color }} />
      </ListItemIcon>
      {children}
    </ListItem>
  );
};

export default LegendListItem;
