import React, { FC } from 'react';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { IconButton } from '@material-ui/core';
import { ShoppingCartLine } from '../../../../../config/api/models/shop';
import { useFetch } from '@react-redux-fetch/hooks';
import { getRemoveCartLineRequest } from '../../api';

type Props = {
  cartLine: ShoppingCartLine;
};
export const DeleteCartLineButton: FC<Props> = ({ cartLine }) => {
  const cartLineUrl = cartLine._links.shoppingCartLine.href;
  const [removeCartLineFetch, removeCartLine] = useFetch(getRemoveCartLineRequest);

  const removeItem = () => {
    removeCartLine(cartLineUrl);
  };

  return (
    <IconButton onClick={removeItem} disabled={removeCartLineFetch?.pending}>
      <DeleteForeverIcon />
    </IconButton>
  );
};
