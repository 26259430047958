import React, { ChangeEvent, useRef } from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import MUITextField, { TextFieldProps } from '@material-ui/core/TextField/TextField';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Button from '../Button';
import ClearIcon from '@material-ui/icons/Clear';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      maxWidth: theme.spacing(57),
      color: theme.palette.text.primary,
      '& .MuiOutlinedInput-root': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    icon: {
      color: theme.palette.text.primary,
    },
    helperText: {
      color: theme.palette.text.helpertext,
    },
    input: {
      borderColor: theme.palette.text.primary,
      padding: '12px 14px',
    },
    inputRoot: {},
    clearIcon: {
      color: '#616161',
      fontSize: 18,
      '&:hover': {
        color: theme.palette.common.black,
        cursor: 'pointer',
      },
    },
  });

type Props = WithStyles &
  TextFieldProps & {
    customError?: string;
    searchCallback: (term: string, event?: React.ChangeEvent<any>) => void;
    initialValue?: string;
    isClearable?: boolean;
  };

const SearchField = ({
  customError,
  classes,
  helperText,
  label,
  searchCallback,
  placeholder,
  initialValue,
  isClearable,
  ...rest
}: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const [inputValue, changeInputValue] = React.useState(initialValue || '');

  const handleInputChange = (
    ev: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    changeInputValue(ev.currentTarget.value);
  };

  const handleKeyPress = (ev: any) => {
    if (ev.key === 'Enter') {
      searchCallback(inputValue, ev);
    }
  };

  const handleBlur = () => {
    searchCallback(inputValue);
  };

  const handleClear = (e: any) => {
    e.preventDefault();
    ref.current?.blur();
    changeInputValue('');
    searchCallback('');
  };

  const clearButton = Boolean(isClearable && inputValue) ? (
    <ClearIcon onMouseDown={handleClear} className={classes.clearIcon} />
  ) : null;

  return (
    <MUITextField
      ref={ref}
      margin="normal"
      {...rest}
      variant="outlined"
      fullWidth
      className={classes.root}
      error={Boolean(customError)}
      onChange={handleInputChange}
      onBlur={handleBlur}
      onKeyPress={handleKeyPress}
      placeholder={placeholder || ''}
      value={inputValue}
      InputProps={{
        classes: {
          root: classes.inputRoot,
          input: classes.input,
        },
        notched: false,
        endAdornment: (
          <InputAdornment position="end">
            {clearButton}
            <Button variant="text" secondary round onClick={() => searchCallback(inputValue)}>
              <SearchIcon />
            </Button>
          </InputAdornment>
        ),
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.helperText,
        },
      }}
    />
  );
};

export default withStyles(styles)(SearchField);
