import { Overrides } from '@material-ui/core/styles/overrides';
import palette from './palette';

const overrides: Overrides = {
  MuiCssBaseline: {
    '@global': {
      'strong, b': {
        fontWeight: 500,
      },
      'p, a': {
        whiteSpace: 'break-spaces',
      },
    },
  },
  MuiTypography: {
    body1: {
      fontSize: 14,
    },
    h2: {
      fontSize: '1.5rem',
    },
    h3: {
      fontSize: '1.2rem',
    },
    h4: {
      fontSize: '1rem',
    },
    h5: {
      fontSize: '1rem',
    },
    subtitle2: {
      fontWeight: 500,
    },
  },
  MuiButton: {
    root: {
      textTransform: 'none',
    },
    contained: {
      boxShadow: 'none',
    },
  },
  MuiOutlinedInput: {
    input: {
      padding: '12px 10px',
      background: palette.background.paper,
      '&[disabled]': {
        background: palette.background.appbar,
      },
    },
    notchedOutline: {},
    adornedStart: {
      paddingLeft: 8,
      background: palette.background.appbar,
    },
    adornedEnd: {
      background: palette.background.appbar,
    },
    multiline: {
      padding: 0,
    },
  },
  MuiInputAdornment: {
    positionEnd: {
      marginLeft: 14,
    },
  },
  MuiInputBase: {
    root: {
      fontSize: '0.875rem',
    },
    multiline: {
      padding: 0,
    },
    input: {},
  },
  MuiInputLabel: {
    root: {
      fontWeight: 500,
      fontSize: '0.875rem',
    },
  },
  MuiFormControl: {
    marginNormal: {
      marginTop: 8,
      marginBottom: 20,
    },
  },
  MuiFormControlLabel: {
    label: {
      fontSize: '0.875rem',
    },
  },
  MuiAppBar: {
    colorPrimary: {
      color: palette.text.primary,
    },
  },
  MuiFormHelperText: {
    contained: {
      margin: '4px 0 0',
    },
    root: {
      color: palette.text.helpertext,
    },
  },
  MuiTableCell: {
    root: {
      padding: '4px 40px 4px 16px',
    },
  },
  MuiDrawer: {
    paper: {
      zIndex: 1,
    },
  },
  MuiListItem: {
    root: {
      '&.MuiMenuItem-root': {
        fontSize: '0.875rem',
        minHeight: 32,
      },
    },
  },
  MuiCircularProgress: {
    colorSecondary: {
      color: palette.text.primary,
    },
  },
};

export default overrides;
