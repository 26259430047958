import { ArrowBack } from '@material-ui/icons';
import React, { FC } from 'react';
import Button, { ButtonProps } from './Button';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  backButton: {
    // color: theme.palette.text.hint,
    // border: `1px solid ${theme.palette.text.hint}`,
  },
}));

const BackButton: FC<ButtonProps> = ({ onClick, children, ...other }) => {
  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      size="small"
      variant="outlined"
      color="primary"
      className={classes.backButton}
      {...other}
    >
      <ArrowBack fontSize="small" /> {children}
    </Button>
  );
};

export default BackButton;
