import React from 'react';
import SubTitle from '../../../components/SubTitle';
import { CheckBox } from '../../../components/Form';
import { Typography, makeStyles } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  infoText: {
    fontSize: 12,
  },
}));

const InfoProcessing = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <SubTitle>{t('form_info_processing_title')}</SubTitle>
      <Typography className={classes.infoText}>
        <Trans
          i18nKey="form_info_processing"
          components={[
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a href="https://www.meta4books.be/privacy" target="_blank" rel="noreferrer" />,
          ]}
        />
      </Typography>
      <CheckBox label={t('form_info_processing_label')} name="privacyConsent" />
    </>
  );
};

export default InfoProcessing;
