import React, { ChangeEvent, useEffect, useState } from 'react';
import { TextField, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Button from '../Button';
import useDebounce from '../../helpers/useDebounce';

const useStyles = makeStyles((theme: Theme) => ({
  numberStepField: {},
  adornedStart: {
    paddingLeft: 0,
  },
  adornedEnd: {
    paddingRight: 0,
  },
  input: {
    padding: '10px 10px',
    background: theme.palette.background.default,
    textAlign: 'center',
  },
  button: {
    minWidth: '2rem',
  },
}));

type Props = {
  value: number;
  onChange: (newValue: number) => void;
  disabled?: boolean;
  name?: string;
  error?: boolean;
  helperText?: string;
};

const MIN_VALUE = 1;

const NumberStepField = ({ value, onChange, disabled, name, error, helperText }: Props) => {
  const [innerValue, setInnerValue] = useState(value.toString());
  const [debouncedInnerValue] = useDebounce(innerValue, 50);
  const classes = useStyles();

  useEffect(() => {
    setInnerValue(value.toString());
  }, [value]);

  useEffect(() => {
    if (debouncedInnerValue) {
      const v = parseInt(debouncedInnerValue);
      if (v < 1) {
        setInnerValue('1');
      }
      onChange(v || 1);
    }
  }, [onChange, debouncedInnerValue]);

  const handleIncrease = () =>
    setInnerValue((prevState) => ((parseInt(prevState) || 0) + 1).toString());
  const handleDecrease = () =>
    setInnerValue((prevState) => Math.max((parseInt(prevState) || 0) - 1, MIN_VALUE).toString());

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInnerValue(e.target.value);
  };

  return (
    <TextField
      fullWidth
      variant="outlined"
      value={innerValue}
      onChange={handleChange}
      margin="none"
      disabled={disabled}
      InputProps={{
        startAdornment: (
          <Button className={classes.button} onClick={handleDecrease} secondary disabled={disabled}>
            -
          </Button>
        ),
        endAdornment: (
          <Button className={classes.button} onClick={handleIncrease} secondary disabled={disabled}>
            +
          </Button>
        ),
        classes: {
          input: classes.input,
          adornedStart: classes.adornedStart,
          adornedEnd: classes.adornedEnd,
        },
      }}
      name={name}
      error={error}
      helperText={helperText}
    />
  );
};

export default NumberStepField;
