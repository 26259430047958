import React, { FC } from 'react';
import { useLocation } from 'react-router';
import Toolbar from '@material-ui/core/Toolbar';
import { Theme, createStyles, Grid, makeStyles } from '@material-ui/core';
import Settings from '../containers/Settings';
import catalog from '../../title/catalog';
import cart from '../../title/cart';
import { Notifications } from '../../notifications';
import AppBar from '../../../components/AppBar';
import { HelpMenuItem } from '../../help';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      height: theme.toolbar.height,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: 8,
    },
    appBar: {
      width: `calc(100% - ${theme.drawer.width}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.down('md')]: {
        width: `calc(100% - 200px)`,
      },
    },
  })
);

const Header: FC = () => {
  const { pathname, search } = useLocation();
  const classes = useStyles();

  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Grid item style={{ flexGrow: 1 }}>
          <catalog.containers.Search path={pathname} query={search} />
        </Grid>
        <Grid item>
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item>
              <Notifications />
            </Grid>
            <Grid item>
              <cart.components.CartSummary />
            </Grid>
            <Grid item>
              <Settings />
            </Grid>
            <Grid item>
              <HelpMenuItem />
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
