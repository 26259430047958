import React, { FC, useEffect, useState } from 'react';
import { Grid, TextField, Tooltip, Typography } from '@material-ui/core';
import { useFetch } from '@react-redux-fetch/hooks';
import { ShoppingCartLine } from '../../../../../config/api/models/shop';
import StatusLabel from '../../../../../components/StatusLabel';
import NumberStepField from '../../../../../components/Form/NumberStepField';
import useDebounce from '../../../../../helpers/useDebounce';
import formatEuro from '../../../../../helpers/formatEuro';
import { getProductForm, getTitleStatus } from '../../../domain';
import { getUpdateCartLineRequest } from '../../api';
import LastColumn from './LastColumn';
import { useTranslation } from 'react-i18next';
import IsbnLink from '../../../components/IsbnLink';
import { DeleteCartLineButton } from './DeleteCartLineButton';
import { inRange, isNumber } from 'lodash';
import { useSelector } from 'react-redux';
import security from '../../../../security';
import { useFetchBookstore } from '../../../../organisation/bookstore/hooks/useFetchBookstore';

const DEBOUNCE_TIMEOUT = 400;

type Props = {
  cartLine: ShoppingCartLine;
  asLibrary?: boolean;
  onInvalidLine: (titleId: string, isInvalid: boolean) => void;
};

function isNumeric(data?: string | number) {
  return data != null && (isNumber(data) || (!isNaN(parseInt(data)) && data.constructor !== Array));
}

const CartLine: FC<Props> = ({ cartLine, asLibrary, onInvalidLine }) => {
  const currentUser = useSelector(security.selectors.getUser);
  const impersonate = useSelector(security.selectors.getImpersonate);
  const { bookstore } = useFetchBookstore(
    impersonate?._embedded.organisation?._links.bookstore.href
  );

  /* currentUser | impersonate */
  const usesFlowNumber =
    currentUser?._embedded.bookstore?.usesFlowNumber || bookstore?.usesFlowNumber;

  const { t } = useTranslation();
  const changeOrderLineStatusUrl = cartLine._links.changeOrderLineStatus.href;

  const [qty, setQty] = useState(cartLine.quantity);
  const [debouncedQty] = useDebounce(qty, DEBOUNCE_TIMEOUT);
  const [reference, setReference] = useState(cartLine.reference || '');
  const [debouncedReference] = useDebounce(reference, DEBOUNCE_TIMEOUT);
  const [flowNumber, setFlowNumber] = useState(cartLine.flowNumber || '');
  const [debouncedFlowNumber] = useDebounce(flowNumber, DEBOUNCE_TIMEOUT);

  const [updateCartLineFetch, updateCartLine] = useFetch(getUpdateCartLineRequest);

  const shouldUpdate =
    debouncedQty !== cartLine.quantity ||
    debouncedReference !== (cartLine.reference || '') ||
    debouncedFlowNumber !== (cartLine.flowNumber || '');
  const cartFetchFailed = updateCartLineFetch?.rejected;
  const cartFetchSuccess = updateCartLineFetch?.fulfilled;
  const uniqueId = changeOrderLineStatusUrl;

  useEffect(() => {
    if (cartFetchFailed) {
      onInvalidLine(uniqueId, true);
    }
  }, [cartFetchFailed, uniqueId, onInvalidLine]);

  useEffect(() => {
    if (cartFetchSuccess) {
      onInvalidLine(uniqueId, false);
    }
  }, [cartFetchSuccess, uniqueId, onInvalidLine]);

  useEffect(() => {
    if (!shouldUpdate) return;

    updateCartLine(changeOrderLineStatusUrl, {
      userId: cartLine.userId,
      quantity: debouncedQty,
      reference: debouncedReference,
      flowNumber: parseInt(debouncedFlowNumber),
      titleId: cartLine.titleId,
    });
  }, [
    updateCartLine,
    debouncedQty,
    changeOrderLineStatusUrl,
    cartLine.userId,
    cartLine.titleId,
    shouldUpdate,
    debouncedReference,
    debouncedFlowNumber,
  ]);

  const errorMessages = updateCartLineFetch?.rejected && updateCartLineFetch?.reason?.cause;
  const referenceErrorMessage = (
    (errorMessages && errorMessages.find((m: any) => m.property_path === 'reference')) ||
    {}
  ).message;
  const quantityErrorMessage = (
    (errorMessages && errorMessages.find((m: any) => m.property_path === 'quantity')) ||
    {}
  ).message;
  const flowNumberErrorMessage = (
    (errorMessages && errorMessages.find((m: any) => m.property_path === 'flowNumber')) ||
    {}
  ).message;

  return (
    <Grid container spacing={1} alignItems="center" justify="flex-end">
      <Grid item>
        <Tooltip title={cartLine._embedded.title.availability.label} placement="top" arrow>
          <div>
            <StatusLabel status={getTitleStatus(cartLine._embedded.title)} />
          </div>
        </Tooltip>
      </Grid>
      <Grid item style={{ flex: 1, overflow: 'hidden' }}>
        <Tooltip placement="top-start" title={cartLine._embedded.title.title} enterDelay={500}>
          <Typography noWrap>{cartLine._embedded.title.title}</Typography>
        </Tooltip>
      </Grid>

      <Grid item style={{ width: usesFlowNumber ? 250 : 280 }}>
        <Typography>
          <IsbnLink orderLine={cartLine} />
          {' | '}
          {getProductForm(cartLine._embedded.title)}
        </Typography>
      </Grid>

      <Grid item style={{ width: usesFlowNumber ? 220 : 280 }}>
        <TextField
          variant="outlined"
          placeholder={t(`form${asLibrary ? '_library' : ''}_order_reference`)}
          value={reference}
          onChange={(e) => setReference(e.target.value)}
          fullWidth
          error={Boolean(referenceErrorMessage)}
          helperText={referenceErrorMessage}
        />
      </Grid>
      {usesFlowNumber && (
        <Grid item style={{ width: 90 }}>
          <TextField
            variant="outlined"
            placeholder={t(`form_flow_number`)}
            value={flowNumber}
            onChange={(e) => {
              if (
                (isNumeric(e.target.value) && inRange(parseInt(e.target.value), 0, 10000)) ||
                e.target.value === ''
              ) {
                return setFlowNumber(e.target.value);
              }
            }}
            fullWidth
            error={Boolean(flowNumberErrorMessage)}
            helperText={flowNumberErrorMessage}
          />
        </Grid>
      )}

      <Grid
        item
        container
        spacing={1}
        alignItems="center"
        justify="space-between"
        style={{ width: 240 }}
      >
        <Grid item xs={4} style={{ textAlign: 'right' }}>
          {formatEuro(cartLine.unitPrice)}
        </Grid>
        <Grid item xs={1}>
          {' X '}
        </Grid>
        <Grid item xs={7}>
          <NumberStepField
            value={qty}
            onChange={setQty}
            error={Boolean(quantityErrorMessage)}
            helperText={quantityErrorMessage}
          />
        </Grid>
      </Grid>

      <Grid item style={{ textAlign: 'right', width: 80 }}>
        {formatEuro(cartLine.total)}
      </Grid>

      <LastColumn style={{ textAlign: 'right' }}>
        <DeleteCartLineButton cartLine={cartLine} />
      </LastColumn>
    </Grid>
  );
};

export default CartLine;
