import { Grid } from '@material-ui/core';
import React from 'react';
import { UseTranslationResponse, useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import ExpandableList from '../../../../components/ExpandableList';
import { Thema } from '../../../../config/api/models/dataSets';
import { TitleFull } from '../../../../config/api/types';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import { getLink } from '../../../../helpers/hateoas';
import {
  MAX_CONTRIBUTORS,
  getAdditionalProductDescription,
  getAgeRange,
  getAviLevelsNew,
  getAviLevelsOld,
  getDimensions,
  getDuration,
  getEditionNumber,
  getFundCode,
  getKeywords,
  getLanguages,
  getNur,
  getThemaQualifiers,
  getThemas,
} from '../../domain';
import Commercial from './Commercial';
import Divider from './Divider';
import SimpleContent from './SimpleContent';
import SimpleList, { Item } from './SimpleList';
import TitleContributors from './TitleContributors';
import TitleHeader from './TitleHeader';
import TitleMatchesWrapper from './TitleMatchesWrapper';
import TitleMetaData from './TitleMetaData';
import TitleMedia from './media/TitleMedia';

type Props = {
  title: TitleFull;
};

const formatTitleLink = (linkWithId?: string, label?: string, selfLink?: string) => {
  if (!linkWithId) {
    return label;
  }

  const redirectPath = selfLink ? getIdFromUrl(selfLink) : '';

  const id = getIdFromUrl(linkWithId);
  return (
    <Button
      variant="text"
      link={{ pathname: `/titels/detail/${id}`, state: { from: redirectPath } }}
      style={{ display: 'inline' }}
    >
      {label}
    </Button>
  );
  // return (
  //   <Link to={{ pathname: `/titels/detail/${id}`, state: { from: redirectPath } }}>{label}</Link>
  // );
};

const targetListItems: Item[] = [
  { label: 'aviLevelsNew', format: getAviLevelsNew },
  { label: 'aviLevelsOld', format: getAviLevelsOld },
  { label: 'ageRange', format: getAgeRange },
];
const relatedListItems: Item[] = [
  {
    label: 'replacedBy',
    format: (title) =>
      formatTitleLink(getLink(title, 'titleReplacedBy'), title.replacedBy, getLink(title, 'self')),
  },
  {
    label: 'replacementOf',
    format: (title) =>
      formatTitleLink(
        getLink(title, 'titleReplacementOf'),
        title.replacementOf,
        getLink(title, 'self')
      ),
  },
];
const pricesListItems: Item[] = [];
const promoListItems: Item[] = [];
const bibOtherListItems: Item[] = [];

const getThemaList = (t: UseTranslationResponse['t']) => (title: TitleFull) => {
  const themas = getThemas(title);
  const qualifiers = getThemaQualifiers(title);

  if (!themas?.length) {
    return null;
  }

  const themaItems = qualifiers?.length ? [...themas, ...qualifiers] : themas;

  return (
    <ExpandableList
      initialLength={3}
      increaseBy={themaItems.length - 3}
      items={themaItems}
      renderListItem={(thema) => (
        <React.Fragment key={thema.code}>
          {`${thema.code} - ${thema.label} ${
            (thema as Thema).isMainSubject ? `(${t('title_main_thema')})` : ''
          }`}
          <br />
        </React.Fragment>
      )}
    />
  );
};

const Title = ({ title }: Props) => {
  const { t } = useTranslation();
  const formatFundCode = (title: TitleFull) => {
    const fundCode = getFundCode(title);
    return fundCode
      ? `${fundCode}${
          title.fund?.status === 'status_active' ? '' : ` (${t('fund_status_not_active')})`
        }`
      : null;
  };

  const bibListItems: Item[] = [
    { label: 'productFormAdditionalDescription', format: getAdditionalProductDescription },
    { value: 'illustrationsNote' },
    { label: 'fundCode', format: formatFundCode },
    { label: 'languages', format: getLanguages },
    { label: 'nurCode', format: getNur },
    { label: 'thema', format: getThemaList(t) },
    { label: 'keywords', format: getKeywords },
    { label: 'editionNumber', format: getEditionNumber },
    { value: 'numPages' },
    { label: 'dimensions', format: getDimensions },
    { value: 'weight' },
    { label: 'duration', format: getDuration },
    { value: 'legalDepotNumber' },
    { value: 'nstc' },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item sm={4} md={3} lg={2}>
        <TitleMedia title={title} />
      </Grid>
      <Grid item sm={8} md={9} lg={10}>
        {/*<Header*/}
        {/*  goBack*/}
        {/*  goBackPath={location.state && location.state.from}*/}
        {/*  style={{ marginTop: -12 }}*/}
        {/*/>*/}

        <TitleHeader title={title} />
        <Commercial title={title} />
        <SimpleList title={title} items={relatedListItems} heading="rel" />
        <TitleMatchesWrapper title={title} />
        {title.contributors.length > MAX_CONTRIBUTORS && <TitleContributors title={title} />}
        <SimpleList title={title} items={bibListItems} heading="bib" />
        <SimpleList title={title} items={targetListItems} heading="target" />
        <SimpleList title={title} items={pricesListItems} heading="prices" />
        <SimpleList title={title} items={promoListItems} heading="promo" />
        <SimpleList title={title} items={bibOtherListItems} heading="bib_other" />
        <Divider />
        {title.annotation && (
          <>
            <SimpleContent heading="annotation" html={title.annotation} />
            <Divider />
          </>
        )}

        {title.promotionalHeadline && (
          <>
            <SimpleContent heading="promotional_headline" html={title.promotionalHeadline} />
            <Divider />
          </>
        )}
        {title.flapCopy && (
          <>
            <SimpleContent heading="flap_copy" html={title.flapCopy} />
            <Divider />
          </>
        )}
        <TitleMetaData title={title} />
      </Grid>
    </Grid>
  );
};

export default Title;
