import React, { useEffect } from 'react';
import { IsbnSteppedFormProps } from '../types';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import IsbnTitleForm from '../components/IsbnTitleForm';
import useApiRoute from '../../../config/api/useApiRoute';
import useFetch from '../../../helpers/useFetch';
import { getFundsRequest } from '../../title/admin/title/api';
import { Funds } from '../../../config/api/types';

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    display: 'flex',
  },
}));

const TitleStep = ({
  submitting,
  values,
  submitErrors,
  changeStep,
  form: { change },
  user,
  errors,
  touched,
}: IsbnSteppedFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const fundsRoute = useApiRoute(['fundsDistributor', 'fundsAdmin', 'fundsBookstore']);
  const [fundsRequest, fetchFunds] = useFetch<Funds>(getFundsRequest());

  useEffect(() => {
    if (fundsRoute && !fundsRequest?.value && !fundsRequest?.pending) {
      fetchFunds(fundsRoute);
    }
  }, [fundsRoute, fundsRequest, fetchFunds]);

  useEffect(() => {
    if (fundsRoute && user && fundsRequest?.value && !values.fund) {
      const defaultFund = fundsRequest.value._embedded.items.find(
        (fund) =>
          fund.isDefault &&
          fund.organisation?.meta4BooksId === user._embedded?.organisation?.meta4BooksId
      );
      if (defaultFund) {
        change('fund', defaultFund);
      }
    }
  }, [fundsRequest, fundsRoute, user, values.fund, change]);

  const actionButtons = (
    <Grid container item justify="space-between">
      <Grid item>
        <Button onClick={() => changeStep(-1)} variant="outlined" disabled={submitting}>
          {t('form_previous')}
        </Button>
      </Grid>
      <Grid item>
        <Button type="submit" disabled={submitting}>
          {t('form_next')}
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <div className={classes.formContainer}>
          <IsbnTitleForm
            values={values}
            submitErrors={submitErrors}
            actionButtons={actionButtons}
            change={change}
            newTitleForm
            isAuthenticated={Boolean(user)}
            validationErrors={errors}
            touched={touched}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default TitleStep;
