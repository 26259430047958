import { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../config/store/types';
import { getAlerts } from '../selectors';
import { State as AlertState } from '../model';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { Actions } from '../actions';
import forEach from 'ramda/es/forEach';
import keys from 'ramda/es/keys';
import has from 'ramda/es/has';
import filter from 'ramda/es/filter';
import { withTranslation, WithTranslation } from 'react-i18next';

type MappedState = {
  alerts: AlertState;
};
type MappedDispatch = {
  closeAlert: typeof Actions.closeAlert;
};
type Props = WithTranslation & WithSnackbarProps & MappedState & MappedDispatch;

class Alert extends Component<Props> {
  displayed = [] as any[];

  storeDisplayed = (id: number) => {
    this.displayed = [...this.displayed, id];
  };

  removeDisplayed = (id: number) => {
    this.displayed = filter((key) => id !== key)(this.displayed);
    this.props.closeAlert(id);
  };

  shouldComponentUpdate(newProps: Props) {
    const { alerts } = this.props;
    const newAlerts = newProps.alerts.messages;
    let notExists = false;
    forEach((key: number) => {
      notExists = notExists || !has(key)(alerts.messages);
    })(keys(newAlerts));
    return notExists;
  }

  componentDidUpdate() {
    const { alerts } = this.props;
    forEach((key: number) => {
      if (!this.displayed.includes(key)) {
        const alert = alerts.messages[key];
        // @ts-ignore
        this.props.enqueueSnackbar(this.props.t(alert.message), {
          variant: alert.variant,
          onClose: () => this.removeDisplayed(key),
        });
        this.storeDisplayed(key);
        return;
      }
    })(keys(alerts.messages));
  }

  render() {
    return null;
  }
}

const mapState = (state: RootState): MappedState => ({
  alerts: getAlerts(state),
});

const mapDispatch: MappedDispatch = {
  closeAlert: Actions.closeAlert,
};

export default connect(mapState, mapDispatch)(withTranslation()(withSnackbar(Alert)));
