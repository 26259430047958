import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  fieldset: {
    margin: `0 0 ${theme.spacing(3)}px`,
    padding: 0,
    border: 'none',
  },
}));

type Props = React.HTMLAttributes<HTMLFieldSetElement>;

const Fieldset = (props: Props) => {
  const classes = useStyles();

  return <fieldset className={classes.fieldset} {...props} />;
};

export default Fieldset;
