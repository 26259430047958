import { Theme } from '@material-ui/core';
import { styled } from '@material-ui/styles';

export default styled('div')(({ theme }: { theme: Theme }) => ({
  width: 304,
  paddingRight: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));
