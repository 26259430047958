import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles, TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import cxs from 'classnames';
import { useEffect, useState } from 'react';
import map from 'ramda/src/map';
import keys from 'ramda/src/keys';
import { useTranslation } from 'react-i18next';
import { TextFieldProps } from '@material-ui/core/TextField';

type ItemObj = {
  label: string;
  [otherProps: string]: any;
};

export type SelectFieldProps = TextFieldProps &
  WithStyles & {
    items: { [key: string]: string | ItemObj };
    label?: string;
    value?: string;
    allowEmpty?: boolean;
  };

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 140,
      backgroundColor: theme.palette.background.default,
    },
    formControlRoot: {
      marginTop: theme.spacing(1),
    },
    label: {
      color: theme.palette.text.primary,
    },
    labelOutlined: {
      transform: 'translate(14px, 15px) scale(1)',
    },
    select: {
      backgroundColor: theme.palette.background.default,
      overflow: 'hidden',
    },
    input: {
      padding: '12px 21px 12px 14px',
    },
  });

const SelectField = ({
  items,
  classes,
  onChange,
  label,
  allowEmpty = true,
  value: selectValue,
  ...rest
}: SelectFieldProps) => {
  const [value, setValue] = useState(selectValue);
  const { t } = useTranslation();

  const handleChange: TextFieldProps['onChange'] = (event) => {
    setValue((event.target.value as string) || '');
    if (onChange) {
      onChange(event);
    }
  };

  useEffect(() => {
    setValue(selectValue || '');
  }, [selectValue]);

  return (
    <TextField
      {...rest}
      select
      label={label}
      className={cxs(classes.formControl, classes.formControlRoot)}
      InputLabelProps={{
        className: classes.label,
        classes: {
          outlined: classes.labelOutlined,
        },
      }}
      value={value}
      onChange={handleChange}
      variant="outlined"
    >
      {allowEmpty && (
        <MenuItem>
          <em>{t('form_no_filter')}</em>
        </MenuItem>
      )}
      {map((itemKey: string) => {
        const item = items[itemKey];

        return (
          <MenuItem key={`select_${itemKey}`} value={itemKey}>
            {typeof item === 'string' ? item : (item as ItemObj).label}
          </MenuItem>
        );
      })(keys(items))}
    </TextField>
  );
};

export default withStyles(styles)(SelectField);
