import { FetchConfig } from '@react-redux-fetch/core';
import {
  AddShoppingCartLineRequest,
  UpdateShoppingCartLineRequest,
} from '../../../config/api/types';
import apiRoutes from '../../../config/api/routes';
import { Data } from '../../../config/store/types';
import { NotificationInfo, ShoppingCart } from '../../../config/api/models/shop';
import { removeLineFromCart, updateCartQuantity } from './apiUtil';

export const getUserDepartmentRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    requestKey: 'userDepartment',
    url: apiRoutes.create(url),
    repository: {
      userDepartment: (prev, next) => next,
    },
  };
};

export const getShoppingCartRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    requestKey: 'shoppingCart',
    url: apiRoutes.create(url),
    repository: {
      bulkOrdersToProcess: (prev, next) => null,
      shoppingCart: (prev, next) => next,
    },
  };
};

export const getAddItemToCartRequest = (
  url: string,
  data: AddShoppingCartLineRequest,
  info: NotificationInfo
): FetchConfig<Data> => ({
  method: 'POST',
  url: apiRoutes.create(url),
  requestKey: `shoppingCart${data.titleId}`,
  fetchOptions: {
    body: data,
  },
  repository: {
    shoppingCart: (prev, next) => next,
    shoppingCartAlert: () => {
      return { ...info, timestamp: new Date().getTime() };
    },
  },
});

export const getUpdateCartLineRequest = (
  url: string,
  data: UpdateShoppingCartLineRequest
): FetchConfig<Data> => {
  return {
    method: 'PATCH',
    url: apiRoutes.create(url),
    requestKey: url,
    fetchOptions: {
      body: data,
    },
    optimistic: {
      shoppingCart: updateCartQuantity(data, url),
    },
    repository: {
      // shoppingCart: (prev, next) => next,
    },
  };
};

export const getRemoveCartLineRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'DELETE',
    url: apiRoutes.create(url),
    optimistic: {
      shoppingCart: removeLineFromCart(url),
    },
    repository: {
      shoppingCart: (prev: ShoppingCart, next: ShoppingCart): ShoppingCart => {
        return next;
      },
    },
  };
};
export const getConfirmOrderRequest = (url: string, data: any): FetchConfig<Data> => {
  return {
    method: 'POST',
    url: apiRoutes.create(url),
    requestKey: 'confirmOrderRequest',
    fetchOptions: {
      body: data,
    },
    repository: {
      shoppingCart: (prev: ShoppingCart, next: ShoppingCart): ShoppingCart => {
        return next;
      },
    },
  };
};
