import React from 'react';
import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import classNames from 'classnames';

type Props = {
  isDragActive?: boolean;
  activeMessage?: string;
  message?: string;
  children?: React.ReactChild | React.ReactChild[];
};

const useStyles = makeStyles((theme: Theme) => ({
  dropZoneWrapper: {
    border: '2px #C8C8C8 dashed',
    padding: theme.spacing(1),
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
  dropZoneContent: {
    position: 'relative',
  },
  dropZoneContentIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(242,251,238, 0.9)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dropZoneContentEmpty: {
    position: 'relative',
    width: '100%',
    paddingTop: '140%',
    backgroundColor: '#f2fbee',
    '& > div': {
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      '&:hover': {
        opacity: 0.8,
      },
    },
  },
  cloudIcon: {
    height: 50,
    width: 50,
    marginRight: theme.spacing(1),
    color: theme.palette.buttons.highlight,
  },
  greenIcon: {
    color: theme.palette.buttons.highlight,
  },
  drop: {},
  active: {},
  inputField: {
    display: 'block',
  },
  text: {
    color: theme.palette.buttons.highlight,
  },
}));

const ImageUploadPlaceholder = ({ isDragActive, activeMessage, message, children }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.dropZoneWrapper}>
      {children ? (
        <div className={classes.dropZoneContent}>
          {children}
          {isDragActive && (
            <div className={classes.dropZoneContentIcon}>
              <AddIcon className={classNames([classes.cloudIcon, classes.greenIcon])} />
            </div>
          )}
        </div>
      ) : (
        <div className={classes.dropZoneContentEmpty}>
          <div>
            {isDragActive ? (
              <AddIcon className={classNames([classes.cloudIcon, classes.greenIcon])} />
            ) : (
              <CloudUploadIcon className={classes.cloudIcon} />
            )}
            {isDragActive ? (
              <Typography className={classes.text}>{activeMessage}</Typography>
            ) : (
              <Typography className={classes.text}>{message}</Typography>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageUploadPlaceholder;
