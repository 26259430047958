import React from 'react';
import classNames from 'classnames';
import SnackbarContent from '@material-ui/core/SnackbarContent/SnackbarContent';
import IconButton from '@material-ui/core/IconButton/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';

type ContentProps = WithStyles & {
  onClose: () => void;
  variant: 'success' | 'warning' | 'error' | 'info';
  message: string;
  className: string;
};

const styles = createStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const Content = ({ variant, onClose, message, className, classes }: ContentProps) => (
  <SnackbarContent
    className={classNames(classes[variant], className)}
    aria-describedby="snackbar-message"
    message={message}
    action={[
      <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
        <CloseIcon />
      </IconButton>,
    ]}
  />
);

export default withStyles(styles)(Content);
