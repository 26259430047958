import React, { FC } from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import Typography from '@material-ui/core/Typography';
import clx from 'classnames';

type Props = WithStyles & TypographyProps;

export const subTitleStyles = createStyles((theme: Theme) => ({
  title: {
    color: theme.palette.text.subtitle,
    fontSize: 18,
  },
}));

const SubTitle: FC<Props> = ({ children, classes, className, ...props }) => {
  return (
    <Typography variant="h2" gutterBottom className={clx(className, classes.title)} {...props}>
      {children}
    </Typography>
  );
};

export default withStyles(subTitleStyles)(SubTitle);
