import React, { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { contributorIsPerson } from '../../../../../../../config/api/typeGuards';
import { Contributor } from '../../../../../../../config/api/types';
import { styled } from '@material-ui/styles';
import ContributorField from './ContributorField';
import { useFormState } from 'react-final-form';

const Container = styled(Grid)({
  cursor: 'pointer',
  '& svg': { opacity: 0, transition: 'opacity 0.2 ease' },
  '&:hover p': {
    textDecoration: 'underline',
  },
  '&:hover svg': {
    opacity: 0.7,
  },
});

type Props = {
  contributor: Contributor;
  name: string;
  onRemove: () => void;
  onToggleContributor: (isCorporate: boolean) => void;
  isBibliographicalVerified: boolean;
  index: number;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
};

const EditContributorField: FC<Props> = ({
  contributor,
  name,
  onRemove,
  onToggleContributor,
  index,
  isBibliographicalVerified,
  isEditing,
  setIsEditing,
}) => {
  const state = useFormState();

  const error = state.errors.contributors?.[index];

  return (
    <>
      {!isEditing && (
        <Container container alignItems="center" spacing={1} onClick={() => setIsEditing(true)}>
          <Grid item>
            <Typography>
              {contributorIsPerson(contributor)
                ? `${contributor.firstName || ''} ${contributor.lastName || ''}`
                : contributor.corporateName}{' '}
              ({contributor.role.label})
            </Typography>
          </Grid>
          {!isBibliographicalVerified && (
            <>
              <Grid item>
                <EditIcon fontSize="small" />
              </Grid>
              <Grid item>
                <DeleteIcon fontSize="small" onClick={onRemove} />
              </Grid>
            </>
          )}
        </Container>
      )}
      <div
        style={{
          display: isEditing ? 'block' : 'none',
        }}
      >
        {/*
         *** Keep this field alive or it won't be seen as dirty and will get resetted when flapText changes.
         *** This because of the prop keepDirtyOnReinitialize on the Form component inside TitleForm.
         */}
        <ContributorField
          isPerson={contributorIsPerson(contributor)}
          name={name}
          onRemove={onRemove}
          RemoveIcon={DeleteIcon}
          onToggleContributor={onToggleContributor}
          onClose={!error ? () => setIsEditing(false) : undefined}
        />
      </div>
    </>
  );
};

export default EditContributorField;
