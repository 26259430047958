import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import security from '../../../../security';
import organisation from '../../../../organisation';
import { getLink } from '../../../../../helpers/hateoas';
import FavoriteBookstoreField from './FavoriteBookstoreField';
import DepartmentField from './DepartmentField';
import { ImpersonatedUser } from '../../../../security/model';
import getIdFromUrl from '../../../../../helpers/getIdFromUrl';
import { useTranslation } from 'react-i18next';

const DepartmentAndBookstoreFields: FC = () => {
  const { t } = useTranslation();
  const user = useSelector(security.selectors.getUser);
  const { departments, favorites } = organisation.hooks.useLibraryDepartmentsAndBookstores();
  const idFromUrl = getIdFromUrl(
    getLink(user?._embedded.organisation, 'departmentCurrentUser') || ''
  );

  const departmentId = (user as ImpersonatedUser)?.departmentId || idFromUrl;

  return (
    <>
      <Grid item xs={6}>
        <FavoriteBookstoreField
          favorites={favorites}
          maxMenuHeight={120}
          label={`${t('form_favorite_bookstore')} *`}
        />
      </Grid>
      <Grid item xs={6}>
        <DepartmentField
          key={departmentId}
          departments={departments}
          initialValue={departmentId}
          isDisabled={!Boolean(departmentId)}
          maxMenuHeight={120}
          name="departmentId"
        />
      </Grid>
    </>
  );
};

export default DepartmentAndBookstoreFields;
