import React, { HTMLProps } from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  tableFilterWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    // marginTop: 16,
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
}));

type Props = HTMLProps<HTMLDivElement>;

const TableFilterWrapper = (props: Props) => {
  const classes = useStyles();

  return <div className={classes.tableFilterWrapper} {...props} />;
};

export default TableFilterWrapper;
