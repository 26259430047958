import React, { FC } from 'react';
import { Orders } from '../../../../config/api/models/shop';
import { useTranslation } from 'react-i18next';
import { CellType, DataType } from '../../../../components/Table/types';
import Table from '../../../../components/Table';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import TextLink from '../../../../components/TextLink';
import { trimStringLength } from '../../../../helpers/trimStringLength';
import moment from 'moment';
import { Box } from '@material-ui/core';
import formatEuro from '../../../../helpers/formatEuro';
import usePath from '../../../../config/routes/usePath';
import ROUTE_KEY from '../../../../config/routes/routeKeys';
import OrdersHistoryForm from '../ordersHistory/OrdersHistoryForm';
import organisation from '../../../organisation';
import { FormApi } from 'final-form';

type Props = {
  pagedOrders: Orders;
  fetchOrders: (url: string) => void;
  isLoading: boolean;
};
export const OrdersTableForBookstore: FC<Props> = ({ pagedOrders, fetchOrders, isLoading }) => {
  const { t } = useTranslation();
  const url = pagedOrders._links.self.href;
  const orderPath = usePath(ROUTE_KEY.ORDER_DETAIL_BOOKSTORE);

  const headers = [
    { id: 'order_ref', label: t('table_headers_library_order_reference'), width: '15%' },
    { id: 'ordered_at', label: t('table_headers_ordered_at'), width: '10rem' },
    { id: 'library', label: t('table_headers_library') },
    { id: 'department', label: t('table_headers_department') },
    { id: 'num_titles', label: t('table_headers_num_titles'), width: '4rem', align: 'center' },
    { id: 'num_pieces', label: t('table_headers_num_pieces'), width: '4rem', align: 'center' },
    { id: 'resale_value', label: t('table_headers_resale_value'), width: '6rem', align: 'right' },
  ];

  const data: DataType = {
    resource: 'orders',
    rows: pagedOrders._embedded.items.map((order) => {
      const id = getIdFromUrl(order._links.self.href);
      const path = orderPath.replace(':id', id);

      return {
        id,
        link: path,
        linkState: {
          from: window.location.pathname,
        },
        cells: [
          {
            type: CellType.Text,
            data: (
              <TextLink
                to={{
                  pathname: path,
                  state: { from: window.location.pathname },
                }}
                title={order.orderReference}
              >
                {trimStringLength(order.orderReference, 45)}
              </TextLink>
            ),
          },
          {
            type: CellType.Text,
            data: moment(order.orderedAt).format('DD/MM/YYYY - HH:mm'),
          },
          {
            type: CellType.Text,
            data: <>{order._embedded.organisation.name}</>,
          },
          {
            type: CellType.Text,
            data: <>{order._embedded.department?.title}</>,
          },
          {
            type: CellType.Text,
            data: <Box textAlign="center">{order.distinctTitleCount}</Box>,
          },
          {
            type: CellType.Text,
            data: (
              <Box textAlign="center">
                <TextLink
                  to={{
                    pathname: path,
                    state: { from: window.location.pathname },
                  }}
                >
                  {order.titleCount}
                </TextLink>
              </Box>
            ),
          },
          {
            type: CellType.Text,
            data: <Box textAlign="right">{formatEuro(order.resaleValue)}</Box>,
          },
        ],
      };
    }),
  };

  return (
    <>
      <OrdersHistoryForm
        fetchOrders={fetchOrders}
        url={url}
        role="library"
        numResults={pagedOrders.total}
        withFreeText={false}
        withExportButton={false}
      >
        {({ form }: { form: FormApi }) => (
          <organisation.components.LibrarySelectField onClearValue={form.submit} />
        )}
      </OrdersHistoryForm>
      <Table
        data={data}
        headers={headers}
        pagedResource={pagedOrders}
        loading={isLoading}
        dispatchFn={fetchOrders}
      />
    </>
  );
};
