import React, { FC } from 'react';
import clsx from 'clsx';
import { useTranslation, Trans } from 'react-i18next';
import {
  makeStyles,
  Theme,
  Grid,
  Box,
  Typography,
  List,
  Toolbar,
  Divider,
} from '@material-ui/core';

// App imports:
import LoginForm from '../containers/LoginForm';
import Button from '../../../components/Button';
import usePath from '../../../config/routes/usePath';
import ROUTE_KEY from '../../../config/routes/routeKeys';
import Logo from '../../app/components/Nav/Logo';
import HomePageUspListItem from '../../app/components/HomePageUspListItem';
import ExternalLink from '../../app/components/ExternalLink';
import { ReactComponent as M4BLogo } from '../../../config/assets/pictures/meta4books_logo.svg';
import { EXTERNAL_LINKS } from '../../../config/data/external_links';
import EnvLabel from '../../app/components/EnvLabel';

// Icons:
import BarcodeIcon from 'mdi-material-ui/Barcode';
import BookshelfIcon from 'mdi-material-ui/Bookshelf';
import ShareVariantIcon from 'mdi-material-ui/ShareVariant';
import CartArrowRightIcon from 'mdi-material-ui/CartArrowRight';
import DollyIcon from 'mdi-material-ui/Dolly';
import CurrencyEurIcon from 'mdi-material-ui/CurrencyEur';

const getGradientStop = (opacity: number, stop: string) => `rgba(0, 0, 0, ${opacity}) ${stop}`;

const leftPageGradientColorStops = [
  getGradientStop(0.7, '0%'),
  getGradientStop(0.5, '1px'),
  getGradientStop(0.2, '8px'),
  getGradientStop(0.05, '48px'),
  getGradientStop(0, '100%'),
];

const rightPageGradientColorStops = [
  getGradientStop(0.2, '0%'),
  getGradientStop(0.1, '8px'),
  getGradientStop(0, '48px'),
  getGradientStop(0.05, '128px'),
  getGradientStop(0, '100%'),
];

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      minHeight: '100vh',
    },
    loginSidePaneContainer: {
      zIndex: 1, // Bump to show Paper (loginSidepane) box-shadow
    },
    loginSidepane: {
      padding: theme.spacing(10, 12, 6, 12),
      minHeight: '100%',
      background: `linear-gradient(to left, ${leftPageGradientColorStops.join(', ')})`,
    },
    mainPane: {
      minHeight: '100%',
      background: `linear-gradient(to right, ${rightPageGradientColorStops.join(', ')})`,
    },
    mainPaneGutters: {
      paddingLeft: theme.spacing(16),
      paddingRight: theme.spacing(25),
    },
    helperText: {
      marginBottom: theme.spacing(3),
    },
    isbnButton: {
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    },
    toolbar: {
      minHeight: 96,
      paddingRight: theme.spacing(8),
      '& > *:not(:last-child)': {
        marginRight: theme.spacing(4),
      },
    },
    topToolbar: {
      minHeight: 104,
      justifyContent: 'flex-end',
    },
    uspList: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(6),
    },
    m4bLogoWrapper: {
      height: 48,
    },
    title: {
      marginBottom: theme.spacing(2),
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.primary.main,
    },
    subTitle: {
      color: 'rgba(0, 0, 0, 0.8)',
      opacity: 0.8,
    },
    envLabel: {
      position: 'fixed',
      margin: theme.spacing(2),
    },
  }),
  { name: 'HomePage' }
);

const HomePage: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const isbnApplicationPath = usePath(ROUTE_KEY.ISBN_APPLICATION, { isbn: '' });

  return (
    <>
      <EnvLabel className={classes.envLabel} />
      <Grid container className={classes.root}>
        <Grid item xs={4} className={classes.loginSidePaneContainer}>
          <Box className={classes.loginSidepane}>
            <Logo />
            <Box pb={3}>
              <LoginForm />
            </Box>
            <Divider />
            <Box pt={3} pb={3}>
              <Typography component="p" variant="subtitle1">
                <Trans
                  i18nKey="home_page_register_helper"
                  components={[<ExternalLink href={EXTERNAL_LINKS['contact']} />]}
                />
              </Typography>
            </Box>
            <Divider />
            <Box pt={3}>
              <Typography component="p" variant="subtitle1" className={classes.helperText}>
                {t('home_page_isbn_helper')}
              </Typography>
              <Box mb={4}>
                <Button
                  fullWidth
                  variant="outlined"
                  link={isbnApplicationPath}
                  className={classes.isbnButton}
                >
                  {t('home_page_isbn_button')}
                </Button>
              </Box>
              <Typography component="p" variant="subtitle1">
                <Trans
                  i18nKey="home_page_isbn_more_info"
                  components={[<ExternalLink href={EXTERNAL_LINKS['isbn-info']} />]}
                />
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box
            position="relative"
            display="flex"
            flexDirection="column"
            className={classes.mainPane}
          >
            <Toolbar className={clsx(classes.toolbar, classes.topToolbar, classes.mainPaneGutters)}>
              <Button variant="outlined" link={EXTERNAL_LINKS['about']} openInNewWindow>
                {t('home_page_about')}
              </Button>
              <Button variant="contained" link={EXTERNAL_LINKS['contact']} openInNewWindow>
                {t('nav_help_contact')}
              </Button>
            </Toolbar>
            <Box flexGrow={1} className={classes.mainPaneGutters}>
              <Typography component="h1" variant="h1" className={classes.title}>
                {t('home_page_pitch_title')}
              </Typography>
              <Typography component="h2" variant="h2" className={classes.subTitle}>
                {t('home_page_pitch_subtitle')}
              </Typography>
              <List className={classes.uspList}>
                <HomePageUspListItem icon={<BarcodeIcon />} text={t('home_page_usp_1')} />
                <HomePageUspListItem icon={<BookshelfIcon />} text={t('home_page_usp_2')} />
                <HomePageUspListItem icon={<CartArrowRightIcon />} text={t('home_page_usp_3')} />
                <HomePageUspListItem
                  icon={<ShareVariantIcon />}
                  text={
                    <Trans
                      i18nKey="home_page_usp_4"
                      components={[<ExternalLink href={EXTERNAL_LINKS['meta-data']} />]}
                    />
                  }
                />

                <HomePageUspListItem icon={<DollyIcon />} text={t('home_page_usp_5')} />
                <HomePageUspListItem
                  icon={<CurrencyEurIcon />}
                  text={
                    <Trans
                      i18nKey="home_page_usp_6"
                      components={[<ExternalLink href={EXTERNAL_LINKS['regulated-book-price']} />]}
                    />
                  }
                />
              </List>
              <Typography variant="subtitle1">
                <Trans
                  i18nKey="home_page_more_info"
                  components={[<ExternalLink href={EXTERNAL_LINKS['home']} />]}
                />
              </Typography>
            </Box>
            <Toolbar className={clsx(classes.toolbar, classes.mainPaneGutters)}>
              <ExternalLink href={EXTERNAL_LINKS['terms-of-service']}>
                {t('home_page_terms_of_service')}
              </ExternalLink>
              <ExternalLink href={EXTERNAL_LINKS['privacy-declaration']}>
                {t('home_page_privacy_declaration')}
              </ExternalLink>
              <Box flexGrow={1} />
              <ExternalLink href={EXTERNAL_LINKS['m4b-home']}>
                <Box className={classes.m4bLogoWrapper}>
                  <M4BLogo />
                </Box>
              </ExternalLink>
            </Toolbar>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default HomePage;
