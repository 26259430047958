import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import { Title } from '../../../config/api/types';

type Props = TypographyProps & {
  collection: Title['collection'];
};

const CollectionText = ({ collection, ...other }: Props) => {
  const { t } = useTranslation();

  if (!collection) {
    return null;
  }

  const text =
    !collection.number || collection.number === '0'
      ? 'title_collection_no_number_with_title'
      : parseInt(collection.number, 10)
      ? 'title_collection_numeric_with_title'
      : 'title_collection_alphanumeric_with_title';

  return <Typography {...other}>{t(text, collection)}</Typography>;
};

export default CollectionText;
