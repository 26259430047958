import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Paper,
  Tooltip,
} from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowRight from '@material-ui/icons/ArrowRight';
import CloseIcon from '@material-ui/icons/Close';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListRowRenderer, List as VirtualizedList } from 'react-virtualized';
import { Code } from '../../../config/api/models/dataSets';
import useDebounce from '../../../helpers/useDebounce';
import { BaseTextField } from '../TextField';
import { ArrowPlaceholder, ClearIcon, TreeIndent, TreeIndentUnit, TreeNode } from './Atoms';
import { FilterTreeNode, TreeData, Value } from './types';
import { defaultTreeNodeFilter, filterTree, flattenTree } from './util';

type OnSelectFavorite<TData> = (item: TData) => void;

type Props<TData extends TreeData> = {
  treeData: TData[];
  searchValue: string;
  onSearch: (term: string) => void;
  treeDefaultExpandAll?: boolean;
  getLabel?: (item: TData) => React.ReactNode;
  width?: number;
  height?: number;
  rowHeight?: number;
  filterTreeNode?: FilterTreeNode;
  favorite?: TData;
  onSelectFavorite?: OnSelectFavorite<TData>;
};
type SingleValueProps<TData extends TreeData, TValue extends Value> = Props<TData> & {
  value?: TValue;
  onSelect: (item: TData) => void;
};
export type MultiValueProps<TData extends TreeData, TValue extends Value> = Props<TData> & {
  value?: TValue[];
  onSelect: (item: (TData | TValue)[]) => void;
};

export type TreeSelectProps<TData extends TreeData, TValue extends Value> =
  | MultiValueProps<TData, TValue>
  | SingleValueProps<TData, TValue>;

const isSingleValue = (value: Value | Value[]): value is Value => !Array.isArray(value);
const isSingleValueProps = <TData extends TreeData, TValue extends Value>(
  props: SingleValueProps<TData, TValue> | MultiValueProps<TData, TValue>
): props is SingleValueProps<TData, TValue> => {
  return !Array.isArray(props.value);
};
const getNearestParent = (codes: Code[]) => (codes.length ? codes[codes.length - 1] : null);

const TreeSelect = <TData extends TreeData, TValue extends Value>(
  props: TreeSelectProps<TData, TValue>
) => {
  const {
    treeData,
    value,
    searchValue,
    onSearch,
    treeDefaultExpandAll,
    width = 500,
    height = 400,
    rowHeight = 28,
    getLabel,
    filterTreeNode = defaultTreeNodeFilter,
    favorite,
    onSelectFavorite,
  } = props;

  const { t } = useTranslation();
  const [openItems, setOpenItems] = useState<Record<Code, boolean>>({});
  const [debouncedSearchValue] = useDebounce(searchValue, 200);

  useEffect(() => {
    setOpenItems({});
  }, [searchValue]);

  const itemIsOpen = (item: TData | Code) => {
    const code = typeof item === 'string' ? item : getNearestParent(item.parents);

    if (code === null) return true;
    if (openItems[code]) return true;

    return treeDefaultExpandAll ? typeof openItems[code] === 'undefined' : false;
  };

  const toggleOpenItem = (item: TData, forceClose = false) => (e?: React.MouseEvent) => {
    e?.preventDefault();
    e?.stopPropagation();

    const newOpenState = forceClose ? false : !itemIsOpen(item.code);

    if (!newOpenState) {
      item.children?.forEach((child) => toggleOpenItem(child as TData, true)());
    }

    setOpenItems((prevItems) => ({
      ...prevItems,
      [item.code]: newOpenState,
    }));
  };

  const handleSelect = (item: TData) => () => {
    if (isSingleValueProps(props)) {
      props.onSelect(item);
    } else {
      const valueWithoutItem = props.value?.filter((v) => v.code !== item.code) || [];
      props.onSelect(
        valueWithoutItem.length === (props.value || []).length
          ? [...valueWithoutItem, item]
          : valueWithoutItem
      );
    }
  };

  const handleSelectFavorite = (item: TData) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (onSelectFavorite) {
      onSelectFavorite(item);
    }
  };

  const filteredData = filterTree(treeData, debouncedSearchValue, filterTreeNode);

  // @ts-ignore
  const filteredFlatTreeData: TData[] = filteredData.reduce(flattenTree, []);
  // @ts-ignore
  const flatTreeData: TData[] = treeData.reduce(flattenTree, []);

  const itemsToRender = filteredFlatTreeData.filter(itemIsOpen);

  const selectedValueCodes = value
    ? isSingleValue(value)
      ? [value.code]
      : value.map((v) => v.code)
    : [];

  const selectedItemsToRender = flatTreeData.filter((item: TData) =>
    selectedValueCodes.includes(item.code)
  );

  const renderTreeNode = (
    items = itemsToRender,
    enableIndent = true,
    collapsible = true,
    onSelectFavorite?: OnSelectFavorite<TData>
  ): ListRowRenderer => ({ key, index, style }) => {
    const item = items[index];
    const isSelected = selectedValueCodes.includes(item.code);
    const isOpen = itemIsOpen(item.code);

    const isSelectable = !item.notSelectable;

    return (
      <TreeNode
        key={`${item.code}${isOpen}`}
        button
        onClick={
          isSelectable
            ? onSelectFavorite
              ? handleSelectFavorite(item)
              : handleSelect(item)
            : undefined
        }
        style={style}
        isSelected={isSelected}
      >
        {enableIndent && (
          <TreeIndent>
            <TreeIndentUnit
              // --> See #1
              // isOpen={(!isLastChild && item.parents.length) || item.parents.length > 1 || isOpen}
              isOpen={false}
            />
            {item.parents.map((code: Code, index: number) => (
              <TreeIndentUnit
                isOpen={false}
                key={index}
                // --> See #1
                // isOpen={
                //   !isLastChild &&
                //   ((item.children?.length ? isOpen : index < item.parents.length - 1) ||
                //     item.parents.length > 1)
                // }
              />
            ))}
          </TreeIndent>
        )}
        {collapsible && item.children?.length ? (
          <ListItemIcon onClick={toggleOpenItem(item)} style={{ justifyContent: 'center' }}>
            {isOpen ? <ArrowDropDown /> : <ArrowRight />}
          </ListItemIcon>
        ) : (
          <ArrowPlaceholder />
        )}
        {onSelectFavorite && isSelectable && (
          <Box mr={2} ml={-1}>
            <Tooltip title={t('title_mark_favorite')}>
              <IconButton
                size="small"
                onClick={handleSelectFavorite(item)}
                style={{ justifyContent: 'center' }}
              >
                {favorite?.code === item.code ? (
                  <RadioButtonChecked fontSize="small" />
                ) : (
                  <RadioButtonUnchecked fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        )}

        {/* @ts-ignore */}
        {getLabel ? getLabel(item) : <ListItemText primary={item.label} style={{ flexGrow: 0 }} />}

        {value && !isSingleValue(value) && isSelected && (
          <IconButton
            size="small"
            style={{ justifyContent: 'center', opacity: 0.6 }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSelect(item)();
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </TreeNode>
    );
  };

  return (
    <Box width={width}>
      <Box component={Paper} display="flex" justifyContent="flex-start">
        <Box width={240} paddingLeft={2} paddingTop={1} paddingBottom={1}>
          <BaseTextField
            autoFocus
            value={searchValue}
            onChange={(e) => onSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchValue && <ClearIcon onClick={() => onSearch('')} />}
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Divider />
      {value && !isSingleValue(value) && selectedItemsToRender.length > 0 && (
        <>
          <Paper>
            <VirtualizedList
              width={width}
              height={Math.min(selectedItemsToRender.length * rowHeight, 132)}
              rowCount={selectedItemsToRender.length}
              rowHeight={rowHeight}
              rowRenderer={renderTreeNode(selectedItemsToRender, false, false, onSelectFavorite)}
            />
          </Paper>
          <Divider />
        </>
      )}
      <Paper>
        <VirtualizedList
          width={width}
          height={height}
          rowCount={itemsToRender.length}
          rowHeight={rowHeight}
          rowRenderer={renderTreeNode()}
        />
      </Paper>
    </Box>
  );
};

export default TreeSelect;
