import React, { FC } from 'react';
import { Box, Grid } from '@material-ui/core';
import { isMoment } from 'moment';
import { API_DATE_FORMAT } from '../../../../helpers/date';
import { retrieveAllQueryParameters, setQueryParameters } from '../../../../helpers/hateoas';
import AutoSave from '../../../../components/Form/AutoSave';
import { TextField } from '../../../../components/Form';
import FromToField from '../../../../components/Form/FromToField';
import Button from '../../../../components/Button';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import organisation from '../../../organisation';

type Props = {
  fetchRetours: (url: string) => void;
  url: string;
  withFreeText?: boolean;
};

export const RetourHistoryFilter: FC<Props> = ({ fetchRetours, url, withFreeText = true }) => {
  const { t } = useTranslation();
  const initialValues = retrieveAllQueryParameters(url);

  return (
    <Box marginTop={4} marginBottom={2}>
      <Form
        onSubmit={(values) => {
          const dateFrom = isMoment(values.from_date)
            ? values.from_date.format(API_DATE_FORMAT)
            : values.from_date;
          const dateTo = isMoment(values.to_date)
            ? values.to_date.format(API_DATE_FORMAT)
            : values.to_date;

          const urlWithParams = setQueryParameters(url, {
            ...values,
            // @ts-ignore
            distributor_id: values.distributor?.distributorId,
            from_date: dateFrom || '',
            to_date: dateTo || '',
            page: 1,
          });

          fetchRetours(urlWithParams);
        }}
        initialValues={initialValues}
      >
        {({ handleSubmit, form, values }) => (
          <form onSubmit={handleSubmit}>
            <AutoSave save={form.submit} />
            <Grid container spacing={1}>
              {withFreeText && (
                <Grid xs={12} md={6} lg={3} item>
                  <TextField
                    name="q"
                    label={t('form_gtin13_search')}
                    placeholder={t('form_placeholder_gtin13_search')}
                    autoComplete="off"
                    isClearable
                    onClear={form.submit}
                  />
                </Grid>
              )}
              <Grid xs={12} md={6} lg={3} item>
                <organisation.components.DistributorsSelectField
                  label={t('table_headers_distributor')}
                  url={'/retour/distributors'}
                  change={form.change}
                  distributorId={values.distributor_id}
                  onClearValue={form.submit}
                />
              </Grid>
              <Grid xs={12} md={6} lg={withFreeText ? 2 : 3} item>
                <TextField
                  name="reference"
                  label={t('table_headers_retour_reference')}
                  placeholder={t('table_headers_retour_reference')}
                  autoComplete="off"
                  isClearable
                  onClear={form.submit}
                />
              </Grid>
              <FromToField onSubmit={form.submit} md={withFreeText ? 2 : 3} />
            </Grid>

            <Button type="submit" style={{ display: 'none' }}>
              {t('form_action_save')}
            </Button>
          </form>
        )}
      </Form>
    </Box>
  );
};
