import React from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import SubTitle from '../../../../../components/SubTitle';
import Field from '../../../../../components/Form';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import { map } from 'ramda';
import countries from '../../../../../config/data/countries';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import FormFieldWrapper from '../../../../../components/Form/FormFieldWrapper';
import Fieldset from './Fieldset';

type countryType = {
  name: string;
  short_name: string;
  calling_code: string;
};

type Props = WithStyles & {};

const styles = createStyles((theme: Theme) => ({
  field: {
    margin: '16px 0px 16px 0',
  },
  divider: {
    marginBottom: 16,
  },
}));

const EditForm = ({ classes }: Props) => {
  const { t } = useTranslation();

  return (
    <Fieldset>
      <SubTitle>{t('form_company_info')}</SubTitle>
      <Divider className={classes.divider} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormFieldWrapper>
            <Field label={`${t('form_name')}*`} type="text" name="name" />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <Field
              label={t('form_company_registration_number')}
              type="text"
              name="companyRegistrationNumber"
              helperText={t('form_company_registration_number_helpertext')}
            />
          </FormFieldWrapper>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Field label={t('form_street')} type="text" name="street" />
            </Grid>
            <Grid item xs={3}>
              <Field label={t('form_number')} type="text" name="number" />
            </Grid>
            <Grid item xs>
              <Field label={t('form_addition')} type="text" name="addition" />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Field label={t('form_postal_code')} type="text" name="postalCode" />
            </Grid>
            <Grid item xs>
              <Field label={t('form_city')} type="text" name="city" />
            </Grid>
          </Grid>
          <FormFieldWrapper>
            <Field label={`${t('form_country_iso')}*`} select type="text" name="countryIso">
              {map((country: countryType) => (
                <MenuItem key={country.short_name} value={country.short_name}>
                  {country.name}
                </MenuItem>
              ))(countries)}
            </Field>
          </FormFieldWrapper>
          <FormFieldWrapper>
            <Field
              label={t('form_phone_number')}
              type="text"
              helperText={t('form_phone_number_helpertext')}
              name="phoneNumber"
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <Field
              label={t('form_email')}
              type="text"
              helperText={t('form_email_helpertext')}
              name="email"
            />
          </FormFieldWrapper>
        </Grid>
        <Grid item xs>
          <FormFieldWrapper>
            <Field
              label={t('form_alternative_names')}
              name="alternativeNames[]"
              type="text"
              className={classes.field}
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <Field
              label={t('form_notes')}
              type="text"
              className={classes.field}
              rows="5"
              name="notes"
              multiline
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <Field
              label={t('form_relation')}
              type="text"
              className={classes.field}
              name="relation"
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <Field
              label={t('form_url')}
              type="text"
              name="url"
              helperText={t('form_url_helpertext')}
            />
          </FormFieldWrapper>
        </Grid>
      </Grid>
    </Fieldset>
  );
};

export default withStyles(styles)(EditForm);
