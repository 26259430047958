import React, { useEffect } from 'react';
import { Box, Grid, Theme } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import { useFetch } from '@react-redux-fetch/hooks';
import groupBy from 'lodash/groupBy';
import { useSelector } from 'react-redux';
import security from '../../../security';
import SubTitle from '../../../../components/SubTitle';
import { getOrder } from '../../selectors';
import DelayedLoader from '../../../../components/DelayedLoader';
import { getOrderRequest } from '../../api';
import organisation from '../../../organisation';
import { makeStyles } from '@material-ui/styles';
import Header from '../../../../components/Header';
import useApiRoute from '../../../../config/api/useApiRoute';
import { Redirect } from 'react-router-dom';
import OrderDetailTable from './OrderDetailTable';
import { Order, ShoppingCartLine } from '../../../../config/api/models/shop';
import { Publisher } from '../../../../config/api/types';
import { Label, Row, Value } from './Atoms';
import ExportButton from '../../../core/exports';

type Props = {
  orderId: string;
  goBackPath?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  distributor: {
    color: theme.palette.text.subtitle,
    fontSize: 18,
  },
}));

type OrderByPublisher = {
  lines: ShoppingCartLine[];
  publisher?: Publisher;
};

const groupOrderByPublisher = (order: Order): OrderByPublisher[] => {
  // @ts-ignore
  const allLines: ShoppingCartLine[] = order.linesByDistributor.reduce((agg, distributorLine) => {
    return distributorLine.lines ? [...agg, ...distributorLine.lines] : agg;
  }, []);

  const grouped = groupBy(allLines, (line) => {
    return line._embedded.title.publisher?.publisherId;
  });

  return Object.values(grouped).map((lines) => {
    return {
      lines,
      publisher: lines[0]._embedded.title.publisher,
    };
  });
};

const OrderDetail = ({ orderId, goBackPath }: Props) => {
  const { t } = useTranslation();
  const orderRef = useApiRoute('order', orderId) || '';
  const order = useSelector(getOrder(orderRef));
  const user = useSelector(security.selectors.getUser);
  const [, fetchOrder] = useFetch(getOrderRequest);
  const classes = useStyles();

  useEffect(() => {
    if (orderRef) fetchOrder(orderRef);
  }, [orderRef, fetchOrder]);

  if (!orderRef) {
    return <Redirect to="/" />;
  }

  const isCustomer =
    (user && user._links.organisation.href) === (order && order._links.organisation.href);
  const isLoading =
    !order || order.linesByDistributor.some((distributorLine) => !distributorLine.lines);

  return (
    <DelayedLoader data={!isLoading}>
      {order ? (
        <>
          <Header
            title={t(`page_title_order_detail${isCustomer ? '_customer' : ''}`)}
            goBack
            goBackPath={goBackPath}
          />

          <Grid container justify="flex-end">
            <Grid item component={Box} marginTop={-7.5}>
              <ExportButton url={orderRef} size="small">
                {t('order_single_export')}
              </ExportButton>
            </Grid>
          </Grid>

          <Box marginTop={3}>
            <Row>
              <Label>{t('order_number')}:</Label>
              <Value>{order.orderNumber}</Value>
            </Row>
            <Row>
              <Label>{t('table_headers_order_reference')}:</Label>
              <Value>{order.orderReference}</Value>
            </Row>
            <Row>
              <Label>{t('table_headers_ordered_at')}:</Label>
              <Value>{moment(order.orderedAt).format(`DD/MM/YYYY [${t('at')}] HH:mm`)}</Value>
            </Row>
            {!isCustomer && (
              <Row>
                <Label>{t('table_headers_bookstore')}:</Label>
                <Value>
                  <organisation.components.OrganisationDialog
                    organisationUrl={order._links.organisation.href}
                  >
                    {order._embedded.organisation.name}
                  </organisation.components.OrganisationDialog>
                </Value>
              </Row>
            )}
          </Box>

          {isCustomer
            ? order.linesByDistributor.map((linesByDistributor) => (
                <Box marginTop={3} key={linesByDistributor._links.organisation.href}>
                  <SubTitle>
                    {/* @ts-ignore */}
                    <Trans
                      i18nKey={
                        linesByDistributor._embedded.distributor?.isCbMember
                          ? 'order_ordered_at_cb'
                          : 'order_ordered_at'
                      }
                      components={[
                        <organisation.components.OrganisationDialog
                          organisationUrl={linesByDistributor._links.organisation.href}
                          toggleButtonProps={{ className: classes.distributor }}
                        >
                          {{ organisation: linesByDistributor._embedded.organisation.name }}
                        </organisation.components.OrganisationDialog>,
                      ]}
                    />
                  </SubTitle>
                  <OrderDetailTable lines={linesByDistributor.lines} />
                </Box>
              ))
            : groupOrderByPublisher(order).map((linesByPublisher, index) => (
                <Box marginTop={3} key={linesByPublisher.publisher?.publisherId || index}>
                  <SubTitle>
                    {/* @ts-ignore */}
                    <Trans
                      i18nKey="order_ordered_at_publisher"
                      components={[
                        <organisation.components.OrganisationDialog
                          organisationUrl={
                            linesByPublisher.publisher?._links.organisation
                              ? linesByPublisher.publisher._links.organisation.href
                              : ''
                          }
                          toggleButtonProps={{ className: classes.distributor }}
                        >
                          {{ organisation: linesByPublisher.publisher?.organisation.name }}
                        </organisation.components.OrganisationDialog>,
                      ]}
                    />
                  </SubTitle>
                  <OrderDetailTable lines={linesByPublisher.lines} />
                </Box>
              ))}
        </>
      ) : null}
    </DelayedLoader>
  );
};

export default OrderDetail;
