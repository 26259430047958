import React, { HTMLAttributes } from 'react';
import { TextField } from '@material-ui/core';
import { SelectOption } from './types';
import { BaseTextFieldProps } from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import { ControlProps } from 'react-select';

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & HTMLAttributes<HTMLDivElement>;

function inputComponent({ inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props} />;
}

export function Control(props: ControlProps<SelectOption, any>) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: {
      classes,
      TextFieldProps,
      isClearable,
      onClearValue,
      inputValue,
      isLoading,
      value,
    },
  } = props;

  const clearButton = !Boolean(value) && Boolean(isClearable) && Boolean(inputValue) && !isLoading && (
    <ClearIcon
      onMouseDown={onClearValue}
      className={classes.clearIcon}
      style={{
        position: 'absolute',
        right: 60,
        top: 8,
        opacity: 0.6,
        cursor: 'pointer',
      }}
    />
  );

  return (
    <>
      <TextField
        fullWidth
        variant="outlined"
        InputProps={{
          inputComponent,
          inputProps: {
            className: classes.input,
            ref: innerRef,
            children,
            ...innerProps,
          },
        }}
        {...TextFieldProps}
      />
      {clearButton}
    </>
  );
}
