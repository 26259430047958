import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { useFetch } from '@react-redux-fetch/hooks';
import moment from 'moment';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import DelayedLoader from '../../../../components/DelayedLoader';
import Header from '../../../../components/Header';
import useApiRoute from '../../../../config/api/useApiRoute';
import ExportButton from '../../../core/exports';
import organisation from '../../../organisation';
import { getOrderRequest } from '../../api';
import { getOrder } from '../../selectors';
import { Label, Row, Value } from './Atoms';
import OrderBookstoreDetailTable from './OrderBookstoreDetailTable';

type Props = {
  orderId: string;
  goBackPath?: string;
};
const OrderBookstoreDetail: FC<Props> = ({ orderId, goBackPath }) => {
  const { t } = useTranslation();
  const [, fetchOrder] = useFetch(getOrderRequest);
  const orderRef = useApiRoute('order', orderId) || '';
  const order = useSelector(getOrder(orderRef));

  useEffect(() => {
    if (orderRef) {
      fetchOrder(orderRef);
    }
  }, [fetchOrder, orderRef]);

  if (!orderRef) {
    return <Redirect to="/" />;
  }

  return (
    <DelayedLoader data={order}>
      {order ? (
        <>
          <Header
            title={t('page_title_library_order_detail_customer')}
            goBack
            goBackPath={goBackPath}
          />

          <Grid container justify="flex-end">
            <Grid item component={Box} marginTop={-7.5}>
              <ExportButton url={orderRef} size="small">
                {t('order_library_single_export')}
              </ExportButton>
            </Grid>
          </Grid>

          <Box marginTop={3}>
            <Grid container justify="space-between">
              <Grid item xs={9}>
                <Row>
                  <Label>{t('order_library_number')}:</Label>
                  <Value>{order.orderNumber}</Value>
                </Row>
                <Row>
                  <Label>{t('table_headers_library_order_reference')}:</Label>
                  <Value>{order.orderReference}</Value>
                </Row>
                <Row>
                  <Label>{t('table_headers_ordered_at')}:</Label>
                  <Value>{moment(order.orderedAt).format(`DD/MM/YYYY [${t('at')}] HH:mm`)}</Value>
                </Row>
                <Row>
                  <Label>{t('table_headers_ordered_by')}:</Label>
                  <Value>
                    <organisation.components.OrganisationDialog
                      organisationUrl={order._links.organisation.href}
                    >
                      {order._embedded.organisation.name}
                    </organisation.components.OrganisationDialog>
                    {t('orders_library_ordered_by_department', {
                      department: order._embedded?.department?.title || '',
                    })}
                  </Value>
                </Row>
              </Grid>
            </Grid>
          </Box>

          <Box marginTop={3}>
            <Grid container spacing={1}>
              <Grid item style={{ width: 120 }}>
                <Typography variant="subtitle2">{t('table_headers_isbn')}</Typography>
              </Grid>
              <Grid item style={{ flex: 1 }}>
                <Typography variant="subtitle2">{t('table_headers_title')}</Typography>
              </Grid>
              <Grid item style={{ width: 160 }}>
                <Typography variant="subtitle2">{t('table_headers_product_form')}</Typography>
              </Grid>
              <Grid item style={{ width: 160 }}>
                <Typography variant="subtitle2">{t('table_headers_line_reference')}</Typography>
              </Grid>
              <Grid item style={{ textAlign: 'center', width: 80 }}>
                <Typography variant="subtitle2">{t('table_headers_num_pieces')}</Typography>
              </Grid>
              <Grid item style={{ textAlign: 'right', width: 80 }}>
                <Typography variant="subtitle2">{t('table_headers_price')}</Typography>
              </Grid>
              <Grid item style={{ textAlign: 'right', width: 80 }}>
                <Typography variant="subtitle2">{t('table_headers_price_total')}</Typography>
              </Grid>
              <Grid item style={{ textAlign: 'center', width: 160 }}>
                <Typography variant="subtitle2">{t('table_headers_status')}</Typography>
              </Grid>
            </Grid>
            <Box marginTop={1} marginBottom={1}>
              <Divider />
            </Box>
            {order.linesByDistributor.map((linesByDistributor) => (
              <OrderBookstoreDetailTable
                lines={linesByDistributor.lines}
                key={linesByDistributor._links.organisation.href}
              />
            ))}
          </Box>
        </>
      ) : null}
    </DelayedLoader>
  );
};

export default OrderBookstoreDetail;
