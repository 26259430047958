import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { ReactComponent as BoekenbankLogo } from '../../../config/assets/pictures/boekenbank_logo2.svg';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100vh',
    marginTop: `calc(-${theme.toolbar.height}px - 48px)`,
  },
  imgWrapper: {
    textAlign: 'center',
    '& > *': {
      width: 200,
    },
  },
}));

const SmallCenterLayout: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" justify="center" className={classes.container}>
      <Grid xs={10} sm={6} md={4} lg={3} item>
        <div className={classes.imgWrapper}>
          <BoekenbankLogo />
        </div>
        {children}
      </Grid>
    </Grid>
  );
};

export default SmallCenterLayout;
