import React, { useState, Fragment } from 'react';
import { getFullName } from '../../domain';
import { Contributor } from '../../../../config/api/types';
import { Theme } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

type Props = {
  contributors: Contributor[];
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.text.helpertext,
    cursor: 'pointer',
    display: 'block',
    lineHeight: 1,
  },
}));

const ContributorList = ({ contributors }: Props) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const firstThree = contributors.slice(0, 3);
  const remaining = contributors.slice(3, contributors.length);

  const renderContributor = (contributor: Contributor) => (
    <Fragment key={getFullName(contributor)}>
      {getFullName(contributor)}
      <br />
    </Fragment>
  );

  return (
    <>
      {firstThree.map(renderContributor)}
      {remaining.length > 0 && (
        <>
          <span onClick={() => setOpen(!open)} className={classes.button}>
            {open ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
          </span>
          {open && <div>{remaining.map(renderContributor)}</div>}
        </>
      )}
    </>
  );
};

export default ContributorList;
