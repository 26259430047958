import React from 'react';
import { Theme } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import MUITableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import cx from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/styles';

type HeaderCellType = {
  id?: string;
  width?: string | number;
  minWidth?: string | number;
  label: string;
  sortable?: boolean;
  align?: 'left' | 'right' | 'center';
};
export type HeaderRowType = HeaderCellType[];

type Props = {
  headers: HeaderRowType;
  orderBy?: string;
  orderDir?: 'asc' | 'desc';
  onSortChange?: (event: any, column?: string) => void;
  dense?: boolean;
  noBorder?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    borderBottom: `2px solid ${theme.palette.text.helpertext}`,
  },
  tableRowNoBorder: {
    borderBottom: 'none',
  },
  tableRowDense: {
    height: 32,
  },
  cell: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.text.primary,
    paddingLeft: 0,
    paddingRight: theme.spacing(2),
  },
}));

const TableHead = ({ headers, orderBy, orderDir, onSortChange, noBorder, dense }: Props) => {
  const classes = useStyles();
  const tableRowClassName = cx(classes.tableRow, {
    [classes.tableRowNoBorder]: noBorder === true,
    [classes.tableRowDense]: dense === true,
  });

  return (
    <MUITableHead>
      <TableRow className={tableRowClassName}>
        {headers.map((row, index) => {
          const active = orderBy === row.id;
          const align = row.align || 'left';

          return (
            <TableCell
              key={`headcell${index}`}
              className={classes.cell}
              sortDirection={active ? orderDir : undefined}
              style={{ width: row.width, minWidth: row.minWidth, textAlign: align }}
            >
              {row.sortable ? (
                <Tooltip title={`Sorteer op ${row.label}`} enterDelay={300} placement="right">
                  {onSortChange ? (
                    <TableSortLabel
                      active={active}
                      direction={orderDir}
                      onClick={(event) => onSortChange(event, row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  ) : (
                    <>{row.label}</>
                  )}
                </Tooltip>
              ) : (
                row.label
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </MUITableHead>
  );
};

export default TableHead;
