import React from 'react';
import Grid, { GridProps } from '@material-ui/core/Grid/Grid';

type Props = {
  children: GridProps['children'];
};

const FormFieldWrapper = ({ children }: Props) => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      {children}
    </Grid>
  </Grid>
);

export default FormFieldWrapper;
