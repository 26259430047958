import React, { FC } from 'react';
import { useFormState } from 'react-final-form';
import { InputLabel, Box } from '@material-ui/core';

export const ContributorsError: FC = () => {
  const state = useFormState();
  const contributorsError = state.submitErrors?.contributors;

  if (!contributorsError) return null;

  return (
    <Box mt={2}>
      <InputLabel error>{contributorsError}</InputLabel>
    </Box>
  );
};
