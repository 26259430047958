import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import { Grid, Divider, Theme, Box, Tooltip, Typography, useTheme } from '@material-ui/core';
import { Order as OrderModel } from '../../../../config/api/models/shop';
import SubTitle from '../../../../components/SubTitle';
import LibraryOrderLine from './LibraryOrderLine';
import { makeStyles } from '@material-ui/styles';
import Button from '../../../../components/Button';
import organisation from '../../../organisation';
import ExportButton from '../../../core/exports';
import { processOrderRequest } from '../../api';
import { useFetch } from '@react-redux-fetch/hooks';
import { getLink } from '../../../../helpers/hateoas';
import OrderLineTotal from './OrderLineTotal';

type Props = {
  order: OrderModel;
  refetch: () => void;
  canMarkProcessed?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  order: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  orderTitle: {
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0,
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    background: theme.palette.background.appbar,
  },
  organisation: {
    ...theme.typography.h2,
    fontSize: 18,
    fontWeight: 'bolder',
  },
  noShrink: {
    display: 'flex',
    flexShrink: 0,
  },
  grow: {
    flexGrow: 1,
  },
}));

const LibraryOrder = ({ order, refetch, canMarkProcessed }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isUpdating, setIsUpdating] = useState(false);
  const [processOrderFetch, processOrder] = useFetch(processOrderRequest);
  const processOrderLink = getLink(order, 'markOrderLinesProcessed');

  const theme = useTheme();

  const fetchIsRejected = processOrderFetch?.rejected;
  const fetchIsSuccess = processOrderFetch?.fulfilled;

  useEffect(() => {
    if (fetchIsSuccess && isUpdating) {
      refetch();
    }
    if (fetchIsRejected && isUpdating) {
      setIsUpdating(false);
    }
  }, [fetchIsRejected, isUpdating, fetchIsSuccess, refetch]);

  if (isUpdating) {
    return null;
  }

  const handleProcessOrder = () => {
    if (processOrderLink) {
      setIsUpdating(true);
      processOrder(processOrderLink, true);
    }
  };

  return (
    <div className={classes.order}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        wrap="nowrap"
        className={classes.orderTitle}
        spacing={2}
      >
        <Grid item className={classes.noShrink}>
          <Box display="flex">
            <SubTitle gutterBottom={false} style={{ fontSize: 16 }}>
              {/* @ts-ignore */}
              <Trans
                i18nKey={'orders_library_ordered_by'}
                components={[
                  <strong>#{{ orderNumber: order.orderNumber }}</strong>,
                  <strong>{{ date: moment(order.orderedAt).format('DD/MM/YYYY') }}</strong>,
                  <organisation.components.OrganisationDialog
                    organisationUrl={order._links.organisation.href}
                    toggleButtonProps={{ className: classes.organisation }}
                  >
                    {{ organisation: order._embedded.organisation.name }}
                  </organisation.components.OrganisationDialog>,
                ]}
              />
              {Boolean(order._embedded.department) &&
                t('orders_library_ordered_by_department', {
                  department: order._embedded.department.title,
                })}
            </SubTitle>
          </Box>
        </Grid>
        <Grid item zeroMinWidth className={classes.grow} style={{ paddingLeft: 0, marginLeft: -2 }}>
          <Tooltip placement="top-start" title={order.orderReference || ''} enterDelay={500}>
            <Typography
              style={{ fontSize: 16, color: theme.palette.text.subtitle }}
              noWrap
              variant="h2"
            >
              <Trans
                i18nKey="with_ref"
                components={[<strong>{{ reference: order.orderReference }}</strong>]}
              />
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item className={classes.noShrink}>
          <Grid container spacing={1} wrap="nowrap">
            {canMarkProcessed && (
              <Grid item>
                <Button size="small" onClick={handleProcessOrder} disabled={isUpdating}>
                  {t('order_mark_complete')}
                </Button>
              </Grid>
            )}
            <Grid item>
              <ExportButton url={order._links.self.href} size="small">
                {t('order_library_single_export')}
              </ExportButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      {order.linesByDistributor.map((distributorLine) =>
        distributorLine.lines?.map((orderLine) => (
          <React.Fragment key={orderLine.titleId}>
            <LibraryOrderLine
              orderLine={orderLine}
              orderRef={order._links.self.href}
              onAddToCart={refetch}
              isCbMember={!!orderLine._embedded.title._embedded.fund.distributor?.isCbMember}
            />
            <Divider />
          </React.Fragment>
        ))
      )}
      <OrderLineTotal amount={order.resaleValue} />
    </div>
  );
};

export default LibraryOrder;
