import React from 'react';
import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { TypographyProps } from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    padding: theme.spacing(1),
    display: 'flex',
  },
  label: {
    flex: 2,
  },
  value: {
    flex: 5,
  },
}));

type Props = {
  label: string;
  value: TypographyProps['children'];
};

const ListItem = ({ label, value }: Props) => {
  const classes = useStyles();

  return (
    <li className={classes.listItem}>
      <Typography className={classes.label}>{label}</Typography>
      <Typography className={classes.value}>{value}</Typography>
    </li>
  );
};

export default ListItem;
