import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import usePath from '../../../config/routes/usePath';
import ROUTE_KEY from '../../../config/routes/routeKeys';
import { useSelector } from 'react-redux';
import { isAuthenticated } from '../../security/selectors';

const RedirectToLoginOnLogout: FC = () => {
  const loginPath = usePath(ROUTE_KEY.LOGIN);
  const auth = useSelector(isAuthenticated);
  const [prevAuth, setPrevAuth] = useState(auth);
  const { push } = useHistory();

  useEffect(() => {
    if (!prevAuth && auth) {
      setPrevAuth(auth);
    }
  }, [prevAuth, auth]);

  useEffect(() => {
    // When the user transitions from authenticated to not authenticated, and the loginPath is available:
    // Redirect the user to the login path.
    if (prevAuth && !auth && loginPath) {
      push(loginPath);
      setPrevAuth(auth);
    }
  }, [prevAuth, auth, loginPath, push]);

  return <></>;
};

export default RedirectToLoginOnLogout;
