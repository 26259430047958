import React from 'react';
import { Theme, Divider as MuiDivider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
}));

const Divider = () => {
  const classes = useStyles();

  return <MuiDivider className={classes.divider} />;
};

export default Divider;
