import React, { useEffect, useState } from 'react';
import { Library, Maybe } from '../../../../../config/api/types';
import FormTitle from '../../components/FormTitle';
import { useTranslation } from 'react-i18next';
import { useFetch } from '@react-redux-fetch/hooks';
import { getFavoriteBookstoresRequest, addFavoriteBookstoreRequest } from '../../../api';
import { useSelector } from 'react-redux';
import { getFavoriteBookstores } from '../../selectors';
import FavoriteBookstoreList from '../../components/Library/FavoriteBookstoreList';
import FavoriteBookstoreForm from '../../components/Library/FavoriteBookstoreForm';
import Button from '../../../../../components/Button';
import { Add } from '@material-ui/icons';
import { getLink } from '../../../../../helpers/hateoas';

type Props = {
  library: Maybe<Library>;
};

const FavoriteBookstore = ({ library }: Props) => {
  const { t } = useTranslation();
  const [, favoriteBookstoresFetch] = useFetch(getFavoriteBookstoresRequest);
  const [addFavoriteResponse, addFavorite] = useFetch(addFavoriteBookstoreRequest);
  const [showFavoritesForm, setShowFavoritesForm] = useState<boolean>(false);

  const favoriteBookstores = useSelector(getFavoriteBookstores);

  const favoriteBookstoresApiUrl = getLink(library, 'favorites');

  useEffect(() => {
    if (
      addFavoriteResponse?.fulfilled &&
      !addFavoriteResponse.pending &&
      favoriteBookstoresApiUrl
    ) {
      favoriteBookstoresFetch(favoriteBookstoresApiUrl);
    }
  }, [favoriteBookstoresFetch, favoriteBookstoresApiUrl, addFavoriteResponse]);

  useEffect(() => {
    if (favoriteBookstoresApiUrl) {
      favoriteBookstoresFetch(favoriteBookstoresApiUrl);
    }
  }, [favoriteBookstoresFetch, favoriteBookstoresApiUrl]);

  return (
    <>
      <FormTitle title={t('organisation_edit_library_favorite_bookstores_title')} />
      <FavoriteBookstoreList
        favoriteBookstores={favoriteBookstores}
        favoritesLink={favoriteBookstoresApiUrl || ''}
      />
      {showFavoritesForm ? (
        <FavoriteBookstoreForm
          onSubmit={(values) => {
            addFavorite(`${favoriteBookstoresApiUrl}/${values.bookstore}`);
          }}
          onClose={() => setShowFavoritesForm(false)}
        />
      ) : (
        <Button variant="text" onClick={() => setShowFavoritesForm(true)}>
          <Add /> {t('form_favorite_bookstore_add')}
        </Button>
      )}
    </>
  );
};

export default FavoriteBookstore;
