import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import Header from '../../../../components/Header';
import FundForm from '../containers/FundForm';
import { ClientRouteContext } from '../../../../config/routes/ClientRouteContext';
import { getParentRoute } from '../../../../config/routes/helpers';

type Props = RouteComponentProps<{ id?: string }>;

const FundDetailPage = ({ location, history, match }: Props) => {
  const { t } = useTranslation();
  const { nestedRoutes: clientRoutes } = useContext(ClientRouteContext);
  const path =
    location.state && location.state.from
      ? location.state.from
      : (getParentRoute(clientRoutes, match.path) || { path: '' }).path;

  return (
    <>
      <Header title={t('page_title_fund')} goBackPath={path} />
      <FundForm prevLocation={path} history={history} id={match.params.id} />
    </>
  );
};

export default FundDetailPage;
