import { Theme } from '@material-ui/core';
import { styled } from '@material-ui/styles';

export default styled('div')(({ theme }: { theme: Theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  overflowY: 'auto',
  height: `calc(100vh - ${theme.toolbar.height + theme.spacing(6)}px)`,
  flex: 1,
  // maxWidth: 840,
  // paddingBottom: '20rem',
  '& > div': {
    marginBottom: theme.spacing(3),
  },
}));
