import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useApiRoute from '../../../../../config/api/useApiRoute';
import { AviLevel as AviLevelModel } from '../../../../../config/api/models/dataSets';
import FacetWrapper from '../../components/Facet/FacetWrapper';
import { Form } from 'react-final-form';
import AutoSave from '../../../../../components/Form/AutoSave';
import SetInitialValueWhenLoaded from '../../../../../components/Form/SetInitialValueWhenLoaded';
import { getAviLevels } from '../../../admin/title/selectors';
import { QueryObjectValue } from '../../../../../helpers/elasticQueryDSL';
import AviLevel from '../../../admin/title/containers/fields/AviLevel';
import { Grid } from '@material-ui/core';

type Props = {
  onFilter: (aviLevelsNewCode?: string, aviLevelsOldCode?: string) => void;
  filters: {
    aviLevelsNewCode?: QueryObjectValue;
    aviLevelsOldCode?: QueryObjectValue;
  };
};

type FormValues = {
  aviLevelsNew?: { code: string };
  aviLevelsOld?: { code: string };
};

const getAviLevelCode = (aviLevel: AviLevelModel) => aviLevel.code;

const AviLevelsFacet: FC<Props> = ({
  onFilter,
  filters: { aviLevelsNewCode, aviLevelsOldCode },
}) => {
  const { t } = useTranslation();
  const urlNewAviLevels = useApiRoute('titleAviLevelsNew');
  const urlOldAviLevels = useApiRoute('titleAviLevelsOld');

  const findNewAviLevel = useCallback(
    (aviLevel: AviLevelModel) => aviLevel.code === aviLevelsNewCode,
    [aviLevelsNewCode]
  );
  const findOldAviLevel = useCallback(
    (aviLevel: AviLevelModel) => aviLevel.code === aviLevelsOldCode,
    [aviLevelsOldCode]
  );

  if (!urlNewAviLevels || !urlOldAviLevels) {
    return null;
  }

  return (
    <FacetWrapper
      title={t('facet_avi_levels')}
      collapsible={false}
      active={Boolean(aviLevelsNewCode) || Boolean(aviLevelsOldCode)}
    >
      <Form
        onSubmit={(values: FormValues) => {
          onFilter(values.aviLevelsNew?.code, values.aviLevelsOld?.code);
        }}
      >
        {({ form: { submit } }) => (
          <>
            <AutoSave save={submit} />
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <AviLevel
                  url={urlNewAviLevels}
                  fieldName="aviLevelsNew"
                  label=""
                  placeholder={t('title_aviLevelsNew_short')}
                  isMulti={false}
                  blurInputOnSelect
                  onClearValue={submit}
                />
              </Grid>
              <Grid item xs={6}>
                <AviLevel
                  url={urlOldAviLevels}
                  fieldName="aviLevelsOld"
                  label=""
                  placeholder={t('title_aviLevelsOld_short')}
                  isMulti={false}
                  blurInputOnSelect
                  onClearValue={submit}
                />
              </Grid>
            </Grid>
            {aviLevelsNewCode && (
              <SetInitialValueWhenLoaded
                name="aviLevelsNew.code"
                dataSelector={getAviLevels('aviLevelsNew')}
                findValue={findNewAviLevel}
                getValue={getAviLevelCode}
              />
            )}
            {aviLevelsOldCode && (
              <SetInitialValueWhenLoaded
                name="aviLevelsOld.code"
                dataSelector={getAviLevels('aviLevelsOld')}
                findValue={findOldAviLevel}
                getValue={getAviLevelCode}
              />
            )}
          </>
        )}
      </Form>
    </FacetWrapper>
  );
};

export default AviLevelsFacet;
