import React, { FC } from 'react';
import FundField from '../../../admin/title/containers/fields/FundField';
import FacetTitle from '../../../catalog/components/Facet/FacetTitle';
import { useTranslation } from 'react-i18next';

const FundsFilter: FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <FacetTitle>{t('facet_fund')}</FacetTitle>
      <FundField label=" " blurInputOnSelect isClearable />
    </div>
  );
};

export default FundsFilter;
