import React from 'react';
import { FtpAccount } from '../../components/Users/index';
import { Form } from 'react-final-form';
import { ReduxFetch } from 'react-redux-fetch';
import { ApiConfig } from '../../../api';
import { Organisation } from '../../../../../config/api/types';
import { getLink } from '../../../../../helpers/hateoas';
import createFinalFormAsyncValidation from '../../../../../helpers/finalFormAsyncValidation';

type Props = {
  organisation: Organisation;
};

const FTPAccounts = ({ organisation }: Props) => {
  const organisationFTPLink = getLink(organisation, 'ftp');

  const { createSubmissionPromise, handleFulfil, handleReject } = createFinalFormAsyncValidation(
    'organisationStatusFetch'
  );

  return (
    <>
      {organisationFTPLink && (
        <ReduxFetch
          config={[ApiConfig.updateOrganisationStatus(organisationFTPLink, true)]}
          onFulfil={handleFulfil}
          onReject={handleReject}
        >
          {({ dispatchOrganisationStatusPut, organisationStatusFetch }) => {
            const ftpData =
              organisationStatusFetch.value?.account === organisation?.ftpLogin
                ? organisationStatusFetch.value
                : { account: organisation?.ftpLogin, password: '' };
            return (
              <Form
                onSubmit={() => {
                  dispatchOrganisationStatusPut();
                  return createSubmissionPromise();
                }}
              >
                {({ handleSubmit, submitting }) => (
                  <form>
                    <FtpAccount
                      handleSubmit={handleSubmit}
                      organisationStatus={ftpData}
                      submitting={submitting}
                    />
                  </form>
                )}
              </Form>
            );
          }}
        </ReduxFetch>
      )}
    </>
  );
};

export default FTPAccounts;
