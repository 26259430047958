import React from 'react';
import SubTitle from '../../../components/SubTitle';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import Field from '../../../components/Form';
import { Radio } from '../../../components/Form';
import DividerWithText from '../../../components/DividerWithText';
import { IsbnPrices } from '../domain';
import { User, IsbnDTO } from '../../../config/api/types';
import WhenFieldChanges from '../../../components/Form/WhenFieldChanges';

type Props = {
  user?: User;
  values: Partial<Omit<IsbnDTO, 'title'>>;
};

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: 500,
  },
}));

const NewIsbnPrefixForm = ({ user, values }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid item container direction="column" spacing={3}>
      <Grid item>
        <SubTitle>{t('form_isbn_choose_prefix_title')}</SubTitle>
        <Typography>{t('form_isbn_prefix_subText')}</Typography>
      </Grid>

      {!user && (
        <>
          <Grid item xs={5}>
            <Field
              type="text"
              name="prefix"
              label={t('form_isbn_prefix_submit_own')}
              helperText={t('form_isbn_prefix_input_helperText')}
            />
          </Grid>

          <Grid item>
            <DividerWithText label={t('or')} />
          </Grid>
        </>
      )}

      <Grid item container direction="column" spacing={1}>
        <Grid item>
          <Typography className={classes.bold}>{t('form_isbn_prefix_new')}</Typography>
          <Typography>{t('form_isbn_prefix_new_subText')}</Typography>
        </Grid>

        <Grid item>
          <Radio
            label={t('form_isbn_prefix_radio_1', { amount: IsbnPrices[1] })}
            name="requestIsbnNumbers"
            value="1"
            size="small"
          />
          <Radio
            label={t('form_isbn_prefix_radio_10', { amount: IsbnPrices[10] })}
            name="requestIsbnNumbers"
            value="10"
            size="small"
          />
          <Radio
            label={t('form_isbn_prefix_radio_100', { amount: IsbnPrices[100] })}
            name="requestIsbnNumbers"
            value="100"
            size="small"
          />
          <Radio
            label={t('form_isbn_prefix_radio_forgot')}
            name="requestIsbnNumbers"
            value="use_existing_prefix"
            size="small"
          />
        </Grid>

        <WhenFieldChanges
          field="prefix"
          set="requestIsbnNumbers"
          to={(value) => {
            return value ? '' : values.requestIsbnNumbers || '';
          }}
        />
        <WhenFieldChanges
          field="requestIsbnNumbers"
          set="prefix"
          to={(value) => {
            return value ? '' : values.prefix || '';
          }}
        />

        <Grid item>
          <Typography>{t('form_isbn_prefix_new_helpText')}</Typography>
          <Typography>
            {t('for_more_info')}{' '}
            <a
              href="http://www.meta4books.be/isbn-tarieven"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://www.meta4books.be/isbn-tarieven
            </a>
            .
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewIsbnPrefixForm;
