import * as React from 'react';
import { MenuItem } from '@material-ui/core';
import { OptionProps } from 'react-select';

export function Option(props: OptionProps<any, any>) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        fontSize: '0.875rem',
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}
