import React from 'react';
import { MasterPrefixDTO } from '../../../../config/api/types';
import { Form } from 'react-final-form';
import Field from '../../../../components/Form';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import Button from '../../../../components/Button';

type Props = {
  onCancel: () => void;
  onSubmit: (values: MasterPrefixDTO) => void;
  disabled?: boolean;
};

const MasterPrefixForm = ({ onCancel, onSubmit, disabled }: Props) => {
  const { t } = useTranslation();

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1} direction="column">
              <Grid item>
                <Field
                  type="text"
                  name="masterPrefix"
                  label={`${t('form_master_prefix')} (*)`}
                  autoFocus
                />
              </Grid>

              <Grid item>
                <Field type="text" name="description" label={t('form_master_prefix_description')} />
              </Grid>

              <Grid item container justify="flex-end" spacing={1}>
                <Grid item>
                  <Button onClick={onCancel}>{t('form_cancel')}</Button>
                </Grid>
                <Grid item>
                  <Button type="submit" disabled={disabled}>
                    {t('form_action_save')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

export default MasterPrefixForm;
