import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../../components/Table';
import { Maybe, Paged } from '../../../../config/api/types';
import { CellType, DataType } from '../../../../components/Table/types';
import { getCountryNameByShortName } from '../../../../config/data/countries';
import { Bookstore } from '../../../../config/api/models/organisation';
import { Typography } from '@material-ui/core';
import organisation from '../../index';

type Props = {
  bookstores: Maybe<Paged<Bookstore>>;
  fetchBookstores: (url: string) => void;
};

export const BookstoresTable: FC<Props> = ({ bookstores, fetchBookstores }) => {
  const { t } = useTranslation();
  const headers = [
    { id: 'bookstore', label: t('table_headers_name') },
    { id: 'city', label: t('table_headers_city') },
    { id: 'country', label: t('table_headers_country') },
    { id: 'registrationNumber', label: t('table_headers_company_registration_number') },
  ];

  if (!bookstores) {
    return null;
  }

  const data: DataType = {
    resource: 'bookstores',
    rows: bookstores._embedded.items.map((bookstore) => {
      return {
        id: bookstore.bookstoreId,
        cells: [
          {
            type: CellType.Text,
            data: (
              <organisation.components.OrganisationDialog
                organisationUrl={bookstore._links.organisation?.href || ''}
              >
                <Typography>{bookstore._embedded.organisation.name}</Typography>
              </organisation.components.OrganisationDialog>
            ),
          },
          {
            type: CellType.Text,
            data: bookstore._embedded.organisation.city,
          },
          {
            type: CellType.Text,
            data: getCountryNameByShortName(bookstore._embedded.organisation.countryIso),
          },
          {
            type: CellType.Text,
            data: bookstore._embedded.organisation.membershipRegistrationNumber,
          },
        ],
      };
    }),
  };

  return (
    <Table data={data} headers={headers} pagedResource={bookstores} dispatchFn={fetchBookstores} />
  );
};
