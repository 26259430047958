import React from 'react';
import { makeStyles, Theme, Chip, ChipProps } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

// @NOTE: possible values for REACT_APP_ENVIRONMENT are:
// dev, local, prod, staging
// These values are used here and in translations.ts

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
    },
    label: {
      fontWeight: 500,
    },
  }),
  { name: 'EnvLabel' }
);

const EnvLabel = ({ ...props }: ChipProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {process.env.REACT_APP_ENVIRONMENT !== 'prod' && (
        <Chip
          label={t(`env_label_${process.env.REACT_APP_ENVIRONMENT}`)}
          classes={{ root: classes.root, label: classes.label }}
          {...props}
        />
      )}
    </>
  );
};

export default EnvLabel;
