import React, { FC, useState } from 'react';
import {
  ExpansionPanel as MuiExpansionPanel,
  ExpansionPanelDetails as MuiExpansionPanelDetails,
  ExpansionPanelSummary as MuiExpansionPanelSummary,
  lighten,
  useTheme,
  withStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FacetTitle from './FacetTitle';

type Props = {
  title: string;
  collapsible?: boolean;
  active?: boolean;
};

type EPProps = { isActive?: Boolean };
const ExpansionPanel = withStyles((theme) => ({
  root: {
    borderRadius: '8px',
    boxShadow: ({ isActive }: EPProps) =>
      isActive ? `-1px 2px 5px ${theme.palette.primary.main}` : 'none',
    backgroundColor: ({ isActive }: EPProps) =>
      isActive ? lighten(theme.palette.primary.light, 0.9) : 'transparent',
    padding: ({ isActive }: EPProps) =>
      isActive
        ? `${theme.spacing(1)}px ${theme.spacing(0.75)}px`
        : `${theme.spacing(0.5)}px ${theme.spacing(0.75)}px`,
    '& .MuiFormLabel-root': {
      color: ({ isActive }: EPProps) => (isActive ? theme.palette.primary.main : 'inherit'),
    },
    '&$expanded': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  expanded: {},
}))(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    padding: 0,
    '&$expanded': {
      minHeight: 0,
    },
  },
  expanded: {
    '& .MuiExpansionPanelSummary-content': {
      marginTop: 4,
      marginBottom: 0,
    },
    '& .MuiIconButton-root': {
      padding: 4,
    },
  },
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: 0,
    flexDirection: 'column',
  },
}))(MuiExpansionPanelDetails);

const FacetWrapper: FC<Props> = ({ title, children, collapsible, active }) => {
  const [expanded, setExpanded] = useState(true);
  const onToggle = () => setExpanded((prevState) => !prevState);
  const theme = useTheme();

  return (
    <ExpansionPanel
      expanded={collapsible ? expanded : true}
      onChange={onToggle}
      isActive={active}
      style={{
        marginTop: active ? `${theme.spacing(1)}px` : 0,
        marginBottom: active ? `${theme.spacing(1)}px` : 0,
      }}
    >
      <ExpansionPanelSummary expandIcon={collapsible ? <ExpandMoreIcon /> : undefined}>
        <FacetTitle>{title}</FacetTitle>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default FacetWrapper;
