import React, { FC } from 'react';
import { AntSwitch } from '../../../../components/Form/AntSwitch';
import { Box } from '@material-ui/core';
import { Organisation, UserRole } from '../../../../config/api/types';
import { updateOrganisationRequest } from '../../api';
import { useFetch } from '@react-redux-fetch/hooks';
import { roleMap } from '../helpers';

const roleRouteMap = Object.keys(roleMap).reduce((prev: Record<string, UserRole>, role) => {
  return {
    ...prev,
    [roleMap[role]]: role as UserRole,
  };
}, {});

type Props = {
  routeKey: string;
  organisation: Organisation;
};
const RoleSwitch: FC<Props> = ({ routeKey, organisation }) => {
  const [updateOrgFetch, updateOrg] = useFetch(updateOrganisationRequest);
  const roleForTab = roleRouteMap[routeKey];
  if (!roleForTab) return null;

  const handleClickToggle = () => {
    const isEnabled = organisation.roles?.[roleForTab] === true;
    const newRoles: Partial<Record<UserRole, boolean>> = {
      ...organisation.roles,
      [roleForTab]: !isEnabled,
    };

    updateOrg(organisation._links.self.href, {
      ...organisation,
      roles: newRoles,
    });
  };

  return (
    <Box marginLeft={1}>
      <AntSwitch
        checked={organisation.roles?.[roleForTab]}
        onClick={handleClickToggle}
        disabled={updateOrgFetch?.pending}
      />
    </Box>
  );
};

export default RoleSwitch;
