import React from 'react';
import { Divider, DividerProps, makeStyles } from '@material-ui/core';

type Props = DividerProps & {
  label: string;
  size?: number;
};

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
  },
  textContainer: {
    position: 'absolute',
    top: -11,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  text: {
    padding: '0px 10px',
    backgroundColor: 'white',
    textTransform: 'uppercase',
    fontSize: 18,
  },
}));

const DividerWithText = ({ label, ...props }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Divider {...props} />
      <div className={classes.textContainer}>
        <div className={classes.text}>{label}</div>
      </div>
    </div>
  );
};

export default DividerWithText;
