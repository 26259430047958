import React, { FC } from 'react';
import ConstrainedPageContent from '../../../components/ConstrainedPageContent';
import { RouteComponentProps } from 'react-router';
import { OrdersProcessedHeader } from './OrdersProcessedForBookstorePage';
import OrdersHistoryProcessedLinesForBookstore from '../components/ordersHistoryLines/OrdersHistoryProcessedLinesForBookstore';

type Props = RouteComponentProps;

const OrdersLinesProcessedForBookstorePage: FC<Props> = (props) => {
  return (
    <ConstrainedPageContent center={false}>
      <OrdersProcessedHeader {...props} />
      <OrdersHistoryProcessedLinesForBookstore />
    </ConstrainedPageContent>
  );
};

export default OrdersLinesProcessedForBookstorePage;
