import React, { FC, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { useTranslation } from 'react-i18next';
import Field from '../../../../../../components/Form';
import Button from '../../../../../../components/Button';
import BaseReactSelectField from '../../../../../../components/Form/BaseReactSelectField';
import AwardSelectField from './AwardSelectField';
import { AwardsData, LiteraturePrize } from '../../../../../../config/api/types';
import { getAwardsDataRequest } from '../../api';
import useFetch from '../../../../../../helpers/useFetch';
import { useIsVisible } from 'react-is-visible';
import { isValidYear } from '../../../../../../helpers/date';
import { isEmpty } from 'lodash';

type Props = {
  name: String;
  onRemove: () => void;
  RemoveIcon?: typeof CloseIcon;
  onClose?: () => void;
};

type AwardResult = { code: string; result: string };

const requiredAward = (message: string) => (option: LiteraturePrize) => {
  return isEmpty(option?.id) ? message : undefined;
};
const requiredResult = (message: string) => (option: AwardResult) => {
  return isEmpty(option?.code) || isEmpty(option?.result) ? message : undefined;
};

const validYear = (message: string) => (value?: string) => {
  return !value || (value && isValidYear(value)) ? undefined : message;
};

const AwardField: FC<Props> = ({ name, onRemove, RemoveIcon = CloseIcon, onClose }) => {
  const { t } = useTranslation();
  const nodeRef = React.useRef(null);
  const isVisible = useIsVisible(nodeRef);

  const [awardsData, fetchAwardsData] = useFetch<AwardsData>(getAwardsDataRequest('prizes'));

  useEffect(() => {
    if (!awardsData && isVisible) {
      fetchAwardsData();
    }
  }, [awardsData, fetchAwardsData, isVisible]);

  if (!awardsData?.value) {
    return <div ref={nodeRef}>&nbsp;</div>;
  }

  const resultsData = Object.entries(awardsData.value.results).map(([code, result]) => ({
    code,
    result,
  }));

  return (
    <Grid container spacing={1} alignItems="flex-start">
      <Grid item style={{ flex: 1 }}>
        <AwardSelectField
          component={BaseReactSelectField}
          label={`${t('form_awardName')} (*)`}
          options={awardsData.value.prizes}
          name={`${name}.literaturePrize`}
          getOptionLabel={(literaturePrize: LiteraturePrize) => literaturePrize.name}
          getOptionValue={(literaturePrize: LiteraturePrize) => literaturePrize.id}
          validate={requiredAward(t('form_award_prize_required'))}
        />
      </Grid>
      <Grid item style={{ width: 170 }}>
        <AwardSelectField
          component={BaseReactSelectField}
          label={`${t('form_awardResult')} (*)`}
          options={resultsData}
          name={`${name}.result`}
          getOptionLabel={({ result }: AwardResult) => result}
          getOptionValue={({ code }: AwardResult) => code}
          validate={requiredResult(t('form_award_result_required'))}
        />
      </Grid>
      <Grid item style={{ width: 80 }}>
        <Field
          name={`${name}.year`}
          type="text"
          label={t('form_awardYear')}
          validate={validYear(t('form_award_year_nonvalid'))}
        />
      </Grid>
      <Grid item>
        <Button
          size="small"
          variant={'text'}
          style={{ marginTop: 19, marginRight: 4 }}
          onClick={onClose}
          disabled={!onClose}
        >
          <CheckIcon />
        </Button>
        <Button
          size="small"
          variant={'text'}
          style={{ marginTop: 19 }}
          color="primary"
          onClick={onRemove}
        >
          <RemoveIcon />
        </Button>
      </Grid>
    </Grid>
  );
};

export default AwardField;
