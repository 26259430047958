import React, { FC, useReducer } from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Header from '../../../../../components/Header';
import SearchTitle from '../containers/addTitle/SearchTitle';
import EditTitle from '../containers/addTitle/EditTitle';
import usePath from '../../../../../config/routes/usePath';
import ROUTE_KEY from '../../../../../config/routes/routeKeys';

type Props = RouteComponentProps;
type StepState = 'searchTitle' | 'editTitle';

const stepsReducer = (state: StepState, action: { type: 'next' | 'prev' }): StepState => {
  if (state === 'searchTitle') {
    switch (action.type) {
      case 'next':
        return 'editTitle';
      default:
        return 'searchTitle';
    }
  } else {
    switch (action.type) {
      case 'prev':
        return 'searchTitle';
      default:
        return 'editTitle';
    }
  }
};

const AddTitlePage: FC<Props> = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const [stepState, dispatch] = useReducer(stepsReducer, 'searchTitle');
  const newTitlePath = usePath(ROUTE_KEY.MANAGE_TITLE_ADD_NEW);

  return (
    <>
      <Header title={t('page_title_add_title')} />
      <Box mt={2}>
        {stepState === 'searchTitle' && (
          <SearchTitle
            onNext={() => dispatch({ type: 'next' })}
            onSubmit={(gtin13) => push(newTitlePath, { path: pathname, gtin13 })}
          />
        )}
        {stepState === 'editTitle' && <EditTitle onPrev={() => dispatch({ type: 'prev' })} />}
      </Box>
    </>
  );
};
export default AddTitlePage;
