import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import moment from 'moment';
import { FormApi } from 'final-form';
import { Orders } from '../../../../config/api/models/shop';
import formatEuro from '../../../../helpers/formatEuro';
import { CellType, DataType } from '../../../../components/Table/types';
import Table from '../../../../components/Table';
import getIdFromUrl from '../../../../helpers/getIdFromUrl';
import TextLink from '../../../../components/TextLink';
import usePath from '../../../../config/routes/usePath';
import ROUTE_KEY from '../../../../config/routes/routeKeys';
import OrdersHistoryForm from '../ordersHistory/OrdersHistoryForm';
import { trimStringLength } from '../../../../helpers/trimStringLength';
import organisation from '../../../organisation';

type Props = {
  pagedOrders: Orders;
  fetchOrders: (url: string) => void;
  isLoading: boolean;
};

const OrdersTable = ({ pagedOrders, fetchOrders, isLoading }: Props) => {
  const { t } = useTranslation();
  const url = pagedOrders._links.self.href;
  const orderPath = usePath(ROUTE_KEY.ORDER_DETAIL);

  const headers = [
    { id: 'ordered_at', label: t('table_headers_ordered_at'), width: '100px' },
    { id: 'order_ref', label: t('table_headers_order_reference'), width: '15%' },
    { id: 'distributors', label: t('table_headers_distributors') },
    { id: 'num_titles', label: t('table_headers_num_titles'), width: '4rem', align: 'center' },
    { id: 'num_pieces', label: t('table_headers_num_pieces'), width: '4rem', align: 'center' },
    { id: 'resale_value', label: t('table_headers_resale_value'), width: '6rem', align: 'right' },
  ];

  const data: DataType = {
    resource: 'orders',
    rows: pagedOrders._embedded.items.map((order) => {
      const id = getIdFromUrl(order._links.self.href);
      const path = orderPath.replace(':id', id);

      return {
        id,
        cells: [
          {
            type: CellType.Text,
            data: moment(order.orderedAt).format('DD/MM/YYYY'),
          },
          {
            type: CellType.Text,
            data: (
              <TextLink
                underline
                to={{
                  pathname: path,
                  state: { from: window.location.pathname },
                }}
                title={order.orderReference}
              >
                {trimStringLength(order.orderReference, 45)}
              </TextLink>
            ),
          },
          {
            type: CellType.Text,
            data: (
              <>
                {order.linesByDistributor.map((line, index) => (
                  <div key={index}>
                    {line._embedded.organisation.name} {line.distributor?.isCbMember ? t('cb') : ''}
                    {index + 1 < order.linesByDistributor.length ? ', ' : ''}
                  </div>
                ))}
              </>
            ),
          },
          {
            type: CellType.Text,
            data: <Box textAlign="center">{order.distinctTitleCount}</Box>,
          },
          {
            type: CellType.Text,
            data: (
              <Box textAlign="center">
                <TextLink to={path}>{order.titleCount}</TextLink>
              </Box>
            ),
          },
          {
            type: CellType.Text,
            data: <Box textAlign="right">{formatEuro(order.resaleValue)}</Box>,
          },
        ],
      };
    }),
  };

  return (
    <>
      <OrdersHistoryForm
        fetchOrders={fetchOrders}
        url={url}
        numResults={pagedOrders?.total}
        withFreeText={false}
        withExportButton={false}
      >
        {({ form, values }: { form: FormApi; values: Record<string, string> }) => (
          <organisation.components.DistributorsSelectField
            label={t('table_headers_distributor')}
            url={'/order/distributors'}
            change={form.change}
            distributorId={values.distributor_id}
            onClearValue={form.submit}
          />
        )}
      </OrdersHistoryForm>
      <Table
        data={data}
        headers={headers}
        pagedResource={pagedOrders}
        loading={isLoading}
        dispatchFn={fetchOrders}
      />
    </>
  );
};

export default OrdersTable;
