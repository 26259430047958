import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RemoteAutocomplete, {
  RemoteAutocompleteProps,
} from '../../../../../../components/Form/RemoteAutocomplete';
import { Fund, Funds } from '../../../../../../config/api/types';
import useApiRoute from '../../../../../../config/api/useApiRoute';
import { updateQueryParameters } from '../../../../../../helpers/hateoas';
import useFetch from '../../../../../../helpers/useFetch';
import { getUserRole } from '../../../../../security/selectors';
import { getFundsRequest } from '../../api';
import CommercialField from '../../components/sections/CommercialField';

type Props = Partial<RemoteAutocompleteProps> & {
  label?: string;
  required?: boolean;
  preFetch?: boolean;
  onlyActive?: boolean;
};

const FundField: FC<Props> = ({ onlyActive, label, required, preFetch, ...other }) => {
  const { t } = useTranslation();
  const userRoles = useSelector(getUserRole);
  const baseUrl = useApiRoute([
    'fundsDistributor',
    'fundsAdmin',
    'fundsBookstore',
    'fundsDataConsumer',
  ]);

  const url =
    baseUrl && userRoles.includes('ROLE_DISTRIBUTOR' || 'ROLE_BOOKSTORE') && onlyActive
      ? updateQueryParameters(baseUrl, { status: 'status_active' })
      : baseUrl;

  const [funds, fetchFunds] = useFetch<Funds>(getFundsRequest());
  const [hasPrefetched, setHasPrefetched] = useState<boolean>(!preFetch);

  // useEffect(
  //   () => {
  //     if (publisherName && !funds) {
  //       fetchFunds(
  //         updateQueryParameters(url, {
  //           q: getSearchQuery(publisherName),
  //         })
  //       );
  //     }
  //   },
  //   [fetchFunds, publisherName, url, funds]
  // );

  useEffect(() => {
    if (preFetch && url && !hasPrefetched) {
      fetchFunds(url);
      setHasPrefetched(true);
    }
  }, [url, preFetch, fetchFunds, hasPrefetched]);

  if (!url) {
    return null;
  }

  return (
    <CommercialField
      component={RemoteAutocomplete}
      label={`${label || t('title_fund_code')} ${required ? '(*)' : ''}`}
      createUrlFromInput={(term: string) =>
        updateQueryParameters(url, {
          q: term.toUpperCase(),
        })
      }
      promiseState={funds}
      makeRequest={fetchFunds}
      minInputLength={preFetch ? 0 : 2}
      options={(funds && funds.value && funds.value._embedded.items) || []}
      getOptionValue={(fund: Fund) => fund.code}
      getOptionLabel={(fund: Fund) =>
        `${fund.code} ${fund.description ? `(${fund.description})` : ''}`
      }
      wideDropdown
      {...other}
      name="fund"
      // inputValue={publisherName}
    />
  );
};

export default FundField;
