import React, { useEffect } from 'react';
import { PrefixSteppedFormProps } from '../types';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  makeStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import usePath from '../../../config/routes/usePath';
import ROUTE_KEY from '../../../config/routes/routeKeys';
import PrefixRequestOverview from '../components/PrefixRequestOverview';
import { useSelector } from 'react-redux';
import { getPublisher } from '../../organisation/admin/selectors';
import { getLink } from '../../../helpers/hateoas';
import { useFetch } from '@react-redux-fetch/hooks';
import { getPublisherRequest } from '../../title/admin/title/api';

const useStyles = makeStyles(() => ({
  dialog: {
    textAlign: 'center',
  },
  dialogButtons: {
    justifyContent: 'center',
  },
}));

const PrefixOverviewStep = ({
  changeStep,
  submitting,
  values,
  submitSucceeded,
  user,
  ...props
}: PrefixSteppedFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const route = usePath(user ? ROUTE_KEY.ISBN : ROUTE_KEY.LOGIN);
  const showBilling = Boolean(values.prefixSize && values.prefixSize !== 'use_existing_prefix');
  const [, fetchPublisher] = useFetch(getPublisherRequest);
  const organisation = user?._embedded.organisation;
  const publisher = useSelector(getPublisher);

  const disableSubmit = !publisher?.isPurchaseOrderRequired
    ? (showBilling && !values.billingConsent) || submitting
    : (showBilling && (!values.billingConsent || !values.purchaseOrderConsent)) || submitting;

  const handleClose = () => {
    history.push(route);
  };

  useEffect(() => {
    if (organisation) {
      const publisherLink = getLink(organisation, 'publisher') || '';
      fetchPublisher(publisherLink);
    }
  }, [organisation, fetchPublisher]);

  return (
    <Grid container spacing={3} direction="column">
      <Grid item xs={12} md={7}>
        {
          <PrefixRequestOverview
            values={values}
            showBilling={showBilling}
            purchaseOrderRequired={publisher?.isPurchaseOrderRequired}
          />
        }
      </Grid>

      <Grid container md={7} xs={12} item justify="space-between">
        <Grid item>
          <Button onClick={() => changeStep(-1)} variant="outlined" disabled={submitting}>
            {t('form_previous')}
          </Button>
        </Grid>
        <Grid item>
          <Button type="submit" disabled={disableSubmit}>
            {t('isbn_submit_application')}
          </Button>
        </Grid>
      </Grid>

      <Dialog open={submitSucceeded} className={classes.dialog}>
        <DialogTitle>{t('prefix_application_dialog_title')}</DialogTitle>
        <DialogContent>{t('prefix_application_dialog_content')}</DialogContent>
        <DialogActions className={classes.dialogButtons}>
          <Button onClick={handleClose}>
            {t(
              user ? 'isbn_application_dialog_button' : 'isbn_application_dialog_anonymous_button'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default PrefixOverviewStep;
