import React from 'react';
import ConstrainedPageContent from '../../../components/ConstrainedPageContent';
import { useTranslation } from 'react-i18next';
import OrdersHistoryConfirmedForLibrary from '../components/ordersHistoryConfirmed/OrdersHistoryConfirmedForLibrary';
import { RouteComponentProps } from 'react-router';
import OrdersTabHeader from '../components/OrdersTabHeader';
import usePath from '../../../config/routes/usePath';
import ROUTE_KEY from '../../../config/routes/routeKeys';

type Props = RouteComponentProps;

export const OrdersHistoryHeader = (props: Props) => {
  const { t } = useTranslation();
  const orderLinesPath = usePath(ROUTE_KEY.ORDERS_HISTORY_LIBRARY);
  const ordersPath = usePath(ROUTE_KEY.ORDERS_HISTORY_LIBRARY_ORDERS);

  return (
    <OrdersTabHeader
      {...props}
      tabs={[
        { route: orderLinesPath, label: t('page_tab_orderlines_bookstore') },
        { route: ordersPath, label: t('page_tab_orders_bookstore') },
      ]}
      title={t('page_title_library_order_overview')}
    />
  );
};

const OrdersConfirmedForLibraryPage = (props: RouteComponentProps) => {
  return (
    <ConstrainedPageContent center={false}>
      <OrdersHistoryHeader {...props} />
      <OrdersHistoryConfirmedForLibrary />
    </ConstrainedPageContent>
  );
};

export default OrdersConfirmedForLibraryPage;
