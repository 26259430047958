import React, { FC } from 'react';
import { OrderLine } from '../../../config/api/models/shop';
import OrderStatusSelect from './OrderStatusSelect';
import AutoSave from '../../../components/Form/AutoSave';
import { Form } from 'react-final-form';
import { useFetch } from '@react-redux-fetch/hooks';
import { patchOrderLineRequest } from '../api';
import { getLink } from '../../../helpers/hateoas';
import { useSelector } from 'react-redux';
import { getOrderLineStatuses } from '../selectors';
import { useTranslation } from 'react-i18next';

type Props = {
  orderLine: OrderLine;
  onRefetch: () => void;
};

export const EditableOrderLibraryState: FC<Props> = ({ orderLine, onRefetch }) => {
  const { t } = useTranslation();
  const orderLineUrl = getLink(orderLine, 'changeOrderLineStatus');

  const [, patchOrderLine] = useFetch(patchOrderLineRequest);
  const statuses = useSelector(getOrderLineStatuses);

  if (!statuses) return null;

  const stateValue =
    statuses.find((status) => t(`orderline_status_${status}`) === orderLine.stateLibrary) ||
    orderLine.stateLibrary;

  const onSubmit = (values: { state: typeof orderLine.stateLibrary }) => {
    if (orderLineUrl && values.state && values.state !== stateValue) {
      patchOrderLine(orderLineUrl, values);
      onRefetch();
    }
  };

  return (
    <Form
      initialValues={{
        state: stateValue,
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <>
          <OrderStatusSelect colored />
          <AutoSave save={handleSubmit} />
        </>
      )}
    </Form>
  );
};
