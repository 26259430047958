import React, { FC } from 'react';
import SectionWrapper from '../SectionWrapper';
import FieldsetTitle from '../FieldsetTitle';
import { useTranslation } from 'react-i18next';
import Fieldset from '../Fieldset';
import { Typography } from '@material-ui/core';

const MediaFilesNotPossible: FC = ({ children }) => {
  const { t } = useTranslation();
  return (
    <SectionWrapper id="mediaFiles">
      <FieldsetTitle>{t('title_section_mediaFiles')}</FieldsetTitle>
      <Fieldset>
        <Typography>{children || t('isbn_mediaFiles_info')}</Typography>
      </Fieldset>
    </SectionWrapper>
  );
};

export default MediaFilesNotPossible;
