import * as React from 'react';
import { PromiseState, ReduxFetch } from 'react-redux-fetch';
import { ApiConfig } from '../../../../api';
import { Roles } from '../../../../../../config/api/types';
import { useTranslation } from 'react-i18next';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import { SelectField as Select } from '../../../../../../components/Form/index';
import { SelectFieldProps } from '../../../../../../components/Form/SelectField';

type ReduxFetchRenderProps = {
  rolesFetch: PromiseState<Roles>;
};
type Props = WithStyles & {
  onChange: SelectFieldProps['onChange'];
  initialValue?: SelectFieldProps['value'];
};

const styles = createStyles((theme: Theme) => ({
  select: {
    // marginLeft: theme.spacing(2)
  },
}));

const RolesForm = ({ onChange, classes, initialValue }: Props) => {
  const { t } = useTranslation();

  return (
    <ReduxFetch config={[ApiConfig.getRoles()]} fetchOnMount>
      {({ rolesFetch }: ReduxFetchRenderProps) => {
        if (!rolesFetch.value) {
          return null;
        }
        const roles = Object.keys(rolesFetch.value.hierarchy).reduce(
          (agg, role) => ({
            ...agg,
            [role]: t(role),
          }),
          { null: t('filter_option_no_role') }
        );

        return (
          <div className={classes.select}>
            <Select
              items={roles}
              onChange={onChange}
              label={t('filter_roles')}
              value={initialValue}
            />
          </div>
        );
      }}
    </ReduxFetch>
  );
};

export default withStyles(styles)(RolesForm);
