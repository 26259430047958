import React, { FC, useEffect } from 'react';
import { Overlay, OverlayRoot } from '../Overlay';
import { CircularProgress } from '@material-ui/core';
import useInterval from '../../helpers/useInterval';
import { Paged } from '../../config/api/types';
import { getLink } from '../../helpers/hateoas';
import moment from 'moment';

type Props = {
  pagedResponse?: Paged<{
    status: string;
    uploadedAt: string;
    numSuccess?: number;
    numTotal?: number;
  }>;
  query: (url: string) => void;
  pollForChanges?: boolean;
  onStopPolling: () => void;
  pollingInterval?: number;
  withoutLoader?: boolean;
};

export const PollForChanges: FC<Props> = ({
  query,
  pagedResponse,
  children,
  pollForChanges,
  onStopPolling,
  pollingInterval = 2000,
  withoutLoader,
}) => {
  const url = getLink(pagedResponse, 'self') || '';
  useInterval(() => query(url), pollForChanges ? pollingInterval : null);

  const fortyFiveMinutesAgo = moment().subtract(45, 'minutes');

  const numItems = pagedResponse && pagedResponse.total;
  const hasPendingMediaFiles = pagedResponse
    ? Boolean(
        pagedResponse._embedded.items.find(
          ({ status, uploadedAt, numSuccess, numTotal }) =>
            (['uploaded', 'processing'].includes(status) ||
              (status === 'process' && numSuccess === numTotal)) &&
            moment(uploadedAt).isAfter(fortyFiveMinutesAgo)
        )
      )
    : false;

  useEffect(() => {
    if (!hasPendingMediaFiles) {
      onStopPolling();
    }
  }, [numItems, hasPendingMediaFiles, onStopPolling]);

  return (
    <OverlayRoot>
      {children}
      {!withoutLoader && pollForChanges && (
        <Overlay>
          <CircularProgress />
        </Overlay>
      )}
    </OverlayRoot>
  );
};
