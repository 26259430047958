import { useFetch } from '@react-redux-fetch/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useApiRoute from '../../../config/api/useApiRoute';
import elasticQueryDSL, { QueryObjectValue } from '../../../helpers/elasticQueryDSL';
import getIdFromUrl from '../../../helpers/getIdFromUrl';
import { retrieveQueryParameter, updateQueryParameters } from '../../../helpers/hateoas';
import urlencode from '../../../helpers/urlencode';
import useQueryParams from '../../../helpers/useQueryParams';
import { isOwnDistributionRequired, isOwnPublicationRequired } from '../../organisation/domain';
import security from '../../security';
import { TITLES_PER_PAGE } from '../domain';
import { getSearchRequest, getTitleAggregationsRequest } from './api';
import { getTitleSearch } from './selectors';

type Params = {
  order?: string;
  orderDir?: string;
  f: string;
  q: string;
  userInput: string;
  page?: string;
  limit: number;
};

const initialParams = (f?: string, q?: string, order?: string, orderDir?: string): Params => ({
  order: order || '',
  orderDir: orderDir || '',
  f: f || '',
  // f: 'themas.code:%28PB%29',
  // f: 'themas.code:%28PB OR PDZM OR PBW%29',
  q: q || '',
  userInput: q || '',
  page: '1',
  limit: TITLES_PER_PAGE,
});

export const NOT_PRODUCT_TYPE = 'NOT productForm.group.code';
export type FilterObject = Record<string, QueryObjectValue>;

const useFetchTitles = (q: string, qFacets: Record<string, string> = {}) => {
  const qTrimmed = encodeURIComponent(q.trim());
  const apiTitleSearchPath = useApiRoute('titles');
  const apiTitleAggregationPath = useApiRoute('titlesAggregations');
  const [cleanSearch, setCleanSearch] = useState<boolean>(Boolean(qTrimmed));
  const userRoles = useSelector(security.selectors.getUserRole) || [];
  const currentUser = useSelector(security.selectors.getUser);
  const impersonate = useSelector(security.selectors.getImpersonate);
  const user = impersonate || currentUser;
  const isMember = user?._embedded?.organisation?.hasMembership ?? false;
  const organisationId = user
    ? getIdFromUrl(user._links.organisation?.href || user._embedded.organisation?._links.self.href)
    : '';
  const isPublisher = userRoles.includes('ROLE_PUBLISHER');
  const isDistributor = userRoles.includes('ROLE_DISTRIBUTOR');
  const isLibrary = userRoles.includes('ROLE_LIBRARY');
  const isBookstore = userRoles.includes('ROLE_BOOKSTORE');

  const pagedTitles = useSelector(getTitleSearch);
  const { state } = useLocation<{ from?: string }>();
  const { queryObj, queryStr, changeParams } = useQueryParams<Params>(
    state?.from === 'detail' && pagedTitles != null
      ? updateQueryParameters(pagedTitles?._links.self.href, { page: 1 })
      : pagedTitles?._links.self.href
  );

  const [, getTitleAggregations, cancelGetAggregations] = useFetch(getTitleAggregationsRequest);
  const [searchFetch, search, cancelGetSearch] = useFetch(getSearchRequest);
  const fFromQueryStr = queryStr && retrieveQueryParameter(queryStr, 'f');

  const { isDefault: ownDistributionDefault } = isOwnDistributionRequired({
    isPublisher,
    isDistributor,
    isMember,
  });
  const { isDefault: ownPublicationDefault } = isOwnPublicationRequired({
    isPublisher,
    isDistributor,
    isMember,
  });

  const initialFObject = useMemo(
    () => ({
      ...(isLibrary || isBookstore
        ? { [NOT_PRODUCT_TYPE]: 'e-book', hasInformationRestriction: 'false' }
        : {}),
      ...(ownPublicationDefault //Checkbox must always be checked when no member
        ? {
            [`publisher.organisation.organisationId`]: organisationId,
          }
        : {}),
      ...(ownDistributionDefault //Checkbox must always be checked when no member
        ? {
            [`fund.organisation.organisationId`]: organisationId,
          }
        : {}),
    }),
    [isBookstore, isLibrary, organisationId, ownDistributionDefault, ownPublicationDefault]
  );

  const f = elasticQueryDSL.stringify({
    ...qFacets,
    ...initialFObject,
  });

  const initialFParam = initialParams(
    urlencode(f),
    urlencode(qTrimmed),
    queryObj.order,
    queryObj.orderDir
  );

  const cleanSearchUrl =
    (apiTitleSearchPath && updateQueryParameters(apiTitleSearchPath, initialFParam)) || '';

  const url =
    cleanSearch || !queryStr
      ? cleanSearchUrl
      : qFacets
      ? updateQueryParameters(queryStr, {
          q: qTrimmed,
          userInput: qTrimmed,
          f: urlencode(
            elasticQueryDSL.stringify({
              ...qFacets,
              ...(fFromQueryStr && elasticQueryDSL.parse(fFromQueryStr)),
              ...(isLibrary || isBookstore ? { hasInformationRestriction: 'false' } : {}),
            })
          ),
          ...(q !== decodeURIComponent(queryObj.q) && { page: 1 }), //q changed
        })
      : queryStr;

  useEffect(() => {
    if (url) {
      cancelGetSearch();
      search(url);
    }
  }, [url, search, cancelGetSearch, cleanSearch]);

  useEffect(() => {
    if (impersonate) {
      const nonImpersonateUrl = queryStr?.replace(`&_switch_user=${impersonate.username}`, '');
      if (cleanSearch && cleanSearchUrl === nonImpersonateUrl) {
        setCleanSearch(false);
      }
    } else if (cleanSearch && cleanSearchUrl === queryStr) {
      setCleanSearch(false);
    }
  }, [cleanSearch, cleanSearchUrl, queryStr, impersonate]);

  const aggregationUrl = [apiTitleAggregationPath, url ? url.split('?')[1] : ''].join('?');

  useEffect(() => {
    if (aggregationUrl) {
      cancelGetAggregations();
      getTitleAggregations(aggregationUrl);
    }
  }, [aggregationUrl, getTitleAggregations, cancelGetAggregations]);

  const onFilter = useCallback(
    (queryObj?: FilterObject, sortingParams?: Record<string, string | number>) => {
      if (!queryObj) {
        setCleanSearch(true);

        changeParams({
          f: elasticQueryDSL.stringify(initialFObject),
          page: '1',
          order: '',
          orderDir: '',
        });
      } else {
        changeParams({
          f: queryObj ? urlencode(elasticQueryDSL.stringify(queryObj)) : initialFParam.f, //initialFParam from global scope
          page: '1',
          ...(sortingParams != null && { ...sortingParams }),
        });
      }
    },
    [changeParams, initialFObject, initialFParam.f]
  );

  return {
    loading: searchFetch?.pending,
    titles: pagedTitles,
    params: queryObj,
    changeParams,
    filters: queryObj && queryObj.f ? elasticQueryDSL.parse(queryObj.f) : {},
    onFilter,
  };
};

export default useFetchTitles;
