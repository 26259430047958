import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useApiRoute from '../../../../../config/api/useApiRoute';
import FacetTitle from '../../../catalog/components/Facet/FacetTitle';
import AvailabilityField from '../../../admin/title/containers/fields/AvailabilityField';

const AvailabilityFilter: FC = () => {
  const { t } = useTranslation();
  const availabilitiesApiLink = useApiRoute('titleAvailabilities') || '';

  if (!availabilitiesApiLink) return null;

  return (
    <div>
      <FacetTitle>{t('facet_availability')}</FacetTitle>
      <AvailabilityField url={availabilitiesApiLink} label=" " isMulti />
    </div>
  );
};

export default AvailabilityFilter;
