import React, { FC } from 'react';
import FacetWrapper from '../../components/Facet/FacetWrapper';
import { useTranslation } from 'react-i18next';
import { FormControlLabel } from '@material-ui/core';
import { useSelector } from 'react-redux';
import security from '../../../../security';
import { UserRole } from '../../../../../config/api/types';
import { FacetCheckBox } from '../../components/Facet/FacetCheckBox';

type Props = {
  onFilterIsOrderable: (isOrderable: boolean) => void;
  onFilterNoEbooks: (noEbooks: boolean) => void;
  isOrderable: boolean;
  noEbooks: boolean;
};

const orderableRoles: UserRole[] = [
  'ROLE_BOOKSTORE',
  'ROLE_LIBRARY',
  'ROLE_PUBLISHER',
  'ROLE_DISTRIBUTOR',
];
const ebooksRoles: UserRole[] = ['ROLE_BOOKSTORE', 'ROLE_LIBRARY'];

const AvailabilityFacet: FC<Props> = ({
  onFilterIsOrderable,
  isOrderable,
  noEbooks,
  onFilterNoEbooks,
}) => {
  const userRoles = useSelector(security.selectors.getUserRole);
  const { t } = useTranslation();

  const showIsOrderable = userRoles.find((userRole) => orderableRoles.includes(userRole));
  const showNoEbooks = userRoles.find((userRole) => ebooksRoles.includes(userRole));

  if (!showIsOrderable && !showNoEbooks) {
    return null;
  }

  return (
    <FacetWrapper title={t('facet_availability')} active={isOrderable}>
      {showIsOrderable && (
        <FormControlLabel
          control={<FacetCheckBox onChange={(e, checked) => onFilterIsOrderable(checked)} />}
          label={t('facet_isOrderable')}
          checked={isOrderable}
        />
      )}
      {showNoEbooks && (
        <FormControlLabel
          control={<FacetCheckBox onChange={(e, checked) => onFilterNoEbooks(checked)} />}
          label={t('facet_no_ebooks')}
          checked={noEbooks}
        />
      )}
    </FacetWrapper>
  );
};

export default AvailabilityFacet;
