import React, { useState } from 'react';
import classNames from 'classnames';
import { Theme, createStyles } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Typography from '@material-ui/core/Typography';
import SettingsMenu from './SettingsMenu';
import SettingsIcon from '@material-ui/icons/Settings';
import { Organisation, User } from '../../../../config/api/types';
import { getEmbedded } from '../../../../helpers/hateoas';
import { withStyles, WithStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',

      height: '100%',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.background.appbarHover,
      },
    },
    contentRoot: {
      height: 64,
      borderLeft: `1px solid #cccccc`,
      borderRight: `1px solid #cccccc`,
      paddingLeft: 16,
      paddingRight: 16,
      display: 'flex',
      alignItems: 'center',
    },
    textWrapper: {
      display: 'flex',
    },
    text: {
      maxWidth: '150px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    company: {
      fontWeight: theme.typography.fontWeightMedium,
      textTransform: 'uppercase',
    },
    icon: {
      color: theme.palette.text.primary,
      width: 24,
      height: 24,
    },
    settingsIcon: {
      // height: theme.icons.height,
      // width: theme.icons.width,
      // color: theme.palette.text.primary,
      marginRight: theme.spacing(1),
    },
  });

type Props = WithStyles<any> & {
  onLogout: () => void;
  user: User;
  organisation?: Organisation;
};

const SettingsButton = ({ classes, onLogout, user }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const fullName = [user.firstName, user.lastName].join(' ');
  const organisation = getEmbedded<Organisation>(user, 'organisation');

  return (
    <>
      <div className={classes.root} onClick={handleClick}>
        <div className={classes.contentRoot}>
          <SettingsIcon className={classes.settingsIcon} fontSize="large" />
          <div>
            <div className={classes.textWrapper}>
              <Typography className={classNames(classes.text, classes.company)}>
                {organisation?.name}
              </Typography>
            </div>
            <div className={classes.textWrapper}>
              <Typography className={classes.text}>{fullName} </Typography>{' '}
              <ArrowDropDownIcon className={classes.icon} />
            </div>
          </div>
        </div>
      </div>
      {anchorEl && (
        <SettingsMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          onLogout={() => {
            handleClose();
            onLogout();
          }}
          user={user}
          organisation={organisation}
        />
      )}
    </>
  );
};

export default withStyles(styles)(SettingsButton);
