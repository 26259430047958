import React, { FC } from 'react';
import { OrderConfirmedNotification as OrderConfirmedAppNotification } from '../../../config/api/models/notifications';
import { useTranslation } from 'react-i18next';
import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import usePath from '../../../config/routes/usePath';
import ROUTE_KEY from '../../../config/routes/routeKeys';
import { apiDate } from '../../../helpers/date';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';

type Props = {
  notification: OrderConfirmedAppNotification;
  onClick: (notification: OrderConfirmedAppNotification, redirectPath?: string) => void;
  onDelete: () => void;
};

const OrderConfirmedNotification: FC<Props> = ({ notification, onClick, onDelete }) => {
  const { t } = useTranslation();
  const ordersPath = usePath(ROUTE_KEY.ORDERS);

  const handleClick = () => {
    onClick(notification, ordersPath);
  };

  return (
    <MenuItem>
      <Box display={'flex'} style={{ gap: 20, width: '100%' }} alignItems={'center'}>
        <Grid container direction="column" style={{ flex: 1 }} onClick={handleClick}>
          <Grid item container direction="row" justify="space-between" spacing={2}>
            <Grid item style={{ position: 'relative' }}>
              {!notification.readAt && (
                <Typography
                  style={{
                    position: 'absolute',
                    left: -3,
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                >
                  {'\u25CF'}
                </Typography>
              )}
              <Typography style={{ fontWeight: notification.readAt == null ? 500 : 400 }}>
                {t('notifications_OrderConfirmedNotification_title')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">
                {apiDate(notification.parameters.date).toDateTime()}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction="row" justify="space-between" spacing={2}>
            <Grid item>
              <Typography>{notification.parameters.bookstoreName}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">
                {t('notifications_OrderConfirmedNotification_qty', notification.parameters)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <IconButton onClick={onDelete} size="small">
          <DeleteIcon />
        </IconButton>
      </Box>
    </MenuItem>
  );
};

export default OrderConfirmedNotification;
