import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Field from '../../../../../../components/Form';
import SectionWrapper from '../SectionWrapper';
import FieldsetTitle from '../FieldsetTitle';
import TitleISBNField from './TitlelISBNField';
import FormFieldWrapper from '../../../../../../components/Form/FormFieldWrapper';
import Fieldset from '../Fieldset';

const TitleISBN: FC = () => {
  const { t } = useTranslation();

  return (
    <SectionWrapper id="gtin13">
      <FieldsetTitle>{t('title_section_gtin13')}</FieldsetTitle>
      <Fieldset>
        <FormFieldWrapper>
          <TitleISBNField
            component={Field}
            label={`${t('title_gtin13')} (*)`}
            type="number"
            name="gtin13"
          />
        </FormFieldWrapper>
      </Fieldset>
    </SectionWrapper>
  );
};

export default TitleISBN;
