import React from 'react';
import { Typography } from '@material-ui/core';
import { SelectOption } from './types';
import { NoticeProps } from 'react-select/src/components/Menu';

type Props = NoticeProps<SelectOption, any>;

const Message = ({ innerProps, selectProps, children }: Props) => {
  return (
    <div className={selectProps.classes.noOptionsMessage}>
      <Typography {...innerProps}>{children}</Typography>
    </div>
  );
};

export default Message;
