import React, { useEffect } from 'react';
import { Editor, EditorProps } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw, convertFromHTML, Modifier } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FieldRenderProps } from 'react-final-form';
import { makeStyles, Theme } from '@material-ui/core';
import { toolbarConfig } from './WysiwygConfig';
import draftToHtml from 'draftjs-to-html';
import useDebounce from '../../../helpers/useDebounce';

const DEBOUNCE_TIME = 400;

type Props = EditorProps & FieldRenderProps<any, any> & { minHeight?: number };

const useStyles = makeStyles((theme: Theme) => {
  return {
    input: (props: { minHeight?: number }) => ({
      border: '1px solid #F1F1F1',
      padding: '0px 5px',
      height: `${props.minHeight || 150}px!important`,
    }),
  };
});

const getInitialEditorState = (content: ContentState, autoFocus: boolean) => {
  const editorStateWithContent = EditorState.createWithContent(content);
  return autoFocus ? EditorState.moveFocusToEnd(editorStateWithContent) : editorStateWithContent;
};

const Wysiwyg = ({ input: { value, onChange }, autoFocus, ...props }: Props) => {
  const { contentBlocks, entityMap } = convertFromHTML(value || '');
  const initialState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  const [editorState, setEditorState] = React.useState<EditorState>(
    getInitialEditorState(initialState, autoFocus)
  );
  const [debounceState] = useDebounce(editorState, DEBOUNCE_TIME);
  const raw = convertToRaw(debounceState.getCurrentContent());
  const classes = useStyles(props);

  useEffect(() => {
    const value = draftToHtml(raw);

    onChange(value.replaceAll('\n', ''));
  }, [raw, onChange]);

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={(newState) => setEditorState(newState)}
      editorClassName={classes.input}
      localization={{
        locale: 'nl',
      }}
      {...props}
      toolbar={toolbarConfig}
      // stripPastedStyles
      handlePastedText={(text, html, editorState, onChange) => {
        const isHTML = Array.isArray(text.match(/<[a-zA-Z]>/g));

        // Allow pasting HTML
        if (isHTML) {
          // const blockMap = stateFromHTML(text.replace(/\\n/g,'<br />')).getBlockMap();
          const blocksFromHTML = convertFromHTML(text);
          const contentState = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );
          const newState = Modifier.replaceWithFragment(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            contentState.getBlockMap()
          );

          onChange(EditorState.push(editorState, newState, 'insert-fragment'));
          return true;
        }
        return false;
      }}
    />
  );
};

export default Wysiwyg;
