import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  flipBook: {
    display: 'none',
  },
  page: {
    position: 'relative',
  },
  pageShadow: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    boxShadow: 'inset 0px 0 30px 0px rgba(0, 0, 0, 0.4)',
  },
}));

export default useStyles;
