import React from 'react';
import { Trans } from 'react-i18next';
import {
  makeStyles,
  Theme,
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import ExternalLink from '../../app/components/ExternalLink';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';

type Props = {
  fullName: string;
  emailAddress: string;
  phoneNumber: string;
  descriptionI18nId: string;
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    icon: {
      minWidth: theme.spacing(5),
    },
    fullName: {
      marginBottom: theme.spacing(1),
    },
    descriptionParapgraph: {
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'EmployeeCard' }
);

const EmployeeCard = ({ fullName, emailAddress, phoneNumber, descriptionI18nId }: Props) => {
  const classes = useStyles();

  return (
    <Box pr={3}>
      <Typography variant="h2" className={classes.fullName}>
        {fullName}
      </Typography>
      <List>
        <ListItem disableGutters dense>
          <ListItemIcon className={classes.icon}>
            <MailIcon />
          </ListItemIcon>
          <ListItemText
            primary={<ExternalLink href={`mailto:${emailAddress}`}>{emailAddress}</ExternalLink>}
          />
        </ListItem>
        <ListItem disableGutters dense>
          <ListItemIcon className={classes.icon}>
            <PhoneIcon />
          </ListItemIcon>
          <ListItemText
            primary={<ExternalLink href={`tel:${phoneNumber}`}>{phoneNumber}</ExternalLink>}
          />
        </ListItem>
      </List>
      <Box pt={1}>
        <Trans
          i18nKey={`contact_page_employee_description_${descriptionI18nId}`}
          components={[<Typography className={classes.descriptionParapgraph} />]}
        />
      </Box>
    </Box>
  );
};

export default EmployeeCard;
