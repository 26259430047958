import React, { useEffect, useState } from 'react';
import Mousetrap from 'mousetrap';
import moment from 'moment';
import { Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';

const AppInfo = () => {
  const [infoModal, showInfoModal] = useState(false);
  useEffect(() => {
    const keys = 'command+shift+k';

    Mousetrap.bind(keys, () => {
      showInfoModal(!infoModal);
    });

    return () => Mousetrap.unbind(keys);
  }, [infoModal]);

  if (!infoModal) {
    return null;
  }

  return (
    <Dialog open={infoModal} onClose={() => showInfoModal(false)}>
      <DialogTitle>Application Info</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle2">Front-end</Typography>
        <Typography>Version: {process.env.REACT_APP_VERSION}</Typography>
        <Typography>
          Build date: {moment(process.env.REACT_APP_BUILD_TIME, 'X').format('DD/MM/YYYY HH:mm')}
        </Typography>
        <Typography variant="caption" style={{ marginTop: 40 }}>
          A product of Meta4Books <br />
          Developed by Calibrate NV
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default AppInfo;
