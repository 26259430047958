import React from 'react';
import { Form } from 'react-final-form';
import noop from 'lodash/noop';
import { Dialog, Theme, Typography } from '@material-ui/core';
import { Organisation } from '../../../../config/api/types';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '../../../../components/Button';
import { useTranslation } from 'react-i18next';
import { validateDistributorCode, formatDistributorCode } from '../../domain';
import Field from '../../../../components/Form';
import { intersection } from 'ramda';
import { ReduxFetch } from 'react-redux-fetch';
import { ApiConfig } from '../../api';
import { getLink } from '../../../../helpers/hateoas';
import { styled } from '@material-ui/styles';
import { ROLE } from '../../../security/model';
import finalFormAsyncValidation from '../../../../helpers/finalFormAsyncValidation';

type Props = {
  roles: string[];
  onClose: () => void;
  onSubmit: () => void;
  organisation: Organisation;
};

type FormValues = {
  code?: string;
};

const rolesWithAdditionalRequirements = [ROLE.DISTRIBUTOR];
let queue: Array<((...args: any[]) => void) | undefined> = [];

const processNextItem = () => {
  if (queue.length > 0) {
    const fn = queue.pop();
    if (fn) {
      fn();
    }
  }
};

type StyledProps = {
  theme: Theme;
};

const Space = styled('div')(({ theme }: StyledProps) => ({
  marginTop: theme.spacing(2),
}));

const RoleConfirmationForm = ({ roles, onClose, organisation, onSubmit }: Props) => {
  const { t } = useTranslation();
  const hasExtraFields = intersection(roles, rolesWithAdditionalRequirements).length > 0;
  const hasDistributorRole = roles.includes(ROLE.DISTRIBUTOR);
  const { createSubmissionPromise, handleFulfil, handleReject } = finalFormAsyncValidation(
    'distributorFetch'
  );

  return (
    <Dialog onClose={onClose} open>
      <ReduxFetch
        config={[
          ApiConfig.distributor(getLink(organisation, 'distributor') || ''),
          ApiConfig.publisher(getLink(organisation, 'publisher') || ''),
          ApiConfig.library(getLink(organisation, 'library') || ''),
          ApiConfig.bookstore(getLink(organisation, 'bookstore') || ''),
          ApiConfig.dataConsumer(getLink(organisation, 'dataConsumer') || ''),
          ApiConfig.dataProducer(getLink(organisation, 'dataProducer') || ''),
        ]}
        onFulfil={() => {
          if (queue.length === 0) {
            onSubmit();
            handleFulfil('distributorFetch');
          } else {
            processNextItem();
          }
        }}
        onReject={handleReject}
      >
        {({
          distributorFetch,
          publisherFetch,
          libraryFetch,
          bookstoreFetch,
          dataConsumerFetch,
          dataProducerFetch,
          dispatchDistributorPost,
          dispatchPublisherPost,
          dispatchLibraryPost,
          dispatchBookstorePost,
          dispatchDataConsumerPost,
          dispatchDataProducerPost,
        }) => (
          <Form
            onSubmit={(values) => {
              const formValues = values as FormValues;

              queue = roles.map((role) => {
                switch (role) {
                  case ROLE.DISTRIBUTOR:
                    if (formValues.code) {
                      return () =>
                        dispatchDistributorPost({ code: formatDistributorCode(formValues.code) });
                    }
                    return noop;
                  case ROLE.PUBLISHER:
                    return dispatchPublisherPost;
                  case ROLE.LIBRARY:
                    return dispatchLibraryPost;
                  case ROLE.BOOKSTORE:
                    return dispatchBookstorePost;
                  case ROLE.DATA_CONSUMER:
                    return dispatchDataConsumerPost;
                  case ROLE.DATA_PRODUCER:
                    return dispatchDataProducerPost;
                  default:
                    return noop;
                }
              });

              processNextItem();

              return createSubmissionPromise();
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <DialogTitle id="alert-dialog-title">
                  {t('form_roles_confirmation_heading', { organisation: organisation.name })}
                </DialogTitle>
                <DialogContent>
                  <Typography gutterBottom>
                    {t(hasExtraFields ? 'form_roles_warning' : 'form_roles_warning_empty', {
                      organisation: organisation.name,
                    })}
                  </Typography>
                  <Space />
                  {hasDistributorRole && (
                    <Field
                      label={t('form_distributor_code')}
                      type="text"
                      name="code"
                      helperText={t('form_distributor_code_help')}
                      format={formatDistributorCode}
                      validate={(value: string) =>
                        validateDistributorCode(formatDistributorCode(value))
                          ? t('form_distributor_code_help')
                          : ''
                      }
                      autoComplete="off"
                    />
                  )}
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={onClose}
                    variant="outlined"
                    color="default"
                    disabled={distributorFetch.pending}
                  >
                    {t('form_confirm_disagree')}
                  </Button>
                  <Button
                    color="primary"
                    autoFocus
                    type="submit"
                    disabled={
                      distributorFetch.pending ||
                      publisherFetch.pending ||
                      libraryFetch.pending ||
                      bookstoreFetch.pending ||
                      dataConsumerFetch.pending ||
                      dataProducerFetch.pending
                    }
                  >
                    {t('form_confirm_agree')}
                  </Button>
                </DialogActions>
              </form>
            )}
          </Form>
        )}
      </ReduxFetch>
    </Dialog>
  );
};

export default RoleConfirmationForm;
