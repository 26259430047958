import React from 'react';
import { TableCell, TableCellProps } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

type Props = TableCellProps & {
  className: string;
  data: React.ReactChild[];
};

const ArrayCell = ({ className, data }: Props) => {
  return (
    <TableCell className={className}>
      {data &&
        data.map((item, index) => (
          <Typography key={`array_cell_${typeof item === 'string' ? item : index}`} variant="body2">
            {item}
          </Typography>
        ))}
    </TableCell>
  );
};

export default ArrayCell;
