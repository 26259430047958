import React, { useState, useEffect } from 'react';
import Button from '../../../../../components/Button';
import RemoteAutocomplete from '../../../../../components/Form/RemoteAutocomplete';
import { getOrganisationsSearchRequest, getBookstoreRequest } from '../../../api';
import { useFetch } from '@react-redux-fetch/hooks';
import { useTranslation } from 'react-i18next';
import { updateQueryParameters, getLink } from '../../../../../helpers/hateoas';
import useApiRoute from '../../../../../config/api/useApiRoute';
import Field from '../../../../../components/Form';
import { ROLE } from '../../../../security/model';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { getOrganisations } from '../../../selectors';
import { Grid } from '@material-ui/core';
import { getBookstore } from '../../selectors';

export type FormValues = {
  bookstore: string;
};

type Props = {
  onClose: () => void;
  onSubmit: (formValues: FormValues) => void;
};

const FavoriteBookstoreForm = ({ onClose, onSubmit }: Props) => {
  const { t } = useTranslation();
  const [selectedBookstore, setSelectedBookstore] = useState<string>('');
  const organisationsPath = useApiRoute('organisations');
  const [bookstoresRequest, bookstoresFetch] = useFetch(getOrganisationsSearchRequest);
  const [, bookstoreFetch] = useFetch(getBookstoreRequest);
  const bookstores = useSelector(getOrganisations);
  const bookstore = useSelector(getBookstore(selectedBookstore));

  const hasSelectedBookstore = Boolean(
    bookstore && selectedBookstore && selectedBookstore === getLink(bookstore, 'self')
  );

  useEffect(() => {
    if (hasSelectedBookstore) {
      onSubmit({ bookstore: bookstore!.bookstoreId });
      setSelectedBookstore('');
    }
  }, [bookstore, onSubmit, hasSelectedBookstore]);

  const options =
    bookstores?._embedded.items.map((bookstore) => ({
      value: getLink(bookstore, 'bookstore') || '',
      label: bookstore.name,
    })) || [];

  return (
    <Form
      initialValues={{ bookstore: '' }}
      // keepDirtyOnReinitialize
      onSubmit={(values) => {
        setSelectedBookstore(values.bookstore);
        bookstoreFetch(values.bookstore);
      }}
    >
      {({ handleSubmit, submitting, values, form: { reset } }) => (
        <form
          onSubmit={(event) => {
            handleSubmit(event);
            reset();
          }}
        >
          <Grid container direction="column">
            <Grid item xs={4} sm={3}>
              {organisationsPath ? (
                <RemoteAutocomplete
                  label={t('form_favorite_bookstore')}
                  name="bookstore"
                  createUrlFromInput={(term) =>
                    updateQueryParameters(organisationsPath, {
                      name: term,
                      role: ROLE.BOOKSTORE,
                      is_member: 'true',
                    })
                  }
                  promiseState={bookstoresRequest}
                  makeRequest={bookstoresFetch}
                  parse={(option) => option.value}
                  options={options}
                  format={(value) => options.filter((option) => option.value === value)}
                />
              ) : (
                <Field label={t('form_favorite_bookstore')} type="text" name="bookstore" disabled />
              )}
            </Grid>
            <Grid item container xs={4} sm={3} justify="space-between">
              <Grid item>
                <Button type="submit" disabled={submitting || !values.bookstore}>
                  {t('form_action_save')}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" color="default" disabled={submitting} onClick={onClose}>
                  {t('form_action_cancel')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Form>
  );
};

export default FavoriteBookstoreForm;
