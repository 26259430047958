import React, { useState } from 'react';
import Button, { ButtonProps } from './Button';
import { Menu } from '@material-ui/core';

type Props = ButtonProps & {
  label: string;
  closeOnChildClick?: boolean;
};

const DropdownButton = ({ children, label, closeOnChildClick, ...rest }: Props) => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  return (
    <>
      <Button onClick={handleClick} {...rest}>
        {label}
      </Button>
      <Menu
        open={Boolean(anchor)}
        onClose={handleClose}
        anchorEl={anchor}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        keepMounted
        onClick={closeOnChildClick ? handleClose : () => null}
      >
        {children}
      </Menu>
    </>
  );
};

export default DropdownButton;
