import { FetchConfig } from '@react-redux-fetch/core';
import { Data } from '../../../../config/store/types';
import apiRoutes from '../../../../config/api/routes';
import { RetourDetail } from '../../../../config/store/retour';

type RetourRequest = {
  id?: string;
  bookstoreOrganisationId: string;
};

export const getRetourRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    requestKey: 'getUnconfirmedRetour',
    repository: {
      unconfirmedRetour: (prev, next?: RetourDetail) => {
        if (next?.confirmed) return null;
        return next;
      },
    },
  };
};

export const createRetourRequest = (url: string, body: RetourRequest): FetchConfig<Data> => {
  return {
    method: 'POST',
    url: apiRoutes.create(url),
    requestKey: 'unconfirmedRetour',
    fetchOptions: {
      body,
    },
    repository: {
      unconfirmedRetour: (prev, next) => next,
    },
  };
};

export const getDeleteRetourLineRequest = (url: string, id: string): FetchConfig<Data> => {
  return {
    method: 'DELETE',
    url: apiRoutes.create(url),
    repository: {
      unconfirmedRetour: (prev: RetourDetail): RetourDetail => {
        return {
          ...prev,
          lines: prev.lines.filter((line) => line.id !== id),
        };
      },
    },
  };
};
