import { Themas } from '../../../../config/api/models/dataSets';
import {
  Award,
  EBookType,
  Maybe,
  MediaCover,
  MediaSample,
  Organisation,
  ProductForm,
  Title,
  TitleFull,
} from '../../../../config/api/types';
import { isEBook, isNonBook, isPhysicalBook } from '../../domain';

type SectionScore = 0 | 50 | 100;

export type SectionTitle =
  | 'gtin13'
  | 'data'
  | 'format'
  | 'bibliographic'
  | 'contributors'
  | 'commercial'
  | 'texts'
  | 'targetAudience'
  | 'mediaFiles'
  | 'thema'
  | 'relations'
  | 'promotion'
  | 'education';

export type SelectedTitle = {
  value?: string;
  label?: string;
  title?: Title;
};

export type FormValues = Partial<Omit<TitleFull, 'keywords' | 'titleLiteraturePrizes'>> & {
  awards?: Award[];
  keywords?: { label: string; value: string }[];
  selectedProductForm?: ProductForm;
  selectedEBookType?: EBookType;
  selectedProductFormDetails?: {
    code: string;
    label: string;
  }[];
  selectedSupplyDate?: string;
  publisherName?: string;
  selectedPublisher?: Organisation;
  priceDescription?: string;
  mediaFrontCover?: MediaCover;
  mediaBackCover?: MediaCover;
  mediaSample?: MediaSample;
  selectedReplacementOf?: SelectedTitle;
  selectedReplacedBy?: SelectedTitle;
  selectedTitleInOtherLanguages?: SelectedTitle[];
  themas?: Themas;
};

export const gtin13Fields = ['gtin13'] as const;
export const dataFields = ['title', 'subtitle', 'collection.title', 'collection.number'] as const;
export const formatFields = [
  'productForm',
  'eBookType',
  'numPages',
  'height',
  'width',
  'thickness',
  'weight',
  'duration',
  'productForm.additionalDescription',
  'illustrationsNote',
  // formfields
  'selectedProductForm',
  'selectedEBookType.code',
] as const;
export const bibliographicFields = [
  'publisher',
  'imprint',
  'isbnFirstPublished',
  'editionNumber',
  'editionDescription',
  'languages',
  'editionTypes',
  'legalDepotNumber',
  'isBibliographicalVerified',
  'isGprc',
] as const;
export const commercialFields = [
  'availability',
  'orderTime',
  'fund',
  'productForm.taxRate',
  'productForm.taxRate.percentageAtHighRate',
  'price',
  'priceIsFreeOfCharge',
  'priceIsToBeAnnounced',
  'discountCode',
  'priceAction.price',
  'priceAction.startDate',
  'priceAction.endDate',
  'priceRegulated.price',
  'priceRegulated.startDate',
  'priceRegulated.endDate',
  'priceRegulatedAction.price',
  'priceRegulatedAction.startDate',
  'priceRegulatedAction.endDate',
  'hasInformationRestriction',
  'hasOrderRestriction',
  // formfields
  'priceDescription',
  'productForm.taxRate.code',
  'discountCode.code',
  'selectedSupplyDate',
] as const;
export const textsFields = ['flapCopy', 'promotionalHeadline', 'annotation'] as const;
export const targetAudienceFields = [
  'ageRange.from',
  'ageRange.to',
  'aviLevelsOld',
  'aviLevelsNew',
] as const;

export const relationFields = [
  'replacedBy',
  'replacementOf',
  'selectedReplacedBy',
  'selectedReplacementOf',
  'workFirstPublished',
  'nstc',
  'selectedTitleInOtherLanguages',
] as const;

export const themaFields = ['nur', 'themas', 'themaQualifiers', 'keywords'] as const;

export const promotionFields = ['feature', 'awards'] as const;
export const awardFields = '';

export const educationFields = ['partTitle', 'sectionTitle'] as const;

export const contributorFields = ['contributors'] as const;

export const fieldsPerSection: Record<SectionTitle, any> = {
  gtin13: gtin13Fields,
  data: dataFields,
  format: formatFields,
  bibliographic: bibliographicFields,
  contributors: contributorFields,
  commercial: commercialFields,
  texts: textsFields,
  mediaFiles: [],
  targetAudience: targetAudienceFields,
  relations: relationFields,
  thema: themaFields,
  promotion: promotionFields,
  education: educationFields,
};

export const legalDepotNumberRegex = /^D\/[0-9]{4}\//;

export const isValidLegalDepotNumber = (editionType: string = ''): boolean => {
  return Boolean(editionType.match(legalDepotNumberRegex));
};

export const showEPubField = (selectedProductForm: Maybe<ProductForm>) => {
  return selectedProductForm?.details?.some(
    (productFormDetail) => productFormDetail.code === 'E101'
  );
};
export const showKindleField = (selectedProductForm: Maybe<ProductForm>) => {
  return selectedProductForm?.details?.some(
    (productFormDetail) => productFormDetail.code === 'E116'
  );
};

export const showProductFormDetailsField = (selectedProductForm: Maybe<ProductForm>) => {
  return selectedProductForm?.code !== 'EA';
};

export const showPagesField = (selectedProductForm: Maybe<ProductForm>) => {
  return selectedProductForm && selectedProductForm.book;
};

export const showDimensionFields = (selectedProductForm: Maybe<ProductForm>) => {
  return selectedProductForm && !selectedProductForm.digital;
};

export const showDurationField = (selectedProductForm: Maybe<ProductForm>) => {
  return selectedProductForm && selectedProductForm.audiovisual;
};

export const sectionHasErrors = (section: SectionTitle, errors?: string[]): boolean => {
  if (!errors) {
    return false;
  }
  return errors.some((error) => fieldsPerSection[section].includes(error));
};

export const calculateSectionScore = (
  section: SectionTitle,
  formData: FormValues
): SectionScore => {
  switch (section) {
    case 'gtin13':
      return formData.gtin13 ? 100 : 0;
    case 'data':
      return formData.title ? 100 : 0;
    case 'format':
      if (isPhysicalBook(formData.selectedProductForm)) {
        if (
          formData.numPages &&
          formData.height &&
          formData.width &&
          formData.thickness &&
          formData.weight
        ) {
          return 100;
        }
      }
      if (isEBook(formData.selectedProductForm)) {
        if (formData.numPages) {
          return 100;
        }
      }
      if (isNonBook(formData.selectedProductForm)) {
        if (formData.height && formData.width && formData.thickness && formData.weight) {
          return 100;
        }
      }
      return formData.selectedProductForm?.code ? 50 : 0;
    case 'bibliographic':
      if (
        formData.publisher &&
        formData.editionNumber &&
        formData.languages &&
        formData.languages.length > 0 &&
        formData.isbnFirstPublished &&
        formData.legalDepotNumber
      ) {
        return 100;
      } else if (formData.editionNumber || (formData.languages && formData.languages.length > 0)) {
        return 50;
      }
      return 0;
    case 'contributors':
      return formData.contributors && formData.contributors.length > 0 ? 100 : 0;
    case 'commercial':
      if (
        formData.availability &&
        formData.fund &&
        formData.productForm?.taxRate?.code &&
        formData.price &&
        formData.discountCode?.code
      ) {
        if (['31', '32'].includes(formData.availability.code) && !formData.selectedSupplyDate) {
          return 50;
        }
        return 100;
      }

      if (
        formData.availability ||
        formData.discountCode?.code ||
        formData.price ||
        formData.priceIsFreeOfCharge ||
        formData.priceIsToBeAnnounced
      ) {
        return 50;
      }
      return 0;
    case 'texts':
      const texts = [formData.flapCopy, formData.promotionalHeadline, formData.annotation].filter(
        Boolean
      );
      if (texts.length === 3) {
        return 100;
      }
      if (texts.length === 0) {
        return 0;
      }
      return 50;
    case 'mediaFiles':
      const hasMediaSample = Boolean(formData.mediaSample && formData.mediaSample.original);
      const hasMediaFrontCover = Boolean(
        formData.mediaFrontCover && formData.mediaFrontCover.thumbnail
      );
      const hasMediaBackCover = Boolean(
        formData.mediaBackCover && formData.mediaBackCover.thumbnail
      );
      if (isPhysicalBook(formData.selectedProductForm) || isEBook(formData.selectedProductForm)) {
        if (hasMediaSample && hasMediaFrontCover && hasMediaBackCover) {
          return 100;
        }
        if (hasMediaSample || hasMediaFrontCover || hasMediaBackCover) {
          return 50;
        }
      } else if (isNonBook(formData.selectedProductForm)) {
        if (hasMediaFrontCover) {
          return 100;
        }
      }
      return 0;
    case 'targetAudience':
      if (formData.ageRange?.from?.qualifier || formData.ageRange?.to?.qualifier) {
        return 100;
      }
      if (formData.aviLevelsOld?.length || formData.aviLevelsNew?.length) {
        return 50;
      }
      return 0;
    case 'relations':
      if (formData.selectedReplacementOf?.value || formData.selectedReplacedBy?.value) {
        return 100;
      }
      if (formData.originalLanguage || formData.originalTitle) {
        return 50;
      }
      return 0;
    case 'thema':
      if (formData.themas?.length) {
        return 100;
      }
      if (formData.nur) {
        return 50;
      }
      return 0;
    case 'promotion':
      if (formData.feature && formData.awards && formData.awards?.length > 0) {
        return 100;
      }
      if (formData.feature || (formData.awards && formData.awards?.length > 0)) {
        return 50;
      }
      return 0;
    case 'education':
      if (formData.partTitle && formData.sectionTitle) {
        return 100;
      }
      if (formData.partTitle || formData.sectionTitle) {
        return 50;
      }
      return 0;
    default:
      return 0;
  }
};
