import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AppBar from '../../../components/AppBar';
import { makeStyles, Theme, Box, Toolbar } from '@material-ui/core';
import { ReactComponent as BoekenbankLogo } from '../../../config/assets/pictures/boekenbank_logo2.svg';
import Button from '../../../components/Button';
import usePath from '../../../config/routes/usePath';
import ROUTE_KEY from '../../../config/routes/routeKeys';
import EnvLabel from './EnvLabel';

const useStyles = makeStyles(
  (theme: Theme) => ({
    envLabel: {
      marginRight: theme.spacing(3),
    },
  }),
  { name: 'PublicHeader' }
);

const PublicHeader: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isbnApplicationPath = usePath(ROUTE_KEY.ISBN_APPLICATION, { isbn: '' });

  return (
    <AppBar>
      <Toolbar>
        <EnvLabel className={classes.envLabel} />
        <Box flexGrow={1} justifyContent="flex-start">
          <Box width={102} height={64}>
            <Link to="/login">
              <BoekenbankLogo />
            </Link>
          </Box>
        </Box>
        {isbnApplicationPath && (
          <Button variant="text" link={isbnApplicationPath}>
            {t('nav_isbn_application')}
          </Button>
        )}
        <Button variant="text" link="/login">
          {t('nav_login')}
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default PublicHeader;
