import * as React from 'react';
import { useTranslation } from 'react-i18next';
import FieldsetTitle from '../FieldsetTitle';
import { TitleFull } from '../../../../../../config/api/types';
import SectionWrapper from '../SectionWrapper';
import Fieldset from '../Fieldset';
import { Grid } from '@material-ui/core';
import LongTextField from '../../containers/fields/LongTextField';
import { FormApi } from 'final-form';
import TextsField from './TextsField';
import { FormValues } from '../../domain';
import InfoTooltip from '../../../../../../components/InfoTooltip';

type Props = {
  flapCopy: TitleFull['flapCopy'];
  promotionalHeadline: TitleFull['promotionalHeadline'];
  annotation: TitleFull['annotation'];
  onChangeFormValue: (name: string, value?: any) => void;
  onSubmit?: FormApi['submit'] | Function;
  errors?: FormValues;
};

const Texts = ({
  flapCopy,
  promotionalHeadline,
  annotation,
  onChangeFormValue,
  onSubmit,
  errors,
}: Props) => {
  const { t } = useTranslation();

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <SectionWrapper id="texts">
      <FieldsetTitle>{t('title_section_texts')}</FieldsetTitle>
      <Fieldset>
        <Grid container spacing={3}>
          <TextsField
            component={LongTextField}
            onSubmit={handleSubmit}
            label={
              <>
                {t('title_flapCopy')} <InfoTooltip title={t('title_flapCopy_tooltip')} inline />
              </>
            }
            name="flapCopy"
            text={flapCopy}
            onChangeFormValue={onChangeFormValue}
            error={errors?.flapCopy}
            maxWidth="md"
            minHeight={400}
          />
          <TextsField
            component={LongTextField}
            onSubmit={handleSubmit}
            label={
              <>
                {t('title_promotionalHeadline')}{' '}
                <InfoTooltip title={t('title_promotionalHeadline_tooltip')} inline />
              </>
            }
            name="promotionalHeadline"
            text={promotionalHeadline}
            onChangeFormValue={onChangeFormValue}
            error={errors?.promotionalHeadline}
          />
          <TextsField
            component={LongTextField}
            onSubmit={handleSubmit}
            label={
              <>
                {t('title_annotation')} <InfoTooltip title={t('title_annotation_tooltip')} inline />
              </>
            }
            name="annotation"
            text={annotation}
            onChangeFormValue={onChangeFormValue}
            error={errors?.annotation}
          />
        </Grid>
      </Fieldset>
    </SectionWrapper>
  );
};

export default Texts;
