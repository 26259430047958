import { Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Title } from '../../../../../config/api/types';
import { updateQueryParameters } from '../../../../../helpers/hateoas';
import { getContributorsList } from '../../../domain';

type Props = {
  title: Title;
  className?: string;
  changeParams?: (values: Record<string, string | number>) => void;
  isDetail?: boolean;
};

export const TitleContributors: FC<Props> = ({ title, className, isDetail, changeParams }) => {
  const { search } = useLocation();
  const { t } = useTranslation();

  return (
    <Typography variant="body2" component="h4" className={className}>
      {t('title_contributors')}
      {getContributorsList(title).map(({ name, label, role }, index) => (
        <React.Fragment key={label}>
          {index !== 0 ? ', ' : ' '}
          <Link
            to={{
              pathname: '/titels',
              search: updateQueryParameters(search, {
                'contributors.name': name.trim(),
                q: '',
              }),
              ...(isDetail && {
                state: {
                  from: 'detail',
                },
              }),
            }}
            style={{ color: 'inherit' }}
            onClick={() => {
              changeParams?.({ page: 1 });
              window.scrollTo(0, 0);
            }}
          >
            {name}{' '}
            <SearchIcon
              style={{
                fontSize: '1rem',
                position: 'relative',
                top: 2,
                left: -2,
              }}
            />
          </Link>
          {role && ` (${role})`}
        </React.Fragment>
      ))}
    </Typography>
  );
};
