import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../../../../helpers/useFetch';
import { ProductForm, TaxRateCode, TaxRates } from '../../../../../../config/api/types';
import { getTaxRequest } from '../../api';
import { MenuItem } from '@material-ui/core';
import Field from '../../../../../../components/Form';
import WhenFieldChanges from '../../../../../../components/Form/WhenFieldChanges';
import CommercialField from '../../components/sections/CommercialField';
import InfoTooltip from '../../../../../../components/InfoTooltip';

type Props = {
  url: string;
  selectedProductFormTaxRateCode?: TaxRateCode;
  otherTaxRateAllowed?: boolean;
};

const TaxField = ({ url, selectedProductFormTaxRateCode, otherTaxRateAllowed }: Props) => {
  const { t } = useTranslation();
  const [taxRatesFetch, fetchTaxRates] = useFetch<TaxRates>(getTaxRequest(url));

  useEffect(() => {
    if (!taxRatesFetch) {
      fetchTaxRates();
    }
  }, [taxRatesFetch, fetchTaxRates]);

  const taxRates = (taxRatesFetch && taxRatesFetch.value) || [];

  const filteredTaxRates = otherTaxRateAllowed
    ? taxRates
    : (taxRates || []).filter(
        (rate) => rate.code === selectedProductFormTaxRateCode || rate.code === 'C'
      ); // #74795

  return (
    <>
      <CommercialField
        component={Field}
        label={
          <>
            {t('title_taxRate_code')} <InfoTooltip title={t('title_taxRate_code_tooltip')} inline />
          </>
        }
        select
        type="select"
        name="productForm.taxRate.code"
      >
        {otherTaxRateAllowed && <MenuItem>&nbsp;</MenuItem>}
        {filteredTaxRates.map((taxRate) => (
          <MenuItem key={taxRate.code} value={taxRate.code}>
            {taxRate.label}
          </MenuItem>
        ))}
      </CommercialField>

      {filteredTaxRates.length && (
        <WhenFieldChanges
          field="selectedProductForm"
          set="productForm.taxRate"
          to={(value: ProductForm) => {
            return value.taxRate || null;
          }}
        />
      )}
    </>
  );
};

/*
          console.log(taxRate, value.taxRate, value.otherTaxRateAllowed, taxRates);
          const filteredTaxRates = (value.otherTaxRateAllowed
            ? taxRates
            : (taxRates || []).filter(
                rate => (value.taxRate && rate.code === value.taxRate.code) || rate.code === 'C'
              )
          ).map(t => t.code);
          console.log(filteredTaxRates);
          return taxRate && filteredTaxRates.includes(taxRate.code) ? taxRate : value.taxRate;

          // if (taxRate) {
          //   if (taxRate.code !== 'C') {
          //     return value.taxRate || null;
          //   }
          //   return taxRate;
          // }
 */

export default TaxField;
