import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { PromiseState } from 'react-redux-fetch';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Grid from '@material-ui/core/Grid/Grid';

type Props = {
  fetchObj?: PromiseState<any>;
  delay?: number;
};

const DelayedLoaderForFetch: FC<Props> = ({ children, fetchObj, delay = 50 }) => {
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowLoading(true), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [delay]);

  if (!showLoading) {
    return null;
  }

  if (showLoading && fetchObj && fetchObj.pending && !fetchObj.value) {
    return (
      <Grid container justify="center" alignItems="center" style={{ marginTop: 20 }}>
        <CircularProgress />
      </Grid>
    );
  }

  return <>{children}</>;
};

export default DelayedLoaderForFetch;
