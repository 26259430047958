import React, { useState, FC } from 'react';
import { IconButton, IconButtonProps, Tooltip, ClickAwayListener, Box } from '@material-ui/core';
import CopyIcon from 'mdi-material-ui/ContentCopy';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import AlertMessage from '../../core/alerts/components/AlertMessage';

type Props = IconButtonProps & {
  textToCopy: string;
  tooltipText: string;
  withMargin?: boolean;
};

const CopyToClipboardButton: FC<Props> = ({
  textToCopy,
  tooltipText,
  withMargin = true,
  ...props
}) => {
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation();

  const handleTooltipClose = () => {
    setCopied(false);
  };

  const handleTooltipOpen = () => {
    setCopied(true);
  };

  const button =
    props.children != null ? (
      <Button
        variant={'outlined'}
        onClick={() => {
          navigator.clipboard.writeText(textToCopy);
          handleTooltipOpen();
        }}
        style={withMargin ? { marginBottom: '3rem' } : {}}
      >
        <Box mr={1} display="flex" flexDirection="column" justifyContent="center">
          <CopyIcon fontSize="inherit" />
        </Box>
        {props.children}
      </Button>
    ) : (
      <IconButton
        size="small"
        {...props}
        onClick={() => {
          navigator.clipboard.writeText(textToCopy);
          handleTooltipOpen();
        }}
      >
        <CopyIcon fontSize="inherit" />
      </IconButton>
    );

  return (
    <>
      <AlertMessage
        variant="success"
        open={copied}
        onClose={handleTooltipClose}
        message={t('clipboard_copied_notistack')}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
      {copied ? (
        <ClickAwayListener onClickAway={handleTooltipClose} mouseEvent="onMouseDown">
          <Tooltip
            arrow
            placement="bottom"
            title={t('clipboard_copied')}
            onClose={handleTooltipClose}
            open={copied}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            {button}
          </Tooltip>
        </ClickAwayListener>
      ) : (
        <Tooltip arrow placement="bottom" title={tooltipText}>
          {button}
        </Tooltip>
      )}
    </>
  );
};

export default CopyToClipboardButton;
