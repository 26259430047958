import React, { FC } from 'react';
import ConstrainedPageContent from '../../../components/ConstrainedPageContent';
import { RetourDetail } from '../retourDetail/RetourDetail';
import { RouteComponentProps } from 'react-router';

type Props = RouteComponentProps<{ id: string }>;

export const RetourDetailPage: FC<Props> = ({ match, location }) => {
  const path = location.state?.from;

  return (
    <ConstrainedPageContent center={false}>
      <RetourDetail retourId={match.params.id} goBackPath={path} />
      {/*<OrderDetail orderId={match.params.id} />*/}
    </ConstrainedPageContent>
  );
};
