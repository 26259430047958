import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Theme, Box, Typography } from '@material-ui/core';
import formatEuro from '../../../../helpers/formatEuro';

type Props = {
  amount: number;
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    label: {
      marginRight: theme.spacing(1),
    },
    amount: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  }),
  { name: 'OrderLineTotal' }
);

const OrderLineTotal = ({ amount }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="flex-end" p={1}>
      <Typography className={classes.label}>{t('orderline_total')}</Typography>
      <Typography className={classes.amount}>{formatEuro(amount)}</Typography>
    </Box>
  );
};

export default OrderLineTotal;
