import * as React from 'react';
import { TextFieldProps } from '@material-ui/core/TextField';
import { FieldRenderProps } from 'react-final-form';
import { Theme } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { UI_DATE_FORMAT } from '../../helpers/date';

type Props = FieldRenderProps<any, any> &
  TextFieldProps &
  KeyboardDatePickerProps & {
    customError?: string;
    onBlur?: () => void;
    isClearable?: boolean;
    onClear?: () => void;
  };

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
  },
  label: {
    // color: theme.palette.text.primary,
    // fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
  icon: {
    padding: 4,
  },
  helperText: {
    // color: theme.palette.text.helpertext,
  },
  input: {
    padding: '10px 10px',
    background: 'white',
  },
  clearIconWrapper: {
    background: 'white',
    marginRight: '-1rem',
    height: 36,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  clearIcon: {
    color: theme.palette.grey['700'],
    padding: 5,
    fontSize: 26,
    '&:hover': {
      color: theme.palette.common.black,
      cursor: 'pointer',
    },
  },
}));

const toErrorString = (value: any): string => {
  if (!value || typeof value === 'string') {
    return value;
  }
  return 'error';
};

const DateField = ({
  input: { name, onChange, onBlur: inputBlur, value, ...restInput },
  meta,
  helperText,
  customError,
  label,
  disabled,
  onBlur,
  isClearable,
  onClear,
  ...rest
}: Props) => {
  const classes = useStyles();

  return (
    <>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <KeyboardDatePicker
        {...rest}
        disabled={disabled}
        // clearable
        fullWidth
        autoOk
        name={name}
        format={UI_DATE_FORMAT}
        helperText={toErrorString(customError || meta.error || meta.submitError || helperText)}
        error={
          (Boolean(meta.error) && meta.touched) || Boolean(meta.submitError) || Boolean(customError)
        }
        onChange={onChange}
        onError={() => {}}
        inputProps={{ ...(restInput as any), type: 'text' }}
        className={classes.root}
        // mask={input => (input ? [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/] : [])}
        value={value || null}
        variant="inline"
        inputVariant="outlined"
        onBlur={(event) => {
          if (onBlur) {
            onBlur(event);
          }
          inputBlur(event as any);
        }}
        KeyboardButtonProps={{
          classes: {
            root: classes.icon,
          },
        }}
        InputProps={{
          classes: {
            input: classes.input,
          },
          endAdornment:
            isClearable && value ? (
              <div
                className={classes.clearIconWrapper}
                onMouseDown={(e) => {
                  e.preventDefault();

                  onChange(null);
                  onClear?.();
                }}
              >
                <ClearIcon className={classes.clearIcon} />
              </div>
            ) : null,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        InputAdornmentProps={{ position: 'start' }}
        FormHelperTextProps={{
          classes: {
            root: classes.helperText,
          },
        }}
        margin="normal"
      />
    </>
  );
};

export default DateField;
