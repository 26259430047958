import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import MUITextField, { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField';
import { FieldRenderProps } from 'react-final-form';
import { Theme } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/styles';
import { InputProps } from '@material-ui/core/Input';
import { toErrorString } from './toErrorString';
import ClearIcon from '@material-ui/icons/Clear';

export type TextFieldProps = FieldRenderProps<any, any> &
  MuiTextFieldProps & {
    customError?: React.ReactNode;
    inputComponent?: InputProps['inputComponent'];
    onBlur?: () => void;
    isClearable?: boolean;
    onClear?: () => void;
  };

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
      '& .MuiSelect-select + input': {
        display: 'none',
      },
    },
    label: {
      color: theme.palette.text.primary,
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
    helperText: {
      color: theme.palette.text.helpertext,
    },
    input: {
      padding: '10px 10px',
      background: theme.palette.background.default,
    },
    inputMultiline: {
      paddingBottom: 9, // multiline TextFields get height set programmatically (inline style) so overriding previous padding override here so all textfields are similar, visually)
    },
    clearIcon: {
      color: theme.palette.grey['700'],
      padding: 5,
      fontSize: 26,
      '&:hover': {
        color: theme.palette.common.black,
        cursor: 'pointer',
      },
    },
    clearableInput: {
      padding: 0,
      backgroundColor: 'transparent',
    },
  }),
  { name: 'TextField' }
);

export const BaseTextField: FC<MuiTextFieldProps> = ({ label, className, ...other }) => {
  const classes = useStyles();
  return (
    <>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <MUITextField
        className={clsx(classes.root, className)}
        margin="normal"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        FormHelperTextProps={{
          classes: {
            root: classes.helperText,
          },
        }}
        variant="outlined"
        {...other}
        InputProps={{
          classes: {
            input: clsx(classes.input, other.multiline && classes.inputMultiline),
          },
          ...other.InputProps,
        }}
      />
    </>
  );
};

const TextField = ({
  input: { name, onChange, value, ...restInput },
  meta,
  helperText,
  customError,
  label,
  InputProps,
  inputComponent,
  select,
  onBlur,
  isClearable,
  onClear,
  validateOnChange,
  ...rest
}: TextFieldProps) => {
  const classes = useStyles();
  const submitError = !meta.dirtySinceLastSubmit && meta.submitError;
  /*
   * Because react-final-form rerenders everything on every keystroke, we keep the input state locally here.
   * Only when the user clicks away from the input field (= onBlur), the input value will bubble up (and causing to rerender only once).
   */
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleClear = (e: any) => {
    e.preventDefault();

    setLocalValue('');
    onChange('');
    if (onClear) {
      onClear();
    }
  };

  const clearButton = Boolean(isClearable && localValue) && (
    <ClearIcon onMouseDown={handleClear} className={classes.clearIcon} />
  );

  return (
    <BaseTextField
      label={label}
      {...rest}
      select={select}
      name={name}
      helperText={
        meta.touched && Boolean(meta.error)
          ? toErrorString(meta.error)
          : toErrorString(customError || submitError || helperText)
      }
      error={(Boolean(meta.error) && meta.touched) || Boolean(submitError) || Boolean(customError)}
      onChange={
        select
          ? onChange
          : (e) => {
              // Workaround for #156681 - FireFox autocomplete doesn't trigger a blur event,
              // so we check if a value changes more than 2 chars (which only happens when a field is programmatically filled in)
              if (e.currentTarget.value.length - localValue.length >= 2) {
                onChange(e);
              }
              setLocalValue(e.currentTarget.value);
            }
      }
      value={select ? value : localValue}
      InputProps={{
        inputProps: {
          ...(restInput as any),
          onChange: validateOnChange && onChange,
          onKeyDown: (e) => {
            if (e.keyCode === 13) {
              onChange(e);
            }
          },
          onBlur: (...args) => {
            onChange(...args);

            if (onBlur) {
              onBlur(...args);
            }
            if (restInput.onBlur) {
              restInput.onBlur(...(args as any));
            }
          },
        },
        inputComponent,
        endAdornment: clearButton,
        className: clearButton ? classes.clearableInput : '',
        ...(InputProps || {}),
      }}
    />
  );
};

export default TextField;
